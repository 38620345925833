import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import ThirdsProportion from '../ResultsDialogBox/ThirdsProportion';
import Angle from '../ResultsDialogBox/Angle';
import FaceType from '../ResultsDialogBox/FaceType';
import JawAngle from '../ResultsDialogBox/JawAngle';
import MouthToEye from '../ResultsDialogBox/MouthToEye';
import NoseToEye from '../ResultsDialogBox/NoseToEye';
import HorizontalPropotion from '../ResultsDialogBox/HorizontalPropotion';
import VerticalXHorizontal from '../ResultsDialogBox/VerticalXHorizontal';
import FacialOpening from '../ResultsDialogBox/FacialOpening';
import AngleEyebrow from '../ResultsDialogBox/AngleEyebrow';
import Error from '../ResultsDialogBox/Error';
import isEmpty from '../../../../../utils/isEmpty';
import { useStore } from '../../../../../hooks/useStore';
import { ANALYSIS_LIST } from '../../../../../constants/ToolsConstants';

/**
 *
 *
 * @param {Object} props
 * @param {Object[]} props.filteredDots
 * @param {number} props.width
 * @param {number} props.strokeWidth
 * @param {Object} props.analysis
 * @param {boolean} props.showText
 * @returns {FC || JSX.Element}
 */

const ErrorMessage = observer(({ id, text }) => {
  const history = useHistory();
  const store = useStore();

  const { id: patientId } = useParams();
  return (
    <div className='d-flex flex-column w-100'>
      <div className='d-flex flex-row justify-content-between mx-2'>
        <span className='flex-fill w-75 fs-7'>{text}</span>
      </div>
      <Button
        variant='link'
        className='text-white fw-bold'
        onClick={() => {
          store.toolsStore.setToolActive('analysis');
          // pega a imagem correta antes de voltar para a análise facial daquela imagem que ainda não a possui
          store.galleryStore.getImage(id).then(() => {
            history.push(`/patients/${patientId}/tools/${id}`);
          });
        }}
      >
        <span className='fs-6-5'>Ir para análise facial</span>
      </Button>
    </div>
  );
});

const WarningMessage = observer(() => (
  <div className='d-flex flex-column w-100  p-2 '>
    <div className='d-flex flex-row justify-content-between mx-2'>
      <span className='flex-fill w-75 fs-7 '>
        Análise não compatível com a categoria da foto
      </span>
    </div>
  </div>
));

const Results = (props) => {
  const {
    showText,
    analysis,
    analysisType,
    imageId,
    imageType,
    hasAnalysis,
    comparative,
  } = props;
  const [categoryType, setCategoryType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [mutateClass, setMutateClass] = useState('bg-dark-50');

  useEffect(() => {
    if (imageType === 'left' || imageType === 'right') {
      setCategoryType('side');
    } else {
      setCategoryType('front');
    }
  }, [imageType]);

  useEffect(() => {
    ANALYSIS_LIST.forEach((analysisList) => {
      // eslint-disable-next-line array-callback-return
      Object.values(analysisList).map((analysisListValues) => {
        // eslint-disable-next-line
        // array-callback-return
        analysisListValues.find((analisysListValue) => {
          if (analisysListValue.value === analysisType) {
            setSelectedCategory(Object.keys(analysisList)[0]);
            return '';
          }
          return '';
        });
      });
    });
  }, [analysisType]);

  const result = useMemo(() => {
    if (
      analysisType != null &&
      analysisType !== '' &&
      hasAnalysis &&
      selectedCategory === categoryType &&
      Object.values(analysis)?.length === 0 &&
      comparative
    ) {
      // INSERIR AQUI COMPONENTE QUE RENDERIZARÁ ERRO MEDIANTE ESSE COMPORTAMENTO
      setMutateClass('bg-red-50 opacity-60');
      return (
        <Error
          hasLabel={false}
          analysis={
            <ErrorMessage id={imageId} text='Faltam pontos para esta análise' />
          }
        />
      );
    }
    if (analysisType != null && analysisType !== '' && !hasAnalysis) {
      setMutateClass('bg-red-50 opacity-60');
      return (
        <Error
          hasLabel={false}
          analysis={
            <ErrorMessage id={imageId} text='Esta foto não possui análise' />
          }
        />
      );
    }

    if (
      analysisType &&
      hasAnalysis &&
      selectedCategory !== categoryType &&
      selectedCategory !== ''
    ) {
      setMutateClass('bg-warning');
      return <WarningMessage />;
    }

    if (analysis == null || isEmpty(analysis)) {
      return null;
    }

    if (analysis.error) {
      return <Error analysis={analysis} />;
    }

    setMutateClass('bg-dark-50  opacity-60');

    switch (analysisType) {
      case 'proportion_face_front':
      case 'proportion_lips_front':
      case 'bottom_third_proportion_front':
      case 'proportion_face_side':
      case 'proportion_lips_side':
      case 'bottom_third_proportion_side':
        return <ThirdsProportion analysis={analysis} showText={showText} />;
      case 'face_type':
        return <FaceType analysis={analysis} showText={showText} />;
      case 'vertical_vs_horizontal_facial':
        return <VerticalXHorizontal analysis={analysis} showText={showText} />;
      case 'chin_facial_opening':
      case 'glabella_facial_opening':
        return <FacialOpening analysis={analysis} showText={showText} />;
      case 'horizontal_proportion_third':
      case 'horizontal_proportion_fifth':
        return <HorizontalPropotion analysis={analysis} showText={showText} />;
      case 'eyebrow_angle':
        return <AngleEyebrow analysis={analysis} showText={showText} />;
      case 'nose_frontal_angle':
      case 'nose_lips_angle':
      case 'profile_type':
        return <Angle analysis={analysis} showText={showText} />;
      case 'jaw_angle':
        return <JawAngle analysis={analysis} showText={showText} />;
      case 'mouth_to_eyes':
        return <MouthToEye analysis={analysis} showText={showText} />;
      case 'nose_to_eyes':
        return <NoseToEye analysis={analysis} showText={showText} />;

      default:
        return null;
    }
  }, [
    analysisType,
    analysis,
    selectedCategory,
    categoryType,
    mutateClass,
    comparative,
  ]);
  return (
    <div
      className={clsx(
        'position-absolute top-xs-3 end-xs-3 fs-7 zi-9 rounded-3 ',
        mutateClass,
      )}
    >
      {result}
    </div>
  );
};

Results.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  analysisType: PropTypes.string,
  imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  imageType: PropTypes.string,
  hasAnalysis: PropTypes.bool,
  comparative: PropTypes.bool,
};

Results.defaultProps = {
  analysisType: null,
  showText: false,
  analysis: {},
  imageId: null,
  imageType: null,
  hasAnalysis: false,
  comparative: false,
};

ErrorMessage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ErrorMessage.defaultProps = {
  id: null,
};

export default observer(Results);
