import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useIsMobile, useStore } from '../../../../../hooks';
import NavbarHeaderMobile from '../../../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import NavbarHeader from '../../../../Styleguide/NavbarHeader/NavbarHeader';

const ComparativeStartNavbar = () => {
  const store = useStore();
  const history = useHistory();
  const {id: patientId} = useParams();

  const { firstImage } = useParams();
  const isMobile = useIsMobile();

  return useMemo(() => {
    if (isMobile) {
      return (
        <NavbarHeaderMobile
          hasGoBack
          content='title'
          title='Comparativo de fotos'
          subtitle={
            firstImage != null ? store.galleryStore.image?.fileName : null
          }
         
          goBackAction={() => history.push(`/patients/${patientId}/library`)}
        />
      );
    }
    return (
      <NavbarHeader
        hasTitle
        title={store.patientsStore.patientSelected?.name}
        content='nav'
        hasHelper
      />
    );
  }, [isMobile, store.galleryStore.image, store.patientsStore.patientSelected]);
};

export default observer(ComparativeStartNavbar);
