import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { MainLayout } from '../../../index';
import ComparativeStartNavbar from './ComparativeStartNavbar';

const ComparativeStartLayout = ({ children }) => (
  <MainLayout customNavBar={<ComparativeStartNavbar />}>{children}</MainLayout>
);

ComparativeStartLayout.propTypes = {
  children: PropTypes.node,
};
ComparativeStartLayout.defaultProps = {
  children: '',
};
export default observer(ComparativeStartLayout);
