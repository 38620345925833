import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import clsx from 'clsx';

// type = grow || border

const LoadingSpinner = ({ desc, type, size, variant, ...rest }) => (
  <div
    className={clsx(
      'mx-2 fs-6 d-flex align-items-center justify-content-between dl-loading-spinner ',
      variant ? `text-${variant}` : '',
    )}
  >
    <Spinner {...rest} animation={type} size={size} variant={variant} />
    <span className='px-1'>{desc}</span>
  </div>
);

export default LoadingSpinner;

LoadingSpinner.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  desc: PropTypes.string,
  variant: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  type: 'border',
  size: '',
  desc: 'Carregando...',
  variant: 'dark',
};
