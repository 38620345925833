const AdminUser = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5625 35.875C2.5625 35.875 0 35.875 0 33.3125C0 30.75 2.5625 23.0625 15.375 23.0625C28.1875 23.0625 30.75 30.75 30.75 33.3125C30.75 35.875 28.1875 35.875 28.1875 35.875H2.5625ZM15.375 20.5C17.4139 20.5 19.3692 19.6901 20.8109 18.2484C22.2526 16.8067 23.0625 14.8514 23.0625 12.8125C23.0625 10.7736 22.2526 8.8183 20.8109 7.37662C19.3692 5.93493 17.4139 5.125 15.375 5.125C13.3361 5.125 11.3808 5.93493 9.93912 7.37662C8.49743 8.8183 7.6875 10.7736 7.6875 12.8125C7.6875 14.8514 8.49743 16.8067 9.93912 18.2484C11.3808 19.6901 13.3361 20.5 15.375 20.5Z'
      fill={color}
    />
    <path
      d='M27.938 22.1694C27.6908 22.3047 27.4102 22.0676 27.4601 21.7649L27.9918 18.5328L25.735 16.2396C25.5242 16.0251 25.6337 15.6328 25.9163 15.5905L29.054 15.1149L30.4531 12.1582C30.5793 11.8917 30.9207 11.8917 31.0469 12.1582L32.446 15.1149L35.5837 15.5905C35.8663 15.6328 35.9758 16.0251 35.765 16.2396L33.5082 18.5328L34.0399 21.7649C34.0898 22.0676 33.8092 22.3047 33.562 22.1694L30.749 20.6278L27.938 22.1694Z'
      fill={color}
    />
  </svg>
);

export default AdminUser;
