const Warning = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 15 15'
    fill='none'
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.4416 1.88999C7.42016 1.90284 7.40265 1.92132 7.39097 1.94342L0.962533 12.8812C0.946519 12.9069 0.937873 12.9365 0.937543 12.9668C0.937212 12.997 0.945209 13.0268 0.960658 13.0528C0.975658 13.0809 0.995345 13.0997 1.01128 13.109C1.02535 13.1184 1.04316 13.125 1.07316 13.125H13.9291C13.9508 13.1258 13.9723 13.1202 13.991 13.109C14.0126 13.095 14.0304 13.0756 14.0425 13.0528C14.0578 13.0267 14.0657 12.9969 14.0652 12.9666C14.0647 12.9364 14.0559 12.9068 14.0397 12.8812L7.61222 1.94342C7.60054 1.92132 7.58303 1.90284 7.5616 1.88999C7.54322 1.87991 7.52255 1.87474 7.5016 1.87499C7.48064 1.87474 7.45997 1.87991 7.4416 1.88999V1.88999ZM8.42035 1.46811C8.32754 1.30645 8.19373 1.17214 8.03241 1.07874C7.8711 0.985341 7.688 0.936157 7.5016 0.936157C7.31519 0.936157 7.13209 0.985341 6.97078 1.07874C6.80946 1.17214 6.67565 1.30645 6.58285 1.46811L0.154408 12.4059C-0.27403 13.1353 0.23972 14.0625 1.07316 14.0625H13.9291C14.7625 14.0625 15.2772 13.1344 14.8478 12.4059L8.42035 1.46811Z'
      fill={color}
    />
    <path
      d='M6.56445 11.25C6.56445 11.1269 6.5887 11.005 6.63582 10.8913C6.68293 10.7775 6.75199 10.6742 6.83904 10.5871C6.9261 10.5001 7.02944 10.431 7.14319 10.3839C7.25693 10.3368 7.37884 10.3125 7.50195 10.3125C7.62507 10.3125 7.74698 10.3368 7.86072 10.3839C7.97446 10.431 8.07781 10.5001 8.16487 10.5871C8.25192 10.6742 8.32098 10.7775 8.36809 10.8913C8.4152 11.005 8.43945 11.1269 8.43945 11.25C8.43945 11.4987 8.34068 11.7371 8.16487 11.913C7.98905 12.0888 7.75059 12.1875 7.50195 12.1875C7.25331 12.1875 7.01486 12.0888 6.83904 11.913C6.66323 11.7371 6.56445 11.4987 6.56445 11.25V11.25ZM6.65633 5.62036C6.64384 5.50209 6.65636 5.38251 6.69308 5.26939C6.72979 5.15627 6.78988 5.05213 6.86944 4.96372C6.949 4.87532 7.04626 4.80464 7.1549 4.75625C7.26354 4.70787 7.38115 4.68286 7.50008 4.68286C7.61901 4.68286 7.73661 4.70787 7.84526 4.75625C7.9539 4.80464 8.05116 4.87532 8.13072 4.96372C8.21028 5.05213 8.27036 5.15627 8.30708 5.26939C8.34379 5.38251 8.35631 5.50209 8.34383 5.62036L8.0157 8.90817C8.00468 9.03733 7.94558 9.15765 7.8501 9.24533C7.75462 9.33301 7.62971 9.38166 7.50008 9.38166C7.37045 9.38166 7.24554 9.33301 7.15006 9.24533C7.05458 9.15765 6.99548 9.03733 6.98445 8.90817L6.65633 5.62036Z'
      fill={color}
    />
  </svg>
);
export default Warning;
