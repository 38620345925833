import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import clsx from 'clsx';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const ModalComponent = ({
	onClose,
	show,
	size,
	title = '',
	titleColor,
	subTitle = '',
	text = '',
	children,
	onClickButton,
	nameButton,
	idButton = 'default',
	idSecondButton,
	isLoading,
	isDelete,
	disabled,
	isSecondDelete,
	secondButtonAction,
	titleClassName = 'text-center w-100 fs-5 text-truncate',
	secondButtonLabel,
	hasButton = true,
	noFooter,
	className,
	bodyClassName,
	bgColor,
	buttonAlign,
	closeButton = true,
	...rest
}) => (
	<Modal
		className={className}
		size={size}
		aria-labelledby='contained-modal-title-vcenter'
		centered
		contentClassName='shadow'
		show={show}
		onHide={onClose}
		id='modal'
		{...rest}
	>
		<Modal.Header
			closeButton ={closeButton}
			className={clsx(
				'pb-0 ',
				titleColor,
				{
					'border-bottom pb-2 pb-md-4 mt-md-2': subTitle,
				},
				bgColor
					? `color-picker-bg-${bgColor} text-white pb-3 rounded-top-1`
					: '',
			)}
		>
			<Modal.Title
				id='contained-modal-title-vcenter'
				className={titleClassName}
			>
				<p className='m-0'>{title}</p>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body className={bodyClassName}>
			<div
				className={clsx('', {
					'text-center': noFooter,
					'mb-1 mb-md-3': subTitle !== '',
				})}
			>
				<span
					className={clsx('', {
						'mb-3 pb-1': subTitle !== '',
					})}
				>
					{subTitle}
				</span>
			</div>

			<div className={clsx('', { 'mb-4': text !== '' })}>
				<span>{text}</span>
			</div>
			{children}
		</Modal.Body>
		{!noFooter && (
			<Modal.Footer className={`justify-content-${buttonAlign}`}>
				{!!secondButtonLabel && (
					<Button
						id={`btn-${idSecondButton}`}
						variant={
							isSecondDelete
								? 'outline-danger'
								: 'outline-primary button-hover-primary'
						}
						onClick={() => secondButtonAction()}
					>
						{secondButtonLabel}
					</Button>
				)}
				{hasButton && (
					<Button
						variant={isDelete ? 'danger' : 'primary'}
						id={`btn-${idButton}`}
						disabled={isLoading || disabled}
						onClick={onClickButton}
						className={clsx(' ', {
							'text-white': !isDelete,
							'btn btn-outline-danger border-grey text-grey pb-2': disabled,
						})}
						type='submit'
					>
						{isLoading ? (
							<LoadingSpinner desc='' size='sm' variant='primary' />
						) : (
							nameButton
						)}
					</Button>
				)}
			</Modal.Footer>
		)}
	</Modal>
);

export default observer(ModalComponent);

ModalComponent.propTypes = {
	show: PropTypes.bool,
	size: PropTypes.string,
	onClose: PropTypes.func,
	onClickButton: PropTypes.func,
	title: PropTypes.string,
	titleColor: PropTypes.string,
	nameButton: PropTypes.string,
	subTitle: PropTypes.string,
	text: PropTypes.string,
	children: PropTypes.node,
	isLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	isDelete: PropTypes.bool,
	disabled: PropTypes.bool,
	isSecondDelete: PropTypes.bool,
	secondButtonAction: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	secondButtonLabel: PropTypes.string,
	hasButton: PropTypes.bool,
	noFooter: PropTypes.bool,
	idButton: PropTypes.string,
	idSecondButton: PropTypes.string,
	className: PropTypes.string,
	bodyClassName: PropTypes.string,
	bgColor: PropTypes.string,
	buttonAlign: PropTypes.string,
	closeButton: PropTypes.bool,
	titleClassName: PropTypes.string
};

ModalComponent.defaultProps = {
	show: false,
	size: '',
	onClose: () => {},
	onClickButton: () => {},
	title: '',
	titleColor: '',
	subTitle: '',
	text: '',
	nameButton: '',
	children: '',
	isLoading: '',
	isDelete: false,
	disabled: false,
	isSecondDelete: false,
	secondButtonAction: null,
	hasButton: true,
	noFooter: false,
	secondButtonLabel: '',
	idButton: 'default',
	idSecondButton: 'cancel',
	className: '',
	bodyClassName: '',
	bgColor: '',
	buttonAlign: 'end',
	closeButton: true,
	titleClassName: ''
};
