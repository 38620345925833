const ThreeDotsY = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '20', color = '#383C42', id, ...rest },
}) => (
  <svg
    width='6'
    height={size}
    viewBox='0 0 6 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
    id={id}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.25 17.5C5.25 18.0967 5.01295 18.669 4.59099 19.091C4.16903 19.5129 3.59674 19.75 3 19.75C2.40326 19.75 1.83097 19.5129 1.40901 19.091C0.987053 18.669 0.75 18.0967 0.75 17.5C0.75 16.9033 0.987053 16.331 1.40901 15.909C1.83097 15.4871 2.40326 15.25 3 15.25C3.59674 15.25 4.16903 15.4871 4.59099 15.909C5.01295 16.331 5.25 16.9033 5.25 17.5ZM5.25 10C5.25 10.5967 5.01295 11.169 4.59099 11.591C4.16903 12.0129 3.59674 12.25 3 12.25C2.40326 12.25 1.83097 12.0129 1.40901 11.591C0.987053 11.169 0.75 10.5967 0.75 10C0.75 9.40326 0.987053 8.83097 1.40901 8.40901C1.83097 7.98705 2.40326 7.75 3 7.75C3.59674 7.75 4.16903 7.98705 4.59099 8.40901C5.01295 8.83097 5.25 9.40326 5.25 10ZM5.25 2.5C5.25 3.09674 5.01295 3.66903 4.59099 4.09099C4.16903 4.51295 3.59674 4.75 3 4.75C2.40326 4.75 1.83097 4.51295 1.40901 4.09099C0.987053 3.66903 0.75 3.09674 0.75 2.5C0.75 1.90326 0.987053 1.33097 1.40901 0.90901C1.83097 0.487053 2.40326 0.25 3 0.25C3.59674 0.25 4.16903 0.487053 4.59099 0.90901C5.01295 1.33097 5.25 1.90326 5.25 2.5Z'
      fill={color}
    />
  </svg>
);
export default ThreeDotsY;
