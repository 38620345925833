const ArrowUp = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '12', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size * 1.5}
    viewBox='0 0 12 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 2.25C6.19891 2.25 6.38968 2.32902 6.53033 2.46967C6.67098 2.61032 6.75 2.80109 6.75 3V16.5C6.75 16.6989 6.67098 16.8897 6.53033 17.0303C6.38968 17.171 6.19891 17.25 6 17.25C5.80109 17.25 5.61032 17.171 5.46967 17.0303C5.32902 16.8897 5.25 16.6989 5.25 16.5V3C5.25 2.80109 5.32902 2.61032 5.46967 2.46967C5.61032 2.32902 5.80109 2.25 6 2.25Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.469 0.969013C5.53867 0.899168 5.62143 0.843754 5.71255 0.805944C5.80367 0.768135 5.90135 0.748672 6 0.748672C6.09865 0.748672 6.19633 0.768135 6.28745 0.805944C6.37857 0.843754 6.46133 0.899168 6.531 0.969013L11.031 5.46901C11.1718 5.60984 11.2509 5.80085 11.2509 6.00001C11.2509 6.19918 11.1718 6.39018 11.031 6.53101C10.8902 6.67184 10.6992 6.75096 10.5 6.75096C10.3008 6.75096 10.1098 6.67184 9.969 6.53101L6 2.56051L2.031 6.53101C1.89017 6.67184 1.69917 6.75096 1.5 6.75096C1.30084 6.75096 1.10983 6.67184 0.969002 6.53101C0.828172 6.39018 0.749054 6.19918 0.749054 6.00001C0.749054 5.80085 0.828172 5.60984 0.969002 5.46901L5.469 0.969013Z'
      fill={color}
    />
  </svg>
);
export default ArrowUp;
