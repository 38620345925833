/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { Image, KonvaNodeEvents, Layer, Line, Stage } from 'react-konva';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import Dot from './FacemeshLayers/Dot';

import useToggle from '../../../../hooks/useToggle';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../../constants/Colors';
import Analysis from './FacemeshLayers/Analysis';
import LoadingSpinner from '../../../Styleguide/LoadingSpinner/LoadingSpinner';
import Results from './FacemeshLayers/Results';

/** Const representing the canvas aspect ratio. Necessary because it has absolute positioning */
const ASPECT_RATIO = 3 / 4;

const ruleOfThree = (oldVal, newVal) => (newVal * 1) / oldVal;

const getCenter = (p1, p2) => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});

const getDistance = (p1, p2) =>
  // eslint-disable-next-line no-restricted-properties
  Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));

const zoomInitialValues = {
  lastCenter: null,
  lastDist: 0,
};

/**
 * @typedef {Object} Ref
 * @property {Object} current
 * @property {(width: number) => void} current.recalculate
 * @property {() => number} current.currentWidth
 */

/**
 * @typedef {Object} Props
 * @property {Ref} ref
 * @property {boolean} [showDots=false]
 * @property {boolean} [showLines=false]
 * @property {boolean} [showAnalysis=false]
 * @property {'none' | 'line' | 'dot'} [insertMode='none']
 * @property {number | undefined} width
 * @property {number | undefined} height
 * @property {function} setLastWidth
 * @property {boolean} [disableCalculation=false]
 */

/**
 * @type {React.ForwardRefRenderFunction<Ref, Props>}
 */

const Facemesh = forwardRef((props, ref) => {
  const {
    hasDots,
    insertMode = 'none',
    showDots = false,
    showLines = false,
    showAnalysis = false,
    exporting = false,
    disableDots,
    isFetched,
    lastWidth = 0,
    imageSize = {},
    aspectRatio = 0,
    analysis = [],
    filteredDots = [],
    analysisActive = '',
    activeDot = null,
    dots = [],
    width = undefined,
    height = undefined,
    newImage,
    setImageWidth,
    changeCategory,
    createDot,
    onDragEnd,
    setLastWidth,
    setDots,
    imageId,
    isComparative,
    imageType,
    hasAnalysis,
    handleRulers,
    rulers,
  } = props;

  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [guideLine, setGuideLine] = useState({});
  const [guideLines, setGuideLines] = useState(rulers);
  const [zoom, setZoom] = useState(zoomInitialValues);
  const [isZooming, setIsZooming] = useState(false);
  const [isRecalculating, setRecalculating] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [show, ToggleShow] = useToggle(true);
  const isCreatingLine = useRef(false);
  const { width: windowWidth } = useWindowSize();
  const [containerRef, { width: measureWidth, height: measureHeight }] =
    useMeasure();

  const canvasWidth = useMemo(() => {
    if (width) {
      return width;
    }
    return measureWidth;
  }, [measureWidth, width]);

  const canvasHeight = useMemo(() => {
    if (height) {
      return height;
    }
    return measureHeight;
  }, [measureHeight, height]);

  const measures = useMemo(() => {
    if (canvasHeight > 0 && canvasWidth > 0) {
      if (windowWidth < 1024) {
        return {
          width: canvasHeight * ASPECT_RATIO,
          height: canvasHeight,
        };
      }
      return { width: canvasWidth, height: canvasWidth / ASPECT_RATIO };
    }

    return { width: 0, height: 0 };
  }, [canvasWidth, canvasHeight]);
  const internalHeight = height ?? measures.height;
  const internalWidth = width ?? measures.width;

  useEffect(() => {
    if (newImage != null) {
      setImageWidth(newImage.width);
    }
  }, [newImage.width]);

  /**
   * Function to recalculate
   */
  const recalculate = () => {
    // Wait for the image to be loaded
    if (lastWidth == null || lastWidth === 0) {
      setLastWidth?.(measures.width);
    }
    if (!isFetched) return;

    setRecalculating(true);

    const oldWidth = lastWidth || measures.width;
    const oldHeight = oldWidth / ASPECT_RATIO;
    const newWidth = measures.width;
    const newHeight = newWidth / ASPECT_RATIO;

    /* if any of the widths is zero, calc will not occur */
    if (oldWidth === 0 || newWidth === 0) {
      setRecalculating(false);
      return;
    }

    // After the image is loaded, set the current measures.width on the dotsStore
    setLastWidth(measures.width);

    /* NOTE: If there is any performance problem this can be reduced to only one proportion */
    const widthProportion = ruleOfThree(oldWidth, newWidth);
    const heightProportion = ruleOfThree(oldHeight, newHeight);

    /* Recalculate dots position */
    if (dots && dots.length > 0) {
      setDots(
        dots.map((dot) => ({
          ...dot,
          x: dot.x * widthProportion,
          y: dot.y * heightProportion,
        })),
      );
    }

    if (guideLines && guideLines?.length > 0) {
      setGuideLines(
        guideLines.map((line) => {
          const points =
            line.x > 0
              ? [0, 0, 0, line.points[3] * heightProportion]
              : [0, 0, line.points[2] * widthProportion, 0];

          const newLine = {
            points,
            x: line.x * widthProportion,
            y: line.y * heightProportion,
          };
          return newLine;
        }),
      );
    }

    setRecalculating(false);
  };

  useEffect(() => {
    recalculate();
  }, [canvasWidth, lastWidth, isFetched]);

  useEffect(() => {
    if (!isRecalculating) {
      setIsReady(true);
    }
  }, [isRecalculating]);

  const handleRulerClick = (e, type) => {
    if (imageType) {
      return createGuideLine(e, type);
    }
    return document.getElementById('btn-modal-define-category')?.click();
  };

  const createGuideLine = (e, type) => {
    isCreatingLine.current = type;

    if (type === 'vertical') {
      setGuideLine({
        x: 30,
        y: 0,
        points: [0, 0, 0, imageSize.height || internalHeight],
      });
    }
    if (type === 'horizontal') {
      setGuideLine({
        x: 0,
        y: 30,
        points: [0, 0, imageSize.width || internalWidth, 0],
      });
    }
  };

  /**
   * @type {KonvaNodeEvents['onDragEnd']}
   * @param {number} i
   */
  const updateGuideLine = (e, i) => {
    const stage = e.target.getStage();

    const point = stage.getPointerPosition();

    const oldScale = stage.scaleX();

    const line = guideLines[i];

    if (!line) {
      return;
    }

    if (line.x && point.x > 30) {
      setGuideLines([
        ...guideLines.slice(0, i),
        {
          ...line,
          x: point.x / oldScale - stage.x() / oldScale,
        },
        ...guideLines.slice(i + 1),
      ]);

      handleRulers([
        ...guideLines.slice(0, i),
        {
          ...line,
          x: point.x / oldScale - stage.x() / oldScale,
        },
        ...guideLines.slice(i + 1),
      ]);
    } else if (line.y && point.y > 30) {
      setGuideLines([
        ...guideLines.slice(0, i),
        {
          ...line,
          y: point.y / oldScale - stage.y() / oldScale,
        },
        ...guideLines.slice(i + 1),
      ]);

      handleRulers([
        ...guideLines.slice(0, i),
        {
          ...line,
          y: point.y / oldScale - stage.y() / oldScale,
        },
        ...guideLines.slice(i + 1),
      ]);
    } else {
      handleRulers([...guideLines.slice(0, i), ...guideLines.slice(i + 1)]);

      setGuideLines([...guideLines.slice(0, i), ...guideLines.slice(i + 1)]);
    }
  };

  /* Mouse events */
  /**
   * @type {KonvaNodeEvents['onMouseDown']}
   */
  const handleMouseDown = (e, optionalAction = null, type = null) => {
    if (e.evt.which !== 1 && e.evt.which !== 0) return;
    if (optionalAction === 'guideLine') {
      createGuideLine(e, type);
      return;
    }

    switch (insertMode) {
      case 'dot':
        if (activeDot) {
          createDot(e);
        }

        break;
      case 'line':
        // createLine(e);
        break;
      default:
        break;
    }
  };
  /* Indicar que está colocando uma reta para o move entrar em cena */
  /**
   * @type {KonvaNodeEvents['onMouseMove']}
   */
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();

    if (!isCreatingLine.current || (e.evt.which !== 1 && e.evt.which !== 0)) {
      return;
    }

    const point = stage.getPointerPosition();
    const oldScale = stage.scaleX();

    // add point
    if (isCreatingLine.current === 'vertical') {
      setGuideLine({
        ...guideLine,
        x: point.x / oldScale - stage.x() / oldScale,
        y: 0,
        points: [0, 0, 0, imageSize.height || internalHeight],
      });
    }

    if (isCreatingLine.current === 'horizontal') {
      setGuideLine({
        ...guideLine,
        x: 0,
        y: point.y / oldScale - stage.y() / oldScale,
        points: [0, 0, imageSize.width || internalWidth, 0],
      });
    }
  };

  /**
   * @type {KonvaNodeEvents['onMouseUp']}
   */
  const handleMouseUp = (e) => {
    if (isCreatingLine.current && (e.evt.which === 1 || e.evt.which === 0)) {
      isCreatingLine.current = false;

      if (guideLine.x > 20 || guideLine.y > 20) {
        if (guideLines?.length > 0) {
          handleRulers([...guideLines, guideLine]);

          setGuideLines([...guideLines, guideLine]);
        } else {
          // caso seja a primeira linha traçada guideLines, possivelmente ele poderá
          // ser nulo e portanto o operador de espalhamento não funcionará
          handleRulers([guideLine]);

          setGuideLines([guideLine]);
        }
      }

      setGuideLine({});
    }
  };

  /**
   * @type {KonvaNodeEvents['onDragMove']}
   */
  const handleDragMove = (e) => {
    setStageX(e.target.x());
    setStageY(e.target.y());
  };

  /**
   * @type {KonvaNodeEvents['onWheel']}
   */
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    const _x =
      -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale;
    const _y =
      -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale;

    const pos = boundFunc({ x: _x, y: _y }, newScale);

    if (newScale < 1) {
      setStageScale(1);
      setStageX(0);
      setStageY(0);
    } else {
      setStageScale(newScale);
      setStageX(pos.x);
      setStageY(pos.y);
    }
  };

  // Touch Events

  /**
   * @type {KonvaNodeEvents['onTouchMove']}
   */
  const handleTouchMove = (e) => {
    const stage = e.target.getStage();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];

    if (isCreatingLine.current) {
      const point = stage.getPointerPosition();
      const oldScale = stage.scaleX();
      // add point
      if (isCreatingLine.current === 'vertical') {
        setGuideLine({
          ...guideLine,
          x: point.x / oldScale - stage.x() / oldScale,
          y: 0,
          points: [0, 0, 0, imageSize.height || internalHeight],
        });
      }
      if (isCreatingLine.current === 'horizontal') {
        setGuideLine({
          ...guideLine,
          x: 0,
          y: point.y / oldScale - stage.y() / oldScale,
          points: [0, 0, imageSize.width || internalWidth, 0],
        });
      }
      return;
    }

    if (
      !isCreatingLine?.current &&
      e.evt?.which === 0 &&
      !!touch1 &&
      !!touch2
    ) {
      setIsZooming(true);
      const p1 = { x: touch1.clientX, y: touch1.clientY };
      const p2 = { x: touch2.clientX, y: touch2.clientY };

      const newCenter = getCenter(p1, p2);
      const dist = getDistance(p1, p2);

      let currentCenter = {
        lastCenter: zoom.lastCenter,
        lastDist: zoom.lastDist,
      };

      if (!zoom.lastCenter) {
        setZoom((prevState) => ({
          ...prevState,
          lastCenter: getCenter(p1, p2),
        }));

        currentCenter = {
          ...currentCenter,
          lastCenter: getCenter(p1, p2),
        };
      }

      if (!zoom.lastDist) {
        setZoom({ ...zoom, lastDist: dist });

        currentCenter = {
          ...currentCenter,
          lastDist: dist,
        };
      }

      const pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleY(),
      };

      const scale = stage.scaleX() * (dist / currentCenter.lastDist);

      setStageScale(scale);

      const dx = newCenter.x - currentCenter.lastCenter.x;
      const dy = newCenter.y - currentCenter.lastCenter.y;

      const newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      if (scale < 1) {
        setStageScale(1);
        setStageX(0);
        setStageY(0);
      } else {
        setStageScale(scale);
        setStageX(newPos.x);
        setStageY(newPos.y);
      }

      setZoom({
        ...zoom,
        lastDist: dist,
      });
    }
  };

  const handleTouchEnd = (e) => {
    if (isCreatingLine.current && (e.evt.which === 1 || e.evt.which === 0)) {
      isCreatingLine.current = false;
      if (guideLine.x > 20 || guideLine.y > 20) {
        setGuideLines([...guideLines, guideLine]);
      }
      setGuideLine({});
      return;
    }
    setZoom(zoomInitialValues);
    setIsZooming(false);
  };

  /**
   * @description A bound function which limits the position of the stage when it is dragged
   */
  const boundFunc = (pos) => {
    const x = Math.min(0, Math.max(pos.x, measureWidth * (1 - stageScale)));
    const y = Math.min(0, Math.max(pos.y, measureHeight * (1 - stageScale)));

    return {
      x,
      y,
    };
  };

  const dragBoundFunc = (pos) => boundFunc(pos);

  return (
    <div
      id='face-mesh-container'
      className={clsx(
        'd-flex flex-column pb-sm-0 h-100 w-100 zi-7  position-relative',
        isComparative && 'align-items-center',
      )}
      ref={containerRef}
    >
      {!isReady && (
        <div
          className='  zi-10 opacity-50 bg-dark-50 position-absolute'
          style={{ height: internalHeight, width: internalWidth }}
        >
          <div className='d-flex h-100 w-100 flex-column justify-content-center align-items-center flex-fill'>
            <LoadingSpinner variant='light' desc='Recalculando...' />
          </div>
        </div>
      )}
      {!isRecalculating &&
      isReady &&
      showAnalysis &&
      show &&
      analysisActive != null ? (
        <Results
          imageId={imageId}
          comparative={isComparative}
          showText
          analysis={analysis ? analysis[analysisActive] : null}
          analysisType={analysisActive}
          imageType={imageType}
          hasAnalysis={hasAnalysis}
        />
      ) : null}
      {showLines && (
        <>
          <IconComponent
            className='position-absolute top-0 start-0 zi-9 bg-dark rounded'
            group='standard'
            name={show ? 'eyehide' : 'eye'}
            size={20}
            color={COLORS.white}
            onClick={() => ToggleShow()}
          />
          {show && (
            <>
              <IconComponent
                className='position-absolute top-0 start-0 zi-8'
                group='analysis'
                name='verticalRuler'
                size={imageSize.height || internalHeight}
                color={COLORS.dark}
                onClick={(e) => handleRulerClick(e, 'vertical')}
              />
              <IconComponent
                className='position-absolute top-0 start-0 zi-8'
                group='analysis'
                name='horizontalRuler'
                size={imageSize.width || internalWidth}
                color={COLORS.dark}
                onClick={(e) => handleRulerClick(e, 'horizontal')}
              />
            </>
          )}
        </>
      )}

      <Stage
        width={internalWidth}
        height={internalHeight}
        scaleX={stageScale}
        scaleY={stageScale}
        ref={ref}
        x={stageX}
        y={stageY}
        drawBorder
        visible
        listening
        className='flex-column h-100 d-flex overflow-hidden'
        onContextMenu={(e) => e.evt.preventDefault()}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        draggable={!isZooming}
        onDragEnd={handleDragMove}
        dragBoundFunc={dragBoundFunc}
      >
        <Layer>
          <Image
            id='img-patient-face-image-mesh'
            image={newImage}
            width={imageSize.width || internalWidth}
            height={imageSize.height || internalHeight}
          />
          {show && (
            <>
              {guideLine && (
                <Line
                  x={guideLine.x}
                  y={guideLine.y}
                  points={guideLine.points}
                  stroke='#808080'
                  strokeWidth={2}
                  dash={[10, 5]}
                />
              )}
              {guideLines &&
                guideLines?.length > 0 &&
                guideLines.map((v, i) => (
                  <Line
                    key={`${v.x}${v.y}`}
                    x={v.x}
                    y={v.y}
                    points={v.points}
                    stroke='#808080'
                    draggable={!disableDots}
                    hitStrokeWidth={10}
                    onDragEnd={(e) => updateGuideLine(e, i)}
                    dash={[10, 5]}
                  />
                ))}

              {!isRecalculating &&
              isReady &&
              showDots &&
              dots &&
              (analysisActive === '' || analysisActive == null)
                ? dots.map((dot) => (
                    <Dot
                      key={dot.id}
                      categoryId={dot?.categoryId}
                      x={dot.x}
                      y={dot.y}
                      showText
                      draggable={!disableDots}
                      text={dot.category?.node_name || dot?.categoryId}
                      isActive={
                        activeDot === dot?.categoryId ||
                        (showAnalysis &&
                          filteredDots.findIndex(
                            (d) => d.categoryId === dot.categoryId,
                          ) > -1)
                      }
                      onDragEnd={onDragEnd}
                      setActiveCategory={changeCategory}
                    />
                  ))
                : null}
              {!isRecalculating &&
              isReady &&
              showAnalysis &&
              analysisActive !== '' &&
              analysisActive != null &&
              analysis &&
              analysis[analysisActive] != null ? (
                <>
                  <Analysis
                    analysis={analysis[analysisActive]}
                    analysisType={analysisActive}
                    width={measureWidth || canvasWidth}
                    filteredDots={filteredDots}
                    strokeWidth={1}
                    imageType={imageType}
                  />
                  {filteredDots.map((dot) => (
                    <Dot
                      key={dot.id}
                      categoryId={dot?.categoryId}
                      x={dot.x}
                      y={dot.y}
                      showText
                      draggable={!disableDots}
                      text={dot.category?.node_name || dot?.categoryId}
                      isActive={
                        activeDot === dot?.categoryId ||
                        (showAnalysis &&
                          filteredDots.findIndex(
                            (d) => d.categoryId === dot.categoryId,
                          ) > -1)
                      }
                      onDragEnd={onDragEnd}
                      setActiveCategory={changeCategory}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
        </Layer>
      </Stage>
    </div>
  );
});

Facemesh.propTypes = {
  hasDots: PropTypes.bool,
  insertMode: PropTypes.oneOf(['none', 'line', 'dot']),
  lastWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableDots: PropTypes.bool,
  setLastWidth: PropTypes.func.isRequired,
  showDots: PropTypes.bool,
  showLines: PropTypes.bool,
  showAnalysis: PropTypes.bool,
  imageSize: PropTypes.oneOfType([PropTypes.object]),
  isFetched: PropTypes.bool,
  aspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  setDots: PropTypes.func,
  onDragEnd: PropTypes.func,
  createDot: PropTypes.func,
  changeCategory: PropTypes.func,
  activeDot: PropTypes.string,
  analysisActive: PropTypes.string,
  filteredDots: PropTypes.arrayOf(PropTypes.object),
  analysis: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  dots: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.number,
  height: PropTypes.number,
  newImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  setImageWidth: PropTypes.func,
  exporting: PropTypes.bool,
  isComparative: PropTypes.bool,
  imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageType: PropTypes.string,
  hasAnalysis: PropTypes.bool,
  handleRulers: PropTypes.func,
  rulers: PropTypes.object,
};

Facemesh.defaultProps = {
  hasDots: false,
  insertMode: 'none',
  lastWidth: 0,
  activeDot: null,
  analysisActive: '',
  disableDots: false,
  imageSize: {},
  filteredDots: [],
  dots: [],
  isFetched: false,
  setDots: () => {},
  onDragEnd: () => {},
  createDot: () => {},
  aspectRatio: 0,
  changeCategory: () => {},
  setImageWidth: () => {},
  newImage: {},
  analysis: [],
  showDots: false,
  showLines: false,
  showAnalysis: false,
  width: undefined,
  height: undefined,
  exporting: false,
  isComparative: false,
  imageId: null,
  imageType: null,
  hasAnalysis: false,
  handleRulers: () => {},
  rulers: {},
};

const exported = observer(Facemesh);
exported.displayName = 'Facemesh';
export default exported;
