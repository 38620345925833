const DoneIcon = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 86 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M85.0432 7.20698C85.3284 7.49145 85.5547 7.82941 85.7091 8.20147C85.8635 8.57353 85.9429 8.9724 85.9429 9.37523C85.9429 9.77805 85.8635 10.1769 85.7091 10.549C85.5547 10.921 85.3284 11.259 85.0432 11.5435L42.1682 54.4185C41.8837 54.7037 41.5458 54.9299 41.1737 55.0843C40.8017 55.2387 40.4028 55.3182 40 55.3182C39.5971 55.3182 39.1983 55.2387 38.8262 55.0843C38.4541 54.9299 38.1162 54.7037 37.8317 54.4185L19.4567 36.0435C19.172 35.7587 18.9461 35.4207 18.792 35.0487C18.6379 34.6766 18.5586 34.2779 18.5586 33.8752C18.5586 33.4725 18.6379 33.0738 18.792 32.7018C18.9461 32.3297 19.172 31.9917 19.4567 31.707C19.7415 31.4222 20.0795 31.1964 20.4515 31.0423C20.8235 30.8882 21.2223 30.8089 21.625 30.8089C22.0276 30.8089 22.4264 30.8882 22.7984 31.0423C23.1704 31.1964 23.5085 31.4222 23.7932 31.707L40 47.9198L80.7067 7.20698C80.9912 6.92178 81.3291 6.6955 81.7012 6.54111C82.0733 6.38672 82.4721 6.30725 82.875 6.30725C83.2778 6.30725 83.6767 6.38672 84.0487 6.54111C84.4208 6.6955 84.7587 6.92178 85.0432 7.20698V7.20698Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.0001 6.31242C33.3373 6.31242 26.8242 8.28816 21.2843 11.9898C15.7444 15.6914 11.4266 20.9527 8.87687 27.1083C6.32714 33.2639 5.66001 40.0373 6.95985 46.572C8.25969 53.1068 11.4681 59.1093 16.1794 63.8206C20.8907 68.5319 26.8932 71.7403 33.428 73.0401C39.9627 74.34 46.7361 73.6728 52.8917 71.1231C59.0473 68.5734 64.3086 64.2556 68.0102 58.7157C71.7118 53.1758 73.6876 46.6627 73.6876 39.9999C73.6876 39.1877 74.0102 38.4087 74.5846 37.8344C75.1589 37.2601 75.9378 36.9374 76.7501 36.9374C77.5623 36.9374 78.3413 37.2601 78.9156 37.8344C79.4899 38.4087 79.8126 39.1877 79.8126 39.9999C79.8114 48.7638 76.9186 57.2824 71.5827 64.2347C66.2468 71.1869 58.7661 76.1843 50.3006 78.4517C41.8351 80.7192 32.858 80.13 24.7615 76.7755C16.665 73.4211 9.90159 67.4889 5.52014 59.8988C1.13869 52.3088 -0.615926 43.4852 0.528408 34.7963C1.67274 26.1075 5.65208 18.039 11.8493 11.8422C18.0465 5.64542 26.1152 1.6666 34.8041 0.522825C43.493 -0.62095 52.3166 1.13423 59.9063 5.51617C60.2723 5.70762 60.5958 5.97113 60.8573 6.29084C61.1188 6.61054 61.3129 6.97985 61.428 7.37653C61.5431 7.77321 61.5767 8.18907 61.5269 8.59909C61.4771 9.00911 61.3449 9.40482 61.1382 9.76241C60.9315 10.12 60.6546 10.4321 60.3241 10.6799C59.9937 10.9277 59.6166 11.1061 59.2154 11.2044C58.8142 11.3026 58.3973 11.3187 57.9897 11.2517C57.5822 11.1847 57.1924 11.0359 56.8438 10.8143C51.7247 7.85402 45.9135 6.30085 40.0001 6.31242V6.31242Z'
      fill={color}
    />
  </svg>
);
export default DoneIcon;
