import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import SearchNavBar from '../NavBar/SearchNavBar/SearchNavBar';

const DocumentLayout = ({ children }) => (
  <MainLayout customNavBar={<SearchNavBar title='Meus documentos' />}>{children}</MainLayout>
);

DocumentLayout.propTypes = {
  children: PropTypes.node,
};
DocumentLayout.defaultProps = {
  children: '',
};

export default observer(DocumentLayout);
