import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { useStore } from '../../../hooks';

const FixedBottomButton = ({ handleClick, label, icon, disabled, id }) => {
	const { defaultStore } = useStore();
	return (
		<div
			className='d-flex justify-content-center d-sm-none fixed-bottom shadow-lg bg-white border-light pb-4-5 pt-2 pe-2 ps-2 h-xs-95px'
			id='btn-add-file-bottom'
		>
			<Button
				disabled={disabled}
				className='text-white d-flex align-items-center fs-5 py-2 px-3'
				onClick={handleClick}
				id={id}
			>
				<div className='me-2'>
					{icon?.name != null && icon?.group != null ? (
						<IconComponent
							group={icon?.group}
							name={icon?.name}
							size={icon?.size}
						/>
					) : null}
				</div>
				{label}
			</Button>
		</div>
	);
};

export default observer(FixedBottomButton);

FixedBottomButton.propTypes = {
	handleClick: PropTypes.func,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.shape({
		group: PropTypes.string,
		name: PropTypes.string,
		size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	id: PropTypes.string,
};

FixedBottomButton.defaultProps = {
	handleClick: () => {},
	disabled: false,
	label: '',
	icon: {
		group: null,
		name: null,
		size: 16,
	},
	id: '',
};
