import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';

const StandardButton = ({
  handleClick,
  label,
  icon,
  disabled,
  iconPosition,
  id,
  ...rest
}) => (
  <div className='d-flex justify-content-center border-light p-1'>
    <Button
      {...rest}
      disabled={disabled}
      className={clsx(
        'text-white d-flex align-items-center fs-xs-5 py-2 px-3',
        rest.className != null && `${rest.className}`,
      )}
      onClick={() => handleClick()}
      id={id}
    >
      <div className='me-2'>
        {icon?.name != null &&
        icon?.name !== '' &&
        icon?.group != null &&
        iconPosition === 'left' ? (
          <IconComponent
            group={icon.group}
            name={icon.name}
            size={icon.size}
            color={icon.color}
          />
        ) : null}
      </div>
      {label}
      <div className='ms-2'>
        {icon?.name != null &&
        icon?.name !== '' &&
        icon?.group != null &&
        iconPosition === 'right' ? (
          <IconComponent
            group={icon.group}
            name={icon.name}
            size={icon.size}
            color={icon.color}
          />
        ) : null}
      </div>
    </Button>
  </div>
);

export default StandardButton;

StandardButton.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  icon: PropTypes.shape({
    group: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
  }),
  id: PropTypes.string,
};

StandardButton.defaultProps = {
  handleClick: () => {},
  disabled: false,
  label: '',
  iconPosition: 'left',
  icon: {
    group: null,
    name: null,
    size: 16,
    color: COLORS.white,
  },
  id: '',
};
