const ANALYSIS_TRANSLATION = {
  top: 'Topo',
  mid: 'Meio',
  bottom: 'Base',
  front: 'Frontal',
  side: 'Perfil',
  SUP: 'Superior',
  INT: 'Interno',
  EXT: 'Externo',
  first: 'Primeiro',
  second: 'Segundo',
  third: 'Terceiro',
  fourth: 'Quarto',
  fifth: 'Quinto',
  left: 'Esquerda',
  right: 'Direita',
};
export default ANALYSIS_TRANSLATION;
