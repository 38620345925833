import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import PatientDataRegisterNavBar from './PatientDataRegisterNavBar';

const PatientDataRegisterLayout = ({ children }) => (
  <MainLayout customNavBar={<PatientDataRegisterNavBar />}>
    {children}
  </MainLayout>
);

PatientDataRegisterLayout.propTypes = {
  children: PropTypes.node,
};
PatientDataRegisterLayout.defaultProps = {
  children: '',
};
export default observer(PatientDataRegisterLayout);
