export default function getMessageErrors(message) {
  if (typeof message === 'string') {
    return message;
  }
  let errors = '';
  Object.keys(message).forEach((item) =>
    message[item].forEach((value) => {
      errors += value.concat('\n');
    }),
  );

  return errors;
}
