const NotAuthorizedCard = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 98 98'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <g clipPath='url(#clip0_7689_57323)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49 91.875C60.3712 91.875 71.2766 87.3578 79.3172 79.3172C87.3578 71.2766 91.875 60.3712 91.875 49C91.875 37.6288 87.3578 26.7234 79.3172 18.6828C71.2766 10.6422 60.3712 6.125 49 6.125C37.6288 6.125 26.7234 10.6422 18.6828 18.6828C10.6422 26.7234 6.125 37.6288 6.125 49C6.125 60.3712 10.6422 71.2766 18.6828 79.3172C26.7234 87.3578 37.6288 91.875 49 91.875V91.875ZM49 98C61.9956 98 74.459 92.8375 83.6482 83.6482C92.8375 74.459 98 61.9956 98 49C98 36.0044 92.8375 23.5411 83.6482 14.3518C74.459 5.16249 61.9956 0 49 0C36.0044 0 23.5411 5.16249 14.3518 14.3518C5.16249 23.5411 0 36.0044 0 49C0 61.9956 5.16249 74.459 14.3518 83.6482C23.5411 92.8375 36.0044 98 49 98V98Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.4375 49C21.4375 48.1878 21.7602 47.4088 22.3345 46.8345C22.9088 46.2602 23.6878 45.9375 24.5 45.9375H73.5C74.3122 45.9375 75.0912 46.2602 75.6655 46.8345C76.2398 47.4088 76.5625 48.1878 76.5625 49C76.5625 49.8122 76.2398 50.5912 75.6655 51.1655C75.0912 51.7398 74.3122 52.0625 73.5 52.0625H24.5C23.6878 52.0625 22.9088 51.7398 22.3345 51.1655C21.7602 50.5912 21.4375 49.8122 21.4375 49Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_7689_57323'>
        <rect width='98' height='98' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default NotAuthorizedCard;
