import { useLocation } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useStore } from '../../../../../hooks';
import ModalComponent from '../../../../Styleguide/ModalComponent/ModalComponent';
import incrementHour from '../../../../../utils/incrementHour';
import { formatTz, timeNow } from '../../../../../utils/dateHelper';
import Input from '../../../../Styleguide/Input/Input';
import ColorPickerModal from '../../../../../screens/ColorPickerModal/ColorPickerModal';

const initialValues = {
	name: '',
	bgColor: 'blue',
	patient: '',
	date: formatTz(new Date(), 'yyyy-MM-dd'),
	startTime: timeNow(),
	endTime: incrementHour(timeNow()),
	place: '',
};

const EditAlbumModal = ({
	editModalShow,
	handleClose,
	albumId,
	patientId,
	formProperties,
}) => {
	const { albumStore, authStore } = useStore();
	const location = useLocation();

	const editAlbumModalSchema = Yup.object().shape({
		name: Yup.string()
			.required('* Campo obrigatório')
			.min(2, 'Nome muito curto')
			.max(100, 'Nome muito longo'),
		date: formProperties.date
			? Yup.string().required('* Campo obrigatório')
			: null,
		startTime: formProperties.startTime
			? Yup.string().required('* Campo obrigatório')
			: null,
		endTime: formProperties.endTime
			? Yup.string().required('* Campo obrigatório')
			: null,
	});

	const handleSubmit = async (payload) => {
		if (location.pathname.includes('/user/documents')) {
			await albumStore.editNameAlbum(
				payload,
				albumId,
				authStore.user?.personalFolder,
			);

			handleClose();

			return true;
		}
		await albumStore.editNameAlbum(payload, albumId, patientId);

		handleClose();

		return true;
	};

	const formik = useFormik({
		initialValues: { ...initialValues },
		onSubmit: (payload) => handleSubmit(payload),
		validationSchema: editAlbumModalSchema,
	});

	const handleSetEditData = () => {
		formik.setValues({
			name: albumStore.albumSelected?.album_name,
			bgColor: albumStore.albumSelected?.color,
			patient: albumStore.albumSelected?.patient_id,
			date: albumStore.albumSelected?.date_start?.slice(
				0,
				albumStore.albumSelected?.date_start.indexOf(' '),
			),
			startTime: albumStore.albumSelected?.date_start?.slice(
				albumStore.albumSelected?.date_start.indexOf(' ') + 1,
				albumStore.albumSelected?.date_start.length,
			),
			endTime: albumStore.albumSelected?.date_end?.slice(
				albumStore.albumSelected?.date_end.indexOf(' ') + 1,
				albumStore.albumSelected?.date_end.length,
			),
			place: albumStore.albumSelected?.location,
		});
	};

	useEffect(() => {
		if (!albumStore.isEditingTitle) {
			handleSetEditData();
		}
	}, [albumStore.albumSelected]);

	return (
		<ModalComponent
			show={editModalShow}
			isLoading={albumStore.isEditingTitle}
			disabled={albumStore.isEditingTitle}
			onClose={handleClose}
			title={formik.values.name}
			nameButton='Salvar'
			idButton='edit-appointment'
			bgColor={formik.values.bgColor}
			titleColor={`rounded-top-1 ${
				formik.values.bgColor ? 'text-white' : ''
			} border-bottom 
      		bg-gradient pb-3`}
			subTitle=''
			onClickButton={() => formik.submitForm()}
		>
			<div
				className='container p-1 p-md-2'
				id='container-appointment-modal-form'
			>
				<div className='row m-0 p-0 justify-content-between align-items-center'>
					<Input
						className={`${
							formProperties?.bgColor ? 'col-10 col-md-11' : 'col-12'
						} p-0 mb-3`}
						autoComplete='off'
						aria-autocomplete='none'
						labelClassName='mb-2'
						value={formik.values?.name}
						name='name'
						id='input-album'
						label={
							formProperties?.album?.label
								? formProperties.album.label
								: 'Nome da consulta'
						}
						onChange={formik.handleChange}
						placeholder={
							formProperties?.album?.placeholder
								? formProperties.album.placeholder
								: 'Ex: Consulta 01'
						}
						maxLength={255}
						onBlur={formik.handleBlur}
						isInvalid={formik.touched.name && !!formik.errors.name}
						error={formik.errors.name}
					/>
					{formProperties?.bgColor ? (
						<ColorPickerModal
							defaultColor={
								formik.values?.bgColor ? formik.values.bgColor : 'blue'
							}
							handleChange={formik.handleChange}
						/>
					) : null}
				</div>
				{formProperties?.date ||
				formProperties?.startTime ||
				formProperties?.endTime ? (
					<div className='row m-0 p-0 justify-content-between'>
						<Input
							className='col-5 p-0 mw-40 mb-3'
							value={formik.values.date}
							name='date'
							id='input-date'
							type='date'
							label='Data'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isInvalid={formik.touched.date && !!formik.errors.date}
							error={formik.errors.date}
						/>
						<Input
							className='col-3  p-0 mb-3'
							value={formik.values.startTime}
							name='startTime'
							id='input-startTime'
							type='time'
							label='Início'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isInvalid={formik.touched.startTime && !!formik.errors.startTime}
							error={formik.errors.startTime}
						/>
						<Input
							className='col-3  p-0 mb-3'
							value={formik.values.endTime}
							name='endTime'
							id='input-endTime'
							type='time'
							label='Final'
							onChange={formik.handleChange}
							placeholder='DD/MM/AAAA'
							onBlur={formik.handleBlur}
							isInvalid={formik.touched.endTime && !!formik.errors.endTime}
							error={formik.errors.endTime}
						/>
					</div>
				) : null}

				{formProperties?.place !== undefined ? (
					<div className='row'>
						<Input
							value={formik.values.place}
							className='h-100 mb-3'
							inputClassName='ps-4-5'
							name='place'
							id='input-place'
							type='place'
							label='Local da consulta'
							onChange={formik.handleChange}
						>
							<FontAwesomeIcon
								icon={faLocationDot}
								className='position-absolute top-50 start-3 translate-middle-y'
							/>
						</Input>
					</div>
				) : null}
			</div>
		</ModalComponent>
	);
};

EditAlbumModal.propTypes = {
	editModalShow: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	albumId: PropTypes.string.isRequired,
	patientId: PropTypes.string.isRequired,
	formProperties: PropTypes.object,
};

EditAlbumModal.defaultProps = {
	formProperties: {},
};

export default EditAlbumModal;
