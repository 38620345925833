import PropTypes from 'prop-types';
import { MONTH_DICTIONARY } from '../../../constants/CalendarDictionary';
import IconComponent from '../../Svg/IconComponent/IconComponent';

const DaySelector = ({ month, handleClick }) => (
  <div>
    <button
      type='button'
      className='d-flex reset-button'
      onClick={handleClick}
    >
      <div className='fs-5 fw-semibold me-2'>{MONTH_DICTIONARY[month]}</div>
      <IconComponent
        group='standard'
        name='chevrondown'
        size='24'
        color='#000000'
      />
    </button>
    {/* {children} */}
  </div>
);

export default DaySelector;

DaySelector.propTypes = {
  month: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  // children: PropTypes.node.isRequired,
};
