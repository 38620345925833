/* eslint-disable react/prop-types */
import useDocumentTitle from '../../hooks/useDocumentTitle';

const CustomRouteComponent = ({ component: Component, params, ...rest }) => {
  useDocumentTitle(params?.title || 'Haiah App');

  return <Component {...rest} />;
};

export default CustomRouteComponent;
