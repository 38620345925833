import React from 'react';

// tip: it's possible to cache screens using webpackPrefetch: true

export const LoginScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "LoginScreen" */
		'../../screens/LoginScreen/LoginScreen'
	),
);

export const HomeScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "HomeScreen" */
		'../../screens/HomeScreen/HomeScreen'
	),
);

export const ForgotPasswordScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ForgotPasswordScreen" */
		'../../screens/ForgotPasswordScreen/ForgotPasswordScreen'
	),
);

export const CodePasswordScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "CodePasswordScreen" */
		'../../screens/CodePasswordScreen/CodePasswordScreen'
	),
);

export const ResetPasswordScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ResetPasswordScreen" */
		'../../screens/ResetPasswordScreen/ResetPasswordScreen'
	),
);

export const PatientsScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientsScreen" */
		'../../screens/PatientsScreen/PatientsScreen'
	),
);

export const RegisterScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "RegisterScreen" */
		'../../screens/RegisterScreen/RegisterScreen'
	),
);

export const ConfirmationScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ConfirmationScreen" */
		'../../screens/ConfirmationScreen/ConfirmationScreen'
	),
);

export const DotsScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "DotsScreen" */
		'../../screens/AnalysisScreen/DotsScreen'
	),
);

export const EditImageScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "EditImageScreen" */
		'../../screens/EditImageScreen/EditImageScreen'
	),
);

export const UserDocumentScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "UserDocumentScreen" */
		'../../screens/UserDocumentScreen/UserDocumentScreen'
	),
);

export const UserDocumentFilesScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "UserDocumentFilesScreen" */
		'../../screens/UserDocumentFilesScreen/UserDocumentFilesScreen'
	),
);

export const LibraryScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "LibraryScreen" */
		'../../screens/LibraryScreen/LibraryScreen'
	),
);

export const MobileLibraryScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "MobileLibraryScreen" */
		'../../screens/LibraryScreen/MobileLibraryScreen'
	),
);

export const ConfirmationFeedbackScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ConfirmationFeedbackScreen" */
		'../../screens/ConfirmationFeedbackScreen/ConfirmationFeedbackScreen'
	),
);

export const ResetFeedbackScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ResetFeedbackScreen" */
		'../../screens/ResetFeedbackScreen/ResetFeedbackScreen'
	),
);

export const PatientDataScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientDataScreen" */
		'../../screens/PatientDataScreen/PatientDataScreen'
	),
);

export const CustomLibraryScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientDataScreen" */
		'../../screens/LibraryScreen/CustomLibraryScreen'
	),
);

export const FileScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientDataScreen" */
		'../../screens/FileScreen/FileScreen'
	),
);

export const CustomComparativeScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientDataScreen" */
		'../../screens/ComparativeScreen/CustomComparativeScreen'
	),
);

export const CustomComparativeSelectScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientDataScreen" */
		'../../screens/ComparativeScreen/CustomComparativeSelectScreen'
	),
);
export const CustomComparativeSelectAlbumScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "PatientDataScreen" */
		'../../screens/ComparativeScreen/CustomComparativeSelectAlbumScreen'
	),
);

export const ToolsScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ToolsScreen" */
		'../../screens/ToolsScreen/ToolsScreen'
	),
);

export const TimelapseScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "TimelapseScreen" */
		'../../screens/TimelapseScreen/TimelapseScreen'
	),
);
export const TimelapseSelectScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "TimelapseSelectScreen" */
		'../../screens/TimelapseScreen/TimelapseSelectScreen'
	),
);

export const AnalysisSelectScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "AnalysisSelectScreen" */
		'../../screens/AnalysisScreen/AnalysisSelectScreen'
	),
);

export const AnalysisListScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "AnalysisListScreen" */
		'../../screens/AnalysisScreen/AnalysisListScreen'
	),
);

export const ComparativeMobileWithFirstScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeScreen" */
		'../../screens/ComparativeScreen/ComparativeMobileWithFirstScreen'
	),
);
export const ComparativeMobileScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeScreen" */
		'../../screens/ComparativeScreen/ComparativeMobileScreen'
	),
);
export const ComparativeSelectScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectScreen" */
		'../../screens/ComparativeScreen/ComparativeSelectScreen'
	),
);
export const ComparativeSelectAlbumScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectAlbumScreen" */
		'../../screens/ComparativeScreen/ComparativeSelectAlbumScreen'
	),
);
export const PhotoToolsScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectAlbumScreen" */
		'../../screens/ToolsScreen/PhotoToolsScreen'
	),
);
export const ProfileStorageScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectAlbumScreen" */
		'../../screens/ProfileStorageScreen/ProfileStorageScreen'
	),
);
export const UpgradeScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectAlbumScreen" */
		'../../screens/UpgradeScreen/UpgradeScreen'
	),
);

export const UserProfileScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectAlbumScreen" */
		'../../screens/UserProfileScreen/UserProfileScreen'
	),
);
export const ComparativeViewScreen = React.lazy(() =>
	import(
		/* webpackChunkName: "ComparativeSelectAlbumScreen" */
		'../../screens/ComparativeViewScreen/ComparativeViewScreen'
	),
);
