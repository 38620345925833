function incrementHour(time, increment = 1) {
  const colon = time.indexOf(':');
  const hour = Number(time.slice(0, colon));
  const minute = time.slice(colon + 1, time.length);

  if (Number(hour) === 24) {
    return `00:${minute.padStart(2, '0')}`;
  }

  return `${(hour + increment).toString().padStart(2, '0')}:${minute.padStart(
    2,
    '0',
  )}`;
}

export default incrementHour;
