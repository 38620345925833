import React from 'react';
import { Line } from 'react-konva';
import PropTypes from 'prop-types';

const MouthToEye = ({ filteredDots, stroke, strokeWidth }) => {
  const IIE = filteredDots.find((v) => v.categoryId === 'IIE');
  const IID = filteredDots.find((v) => v.categoryId === 'IID');
  const ABE = filteredDots.find((v) => v.categoryId === 'ABE');
  const ABD = filteredDots.find((v) => v.categoryId === 'ABD');
  const plusY = 20;

  return (
    <>
      <Line
        strokeWidth={strokeWidth}
        stroke={stroke}
        points={[IIE.x, IIE.y, IIE.x, ABE.y + plusY]}
      />
      <Line
        strokeWidth={strokeWidth}
        stroke={stroke}
        points={[ABE.x, ABE.y, ABD.x, ABD.y]}
      />
      <Line
        strokeWidth={strokeWidth}
        stroke={stroke}
        points={[IID.x, IID.y, IID.x, ABD.y + plusY]}
      />
    </>
  );
};

MouthToEye.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

MouthToEye.defaultProps = {
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default MouthToEye;
