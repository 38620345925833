import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import SearchNavBar from '../NavBar/SearchNavBar/SearchNavBar';

const PatientLayout = ({ children }) => (
	<MainLayout customNavBar={<SearchNavBar title='Pacientes' />}>
		{children}
	</MainLayout>
);

PatientLayout.propTypes = {
	children: PropTypes.node,
};
PatientLayout.defaultProps = {
	children: '',
};
export default observer(PatientLayout);
