import { add, format } from 'date-fns';

/**
 * This function will always return the current hour on GMT timezone
 *
 * @returns {number}
 */
export function nowWithoutTz() {
	return Date.now() - millisecondsTzOffset();
}

export function dateWithoutTz(date) {
	return add(date, { seconds: secondsTzOffset() });
}

export function millisecondsTzOffset() {
	return new Date().getTimezoneOffset() * 60 * 1000;
}

export function secondsTzOffset() {
	return new Date().getTimezoneOffset() * 60;
}

export function formatTz(date, pattern, options) {
	return format(dateWithoutTz(date), pattern, options);
}

/**
 * @returns {string}
 */
export function timeNow(date = new Date()) {
	return `${date.getHours().toString().padStart(2, '0')}:${date
		.getMinutes()
		.toString()
		.padStart(2, '0')}`;
}

// recebe horário no formato string 00:00 e retorna minutos que se passaram desde 00hrs
export function getTimeInMinutes(time) {
	return parseInt(time?.slice(0, 2), 10) * 60 + parseInt(time?.slice(3, 5), 10);
}

// Calcula a duração do evento no dia. caso o evento dure mais que um dia, ele irá considerar que terminou meia-noite do primeiro dia.
//  Formato de data e hora que a função espera receber: string hh:mm  yyyy/mm/dd
export function getDurationInMinutes(
	startTime,
	endTime,
	startDate = '0000/00/00',
	endDate = '0000/00/00',
) {
	const start =
		parseInt(startTime.slice(0, 2), 10) * 60 +
		parseInt(startTime.slice(3, 5), 10);
	const end =
		parseInt(endTime.slice(0, 2), 10) * 60 + parseInt(endTime.slice(3, 5), 10);

	if (startDate === endDate) {
		return end - start;
	}

	return 1440 - start;
}

/* verifica se o segundo evento inicia antes de o primeiro ter terminado, considerando que os horários de início dos eventos 
já estão ordenados. Recebe uma matriz, e o índice do elemento que está sendo verificado */
export function theEventStartsInTheSamePeriodAsAnotherOne(
	allEvents,
	currentEventIndex,
) {
	const eventInTheSamePeriod = allEvents.find(
		(event, i) =>
			getTimeInMinutes(allEvents[currentEventIndex].startTime) <=
				getTimeInMinutes(event.endTime) && i < currentEventIndex,
	);

	if (eventInTheSamePeriod) return true;

	return false;
}

// 2022-08-31 + 1 day
export function getTodayDate() {
	const date = new Date();
	const month = date.getMonth() + 1;

	return `${date.getFullYear()}-${month >= 10 ? month : `0${month}`}-${date
		.getDate()
		.toString()
		.padStart(2, '0')}`;
}

// Pega o horário de agora
export function getHourNow(date = new Date()) {
	return [
		date.getHours().toString().padStart(2, '0'),
		date.getMinutes().toString().padStart(2, '0'),
	].join(':');
}

// Incrementa uma hora ao horário atual ou um valor específico à uma hora específica
export function incrementHour(time = getHourNow(), incrementConstant = 1) {
	const arrTime = time.split(':');

	const hour = arrTime[0];
	const minute = arrTime[1];

	const incrementedHour = Number(hour) + incrementConstant;

	return `${incrementedHour}:${minute}`;
}

// // Incrementa uma hora ao horário atual ou um valor específico à uma hora específica
// export function incrementHour(time = getHourNow(), incrementConstant = 1) {
// 	const arrTime = time.split(':');

// 	let hour = arrTime[0];
// 	let minute = arrTime[1];
// 	const incrementConstantToMinute = incrementConstant*60;
// 	const incrementedMinuteTime = minute + incrementConstantToMinute

// 	// se minute + incrementConstantToMinutes for maior que 59, dividir 59 e incrementar o resultado hora. O resto deverá ser passado para minutos e incrementado
// 	if (incrementedMinuteTime > 59) {
// 		hour += incrementedMinuteTime %
// 	}

// 	hour = Number(hour) + incrementConstant;

// 	return `${hour}:${minute}`;
// }

// Incrementa um dia à data atual ou um valor específico à um dia específico
export function incrementDay(date = getTodayDate()) {
	const arrDate = date.split('-');
	const incrementConstant = 1;

	let day = Number(arrDate[0]);
	let month = Number(arrDate[1]);
	const year = Number(arrDate[2]);

	// caso o número do mês seja par
	if (month % 2 === 0) {
		// caso seja fevereiro
		if (month === 2) {
			// caso o dia seja menor que 28
			if (day < 28) {
				day += incrementConstant;
			}
			// senão voltará para o dia 1
			day = 1;
			month += 1;
		}
		// caso não seja
		if (day < 31) {
			day += incrementConstant;
		}
		// senão voltará para o dia 1
		day = 1;
		month += 1;
	}
	// senão terá 30 dias
	else {
		if (day < 30) {
			day += incrementConstant;
		}
		// senão voltará para o dia 1
		day = 1;
		month += 1;
	}

	return `${year}-${month}-${day}`;
}

export function isAnEventGreaterThanDayWithMultipleRenders(
	startTime,
	endTime,
	startDate,
	endDate,
) {
	if (isThisDateGreaterThanAnother(startDate, endDate)) {
		if (getTimeInMinutes(endTime) < getTimeInMinutes(startTime)) {
			return true;
		}

		return false;
	}

	return false;
}

export function isThisDateGreaterThanAnother(initialDate, finalDate) {
	const firstDate = new Date(initialDate);
	const secondDate = new Date(finalDate);

	if (secondDate.getYear() < firstDate.getYear()) {
		return false;
	}

	if (secondDate.getMonth() < firstDate.getMonth()) {
		return false;
	}

	if (secondDate.getDate() <= firstDate.getDate()) {
		return false;
	}

	return true;
}

export function parseDateToString(date = new Date()) {
	const month = date.getMonth() + 1;

	return `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${date
		.getDate()
		.toString()
		.padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date
		.getMinutes()
		.toString()
		.padStart(2, '0')}`;
}
