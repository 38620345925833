const CellPhoneOutline = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 15 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_780_101230)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.125 0.875H4.875C4.64294 0.875 4.42038 0.967187 4.25628 1.13128C4.09219 1.29538 4 1.51794 4 1.75V12.25C4 12.4821 4.09219 12.7046 4.25628 12.8687C4.42038 13.0328 4.64294 13.125 4.875 13.125H10.125C10.3571 13.125 10.5796 13.0328 10.7437 12.8687C10.9078 12.7046 11 12.4821 11 12.25V1.75C11 1.51794 10.9078 1.29538 10.7437 1.13128C10.5796 0.967187 10.3571 0.875 10.125 0.875ZM4.875 0C4.41087 0 3.96575 0.184374 3.63756 0.512563C3.30937 0.840752 3.125 1.28587 3.125 1.75V12.25C3.125 12.7141 3.30937 13.1592 3.63756 13.4874C3.96575 13.8156 4.41087 14 4.875 14H10.125C10.5891 14 11.0342 13.8156 11.3624 13.4874C11.6906 13.1592 11.875 12.7141 11.875 12.25V1.75C11.875 1.28587 11.6906 0.840752 11.3624 0.512563C11.0342 0.184374 10.5891 0 10.125 0L4.875 0Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.5 12.25C7.73206 12.25 7.95462 12.1578 8.11872 11.9937C8.28281 11.8296 8.375 11.6071 8.375 11.375C8.375 11.1429 8.28281 10.9204 8.11872 10.7563C7.95462 10.5922 7.73206 10.5 7.5 10.5C7.26794 10.5 7.04538 10.5922 6.88128 10.7563C6.71719 10.9204 6.625 11.1429 6.625 11.375C6.625 11.6071 6.71719 11.8296 6.88128 11.9937C7.04538 12.1578 7.26794 12.25 7.5 12.25Z'
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id='clip0_780_101230'>
				<rect
					width={size}
					height={size}
					fill={color}
					transform='translate(0.5)'
				/>
			</clipPath>
		</defs>
	</svg>
);
export default CellPhoneOutline;
