const Photo = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'black', ...rest },
}) => (
  <svg
    width={size * 1.127}
    height={size}
    viewBox='0 0 118 133'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <rect
      x='0.5'
      y='0.5'
      width={size * 0.8763}
      height={size * 0.9914}
      rx='3.5'
      stroke={color}
      fill={rest.background}
      strokeOpacity='0.6'
    />
    <rect
      x='6.13672'
      y='6.13623'
      width={size * 0.7916}
      height={size * 0.7795}
      rx='3.5'
      stroke={color}
      strokeOpacity='0.6'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.559 101.455C24.559 101.455 17.7148 101.455 17.7148 94.6104C17.7148 87.7663 24.559 67.2338 58.7798 67.2338C93.0006 67.2338 99.8447 87.7663 99.8447 94.6104C99.8447 101.455 93.0006 101.455 93.0006 101.455H24.559ZM58.7798 60.3896C64.2253 60.3896 69.4478 58.2264 73.2984 54.3758C77.149 50.5252 79.3122 45.3027 79.3122 39.8572C79.3122 34.4116 77.149 29.1891 73.2984 25.3385C69.4478 21.4879 64.2253 19.3247 58.7798 19.3247C53.3342 19.3247 48.1117 21.4879 44.2611 25.3385C40.4105 29.1891 38.2473 34.4116 38.2473 39.8572C38.2473 45.3027 40.4105 50.5252 44.2611 54.3758C48.1117 58.2264 53.3342 60.3896 58.7798 60.3896V60.3896Z'
      fill={color}
      fillOpacity='0.6'
    />
  </svg>
);

export default Photo;
