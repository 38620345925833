import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import ComparativeViewMobile from './ComparativeMobile/ComparativeViewMobile';
import ComparativeViewDesktop from './ComparativeDesktop/ComparativeViewDesktop';
import { useIsMobile } from '../../../hooks';

const ComparativeView = () => {
  const isMobile = useIsMobile();
  const comparativeView = useMemo(() => {
    if (!isMobile) {
      return <ComparativeViewDesktop />;
    }

    return <ComparativeViewMobile />;
  }, [isMobile]);

  return comparativeView;
};

export default observer(ComparativeView);
