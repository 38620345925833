import { useEffect, useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';

const ORIENTATION = {
  landscape: 'landscape',
  portrait: 'portrait',
};

export default function useIsMobile() {
  const { width, height } = useWindowSize();
  const [orientation, setOrientation] = useState(
    width <= height ? ORIENTATION.portrait : ORIENTATION.landscape,
  );
  const [isMobile, setIsMobile] = useState(
    (orientation === ORIENTATION.landscape &&
      (width < 1024 &&
      height < 576)) || orientation === ORIENTATION.portrait &&
      width < 576 || window.navigator.userAgent?.toLowerCase().indexOf('ipad') !== -1
  );

  useEffect(() => {
    if (width <= height) {
      setOrientation(ORIENTATION.portrait);
    } else {
      setOrientation(ORIENTATION.landscape);
    }
  }, [width, height]);

  useDebounce(
    () =>
      setIsMobile(
        (orientation === ORIENTATION.landscape &&
          (width < 1024 &&
          height < 576)) || orientation === ORIENTATION.portrait &&
          width < 576 || window.navigator.userAgent?.toLowerCase().indexOf('ipad') !== -1
      ),
    250,
    [(width, orientation, height)],
  );

  return isMobile;
}
