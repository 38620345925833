/* eslint-disable react/require-default-props */
import { observer } from 'mobx-react';
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useStore } from '../../hooks';
import IconComponent from '../Svg/IconComponent/IconComponent';
import HiddenRequestRow from './Row/HiddenRequestRow';

const HiddenRequest = () => {
  const { defaultStore, galleryStore, patientsStore } = useStore();

  const [isOpen, setIsOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [transferDataValues, setTransferDataValues] = useState([]);
  const ref = useRef();

  let transferedDataValues = [];

	useEffect(() => {
		if (!ref.current) {
			return false;
		}

		const resizeObserver = new ResizeObserver(() => {
		defaultStore.setHiddenRequestBlackBox(ref.current?.offsetWidth);
		})

   		resizeObserver.observe(ref.current);
	}, [ref?.current?.offsetWidth]);


  const handleCancelRequest = (i) => {
    defaultStore.setCancelRequest(true);
    defaultStore.sourceAxios[i]?.cancel();

    if (!defaultStore.sourceAxios[i]) {
      defaultStore.resetTransferData();
    }

    defaultStore.updateTransferData(Object.keys(defaultStore.transferData)[i], {
      done: true,
      error: 'Operação cancelada pelo usuário.',
      name: Object.keys(defaultStore.transferData)[i],
    });
  };

  const cancelUpload = () => {
    Object.keys(defaultStore.transferData).forEach((_, i) => {
      handleCancelRequest(i);
    });

    defaultStore.resetTransferData();
    defaultStore.resetSourceAxios();
    defaultStore.resetToTransfer();
    
    setOpenModal(false);
  };

  const handleClose = () => {
    if (transferedDataValues?.length < transferDataValues?.length && defaultStore.toTransfer?.length > 0 && defaultStore.hasError === false) {
      setOpenModal(true);
    } else {
      setOpenModal(false);

      setTransferDataValues([]);

      defaultStore.resetSourceAxios();
      defaultStore.resetTransferData();
      defaultStore.resetToTransfer();
      defaultStore.setIsHiddenRequestOpen(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    defaultStore.transferData = {};
  };

  useLayoutEffect(() => {
    if (Object.values(defaultStore.transferData)[0]) {
      setTransferDataValues(Object.values(defaultStore.transferData));
      return;
    }
    setTransferDataValues([]);
  }, [JSON.stringify(defaultStore.transferData)]);

  /**
   * Verificar se todos os arquivos já foram carregados
   * Caso não tenham sido o modal não pode abrir. Além disso, é necessário verificar se o
   * o tamanho do array é diferente de 0, nesse caso o modal também não poderá abrir.
   */
  useEffect(() => {
    if (transferDataValues) {
      if (
        transferDataValues.length === transferedDataValues?.length &&
        transferDataValues.length !== 0
      ) {
        if (
          defaultStore.hiddenRequestModalProps.text ||
          defaultStore.hiddenRequestModalProps.title
        ) {
          document.getElementById('btn-show-modal-hidden-request').click();
        }
        setIsOpen(true);
        defaultStore.setIsHiddenRequestOpen(true);
      }
    }
  }, [
    JSON.stringify(transferDataValues),
    JSON.stringify(transferedDataValues),
  ]);

  const containError = transferDataValues.map(item => item.error).includes(true);

  transferedDataValues = transferDataValues?.filter(
    (transfer) => transfer && transfer.done === true,
  );

  return (
    <div
      className='container position-fixed bottom-0 end-0'
      id='hidden-request-container'
    >
      <div
        className='row justify-content-end'
      >
        <div
          ref={ref}
          className={`${
            transferDataValues.length === 0 ? 'd-none' : ''
          } col col-sm-10 col-md-8 col-lg-5 shadow overflow-auto max-vh-100 text-white bg-dark mh-100vh zi-1300 `}
          id='hidden-request-black-box'
          style={patientsStore.containerPatientButtonsHeight > 0 ? {'marginBottom': patientsStore.containerPatientButtonsHeight}: {'marginBottom': 0}}
        >
          <div className='row align-items-center py-1 py-md-2 py-xl-3 px-2 color-picker-bg-gray'>
            <div className='col-9 col-sm-10 d-flex'>
              <p className='my-1'>{` ${
                galleryStore.isFetching || defaultStore.isFetching === true
                  ? 'Upload em andamento'
                  : (containError ? 'Falha no Upload' : 'Upload concluído')
                }`}
              </p>
            </div>
            <div className='d-flex col-3 col-sm-2 justify-content-around'>
              <div className='p-1'>
                <button
                  type='button'
                  id='btn-open-close-hidden-request'
                  className='m-0 p-0 border-0 d-flex bg-transparent'
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <IconComponent
                    group='standard'
                    name={isOpen ? 'chevrondown' : 'chevronup'}
                    color='#fff'
                    size='24'
                  />
                </button>
              </div>
              <div className='p-1'>
                <button
                  type='button'
                  id='btn-close-modal-hidden-request'
                  className='text-white border-0 button-size bg-transparent'
                  onClick={handleClose}
                  >
                  X
                </button>
              </div>
            </div>
          </div>
          <div>
            {isOpen && transferDataValues?.length > 0
              ? transferDataValues.map((transferDataValue, i) => (
                  <div key={i}>
                    {/* Para testar, descomente o código abaixo */}
                    {/* <HiddenRequestRow
                          cancel={() => handleCancelRequest(i)}
                          key={`HiddenRequestRow@${i}`}
                          name={transferDataValue?.name}
                          error={transferDataValue?.error}
                          done={transferDataValue?.done}
                        /> */}
                      <div
                        className='d-flex justify-content-between py-3 w-100 align-items-center w-100 opacity-75'
                        key={`transferData@${i}`}
                        id={`transferData@${i}`}
                      >
                        <HiddenRequestRow
                          cancel={() => handleCancelRequest(i)}
                          key={`HiddenRequestRow@${i}`}
                          name={transferDataValue?.name}
                          error={transferDataValue?.error}
                          done={transferDataValue?.done}
                          importId={transferDataValue?.importId}
                          // progress={transferDataValue?.progress}
                        />
                      </div>
                  </div>
                ))
              : null}
          </div>
          <div>
            {openModal && (
              <Modal
                show={openModal}
                onHide={handleCloseModal}
                className='cancel-modal-position'
              >
                <Modal.Header closeButton className='p-4'>
                  <Modal.Title className='col'>
                    <h1>Cancelar Upload</h1>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='align-self-center p-4'>
                  Seu upload não foi concluído. Quer cancelar o upload?
                </Modal.Body>
                <Modal.Footer className='justify-content-center g-4 p-4'>
                  <Button
                    className='text-white'
                    variant='secondary'
                    onClick={() => setOpenModal(false)}
                  >
                    Continuar Upload
                  </Button>
                  <Button
                    variant='danger'
                    className='text-white'
                    onClick={cancelUpload}
                  >
                    Cancelar Upload
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(HiddenRequest);
