import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import Overlay from '../../../Styleguide/Overlay/Overlay';
import DotsTable from '../DotsTable/DotsTable';

const DotsModal = ({ show, toggleShow }) => (
  <>
    <Modal show={show} onHide={() => toggleShow()}>
      <Modal.Header closeButton>
        <Modal.Title className='w-100'>
          <div className='d-flex w-100 justify-content-center'>
            <h6 className='d-flex w-100 px-4 justify-content-center'>
              Gerenciar pontos
            </h6>
            <div className='d-flex w-25 justify-content-end'>
              <Overlay
                className='d-flex p-5'
                placement='end'
                title='Ajuda'
                icon={{
                  group: 'standard',
                  name: 'question',
                  color: '#c0c5ca',
                }}
              />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DotsTable closeAction={toggleShow} />
      </Modal.Body>
    </Modal>
  </>
);

DotsModal.propTypes = {
  show: PropTypes.bool,
  toggleShow: PropTypes.func,
};

DotsModal.defaultProps = {
  show: false,
  toggleShow: () => {},
};

export default observer(DotsModal);
