import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import ANALYSIS_TRANSLATION from '../../../constants/AnalysisTranslation';

const InputSelect = ({
  id,
  value,
  label,
  placeholder,
  error,
  disabled,
  isInvalid,
  options,
  noEmpty = false,
  ...rest
}) => (
  <Form.Group className='mb-4'>
    <Form.Label>{label}</Form.Label>
    <Form.Select
      id={id}
      name={id}
      value={value}
      disabled={disabled}
      isInvalid={isInvalid}
      className={value ? '' : `text-grey`}
      {...rest}
    >
      {!noEmpty && (
        <option className='text-dark' value=''>
          {placeholder}
        </option>
      )}
      {options.map((v, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`option_${index}`}>
          {!!v?.key && !!v?.text && (!!v?.value || v?.value === '') ? (
            <option className='text-dark' key={v.key} value={v.value}>
              {v.text}
            </option>
          ) : (
            Object.keys(v).map((val) => (
              <optgroup key={val} label={ANALYSIS_TRANSLATION[val]}>
                {v?.[val].map((option) => (
                  <option
                    className='text-dark'
                    key={option.key}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                ))}
              </optgroup>
            ))
          )}
        </Fragment>
      ))}
    </Form.Select>
    <Form.Control.Feedback type='invalid' className={isInvalid ? 'd-block' : 'd-none'}>{error}</Form.Control.Feedback>
  </Form.Group>
);

export default observer(InputSelect);

InputSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  noEmpty: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

InputSelect.defaultProps = {
  id: '',
  value: '',
  label: '',
  placeholder: '',
  error: '',
  disabled: false,
  isInvalid: false,
  noEmpty: false,
  options: [],
};
