import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';

import { useStore } from '../../../../hooks';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import FacemeshMobx from '../Facemesh/FacemeshMobx';
import {
  IMAGE_HEIGHT_EXPORT,
  IMAGE_WIDTH_EXPORT,
} from '../../../../constants/ToolsConstants';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../../constants/Colors';
import FeedbackBadge from '../../../Styleguide/FeedbackBadge/FeedbackBadge';
// import StandardLoader from '../../../Styleguide/StandardLoader/StandardLoader';

const ExportComparativeModal = ({ isOpen, setIsOpen }) => {
  const store = useStore();
  const history = useHistory();

  const { id: patientId } = useParams();

  const comparativeRef = useRef([]);
  const [showAnalysis, setShowAnalysis] = useState(true);
  const { width } = useWindowSize();

  const [showInfo, setShowInfo] = useState(false);

  const setRef = (ref, index) => {
    comparativeRef.current[index] = ref;
  };

  const handleClickSee = async () => {
    if (store.toolsStore.imagesComparative) {
      history.push(
        `/patients/${patientId}/appointment/${store.toolsStore.imagesComparative[0].albumId}`,
      );
    }

    document.getElementById('btn-close-modal-hidden-request').click();
  };

  const exportComparative = async () => {
    setIsOpen();

    store.comparativeStore.setIsFetching(true);

    document
      .getElementById('loader-comparative-desktop')
      ?.classList.add('d-block');

    document
      .getElementById('hidden-request-container')
      ?.classList.add('d-none');

    const link = document.getElementById('a-to-download-comparative-report');

    const handleClick = async () => {
      const { data } = await store.patientsStore.getPatientsById(patientId);

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yy = today.getFullYear();
      const hh = String(today.getHours()).padStart(2, '0');
      const min = String(today.getMinutes()).padStart(2, '0');
      const ss = String(today.getSeconds()).padStart(2, '0');
      const separateName = data.name.replace(/\s/g, '-');

      const comparativeReportName = `relatorio-comparativo-analise-${separateName}-${dd}-${mm}-${yy}-${hh}-${min}-${ss}`;

      link.download = comparativeReportName;
      link.click();

      document.getElementById('btn-close-modal-hidden-request')?.click();

      document
        .getElementById('hidden-request-container')
        ?.classList.add('d-block');
    };

    try {
      store.defaultStore.setTransferData(
        'Exportação de relatório de comparativo',
      );

      store.defaultStore.setHiddenRequestModalProps({
        title: 'Export de relatório de comparativo',
      });

      comparativeRef.current.forEach(
        async (v) =>
          // eslint-disable-next-line
          await v.exportComparative(),
      );

      const firstId = store.toolsStore.imagesComparative[0].id;
      const secondId = store.toolsStore.imagesComparative[1].id;

      const result = await store.comparativeStore.exportComparative(
        showInfo,
        store.analysisStore.analysisActive,
        store.comparativeStore.comparativeImages,
        firstId,
        secondId,
      );

      if (result) {
        store.defaultStore.setTransferData(null, {
          done: true,
          name: 'Exportação de relatório de comparativo',
        });

        store.defaultStore.setHiddenRequestModalProps({
          text: (
            <FeedbackBadge
              icon={{
                group: 'standard',
                name: 'done',
                color: COLORS.success,
                size: 80,
              }}
              text={
                <>
                  Relatório exportado <br /> com sucesso!
                </>
              }
              textColor='dark'
            />
          ),
          buttonLabel: 'Ver relatório',
          buttonAction: handleClickSee,
          secondButtonLabel: 'Fazer download',
          secondButtonAction: handleClick,
        });

        document
          .getElementById('loader-comparative-desktop')
          ?.classList.remove('d-block');

        store.comparativeStore.setIsFetching(false);

        return true;
      }

      store.defaultStore.showExportationError('loader-comparative-desktop');

      store.comparativeStore.setIsFetching(false);

      return true;
    } catch (e) {
      store.defaultStore.showExportationError('loader-comparative-desktop');

      store.comparativeStore.setIsFetching(false);
    }
    return true;
  };

  useEffect(() => {
    comparativeRef.current = comparativeRef.current.slice(
      0,
      store.toolsStore.imagesComparative.length,
    );
  }, [store.toolsStore.imagesComparative]);

  useEffect(() => () => store.comparativeStore.cleanExportMap(), []);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <ModalComponent
        className='d-flex flex-fill h-100 flex-column w-100'
        title={
          <div
            className='d-flex flex-fill justify-content-center'
            style={{ whiteSpace: 'normal' }}
          >
            <span>
              Exportar {width < 768 ? <br /> : null} comparativo de fotos
            </span>
          </div>
        }
        subTitle=' '
        onClose={() => {
          setIsOpen();
        }}
        show
        hasButton
        isLoading={store.toolsStore.imagesComparative.length !== 2}
        disabled={!(store.toolsStore.imagesComparative.length === 2)}
        nameButton={
          <>
            <div className='d-flex flex-row align-items-center'>
              <a
                id='a-to-download-comparative-report'
                href='/'
                className='d-none'
              >
                a
              </a>
              <IconComponent
                className='mb-1'
                group='standard'
                name='download'
                size='29'
                width='42'
                color={COLORS.white}
              />
              <span className='fs-5'>Exportar agora</span>
            </div>
          </>
        }
        idButton='btn-comparative-export-now'
        onClickButton={() => exportComparative()}
        size={width < 768 ? 'sm' : 'lg'}
      >
        <div className='d-flex flex-column justify-content-between h-100 w-100 px-md-4'>
          <div className='px-2'>
            <span className=''>Opções de exportação</span>
            <br />
            <br />
            <Form.Check
              type='checkbox'
              label='Fotos'
              checked
              disabled
              className='my-2 fs-analysis'
            />
            <Form.Check
              type='checkbox'
              label='Análise facial selecionada'
              checked={showAnalysis}
              onChange={(e) => setShowAnalysis(e.currentTarget.checked)}
              className='my-3 fs-analysis'
            />
            <Form.Check
              type='checkbox'
              label='Anotações'
              checked={showInfo}
              onChange={(e) => setShowInfo(e.currentTarget.checked)}
              className='my-2 fs-analysis'
            />
          </div>
          <br />
        </div>
        <div className='d-none'>
          {store.toolsStore.imagesComparative?.map((i, index) => (
            <FacemeshMobx
              key={i.id}
              exporting
              timeInterval={index}
              ref={(ref) => setRef(ref, index)}
              show={showAnalysis}
              comparative
              width={IMAGE_WIDTH_EXPORT}
              height={IMAGE_HEIGHT_EXPORT}
              image={i}
              patientId={i.patient_id}
              disableDots
            />
          ))}
        </div>
      </ModalComponent>
    </>
  );
};

export default observer(ExportComparativeModal);

ExportComparativeModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

ExportComparativeModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
};
