import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ANALYSIS_TRANSLATION from '../../../../../constants/AnalysisTranslation';
import IconComponent from '../../../../Svg/IconComponent/IconComponent';
import useToggle from '../../../../../hooks/useToggle';

const ThirdsProportion = ({ showText, analysis }) => {
  const [details, toggleDetails] = useToggle(false);

  return (
    <div className='d-flex flex-column '>
      {details ? (
        <div className='flex-fill p-2'>
          {showText && (
            <>
              <div className='text-primary d-flex flex-row justify-content-between'>
                <p className='text-white m-0'>Diferença:</p>
                <div className='ms-2'>
                  <IconComponent
                    group='analysis'
                    name='minimize'
                    size='16'
                    color='white'
                    onClick={() => toggleDetails()}
                  />
                </div>
              </div>
              {Object.keys(analysis.deviation_acceptable).map((v) => (
                <Fragment key={v.concat('deviation')}>
                  <span className='text-white'>
                    {`${ANALYSIS_TRANSLATION[v]}`}:
                  </span>
                  <span className='text-success'>
                    {`${(analysis.deviation_acceptable[v] * 100).toFixed(2)}%`}
                  </span>
                  <br />
                </Fragment>
              ))}
              <br />
              <p className='text-white m-0'>Obtido:</p>
              {Object.keys(analysis.proportion).map((v) => (
                <Fragment key={v.concat('proportion')}>
                  <span className='text-white'>
                    {`${ANALYSIS_TRANSLATION[v]}`}:
                  </span>
                  <span className='text-warning'>
                    {`${(analysis.proportion[v] * 100).toFixed(2)}%`}
                  </span>
                  <br />
                </Fragment>
              ))}
              <br />
              <p className='text-white m-0'>Referência:</p>
              {Object.keys(analysis.proportion).map((v) => (
                <Fragment key={v.concat('proportion')}>
                  <span className='text-white'>
                    {`${ANALYSIS_TRANSLATION[v]}`}:
                  </span>
                  <span className='text-white'>
                    {`${(analysis.base[v] * 100).toFixed(2)}%`}
                  </span>
                  <br />
                </Fragment>
              ))}
            </>
          )}
        </div>
      ) : (
        <div className='flex-fill p-2'>
          <div className='text-primary d-flex flex-row justify-content-between'>
            <p className='text-white m-0'>Diferença:</p>
            <div className='ms-2'>
              <IconComponent
                group='analysis'
                name='expand'
                size='16'
                color='white'
                onClick={() => toggleDetails()}
              />
            </div>
          </div>
          {showText &&
            Object.keys(analysis.deviation_acceptable).map((v) => (
              <Fragment key={v.concat('deviation')}>
                <span className='text-white'>
                  {`${ANALYSIS_TRANSLATION[v]}`}:
                </span>
                <span className='text-success'>
                  {`${(analysis.deviation_acceptable[v] * 100).toFixed(2)}%`}
                </span>
                <br />
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

ThirdsProportion.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

ThirdsProportion.defaultProps = {
  showText: false,
  analysis: null,
};

export default ThirdsProportion;
