const CalendarClock = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...rest}
	>
		<circle cx='12' cy='12' r='12' fill={color} />
		<path
			d='M12 7V13H17'
			stroke='white'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
	</svg>
);

export default CalendarClock;
