/* eslint-disable react/prop-types */
import React from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { useStore } from '../../hooks';
import CustomRouteComponent from './CustomRouteComponent';

const PublicRoute = ({ component, layout: Layout, ...rest }) => {
  const store = useStore();
  const CustomLayout = Layout || React.Fragment;

  return (
    <Route
      {...rest}
      render={(props) =>
        store.authStore.isAuthenticated ? (
          <Redirect from='/*' to='/' />
        ) : (
          <CustomLayout>
            <CustomRouteComponent
              {...props}
              {...rest}
              key={document.location.href}
              component={component}
            />
          </CustomLayout>
        )
      }
    />
  );
};

export default withRouter(observer(PublicRoute));
