import { format, isAfter, parse, toDate } from 'date-fns';
import * as Yup from 'yup';

import {
  PAYMENT_TYPE_OPTIONS,
} from '../../constants/SelectOptions';
import {
  ValidationRules as VR,
  ValidationMessages as VM,
} from '../../constants/Validations';

/**
 * @typedef {ReturnType<mapPropsToValues>} FormikValues
 */

/**
 * @typedef {import('formik').FormikContextType<FormikValues>} CheckoutValues
 */

/**
 * @typedef {typeof initialState} InitialState
 */

export const initialState = {
  /** @type {'billing'|'coupon'|'payment'} */
  activeTab: 'billing',
};

/**
 *
 * @param {InitialState} state
 * @param {function} action
 * @returns {InitialState}
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_TAB':
      if (action.payload === state.activeTab) return !state;
      return { ...state, activeTab: action.payload };

    default:
      return state;
  }
};

export const createActions = (dispatch) => ({
  /**
   *
   * @param {'billing'|'coupon'|'payment'} tab
   * @return {void}
   */
  setActiveTab(tab) {
    dispatch({ type: 'CHANGE_TAB', payload: tab });
  },
});

const today = new Date();

export const validationSchema = Yup.object().shape({
  billing: Yup.object().shape({
    personType: VR.personType,
    fullName: VR.name,
    cpfCnpj: VR.cpfCnpj,
    phone: VR.phone,
    zip: VR.zipCode,
    street: VR.street,
    number: VR.requiredString,
    district: VR.district,
    city: VR.city,
    uf: VR.uf,
  }),
  payment: Yup.object().shape({
    type: Yup.string()
      .oneOf(PAYMENT_TYPE_OPTIONS.map((payment) => payment.value))
      .required(VM.required),
    credit_card: Yup.mixed().when('type', {
      is: PAYMENT_TYPE_OPTIONS[0].value,
      then: () =>
        Yup.object()
          .shape({
            cvc: Yup.string()
              .matches(/\d{3}/, 'Valor de verificação inválido')
              .required(VM.required),
            expiry: Yup.string()
              .matches(
                /(0[1-9]|1[0-2])\/([2-9]\d{1})/,
                'O formato deve seguir o padrão: MM/AA',
              )
              .test({
                name: 'today',
                params: { today },
                message: `É necessário uma data maior ou igual à ${format(
                  today,
                  'MM/yy',
                )}`,
                test: (value) =>
                  value == null ||
                  value === '' ||
                  isAfter(toDate(parse(value, 'MM/yy', new Date())), today) ||
                  format(today, 'MM/yy') === value,
              })
              .required(VM.required),
            name: VR.name,
            number: Yup.string()
              .transform((value) => String(value).replace(/\s/g, ''))
              .matches(/\d{14,16}/, 'Valor de cartão inválido')
              .required(VM.required),
          })
          .required(VM.required),
    }),
    remember: Yup.bool(),
  }),
  coupon: Yup.object().shape({
    code: Yup.string(),
  }),
});

export const mapPropsToValues = () => ({
  billing: {
    personType: '',
    fullName: '',
    cpfCnpj: '',
    phone: '',
    zip: '',
    street: '',
    number: '',
    complement: '',
    city: '',
    district: '',
    uf: '',
  },
  payment: {
    type: PAYMENT_TYPE_OPTIONS[0].value,
    credit_card: {
      cvc: '',
      expiry: '',
      name: '',
      number: '',
    },
    invoice: {},
    remember: false,
  },
  coupon: {
    code: '',
  },
});

export {};
