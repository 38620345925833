import axios from 'axios';
import { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import toDataUrl from '../../stores/containers/toDataUrl';

const useFileReaderController = (file, cases) => {
	const [isOpenFileReaderModal, setIsOpenFileReaderModal] = useState(false);
	const { galleryStore } = useStore();
	const [reader, setReader] = useState(<></>);

	function matchExpressionWithProps(prop, expression, fileCases) {
		if (prop) {
			return fileCases(prop)[expression];
		}
		if (fileCases?.default) {
			return fileCases?.default;
		}

		return new Error('Não foi possível ler o arquivo');
	}

	const handleClickDownload = (name, url, anchor) => {
		toDataUrl(url).then((dataUrl) => {
			anchor.href = dataUrl;
			anchor.download = name;
			anchor.click();
		});
	};

	const handleSourceFile = async (urlFile, fileId) => {
		const imageRequest = axios.create({
			baseURL: urlFile,
		});

		try {
			await imageRequest.get();

			return fileObject.media;
		} catch (error) {
			const res = await galleryStore.getImage(fileId);

			return res.data.media;
		}
	};

	useEffect(() => {
		if (file) {
			setReader(matchExpressionWithProps(file, file.fileType, cases));
		}
	}, [file]);

	return {
		isOpenFileReaderModal,
		setIsOpenFileReaderModal,
		reader,
		handleClickDownload,
		handleSourceFile,
	};
};

export default useFileReaderController;
