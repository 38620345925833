import { toast } from "react-toastify";

const createAnchorToDownloadFile = async (url, name, fileRefresher) => {
	try {
		const blob = await fetch(url).then((res) => res.blob());

		if (blob) {
			const anchor = document.createElement('a');
			anchor.href = URL.createObjectURL(blob);
			anchor.download = name;
			anchor.click();

			return Promise.resolve(blob);
		}

		return new Error('Não foi possível encontrar o arquivo.');
	} catch (e) {
		toast.info('Não foi possível buscar o arquivo. Tentando novamente...');

		createAnchorToDownloadFile(fileRefresher?.data?.media, name);

		return Promise.reject(
			new Error(`Não foi possível buscar o arquivo. ${e.message}`),
		);
	}
};

export default createAnchorToDownloadFile;
