import PropTypes from 'prop-types';
import { PAYMENT_TYPE_OPTIONS } from '../../../constants/SelectOptions';

const InfoModalCheckOut = ({
  title,
  price,
  paymentType,
  discountCode,
  finalCardNumber,
}) => (
  <div className='d-flex flex-column flex-start fs-7'>
    <div>
      <strong>Dados da compra</strong>
    </div>
    <div>
      <span>{title}</span>
    </div>
    <div>
      <span>Valor: {price}</span>
    </div>
    <div>
      <span>Período: mensal (renovação automática)</span>
    </div>
    <div>
      <span>Método de pagamento:</span>
    </div>
    <div>
      <span>
        {paymentType === PAYMENT_TYPE_OPTIONS[0].value
          ? `Cartão de crédito: ************${finalCardNumber} `
          : 'Boleto'}
      </span>
    </div>

    <div className='d-flex flex-row justify-content-between'>
      <span>{discountCode ? `Cupom de desconto: ${discountCode}` : null}</span>
    </div>
  </div>
);

InfoModalCheckOut.propTypes = {
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  discountCode: PropTypes.string,
  paymentType: PropTypes.string,
  finalCardNumber: PropTypes.string,
};

InfoModalCheckOut.defaultProps = {
  title: '',
  price: 0,
  paymentType: '',
  discountCode: '',
  finalCardNumber: '',
};

export default InfoModalCheckOut;
