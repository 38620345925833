import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMeasure, useWindowSize } from 'react-use';
import SideBar from '../SideBar/SideBar';
import NavBar from '../NavBar/NavBar';

const MainLayout = ({ customNavBar, children }) => {
  const { width } = useWindowSize();
  const [ref, { height }] = useMeasure();
  const [navRef, nav] = useMeasure();


  return useMemo(() => {
    if (width < 576) {
      return (
        <div className='d-flex h-100 flex-sm-row flex-column w-100 '>
          <div
            className='d-flex flex-fill flex-column w-100 position-fixed fixed-top bg-white zi-1050'
            ref={ref}
          >
            <SideBar />
            {customNavBar || <NavBar />}
          </div>
          <div
          id='mobile-main-container'
            className=' d-flex flex-fill flex-column w-100'
            style={{ marginTop: `${height}px` }}
          >
            {children}
          </div>
        </div>
      );
    }
    return (
      <div className='d-flex flex-sm-row flex-column w-100'>
        <SideBar />
        <div className='d-flex flex-column w-100 h-100 align-content-stretch align-items-stretch justify-content-stretch  overflow-hidden'>
          <div ref={navRef} className="w-webkit-fill h-auto">
            {customNavBar || <NavBar />}
          </div>
          {children}
        </div>
     </div>
    )
  }, [width, ref, height, nav]);
};

MainLayout.propTypes = {
  children: PropTypes.node,
  customNavBar: PropTypes.node,
};
MainLayout.defaultProps = {
  children: '',
  customNavBar: '',
};
export default observer(MainLayout);
