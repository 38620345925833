import PropTypes from 'prop-types';

const DayOfMonth = ({ dateOfMonth, dayOfWeek }) => (
  <div className="position-absolute top-50 start-50 translate-middle d-md-flex h-md-100 align-items-center">
      <strong id='txt-date' className='fs-xs-4 fs-md-3 lh-xs mb-n1 '>{dateOfMonth}</strong>
      <p id='txt-day-of-week' className='fs-xs-7 fs-md-5 m-0 ms-md-2 lh-sm border-bottom border-sm-0 border-dark mt-n1 mt-md-0 mw-fit' >{dayOfWeek}</p>
  </div>
);

export default DayOfMonth;

DayOfMonth.propTypes = {
  dateOfMonth: PropTypes.string.isRequired,
  dayOfWeek: PropTypes.string.isRequired,
};
