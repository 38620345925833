const Note = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#383C42', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M5 2H12.5V3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H17C17.3978 21.5 17.7794 21.342 18.0607 21.0607C18.342 20.7794 18.5 20.3978 18.5 20V18L20 16.5V20C20 20.7957 19.6839 21.5587 19.1213 22.1213C18.5587 22.6839 17.7956 23 17 23H5C4.20435 23 3.44129 22.6839 2.87868 22.1213C2.31607 21.5587 2 20.7957 2 20V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2Z'
      fill={color}
    />
    <path d='M20 9.5H18.5V12L20 11V9.5Z' fill={color} />
    <path
      d='M12.5 7.25V2L20 9.5H14.75C14.1533 9.5 13.581 9.26295 13.159 8.84099C12.7371 8.41903 12.5 7.84674 12.5 7.25Z'
      fill={color}
    />
    <path
      d='M22.8873 11.6128C22.9595 11.6852 23 11.7833 23 11.8856C23 11.9878 22.9595 12.0859 22.8873 12.1583L22.0814 12.9649L20.536 11.4197L21.3419 10.6131C21.4143 10.5407 21.5126 10.5 21.615 10.5C21.7175 10.5 21.8157 10.5407 21.8882 10.6131L22.8873 11.6121V11.6128ZM21.5351 13.5103L19.9897 11.9651L14.7253 17.2296C14.6828 17.2721 14.6508 17.3239 14.6318 17.381L14.0098 19.246C13.9985 19.28 13.9969 19.3165 14.0052 19.3514C14.0135 19.3862 14.0312 19.4181 14.0566 19.4434C14.0819 19.4688 14.1138 19.4865 14.1487 19.4948C14.1835 19.5031 14.22 19.5015 14.254 19.4902L16.1193 18.8682C16.1763 18.8495 16.2281 18.8178 16.2707 18.7755L21.5351 13.5111V13.5103Z'
      fill={color}
    />
  </svg>
);
export default Note;
