import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import IconComponent from '../../Svg/IconComponent/IconComponent';

const DimmerDetails = (props) => {
  const { show, onClose, children } = props;

  return (
    <Container fluid>
      {show ? (
        <Row
          className='d-flex align-items-center position-absolute w-100 h-100'
          style={{
            backgroundColor: '#000',
            opacity: '0.8',
            maxHeight: '100px',
            top: 0,
          }}
        >
          <Col
            className='d-flex flex-column text-white justify-content-between w-100 h-100'
            style={{ fontSize: '0.9rem' }}
          >
            <div className='d-flex flex-row text-white justify-content-between w-100 h-100 align-items-center p-2'>
              {children}

              <IconComponent
                className='align-self-start'
                group='standard'
                name='closex'
                color='white'
                size='14'
                onClick={onClose}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Container>
  );
};

DimmerDetails.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

DimmerDetails.defaultProps = {
  children: '',
};

export default observer(DimmerDetails);
