const TutorialVideo = ( {
    // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
    <svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_876_105630)">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.3125 15.4727H1.6875C1.53832 15.4727 1.39524 15.4134 1.28975 15.3079C1.18426 15.2024 1.125 15.0593 1.125 14.9102V7.03516C1.125 6.88597 1.18426 6.7429 1.28975 6.63741C1.39524 6.53192 1.53832 6.47266 1.6875 6.47266H16.3125C16.4617 6.47266 16.6048 6.53192 16.7102 6.63741C16.8157 6.7429 16.875 6.88597 16.875 7.03516V14.9102C16.875 15.0593 16.8157 15.2024 16.7102 15.3079C16.6048 15.4134 16.4617 15.4727 16.3125 15.4727ZM1.6875 16.5977C1.23995 16.5977 0.810725 16.4199 0.494257 16.1034C0.17779 15.7869 0 15.3577 0 14.9102L0 7.03516C0 6.5876 0.17779 6.15838 0.494257 5.84191C0.810725 5.52545 1.23995 5.34766 1.6875 5.34766H16.3125C16.7601 5.34766 17.1893 5.52545 17.5057 5.84191C17.8222 6.15838 18 6.5876 18 7.03516V14.9102C18 15.3577 17.8222 15.7869 17.5057 16.1034C17.1893 16.4199 16.7601 16.5977 16.3125 16.5977H1.6875ZM2.25 3.66016C2.25 3.80934 2.30926 3.95241 2.41475 4.0579C2.52024 4.16339 2.66332 4.22266 2.8125 4.22266H15.1875C15.3367 4.22266 15.4798 4.16339 15.5852 4.0579C15.6907 3.95241 15.75 3.80934 15.75 3.66016C15.75 3.51097 15.6907 3.3679 15.5852 3.26241C15.4798 3.15692 15.3367 3.09766 15.1875 3.09766H2.8125C2.66332 3.09766 2.52024 3.15692 2.41475 3.26241C2.30926 3.3679 2.25 3.51097 2.25 3.66016V3.66016ZM4.5 1.41016C4.5 1.55934 4.55926 1.70241 4.66475 1.8079C4.77024 1.91339 4.91332 1.97266 5.0625 1.97266H12.9375C13.0867 1.97266 13.2298 1.91339 13.3352 1.8079C13.4407 1.70241 13.5 1.55934 13.5 1.41016C13.5 1.26097 13.4407 1.1179 13.3352 1.01241C13.2298 0.906919 13.0867 0.847656 12.9375 0.847656H5.0625C4.91332 0.847656 4.77024 0.906919 4.66475 1.01241C4.55926 1.1179 4.5 1.26097 4.5 1.41016V1.41016Z" fill="#F7F7F7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.04025 7.66821C7.12799 7.61957 7.22714 7.59527 7.32743 7.59784C7.42772 7.60041 7.5255 7.62976 7.61062 7.68284L12.1106 10.4953C12.1915 10.5459 12.2582 10.6162 12.3045 10.6997C12.3507 10.7831 12.375 10.8769 12.375 10.9723C12.375 11.0677 12.3507 11.1616 12.3045 11.245C12.2582 11.3285 12.1915 11.3988 12.1106 11.4493L7.61062 14.2618C7.52548 14.3151 7.42764 14.3445 7.32726 14.3471C7.22689 14.3498 7.12764 14.3255 7.03982 14.2768C6.952 14.2282 6.8788 14.1569 6.82784 14.0703C6.77688 13.9838 6.75 13.8852 6.75 13.7848V8.15984C6.75011 8.05945 6.77708 7.96091 6.82812 7.87447C6.87916 7.78802 6.9524 7.7168 7.04025 7.66821Z" fill="#F7F7F7"/>
        </g>
        <defs>
            <clipPath id="clip0_876_105630">
                <rect width={size} height={size} fill={color} transform="translate(0 0.285156)"/>
            </clipPath>
        </defs>
    </svg>
)

export default TutorialVideo