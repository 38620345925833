import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useStore } from '../../../hooks';

import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';

const RemoveUserAvatarModal = ({ open, toggleOpen }) => {
  const store = useStore();

  const handleResetAvatar = () => {
    const resetAvatar = store.authStore.resetUserAvatar();

    if (resetAvatar) {
      toggleOpen();
    }
  };

  return (
    <ModalComponent
      show={open}
      onClose={toggleOpen}
      title='Remover imagem'
      subTitle={`Tem certeza que deseja remover a imagem de usuário ${store.authStore.user?.name}?`}
      nameButton='Remover'
      idButton='delete-image'
      onClickButton={() => handleResetAvatar()}
    />
  );
};

export default observer(RemoveUserAvatarModal);

RemoveUserAvatarModal.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

RemoveUserAvatarModal.defaultProps = {
  open: false,
  toggleOpen: () => {},
};
