const DoubleQuoteOutline = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 15 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_780_101235)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.75 0.875H2.25C2.01794 0.875 1.79538 0.967187 1.63128 1.13128C1.46719 1.29538 1.375 1.51794 1.375 1.75V8.75C1.375 8.98206 1.46719 9.20462 1.63128 9.36872C1.79538 9.53281 2.01794 9.625 2.25 9.625H4.4375C4.70918 9.625 4.97713 9.68825 5.22012 9.80975C5.46312 9.93125 5.67449 10.1077 5.8375 10.325L7.5 12.5414L9.1625 10.325C9.32551 10.1077 9.53688 9.93125 9.77988 9.80975C10.0229 9.68825 10.2908 9.625 10.5625 9.625H12.75C12.9821 9.625 13.2046 9.53281 13.3687 9.36872C13.5328 9.20462 13.625 8.98206 13.625 8.75V1.75C13.625 1.51794 13.5328 1.29538 13.3687 1.13128C13.2046 0.967187 12.9821 0.875 12.75 0.875ZM2.25 0C1.78587 0 1.34075 0.184374 1.01256 0.512563C0.684374 0.840752 0.5 1.28587 0.5 1.75L0.5 8.75C0.5 9.21413 0.684374 9.65925 1.01256 9.98744C1.34075 10.3156 1.78587 10.5 2.25 10.5H4.4375C4.57334 10.5 4.70731 10.5316 4.82881 10.5924C4.95031 10.6531 5.056 10.7413 5.1375 10.85L6.8 13.0664C6.8815 13.175 6.98719 13.2632 7.10869 13.324C7.23019 13.3847 7.36416 13.4164 7.5 13.4164C7.63584 13.4164 7.76981 13.3847 7.89131 13.324C8.01281 13.2632 8.1185 13.175 8.2 13.0664L9.8625 10.85C9.944 10.7413 10.0497 10.6531 10.1712 10.5924C10.2927 10.5316 10.4267 10.5 10.5625 10.5H12.75C13.2141 10.5 13.6592 10.3156 13.9874 9.98744C14.3156 9.65925 14.5 9.21413 14.5 8.75V1.75C14.5 1.28587 14.3156 0.840752 13.9874 0.512563C13.6592 0.184374 13.2141 0 12.75 0L2.25 0Z'
				fill={color}
			/>
			<path
				d='M7.0345 4.95862C7.0345 5.76362 6.3555 6.41638 5.51725 6.41638C4.679 6.41638 4 5.76362 4 4.95862C4 4.15362 4.679 3.5 5.51725 3.5C6.3555 3.5 7.0345 4.15275 7.0345 4.95862Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.88711 4.31892C5.8727 4.36852 5.84841 4.4147 5.8157 4.45468C5.78298 4.49465 5.74253 4.5276 5.69676 4.55155C5.65099 4.5755 5.60086 4.58994 5.54936 4.59402C5.49787 4.5981 5.44608 4.59173 5.39711 4.57529C5.35063 4.56148 5.30739 4.53845 5.26999 4.50758C5.23259 4.4767 5.20179 4.43862 5.17942 4.39559C5.15705 4.35256 5.14357 4.30547 5.13978 4.25712C5.13599 4.20877 5.14197 4.16016 5.15736 4.11417C5.22736 3.91292 5.35861 3.72917 5.57474 3.63642C5.67212 3.59724 5.77642 3.57813 5.88136 3.58023C5.98631 3.58234 6.08975 3.60562 6.18549 3.64867C6.52411 3.78867 6.81549 4.12554 6.99311 4.52104C7.36761 5.35054 7.33699 6.60092 6.16886 7.76379C6.09757 7.83329 6.0024 7.8729 5.90285 7.87453C5.8033 7.87615 5.7069 7.83967 5.63336 7.77254C5.59751 7.74007 5.56869 7.7006 5.54867 7.65657C5.52865 7.61253 5.51785 7.56486 5.51695 7.5165C5.51605 7.46814 5.52506 7.4201 5.54342 7.37535C5.56179 7.3306 5.58912 7.29008 5.62374 7.25629C6.58011 6.30517 6.54949 5.36804 6.29749 4.81154C6.16449 4.51754 5.98774 4.36179 5.88624 4.31892H5.88711Z'
				fill={color}
			/>
			<path
				d='M10.8275 4.95862C10.8275 5.76362 10.1485 6.41638 9.31022 6.41638C8.47284 6.41638 7.79297 5.76362 7.79297 4.95862C7.79297 4.15362 8.47284 3.5 9.31022 3.5C10.1485 3.5 10.8275 4.15275 10.8275 4.95862Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.68008 4.31892C9.66567 4.36852 9.64138 4.4147 9.60866 4.45468C9.57595 4.49465 9.5355 4.5276 9.48973 4.55155C9.44396 4.5755 9.39382 4.58994 9.34233 4.59402C9.29084 4.59811 9.23905 4.59173 9.19008 4.57529C9.1436 4.56148 9.10036 4.53845 9.06296 4.50758C9.02556 4.4767 8.99476 4.43862 8.97239 4.39559C8.95002 4.35256 8.93653 4.30547 8.93275 4.25712C8.92896 4.20877 8.93494 4.16016 8.95033 4.11417C9.02033 3.91292 9.15158 3.72917 9.36771 3.63642C9.46509 3.59724 9.56938 3.57813 9.67433 3.58023C9.77928 3.58234 9.88272 3.60562 9.97846 3.64867C10.3171 3.78867 10.6085 4.12554 10.787 4.52104C11.1615 5.35054 11.13 6.60092 9.96271 7.76379C9.89137 7.83354 9.79602 7.87332 9.69626 7.87494C9.5965 7.87657 9.49991 7.83993 9.42633 7.77255C9.39048 7.74007 9.36166 7.7006 9.34164 7.65657C9.32162 7.61253 9.31082 7.56486 9.30992 7.5165C9.30902 7.46814 9.31803 7.4201 9.33639 7.37535C9.35476 7.3306 9.38209 7.29008 9.41671 7.2563C10.3731 6.30517 10.3425 5.36804 10.0905 4.81154C9.95746 4.51754 9.78071 4.36179 9.68008 4.31892Z'
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id='clip0_780_101235'>
				<rect
					width={size}
					height={size}
					fill={color}
					transform='translate(0.5)'
				/>
			</clipPath>
		</defs>
	</svg>
);
export default DoubleQuoteOutline;
