import { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IMask from 'imask';
import viaCepAPI from "../../../services/viaCepAPI";
import { setPhone } from '../../../utils/maskPhone';
import Input from '../../Styleguide/Input/Input';
import InputSelect from '../../Styleguide/InputSelect/InputSelect';
import {
  PEOPLE_TYPE_OPTIONS,
  STATE_OPTIONS,
} from '../../../constants/SelectOptions';
import sanatizeNumberField from '../../../utils/sanatizeNumber';

/**
 *
 *
 * @param {object} props
 * @param {boolean} [props.disabled=false]
 * @returns {JSX.Element}
 */
export default function BillingForm(props) {
  const { disabled } = props;

  /** @type {import('../../../screens/PlanCheckoutScreen/utils').CheckoutValues} */
  const formik = useFormikContext();

  const [userType, setUserType] = useState(null);

  const cnpjRef = useRef();
  const cpfRef = useRef();
  const phoneRef = useRef();
  const zipCodeRef = useRef();

  useMemo(() => {
    searchCep(formik.values.billing.zip);
  },[formik.values.billing.zip])
  
  useEffect(() => {
    if (formik.values.billing.personType === PEOPLE_TYPE_OPTIONS[1].value) {
      setUserType({
        name: 'Nome completo',
        credential: 'CPF',
        mask: '000.000.000-00',
      });
    } else if (
      formik.values.billing.personType === PEOPLE_TYPE_OPTIONS[2].value
    ) {
      setUserType({
        name: 'Razão social',
        credential: 'CNPJ',
        mask: '00.000.000/0000-00',
      });
    } else {
      setUserType(null);
    }
    if (cpfRef.current != null) {
      IMask(cpfRef.current, {
        mask: '000.000.000-00',
      });
    }
    if (cnpjRef.current != null) {
      IMask(cnpjRef.current, {
        mask: '00.000.000/0000-00',
      });
    }
    

    if (zipCodeRef.current != null) {
      IMask(zipCodeRef.current, {
        mask: '00.000-000',
      });
    }
  }, [formik.values]);

  const maskBuilder = (v) => {
    if (!v || v.length === 0) return '';
    const a = setPhone(v);
    return a.length >= 6 && a[5] === '9' ? '(99) 99999-9999' : '(99) 9999-9999';
  };

  async function searchCep(e) {
    if(String(e).length >= 8) {
      let cep;

      if(e) {
        cep = String(e)?.replace(/[^0-9]/g, '');
      } else {
        cep = String(e).replace(/\D/g, '');
      }

      if (cep.length !== 8) {
        return 0;
      }

      try {
        const viaCepRes = await viaCepAPI.get(`${cep}/json`);
        formik.setValues({
          ...formik.values,
          billing: {
            ...formik.values.billing,
            city: viaCepRes.data.localidade,
            street: viaCepRes.data.logradouro,
            uf: viaCepRes.data.uf,
            district: viaCepRes.data.bairro,
          }
        });
      } catch(error){
        toast.error(`Houve um erro ao buscar seu CEP automaticamente`);
      }
    }

    return true;
  } 

  return (
    <Form className='d-flex flex-column justify-content-between h-100'>
      <InputSelect
        label='Tipo de pessoa'
        options={PEOPLE_TYPE_OPTIONS}
        noEmpty
        disabled={disabled}
        onBlur={formik.handleBlur}
        value={formik.values.billing?.personType}
        isInvalid={
          formik.touched.billing?.personType &&
          !!formik.errors.billing?.personType
        }
        error={formik.errors.billing?.personType}
        onChange={(e) => {
          formik.setFieldValue('billing.personType', e.target.value);
        }}
      />
      {userType && (
        <>
          <Input
            id='billing.fullName'
            name='billing.fullName'
            label={userType.name}
            type='text'
            disabled={disabled}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.billing?.fullName}
            isInvalid={
              formik.touched.billing?.fullName &&
              !!formik.errors.billing?.fullName
            }
            error={formik.errors.billing?.fullName}
            maxLength={60}
          />
          {userType.credential === 'CPF' && (
            <Input
              ref={cpfRef}
              id='billing.cpfCnpj'
              name='billing.cpfCnpj'
              label={userType.credential}
              type='text'
              disabled={disabled}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.billing?.cpfCnpj}
              isInvalid={
                formik.touched.billing?.cpfCnpj &&
                !!formik.errors.billing?.cpfCnpj
              }
              error={formik.errors.billing?.cpfCnpj}
              maxLength={14}
            />
          )}
          {userType.credential === 'CNPJ' && (
            <Input
              ref={cnpjRef}
              id='billing.cpfCnpj'
              name='billing.cpfCnpj'
              label={userType.credential}
              type='text'
              disabled={disabled}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.billing?.cpfCnpj}
              isInvalid={
                formik.touched.billing?.cpfCnpj &&
                !!formik.errors.billing?.cpfCnpj
              }
              error={formik.errors.billing?.cpfCnpj}
              maxLength={18}
            />
          )}
        </>
      )}

      <Input
        ref={phoneRef}
        id='billing.phone'
        name='billing.phone'
        label='Telefone'
        type='text'
        mask={maskBuilder(formik.values.billing.phone)}
        disabled={disabled}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          sanatizeNumberField(
            setPhone(e.target.value),
            formik.setFieldValue,
            'billing.phone',
          )}
        }
        value={formik.values.billing?.phone}
        isInvalid={
          formik.touched.billing?.phone && !!formik.errors.billing?.phone
        }
        error={formik.errors.billing?.phone}
        maxLength={15}
      />

      <Input
        ref={zipCodeRef}
        id='billing.zip'
        name='billing.zip'
        label='CEP'
        type='text'
        disabled={disabled}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.billing?.zip}
        isInvalid={formik.touched.billing?.zip && !!formik.errors.billing?.zip}
        error={formik.errors.billing?.zip}
        maxLength='10'
      />

      <Input
        id='billing.street'
        name='billing.street'
        label='Endereço'
        type='text'
        disabled={disabled}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.billing?.street}
        isInvalid={
          formik.touched.billing?.street && !!formik.errors.billing?.street
        }
        error={formik.errors.billing?.street}
        maxLength={255}
      />

      <div className='row'>
        <div className='col-6'>
          <Input
            id='billing.number'
            name='billing.number'
            label='Número'
            type='text'
            disabled={disabled}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.billing?.number}
            isInvalid={
              formik.touched.billing?.number && !!formik.errors.billing?.number
            }
            error={formik.errors.billing?.number}
            maxLength={6}
          />
        </div>
      </div>

      <Input
        id='billing.complement'
        name='billing.complement'
        label='Complemento (opcional)'
        type='text'
        disabled={disabled}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.billing?.complement}
        isInvalid={
          formik.touched.billing?.complement &&
          !!formik.errors.billing?.complement
        }
        error={formik.errors.billing?.complement}
        maxLength={64}
      />

      <Input
        id='billing.district'
        name='billing.district'
        label='Bairro'
        type='text'
        disabled={disabled}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.billing?.district}
        isInvalid={
          formik.touched.billing?.district && !!formik.errors.billing?.district
        }
        error={formik.errors.billing?.district}
        maxLength={32}
      />

      <Input
        id='billing.city'
        name='billing.city'
        label='Cidade'
        type='text'
        disabled={disabled}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.billing?.city}
        isInvalid={
          formik.touched.billing?.city && !!formik.errors.billing?.city
        }
        error={formik.errors.billing?.city}
        maxLength={32}
      />

      <InputSelect
        id='billing.uf'
        name='billing.uf'
        label='UF'
        disabled={disabled}
        options={STATE_OPTIONS}
        type='search'
        autoComplete='off'
        onBlur={formik.handleBlur}
        value={formik.values.billing?.uf || ''}
        isInvalid={formik.touched.billing?.uf && !!formik.errors.billing?.uf}
        error={formik.errors.billing?.uf}
        onChange={(e) => {
          formik.setFieldValue('billing.uf', e.target.value);
        }}
        maxLength={2}
      />
    </Form>
  );
}

BillingForm.propTypes = {
  disabled: PropTypes.bool,
};

BillingForm.defaultProps = {
  disabled: false,
};
