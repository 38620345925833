import { action, makeAutoObservable, observable } from 'mobx';
import {
	formatTz,
	isAnEventGreaterThanDayWithMultipleRenders,
} from '../../utils/dateHelper';
// import {getTodayDate} from '../../utils/dateHelper';
// import Default from './defaultStore';

class CalendarStore {
	isCalendarOpen = false;

	isFullscreenModalOpen = false;

	calendarDate = new Date();

	eventsList = [];

	translatedEventsList = [];

	selectedDateOnCalendar = '';

	isFetching = false;

	constructor() {
		// super();
		makeAutoObservable(this, {
			isCalendarOpen: observable,
			setIsCalendarOpen: action,

			isFullscreenModalOpen: observable,
			setIsFullscreenModalOpen: action,

			calendarDate: observable,
			setCalendarDate: action,

			eventsList: observable,
			setEventsList: action,
			appendToEventList: action,

			isFetching: observable,
			setIsFetching: action,

			isLoadingPromise: action,

			selectedDateOnCalendar: observable,
			setSelectedDateOnCalendar: action,

			translatedEventsList: observable,
			translateEventsList: action,

			makeNewEvent: action,
		});
	}

	// MÉTODOS SÍNCRONOS
	setIsFetching(isFetching) {
		this.isFetching = isFetching;
	}

	setIsCalendarOpen() {
		this.isCalendarOpen = !this.isCalendarOpen;
	}

	setIsFullscreenModalOpen(value) {
		this.isFullscreenModalOpen = value;
	}

	setCalendarDate(date) {
		this.calendarDate = date;
	}

	setEventsList(eventList) {
		this.eventsList = [...eventList];
		this.translateEventsList();
	}

	setSelectedDateOnCalendar(newDate) {
		this.selectedDateOnCalendar = newDate;
	}

	appendToEventList(eventList) {
		this.eventsList.push(eventList);
	}

	appendToTranslatedEventList(translatedEvent) {
		this.translatedEventsList.push(translatedEvent);
	}

	makeNewEventWithMultipleRenders(event) {
		if (
			isAnEventGreaterThanDayWithMultipleRenders(
				event?.startTime,
				event?.endTime,
				event?.initialDate,
				event?.finalDate,
			)
		) {
			if (
				Number(
					event?.initialDate.slice(
						event?.initialDate.lastIndexOf('-') + 1,
						event?.initialDate.length,
					),
				) !== this.calendarDate.getDate()
			) {
				const newObject = {
					...event,
					startTime: '00:00',
					finalDate: formatTz(this.calendarDate, 'yyyy-MM-dd'),
					initialDate: formatTz(this.calendarDate, 'yyyy-MM-dd'),
					margin: false,
				};

				return { ...newObject };
			}
		}

		return null;
	}

	translateEventsList() {
		let newTranslatedEvent = null;
		// eslint-disable-next-line
		this.translatedEventsList = this.eventsList?.map((event) => {
			const translatedEvent = {
				patient: {
					id: event?.patient_id,
					name: event?.patient_name,
					avatar: event?.patient_media,
				},
				eventId: event?.id,
				date: event?.date_start?.slice(0, event?.date_start.indexOf(' ')),
				startTime: event?.date_start?.slice(
					event?.date_start.indexOf(' ') + 1,
					event?.date_start.length,
				),
				initialDate: event?.date_start?.slice(
					0,
					event?.date_start.indexOf(' '),
				),
				endTime: event?.date_end?.slice(
					event?.date_end.indexOf(' ') + 1,
					event?.date_end.length,
				),
				finalDate: event?.date_end?.slice(0, event?.date_end.indexOf(' ')),
				bgColor: event?.color,
				type: event?.type,
				name: event?.name,
				description: event?.description || event?.location,
				place: event?.location,
				imageAlbumId: event?.image_album_id,
			};

			if (
				isAnEventGreaterThanDayWithMultipleRenders(
					translatedEvent?.startTime,
					translatedEvent?.endTime,
					translatedEvent?.initialDate,
					translatedEvent?.finalDate,
				)
			) {
				if (
					Number(
						translatedEvent?.initialDate.slice(
							translatedEvent?.initialDate.lastIndexOf('-') + 1,
							translatedEvent?.initialDate.length,
						),
					) !== this.calendarDate.getDate()
				) {
					newTranslatedEvent =
						this.makeNewEventWithMultipleRenders(translatedEvent);
				}
			}

			return {
				...translatedEvent,
			};
		});

		if (newTranslatedEvent) {
			this.appendToTranslatedEventList({ ...newTranslatedEvent });
		}
	}

	/** ************* */

	async isLoadingPromise(promise) {
		let load = '';

		try {
			load = setTimeout(() => {
				this.setIsFetching(true);
			}, 200);

			const response = await promise;
			if (load) {
				clearTimeout(load);
			}

			if (load != null) {
				clearTimeout(load);
			}

			this.setIsFetching(false);

			return Promise.resolve(response);
		} catch (error) {
			if (load) {
				clearTimeout(load);
			}

			this.setIsFetching(false);

			return Promise.reject(error);
		}
	}
}

export default new CalendarStore();
