import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../hooks';
import firstPhoto from '../../assets/images/onboarding-carroussel/cena 1.png';
import secondPhoto from '../../assets/images/onboarding-carroussel/Cena 2.png';
import thirdPhoto from '../../assets/images/onboarding-carroussel/Cena 3.png';
import convertRouteToCamelCase from '../../utils/convertRouteToCamelCase';
import YoutubeEmbedUrl from "../../utils/youtubeEmbedUrl";

const Context = createContext();

const TutorialContext = observer(({ children }) => {
	const { tutorialStore, authStore } = useStore();

	const [isCardOpened, setIsCardOpened] = useState(false);
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [isWelcomeTutorialOpened, setIsWelcomeTutorialOpened] = useState(false);
	const [shouldOpenModal, setShouldOpenModal] = useState(true);
	const [tutorialInfo, setTutorialInfo] = useState();
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	const [pageHasTutorial, setPageHasTutorial] = useState();
	const [index, setIndex] = useState(0);

	const location = useLocation();
	const [slug, setSlug] = useState(convertRouteToCamelCase(location.pathname));

	const welcomeTutorialInfo = [
		{
			type: 'image',
			title: 'Bem-vindo(a) ao tutorial do HAIAH',
			image: firstPhoto,
			description:
				'Nesse tutorial, você irá aprender como utilizar o HAIAH de uma forma rápida e didática.',
			onClick: () => {},
		},
		{
			type: 'image',
			title: 'Primeiro passos',
			image: secondPhoto,
			description:
				'Em cada tela, você terá um vídeo explicativo sobre a funcionalidade e como utilizá-la.',
			onClick: () => {},
		},
		{
			type: 'image',
			title: 'Já conhece o HAIAH?',
			image: thirdPhoto,
			description:
				'Caso não queira mais visualizar o nosso tutorial, basta desativar os primeiros passos no menu central de ajuda.',
			onClick: () => {},
		},
		{
			type: 'video',
			title: 'Interface básica',
			image: 'https://img.youtube.com/vi/TZIK5n4NycM/0.jpg',
			videoUrl: 'https://www.youtube.com/watch?v=TZIK5n4NycM',
			description: 'Conheça as principais funcionalidades do HAIAH.',
			onClick: () => {},
		},
	];

	const cases = {
		image: {
			component: (
				<img
					src={welcomeTutorialInfo[index].image}
					style={{ height: 150 }}
					alt='carrossel'
					className='d-flex mx-auto w-auto'
					onClick={welcomeTutorialInfo[index].onClick}
					onKeyDown={welcomeTutorialInfo[index].onClick}
				/>
			),
		},
		video: {
			component: (
				<div>
					<div style={{ height: '250px', overflow: 'hidden', position: 'relative' }} className="w-100">
						<iframe
							className="w-100 h-100 position-absolute"
							src={YoutubeEmbedUrl(welcomeTutorialInfo[index]?.videoUrl)}
							title="YouTube video player" 
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
							allowFullScreen
						>
						</iframe>
					</div>
            	</div>
			),
		}
	}

	useEffect(async () => {
		setSlug(convertRouteToCamelCase(location.pathname));

		if (authStore.user.show_onboarding_videos) {
			if (slug !== 'home')
				await tutorialStore.fetchTutorialVideo(slug).then(async (res) => {
					setTutorialInfo(toJS(res));
					setPageHasTutorial(true);

					await tutorialStore
						.getTutorialFlags(res.id)
						.then(({ show }) => {
							if (typeof show !== 'boolean') {
								setIsModalOpened(true);
								setShouldOpenModal(true);
								setIsCheckboxChecked(false);
							} else {
								setIsModalOpened(show);
								setShouldOpenModal(show);
								setIsCheckboxChecked(!show);
							}
						})
						.catch((err) => {
							setIsModalOpened(false);
							setPageHasTutorial(false);
						});
				});
			else if (!authStore.user.not_show_modal) {
				setPageHasTutorial(true);
				setIsModalOpened(false);
				setIsWelcomeTutorialOpened(true);
				setIsCheckboxChecked(toJS(authStore.user.not_show_modal));
			} else {
				setPageHasTutorial(true);
			}
		}
	}, []);

	useEffect(async () => {
		if (slug !== 'home' && tutorialInfo?.id) {
			await tutorialStore
				.getTutorialFlags(tutorialInfo?.id)
				.then(({ show }) => {
					if (typeof show !== 'boolean') {
						setShouldOpenModal(true);
						setIsCheckboxChecked(false);
					} else {
						setShouldOpenModal(show);
						setIsCheckboxChecked(!show);
					}
				});
		} else {
			setIsCheckboxChecked(toJS(authStore.user.not_show_modal));
		}
	}, [isModalOpened, isWelcomeTutorialOpened]);

	const contextValues = {
		location,
		isModalOpened,
		setIsModalOpened,
		isCardOpened,
		setIsCardOpened,
		tutorialInfo,
		isWelcomeTutorialOpened,
		setIsWelcomeTutorialOpened,
		welcomeTutorialInfo,
		shouldOpenModal,
		slug,
		isCheckboxChecked,
		setIsCheckboxChecked,
		pageHasTutorial,
		index,
		setIndex,
		cases,
	};

	return <Context.Provider value={contextValues}>{children}</Context.Provider>;
});

const useTutorialContext = () => {
	const context = useContext(Context);
	if (context === undefined) {
		throw new Error(
			'useTutorialContext() can be called just for components inside TutorialContext',
		);
	}

	return context;
};

export { TutorialContext, useTutorialContext };

TutorialContext.propTypes = {
	children: PropTypes.node.isRequired,
};
