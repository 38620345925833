const ArrowDown = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '12', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size * 1.5}
    viewBox='0 0 12 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.969 11.469C1.03867 11.3992 1.12143 11.3438 1.21255 11.3059C1.30367 11.2681 1.40135 11.2487 1.5 11.2487C1.59865 11.2487 1.69633 11.2681 1.78745 11.3059C1.87857 11.3438 1.96133 11.3992 2.031 11.469L6 15.4395L9.969 11.469C10.1098 11.3282 10.3008 11.2491 10.5 11.2491C10.6992 11.2491 10.8902 11.3282 11.031 11.469C11.1718 11.6098 11.2509 11.8008 11.2509 12C11.2509 12.1992 11.1718 12.3902 11.031 12.531L6.531 17.031C6.46133 17.1009 6.37857 17.1563 6.28745 17.1941C6.19633 17.2319 6.09865 17.2514 6 17.2514C5.90135 17.2514 5.80367 17.2319 5.71255 17.1941C5.62143 17.1563 5.53867 17.1009 5.469 17.031L0.969 12.531C0.899155 12.4613 0.843741 12.3786 0.805931 12.2875C0.768121 12.1963 0.74866 12.0987 0.74866 12C0.74866 11.9014 0.768121 11.8037 0.805931 11.7126C0.843741 11.6214 0.899155 11.5387 0.969 11.469Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 0.75C6.19891 0.75 6.38968 0.829018 6.53033 0.96967C6.67098 1.11032 6.75 1.30109 6.75 1.5V15C6.75 15.1989 6.67098 15.3897 6.53033 15.5303C6.38968 15.671 6.19891 15.75 6 15.75C5.80109 15.75 5.61032 15.671 5.46967 15.5303C5.32902 15.3897 5.25 15.1989 5.25 15V1.5C5.25 1.30109 5.32902 1.11032 5.46967 0.96967C5.61032 0.829018 5.80109 0.75 6 0.75Z'
      fill={color}
    />
  </svg>
);
export default ArrowDown;
