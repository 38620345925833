import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import OverlayTriggered from '../Overlay/OverlayTriggered';
import Input from '../Input/Input';

const AnnotationOverlay = ({
  title,
  overlay,
  toggleOverlay,
  saveAction,
  value,
  loading,
}) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    if (value != null) {
      setNote(value);
    }
  }, [value]);
  return (
    <OverlayTriggered
      setShow={toggleOverlay}
      show={overlay}
      title={`Anotações ${title}`}
      placement='end'
    >
      <Input
        rows={20}
        as='textarea'
        centeredLabel
        label='Escreva aqui suas observações:'
        value={note || ''}
        name='note'
        id='note'
        onChange={(e) => setNote(e.target.value)}
        maxLength={65535}
      />
      <div className='d-flex justify-content-end'>
        <Button
          disabled={loading}
          type='submit'
          onClick={async () => {
            await saveAction({ obs: note });
            toggleOverlay();
          }}
          className='text-white'
          variant='primary'
          id="btn-annotation-save-and-close"
        >
          {loading ? 'Salvando...' : 'Salvar e fechar'}
        </Button>
        <span className='px-3' />
        <Button
          disabled={loading}
          type='submit'
          onClick={() => saveAction({ obs: note })}
          className='text-white'
          variant='primary'
        >
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      </div>
    </OverlayTriggered>
  );
};

export default observer(AnnotationOverlay);

AnnotationOverlay.propTypes = {
  title: PropTypes.string,
  overlay: PropTypes.bool,
  toggleOverlay: PropTypes.func,
  saveAction: PropTypes.func,
  value: PropTypes.string,
  loading: PropTypes.bool,
};

AnnotationOverlay.defaultProps = {
  title: '',
  overlay: false,
  toggleOverlay: () => {},
  saveAction: () => {},
  value: '',
  loading: false,
};