const ChevronLeft = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2887 1.08597C13.376 1.17306 13.4453 1.27651 13.4926 1.39041C13.5398 1.50431 13.5641 1.62641 13.5641 1.74972C13.5641 1.87304 13.5398 1.99514 13.4926 2.10904C13.4453 2.22293 13.376 2.32639 13.2887 2.41347L2.70059 12.9997L13.2887 23.586C13.4648 23.762 13.5637 24.0008 13.5637 24.2497C13.5637 24.4987 13.4648 24.7374 13.2887 24.9135C13.1127 25.0895 12.8739 25.1884 12.625 25.1884C12.376 25.1884 12.1373 25.0895 11.9612 24.9135L0.711216 13.6635C0.62391 13.5764 0.554642 13.4729 0.50738 13.359C0.460118 13.2451 0.435791 13.123 0.435791 12.9997C0.435791 12.8764 0.460118 12.7543 0.50738 12.6404C0.554642 12.5265 0.62391 12.4231 0.711216 12.336L11.9612 1.08597C12.0483 0.998667 12.1518 0.929399 12.2657 0.882137C12.3796 0.834875 12.5017 0.810547 12.625 0.810547C12.7483 0.810547 12.8704 0.834875 12.9843 0.882137C13.0982 0.929399 13.2016 0.998667 13.2887 1.08597V1.08597Z'
      fill={color}
      fillOpacity='0.6'
    />
  </svg>
);

export default ChevronLeft;
