import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-konva';

const VerticalXHorizontal = ({ filteredDots, stroke, strokeWidth }) => {
  const Trichio = filteredDots.find((d) => d.categoryId === 'Trichio');
  const TragusD = filteredDots.find((d) => d.categoryId === 'Tragus_D');
  const TragusE = filteredDots.find((d) => d.categoryId === 'Tragus_E');
  const Pg = filteredDots.find((d) => d.categoryId === 'Pg');

  return (
    <>
      <Line
        stroke={stroke}
        points={[Trichio?.x, Trichio?.y, Pg?.x, Pg?.y]}
        strokeWidth={strokeWidth}
      />
      <Line
        stroke={stroke}
        points={[TragusD?.x, TragusD?.y, TragusE?.x, TragusE?.y]}
        strokeWidth={strokeWidth}
      />
    </>
  );
};

VerticalXHorizontal.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

VerticalXHorizontal.defaultProps = {
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default VerticalXHorizontal;
