const ArrowLeft = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#50565F', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.781 6.96888C11.8508 7.03854 11.9063 7.12131 11.9441 7.21242C11.9819 7.30354 12.0013 7.40122 12.0013 7.49988C12.0013 7.59853 11.9819 7.69621 11.9441 7.78733C11.9063 7.87844 11.8508 7.96121 11.781 8.03088L7.8105 11.9999L11.781 15.9689C11.8507 16.0386 11.906 16.1214 11.9438 16.2125C11.9815 16.3036 12.0009 16.4013 12.0009 16.4999C12.0009 16.5985 11.9815 16.6961 11.9438 16.7873C11.906 16.8784 11.8507 16.9611 11.781 17.0309C11.7113 17.1006 11.6285 17.1559 11.5374 17.1937C11.4463 17.2314 11.3486 17.2508 11.25 17.2508C11.1514 17.2508 11.0537 17.2314 10.9626 17.1937C10.8715 17.1559 10.7887 17.1006 10.719 17.0309L6.219 12.5309C6.14915 12.4612 6.09374 12.3784 6.05593 12.2873C6.01812 12.1962 5.99866 12.0985 5.99866 11.9999C5.99866 11.9012 6.01812 11.8035 6.05593 11.7124C6.09374 11.6213 6.14915 11.5385 6.219 11.4689L10.719 6.96888C10.7887 6.89903 10.8714 6.84362 10.9625 6.80581C11.0537 6.768 11.1513 6.74854 11.25 6.74854C11.3486 6.74854 11.4463 6.768 11.5374 6.80581C11.6286 6.84362 11.7113 6.89903 11.781 6.96888Z'
      fill={color}
      fillOpacity='0.6'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.75 12C6.75 11.8011 6.82902 11.6103 6.96967 11.4697C7.11032 11.329 7.30109 11.25 7.5 11.25H17.25C17.4489 11.25 17.6397 11.329 17.7803 11.4697C17.921 11.6103 18 11.8011 18 12C18 12.1989 17.921 12.3897 17.7803 12.5303C17.6397 12.671 17.4489 12.75 17.25 12.75H7.5C7.30109 12.75 7.11032 12.671 6.96967 12.5303C6.82902 12.3897 6.75 12.1989 6.75 12Z'
      fill={color}
      fillOpacity='0.6'
    />
  </svg>
);
export default ArrowLeft;
