import { makeObservable, observable } from 'mobx';

import { v4 as uuidv4 } from 'uuid';

/**
 * Class representing a dot/circle on Konvas React
 *
 * @class DotModel
 * @since 0.0.2
 */
export default class DotModel {
  /** @type {number | string} */
  id = null;

  /** @type {number} */
  x = 0;

  /** @type {number} */
  y = 0;

  /** @type {string} */
  name = '';

  /** @type {number} */
  categoryId = null;

  /**
   * Create new instance of DotModel
   *
   * @param {Partial<typeof defaultOptions>} [options={}]
   * @constructor
   * @since 0.0.1
   */
  constructor(options = {}) {
    makeObservable(this, {
      id: observable,
      x: observable,
      y: observable,
      name: observable,
      categoryId: observable,
    });

    const { id, x, y, name, categoryId } = {
      ...options,
    };

    this.id = id != null ? id : uuidv4();
    this.x = x;
    this.y = y;
    this.name = name;
    this.categoryId = categoryId;
  }
}

// Created so it can export typedefs
// @ts-ignore
export {};

/**
 * @typedef {typeof defaultOptions} DotModelConstructor
 */
