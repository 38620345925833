import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';

import MainLayout from '../MainLayout/MainLayout';
import TimelapseNavBar from './TimelapseNavBar';

const TimelapseLayout = ({ children }) => (
  <MainLayout customNavBar={<TimelapseNavBar />}>{children}</MainLayout>
);

TimelapseLayout.propTypes = {
  children: PropTypes.node,
};
TimelapseLayout.defaultProps = {
  children: '',
};

export default observer(TimelapseLayout);
