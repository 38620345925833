import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import AnalysisNavBar from './AnalysisNavBar';

const AnalysisLayout = ({ children }) => (
  <MainLayout customNavBar={<AnalysisNavBar />}>{children}</MainLayout>
);

AnalysisLayout.propTypes = {
  children: PropTypes.node,
};
AnalysisLayout.defaultProps = {
  children: '',
};

export default observer(AnalysisLayout);
