const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

const brightnessChange = (data, brightness) => {
  const colors = data.data;

  for (let i = 0; i < colors.length; i += 4) {
    const red = colors[i];
    const green = colors[i + 1];
    const blue = colors[i + 2];

    const newRed = brightness * red;
    const newGreen = brightness * green;
    const newBlue = brightness * blue;

    colors[i] = Math.max(0, Math.min(255, newRed));
    colors[i + 1] = Math.max(0, Math.min(255, newGreen));
    colors[i + 2] = Math.max(0, Math.min(255, newBlue));
  }
};

const truncateValue = (value) => {
  if (value > 255) {
    return 255;
  }
  if (value < 0) {
    return 0;
  }
  return value;
};

const contrastChange = (data, contrast) => {
  const colors = data.data;

  const factor =
    (259 * (contrast * 100 - 100 + 255)) /
    (255 * (259 - (contrast * 100 - 100)));

  for (let i = 0; i < colors.length; i += 4) {
    colors[i] = truncateValue(factor * (colors[i] - 128) + 128);
    colors[i + 1] = truncateValue(factor * (colors[i + 1] - 128) + 128);
    colors[i + 2] = truncateValue(factor * (colors[i + 2] - 128) + 128);
  }
};

const saturateChange = (data, saturate) => {
  const colors = data.data;
  const luR = 0.3086;
  const luG = 0.6094;
  const luB = 0.082;

  const az = (1 - parseFloat(saturate)) * luR + parseFloat(saturate);
  const bz = (1 - parseFloat(saturate)) * luG;
  const cz = (1 - parseFloat(saturate)) * luB;
  const dz = (1 - parseFloat(saturate)) * luR;
  const ez = (1 - parseFloat(saturate)) * luG + parseFloat(saturate);
  const fz = (1 - parseFloat(saturate)) * luB;
  const gz = (1 - parseFloat(saturate)) * luR;
  const hz = (1 - parseFloat(saturate)) * luG;
  const iz = (1 - parseFloat(saturate)) * luB + parseFloat(saturate);
  for (let i = 0; i < colors.length; i += 4) {
    const red = colors[i];
    const green = colors[i + 1];
    const blue = colors[i + 2];

    const saturatedRed = truncateValue(az * red + bz * green + cz * blue);
    const saturatedGreen = truncateValue(dz * red + ez * green + fz * blue);
    const saturatedBlue = truncateValue(gz * red + hz * green + iz * blue);

    colors[i] = saturatedRed;
    colors[i + 1] = saturatedGreen;
    colors[i + 2] = saturatedBlue;
  }
};

const getCroppedImg = async (
  imageSrc,
  crop,
  rotation = 0,
  _brightness = 1,
  _contrast = 1,
  saturate = 1,
) => {
  const brightness = parseFloat(_brightness) + 1;
  const contrast = parseFloat(_contrast) + 1;

  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);

  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  brightnessChange(data, brightness);
  contrastChange(data, contrast);
  saturateChange(data, saturate);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = crop.width;
  canvas.height = crop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y),
  );

  const regeneratedImage = await new Promise((resolve, reject) => {
    try {
      canvas.toBlob((file) => resolve(file), 'image/jpeg');

    } catch (err) {
      reject(err)
    }
  })

  return regeneratedImage
};

export { createImage };

export default getCroppedImg;
