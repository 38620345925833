import { observer} from "mobx-react";
import { PropTypes } from "prop-types";
import { Form } from "react-bootstrap";
import { useStore } from "../../../hooks";
import { useTutorialContext } from "../../../stores/contexts/TutorialContext";

const DontShowTutorialCheckbox = ({videoId=0}) => {

    
    const {slug, isCheckboxChecked, setIsCheckboxChecked} = useTutorialContext()
    const {tutorialStore, authStore} = useStore()
    
    return(
        <Form className='d-flex position-relative col-12'>
            <Form.Check
                type = 'checkbox'
                label = 'Não exibir novamente'
                checked = {isCheckboxChecked}
                disabled = {tutorialStore.isLoading}
                onChange = {()=>{   
                    setIsCheckboxChecked(!isCheckboxChecked)
                    if(slug === 'home'){
                        authStore.setGoToUserFormModalOpen(!isCheckboxChecked)
                    }else{                       
                        tutorialStore.updateFlagDontRenderAnymore(isCheckboxChecked, videoId)                    
                    }
                }}
                className = 'mx-auto my-3 fs-7'
            />
        </Form>
    )
}

DontShowTutorialCheckbox.propTypes = {
    videoId: PropTypes.number,
};

DontShowTutorialCheckbox.defaultProps = {
    videoId: null,
};

export default observer(DontShowTutorialCheckbox);