const PDF = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M6 1.5H13.5V3H6C5.60218 3 5.22064 3.15804 4.93934 3.43934C4.65804 3.72064 4.5 4.10218 4.5 4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V9H21V19.5C21 20.2956 20.6839 21.0587 20.1213 21.6213C19.5587 22.1839 18.7956 22.5 18 22.5H6C5.20435 22.5 4.44129 22.1839 3.87868 21.6213C3.31607 21.0587 3 20.2956 3 19.5V4.5C3 3.70435 3.31607 2.94129 3.87868 2.37868C4.44129 1.81607 5.20435 1.5 6 1.5Z'
      fill='#383C42'
    />
    <path
      d='M13.5 6.75V1.5L21 9H15.75C15.1533 9 14.581 8.76295 14.159 8.34099C13.7371 7.91903 13.5 7.34674 13.5 6.75Z'
      fill={color}
    />
  </svg>
);
export default PDF;
