import PropTypes from 'prop-types';
import React from 'react';
import useToggle from '../../../../../hooks/useToggle';
import IconComponent from '../../../../Svg/IconComponent/IconComponent';

const VerticalXHorizontal = ({
                               showText,
                               analysis,
                             
                             }) => {
  const [details, toggleDetails] = useToggle(false);

  return (

    <div className='d-flex flex-column   '>
      {details ? (
        <div className='flex-fill p-2'>
          {showText && (
            <>
              <div className='text-primary d-flex flex-row justify-content-between'>
                <div className='text-white'>Desvio:</div>
                <div className='ms-2'>
                  <IconComponent
                    group='analysis'
                    name='minimize'
                    size='16'
                    color='white'
                    onClick={() => toggleDetails()}
                  />
                </div>
              </div>
              <span className='text-success'>
                    {(analysis.deviation_acceptable * 100).toFixed(2)}%
                  </span>
              <br />
              <br />
              <div className='text-white'>Obtido:</div>

              <span className='text-warning'>
                    {(analysis.proportion * 100).toFixed(2)}º
                  </span>
              <br />
              <br />
              <div className='text-white'>Referência:</div>

              <span className='text-white'>
                    {(analysis.base * 100).toFixed(2)}º
                  </span>
            </>
          )}
        </div>
      ) : (
        <div className='flex-fill p-2'>
          <div className='text-primary d-flex flex-row justify-content-between'>
            <div className='text-white'>Desvio:</div>
            <div className='ms-2'>
              <IconComponent
                group='analysis'
                name='expand'
                size='16'
                color='white'
                onClick={() => toggleDetails()}
              />
            </div>
          </div>
          {showText && (
            <span className='text-success'>
                  {(analysis.deviation_acceptable * 100).toFixed(2)}%
                </span>
          )}
        </div>
      )}
    </div>

  );
};

VerticalXHorizontal.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

VerticalXHorizontal.defaultProps = {
  showText: false,
  analysis: null,

};

export default VerticalXHorizontal;
