const Dash = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 14 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.25 1C0.25 0.801088 0.329018 0.610322 0.46967 0.46967C0.610322 0.329018 0.801088 0.25 1 0.25H13C13.1989 0.25 13.3897 0.329018 13.5303 0.46967C13.671 0.610322 13.75 0.801088 13.75 1C13.75 1.19891 13.671 1.38968 13.5303 1.53033C13.3897 1.67098 13.1989 1.75 13 1.75H1C0.801088 1.75 0.610322 1.67098 0.46967 1.53033C0.329018 1.38968 0.25 1.19891 0.25 1Z'
      fill={color}
    />
  </svg>
);
export default Dash;
