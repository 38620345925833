const Contrast = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
  <svg
    {...rest}
    width={size}
    height={size}
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5088 18.75V1.25C8.18814 1.25 5.96255 2.17187 4.3216 3.81282C2.68066 5.45376 1.75879 7.67936 1.75879 10C1.75879 12.3206 2.68066 14.5462 4.3216 16.1872C5.96255 17.8281 8.18814 18.75 10.5088 18.75ZM10.5088 20C13.161 20 15.7045 18.9464 17.5799 17.0711C19.4552 15.1957 20.5088 12.6522 20.5088 10C20.5088 7.34784 19.4552 4.8043 17.5799 2.92893C15.7045 1.05357 13.161 0 10.5088 0C7.85662 0 5.31309 1.05357 3.43772 2.92893C1.56236 4.8043 0.508789 7.34784 0.508789 10C0.508789 12.6522 1.56236 15.1957 3.43772 17.0711C5.31309 18.9464 7.85662 20 10.5088 20Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width={size}
          height={size}
          fill='white'
          transform='translate(0.508789)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default Contrast;
