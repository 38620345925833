const Positive = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 13 9'
    fill='none'
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.854 0.645917C11.9006 0.692363 11.9375 0.747538 11.9627 0.808284C11.9879 0.869029 12.0009 0.93415 12.0009 0.999917C12.0009 1.06568 11.9879 1.13081 11.9627 1.19155C11.9375 1.2523 11.9006 1.30747 11.854 1.35392L4.85402 8.35392C4.80758 8.40048 4.7524 8.43742 4.69166 8.46263C4.63091 8.48784 4.56579 8.50081 4.50002 8.50081C4.43425 8.50081 4.36913 8.48784 4.30839 8.46263C4.24764 8.43742 4.19247 8.40048 4.14602 8.35392L0.646021 4.85392C0.552134 4.76003 0.49939 4.63269 0.49939 4.49992C0.49939 4.36714 0.552134 4.2398 0.646021 4.14592C0.739908 4.05203 0.867245 3.99929 1.00002 3.99929C1.1328 3.99929 1.26013 4.05203 1.35402 4.14592L4.50002 7.29292L11.146 0.645917C11.1925 0.599354 11.2476 0.562411 11.3084 0.537205C11.3691 0.511998 11.4343 0.499023 11.5 0.499023C11.5658 0.499023 11.6309 0.511998 11.6917 0.537205C11.7524 0.562411 11.8076 0.599354 11.854 0.645917Z'
      fill={color}
      stroke={color}
    />
  </svg>
);
export default Positive;
