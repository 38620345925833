import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import MainLayout from '../../MainLayout/MainLayout';
import ComparativeViewNavbar from './ComparativeViewNavbar';
import { useIsMobile } from '../../../../hooks';

const ComparativeViewLayout = ({ children }) => {
  const isMobile = useIsMobile();

  const comparativeView = useMemo(() => {
    if (!isMobile) {
      return (
        <MainLayout customNavBar={<ComparativeViewNavbar />}>
          {children}
        </MainLayout>
      );
    }

    return children;
  }, [isMobile]);

  return comparativeView;
};

export default observer(ComparativeViewLayout);
