import { useEffect } from 'react';
import { useDebounce } from 'react-use';
import useToggle from './useToggle';

const useAutoSave = (isChanged, formik, dbTime = 2000) => {
  // NOTE: renderCount needs to be 2 because the first render is for the formik
  // const [renderCount, setRenderCount] = useReducer((prev) => prev + 1, 1);
  const [isRunningTimeout, toggleTimeout] = useToggle(false);
 
  useEffect(() => {
    if (!isRunningTimeout) {
      toggleTimeout(true);
    }
  }, [formik.values]);

  useDebounce(
    () => {
      if (isChanged) {
        formik.submitForm();
        toggleTimeout(false);
      } 
    }, dbTime, 
    [formik.values, isChanged],
  );

  return [isRunningTimeout];
};

export default useAutoSave;

