import { observer } from 'mobx-react';
import clsx from 'clsx';
import Offcanvas from 'react-bootstrap/Offcanvas';
import PropTypes from 'prop-types';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';
import useToggle from '../../../hooks/useToggle';

const Overlay = (props) => {
  const {
    icon: { group, name, size, color },
    title,
    children,
    bgDark,
    open,
    setOpen,
  } = props;

  const [show, setShow] = useToggle(false);

  const action = {
    setOverlay: setOpen != null ? setOpen : setShow,
    overlay: setOpen != null ? open : show,
  };

  const { overlay, setOverlay } = action;

  return (
    <>
      <IconComponent
        group={group}
        name={name}
        onClick={() => setOverlay()}
        color={color}
        size={size}
      />

      <Offcanvas
        show={overlay}
        onHide={() => setOverlay()}
        {...props}
        className={clsx(bgDark && 'bg-dark', 'zi-1300')}
      >
        <Offcanvas.Header closeButton={setOpen == null}>
          <Offcanvas.Title className='px-2 text-center w-100'>
            {title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='py-0 d-flex justify-content-between flex-column pb-3'>
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

Overlay.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.shape({
    group: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
  }),
  children: PropTypes.node,
  bgDark: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Overlay.defaultProps = {
  open: false,
  setOpen: null,
  title: '',
  icon: { size: 24, color: COLORS.greyBoard },
  children: '',
  bgDark: false,
};

export default observer(Overlay);
