import React from 'react';
import PropTypes from 'prop-types';
import useToggle from '../../../../../hooks/useToggle';
import IconComponent from '../../../../Svg/IconComponent/IconComponent';

const MouthToEye = ({ showText, analysis }) => {
  const [details, toggleDetails] = useToggle(false);

  return (
    <div className='d-flex flex-column '>
      {details ? (
        <div className='flex-fill p-2'>
          {showText && (
            <>
              <div className='text-primary d-flex flex-row justify-content-between'>
               <p className='text-white m-0'>Diferença:</p>
                <div className='ms-2'>
                  <IconComponent
                    group='analysis'
                    name='minimize'
                    size='16'
                    color='white'
                    onClick={() => toggleDetails()}
                  />
                </div>
              </div>
              <span className='text-success'>
                {analysis.deviation_acceptable.toFixed(2)}%
              </span>
              <br />
              <br />
             <p className='text-white m-0'>Obtido:</p>
              <span className='text-warning'>
                {analysis.proportion.toFixed(2)}%
              </span>
              <br />
              <br />
             <p className='text-white m-0'>Referência:</p>
              <span className='text-white'>{analysis.base.toFixed(2)}%</span>
            </>
          )}
        </div>
      ) : (
        <div className='flex-fill p-2'>
          <div className='text-primary d-flex flex-row justify-content-between'>
           <p className='text-white m-0'>Diferença:</p>
            <div className='ms-2'>
              <IconComponent
                group='analysis'
                name='expand'
                size='16'
                color='white'
                onClick={() => toggleDetails()}
              />
            </div>
          </div>
          {showText && (
            <span className='text-success'>
              {analysis.deviation_acceptable.toFixed(2)}%
            </span>
          )}
        </div>
      )}
    </div>
  );
};

MouthToEye.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

MouthToEye.defaultProps = {
  showText: false,
  analysis: null,
};

export default MouthToEye;
