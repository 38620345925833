const PatientIcon = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = '#383C42', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.375 26.25C24.375 26.25 26.25 26.25 26.25 24.375C26.25 22.5 24.375 16.875 15 16.875C5.625 16.875 3.75 22.5 3.75 24.375C3.75 26.25 5.625 26.25 5.625 26.25H24.375ZM5.63438 24.48V24.4762V24.48ZM5.66625 24.375H24.3337C24.3425 24.374 24.3513 24.3727 24.36 24.3713L24.375 24.3675C24.3731 23.9063 24.0863 22.5187 22.815 21.2475C21.5925 20.025 19.2919 18.75 15 18.75C10.7063 18.75 8.4075 20.025 7.185 21.2475C5.91375 22.5187 5.62875 23.9063 5.625 24.3675C5.63872 24.3701 5.65247 24.3726 5.66625 24.375V24.375ZM24.3675 24.48V24.4762V24.48ZM15 13.125C15.9946 13.125 16.9484 12.7299 17.6516 12.0267C18.3549 11.3234 18.75 10.3696 18.75 9.375C18.75 8.38044 18.3549 7.42661 17.6516 6.72335C16.9484 6.02009 15.9946 5.625 15 5.625C14.0054 5.625 13.0516 6.02009 12.3483 6.72335C11.6451 7.42661 11.25 8.38044 11.25 9.375C11.25 10.3696 11.6451 11.3234 12.3483 12.0267C13.0516 12.7299 14.0054 13.125 15 13.125V13.125ZM20.625 9.375C20.625 10.8668 20.0324 12.2976 18.9775 13.3525C17.9226 14.4074 16.4918 15 15 15C13.5082 15 12.0774 14.4074 11.0225 13.3525C9.96763 12.2976 9.375 10.8668 9.375 9.375C9.375 7.88316 9.96763 6.45242 11.0225 5.39752C12.0774 4.34263 13.5082 3.75 15 3.75C16.4918 3.75 17.9226 4.34263 18.9775 5.39752C20.0324 6.45242 20.625 7.88316 20.625 9.375V9.375Z'
      fill={color}
    />
  </svg>
);
export default PatientIcon;
