const MailOutline = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 15 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_780_101248)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.75 2.625H2.25C2.01794 2.625 1.79538 2.71719 1.63128 2.88128C1.46719 3.04538 1.375 3.26794 1.375 3.5V10.5C1.375 10.7321 1.46719 10.9546 1.63128 11.1187C1.79538 11.2828 2.01794 11.375 2.25 11.375H12.75C12.9821 11.375 13.2046 11.2828 13.3687 11.1187C13.5328 10.9546 13.625 10.7321 13.625 10.5V3.5C13.625 3.26794 13.5328 3.04538 13.3687 2.88128C13.2046 2.71719 12.9821 2.625 12.75 2.625ZM2.25 1.75C1.78587 1.75 1.34075 1.93437 1.01256 2.26256C0.684374 2.59075 0.5 3.03587 0.5 3.5L0.5 10.5C0.5 10.9641 0.684374 11.4092 1.01256 11.7374C1.34075 12.0656 1.78587 12.25 2.25 12.25H12.75C13.2141 12.25 13.6592 12.0656 13.9874 11.7374C14.3156 11.4092 14.5 10.9641 14.5 10.5V3.5C14.5 3.03587 14.3156 2.59075 13.9874 2.26256C13.6592 1.93437 13.2141 1.75 12.75 1.75H2.25Z'
				fill='#F7F7F7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.562233 3.71273C0.591757 3.66343 0.630704 3.62043 0.676851 3.58618C0.722998 3.55193 0.775439 3.52711 0.831181 3.51314C0.886922 3.49916 0.94487 3.4963 1.00172 3.50473C1.05856 3.51315 1.11319 3.53269 1.16248 3.56223L7.50011 7.36498L13.8377 3.56223C13.887 3.5327 13.9417 3.51317 13.9985 3.50475C14.0553 3.49633 14.1133 3.49919 14.169 3.51317C14.2248 3.52714 14.2772 3.55196 14.3234 3.5862C14.3695 3.62044 14.4085 3.66344 14.438 3.71273C14.4675 3.76203 14.487 3.81666 14.4955 3.8735C14.5039 3.93034 14.501 3.98829 14.487 4.04403C14.4731 4.09977 14.4483 4.15221 14.414 4.19835C14.3798 4.2445 14.3368 4.28345 14.2875 4.31298L7.50011 8.38523L0.712733 4.31386C0.66343 4.28433 0.620427 4.24539 0.58618 4.19924C0.551932 4.15309 0.527111 4.10065 0.513136 4.04491C0.49916 3.98917 0.496302 3.93122 0.504726 3.87438C0.513151 3.81753 0.532692 3.7629 0.562233 3.71361V3.71273Z'
				fill='#F7F7F7'
			/>
			<path
				d='M6.4078 7.81537L6.7858 7.59487L6.3448 6.83887L5.9668 7.05937L6.4078 7.81537ZM1.1578 10.8779L6.4078 7.81537L5.9668 7.05937L0.716797 10.1219L1.1578 10.8779ZM8.5918 7.81537L8.2138 7.59487L8.6548 6.83887L9.0328 7.05937L8.5918 7.81537ZM13.8418 10.8779L8.5918 7.81537L9.0328 7.05937L14.2828 10.1219L13.8418 10.8779Z'
				fill='#F7F7F7'
			/>
		</g>
		<defs>
			<clipPath id='clip0_780_101248'>
				<rect
					width={size}
					height={size}
					fill={color}
					transform='translate(0.5)'
				/>
			</clipPath>
		</defs>
	</svg>
);
export default MailOutline;
