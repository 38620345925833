const GoogleDrive = ({
    // eslint-disable-next-line react/prop-types
    iconStyleProps: { size = '24', color = 'white', ...rest },
  }) => (
    <svg width={size} height={size} viewBox="0 0 26 23" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M17.2148 14.366L8.90703 0H17.093L25.4008 14.366H17.2148ZM10.2324 15.5645L6.13945 22.75H21.907L26 15.5645H10.2324ZM7.82539 1.79766L0 15.5645L4.09297 22.75L12.0352 8.97814L7.82539 1.79766Z" fill="#F7F7F7"/>
    </svg>

  );
  export default GoogleDrive;
  