import { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';

const UpgradeNavBar = () => {
  const { width } = useWindowSize();

  return useMemo(() => {
    if (width <= 768) {
      return (
        <NavbarHeaderMobile
          content='title'
          title='Selecione um plano'
          topSubtitle='Upgrade'
          hasGoBack
          hasBottomBorder={false}
        />
      );
    }

    return (
      <NavbarHeader
        content='content'
        hasTitle
        centerContent='Selecione um plano'
        topContent='Upgrade'
        hasGoBack
        hasBottomBorder={false}
        hasBg={false}
      />
    );
  }, [width]);
};

export default UpgradeNavBar;
