const Files = ({
    // eslint-disable-next-line react/prop-types
    iconStyleProps: { size = '24', color = 'white', ...rest },
  }) => (
    <svg width={size} height={size} viewBox="0 0 26 26" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.5029 6.5H3.25293C2.82195 6.5 2.40863 6.67121 2.10388 6.97595C1.79913 7.2807 1.62793 7.69402 1.62793 8.125V21.125C1.62793 21.556 1.79913 21.9693 2.10388 22.274C2.40863 22.5788 2.82195 22.75 3.25293 22.75H19.5029C19.9339 22.75 20.3472 22.5788 20.652 22.274C20.9567 21.9693 21.1279 21.556 21.1279 21.125V8.125C21.1279 7.69402 20.9567 7.2807 20.652 6.97595C20.3472 6.67121 19.9339 6.5 19.5029 6.5V6.5ZM3.25293 4.875C2.39098 4.875 1.56433 5.21741 0.954833 5.8269C0.345339 6.4364 0.00292969 7.26305 0.00292969 8.125V21.125C0.00292969 21.987 0.345339 22.8136 0.954833 23.4231C1.56433 24.0326 2.39098 24.375 3.25293 24.375H19.5029C20.3649 24.375 21.1915 24.0326 21.801 23.4231C22.4105 22.8136 22.7529 21.987 22.7529 21.125V8.125C22.7529 7.26305 22.4105 6.4364 21.801 5.8269C21.1915 5.21741 20.3649 4.875 19.5029 4.875H3.25293Z" fill="#383C42"/>
        <path d="M17.3027 14.0497C17.4234 13.9293 17.5788 13.85 17.7471 13.8229C17.9154 13.7957 18.0879 13.8222 18.2403 13.8986L21.1279 17.0624V22.7499H1.62793V21.1249L5.92768 17.2997C6.0605 17.1673 6.23499 17.085 6.42158 17.0667C6.60817 17.0483 6.79536 17.095 6.95143 17.1989L11.2739 20.0801L17.3027 14.0513V14.0497Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.31575 14.625C7.96221 14.625 8.5822 14.3682 9.03932 13.9111C9.49644 13.454 9.75325 12.834 9.75325 12.1875C9.75325 11.541 9.49644 10.921 9.03932 10.4639C8.5822 10.0068 7.96221 9.75 7.31575 9.75C6.66928 9.75 6.0493 10.0068 5.59218 10.4639C5.13506 10.921 4.87825 11.541 4.87825 12.1875C4.87825 12.834 5.13506 13.454 5.59218 13.9111C6.0493 14.3682 6.66928 14.625 7.31575 14.625V14.625ZM6.5 3.25H22.75C23.181 3.25 23.5943 3.4212 23.899 3.72595C24.2038 4.0307 24.375 4.44402 24.375 4.875V17.875C24.375 18.306 24.2038 18.7193 23.899 19.024C23.5943 19.3288 23.181 19.5 22.75 19.5V21.125C23.612 21.125 24.4386 20.7826 25.0481 20.1731C25.6576 19.5636 26 18.737 26 17.875V4.875C26 4.01305 25.6576 3.1864 25.0481 2.5769C24.4386 1.96741 23.612 1.625 22.75 1.625H6.5C5.63805 1.625 4.8114 1.96741 4.2019 2.5769C3.59241 3.1864 3.25 4.01305 3.25 4.875H4.875C4.875 4.44402 5.0462 4.0307 5.35095 3.72595C5.6557 3.4212 6.06902 3.25 6.5 3.25V3.25Z" fill="black"/>
    </svg>
  );

  export default Files;