export default function currency(value) {
  if (Number(value) === value && value % 1 !== 0) {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }
  const valor = parseFloat(value);
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}
