const COLORS = {
	primary: '#007BFF',
	secondary: '#2699FB',
	tertiary: '#00F1D0',
	success: '#0AE2B9',
	danger: '#DC3545',
	warning: '#FDCD0E',
	light: '#F8F9FA',
	dark: '#383C42',
	white: '#FFFFFF',
	greyBoard: '#ced4da',
	greyIcon: '#F1F1F1',
	greyPlaceholder: '#DFDFDF',
	grayHelper: '#D7DCE2',
	greyEmpty: '#e7eaee',
	allBlack: '#000000',
	dropdownText: '#0091FF',
	blueBorder: '#80BDFF',
	uploadCircleLow: 'rgb(10,226,185)',
	uploadCircleMedium: 'rgb(38,153,251 )',
	uploadCircleHigh: 'rgb(255,0,41 )',
	brandGreen: '#00f1d0',
	dropdownIcon: '#F7F7F7',
	uploadGreen: '#42cf85',
};
const COLORS_TEXT = {
	muted: '#383C42',
};

export { COLORS, COLORS_TEXT };
