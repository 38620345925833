import axios from 'axios';
import { action, makeObservable, observable, override } from 'mobx';
import { toast } from 'react-toastify';
import baseAPI, { baseURL } from '../../services/baseAPI';
import { dotsStore, authStore, galleryStore, defaultStore } from '.';
import Analysis from '../models/Analysis';
import DefaultStore from './defaultStore';
import FeedbackBadge from '../../components/Styleguide/FeedbackBadge/FeedbackBadge';
import { COLORS } from '../../constants/Colors';

class AnalysisStore extends DefaultStore {
  analysisResult = null;

  exporting = false;

  analysisActive = '';

  exportMap = new Map();

  blobToDownload = '';

  analysisMap = new Map();

  modal = false;

  guideLines = [];

  isFetching = 'idle';

  isReady = false;

  isFullDots = false;

  facemeshRecalculating = true;

  constructor() {
    super();
    makeObservable(this, {
      isFetching: override,
      setIsFetching: override,
      isLoadingPromise: override,

      blobToDownload: observable,
      setBlobToDownload: action,

      analysisResult: observable,
      exporting: observable,

      setAnalysisActive: action,
      analysisActive: observable,

      exportMap: observable,
      analysisMap: observable,
      modal: observable,
      isReady: observable,
      facemeshRecalculating: observable,
      isFullDots: observable,
      toggleModal: action,

      guideLines: observable,
      setGuideLines: action,

      handleRedefineDots: action,
    });
  }

  get analysisOptions() {
    return [...this.analysisMap].map(([key]) => key);
  }

  setGuideLines(guideLines) {
    this.guideLines = guideLines;
  }

  setBlobToDownload(blobToDownload) {
    this.blobToDonwload = blobToDownload;
  }

  get analysis() {
    const result = {};
    this.analysisMap.forEach((v, k) => Object.assign(result, { [k]: v }));
    return result;
  }

  get facemeshIsRecalculating() {
    return this.facemeshRecalculating;
  }

  toggleReady(value) {
    if (value) {
      this.isReady = value;
      return;
    }
    this.isReady = !this.isReady;
  }

  toggleModal(value) {
    if (value) {
      this.modal = value;
      return;
    }
    this.modal = !this.modal;
  }

  toggleExporting(value = null) {
    this.exporting = value || !this.exporting;
  }

  setFetchingStatus(status) {
    this.isFetching = status;
  }

  setExport(key, value) {
    this.exportMap.set(key, value);
  }

  setFacemeshRecalculating(value) {
    this.facemeshRecalculating = value;
  }

  get(key) {
    return this.analysisMap.get(key);
  }

  setAnalysisActive(value) {
    this.analysisActive = value;
  }

  setAnalysisList(analysis) {
    this.analysisMap.clear();

    if (typeof analysis === 'string') {
      const list = JSON.parse(analysis);
      return Object.keys(list).forEach((v) => this.analysisMap.set(v, list[v]));
    }

    return Object.keys(analysis).forEach((v) =>
      this.analysisMap.set(v, analysis[v]),
    );
  }

  exportAnalysis(payload, imageId) {
    return new Promise(async (resolve, reject) => {
      try {
        this.toggleModal(false);

        const { CancelToken } = axios;
        const source = CancelToken.source();

        defaultStore.setSourceAxios(source);

        const res = await axios.request({
          url: `${baseURL}/v1/analysis/report/${imageId}`,
          method: 'post',
          responseType: 'blob',
          data: { ...payload, obs: 'report' },
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('haiah_app@appToken'),
            )}`,
            ContentType: 'application/octet-stream',
            reponseType: 'blob',
          },
          cancelToken: source.token,
        });

        const type = res.headers['content-type'];
        this.setBlobToDownload(
          new Blob([res.data], { type, encoding: 'UTF-8' }),
        );

        this.exporting = false;

        this.setIsFetching(false);

        return resolve(res);
      } catch (e) {
        defaultStore.setHiddenRequestModalProps({
          text: (
            <FeedbackBadge
              icon={{
                group: 'standard',
                name: 'failCircle',
                color: COLORS.danger,
                size: 80,
              }}
              text='Falha ao realizar a exportação'
              textColor='danger'
            />
          ),
          buttonLabel: 'Fechar',
        });

        this.setIsFetching(false);

        this.exporting = false;
        return reject(e);
      }
    });
  }

  updateDotsAndAnalysis(data) {
    galleryStore.setHasDots(data ? !!data.analysis_dots : false);

    if (data == null) {
      this.analysisResult = null;
      return false;
    }

    dotsStore.dotListSetter(JSON.parse(data.analysis_dots), dotsStore.dotsMap);

    if (data.results) {
      this.setAnalysisList(data.results);
    }

    // Configurar o modelo de análise conforme resultado da atualização
    galleryStore.setAnalysisImage(new Analysis(data));

    // Determinar se a análise possui análise de acordo com valor de analysis_dots
    this.analysisResult = data;

    if (data.analysis_dots == null && data.results == null) {
      this.clearAllAnalysisData();
      return false;
    }
    dotsStore.setLastWidth(data.last_width);

    return {
      dots: dotsStore.dots,
      analysis: this.analysis,
      lastWidth: data.last_width,
    };
  }

  clearAllAnalysisData() {
    dotsStore.clearAllDots();
    this.analysisActive = null;
    this.analysisMap.clear();
  }

  handleRedefineDots(imageId) {
    document.getElementById('btn-close-modal-redefine-dots')?.click();

    this.resetAllData(imageId)
      .then(() => {
        toast.success('Pontos removidos com sucesso');
      })
      .catch(() => {
        toast.warning('Não foi possível remover os pontos');
      });
  }

  resetAllData(imageId) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.isLoadingPromise(
          baseAPI.post('/v1/analysis/result/reset', {
            face_image_id: imageId,
          }),
        );

        if (res.data != null) {
          this.isFullDots = false;
          this.updateDotsAndAnalysis(res.data.data);
        }

        this.setIsFetching(false);

        return resolve(res);
      } catch (e) {
        this.setIsFetching(false);

        return reject(e);
      }
    });
  }

  saveAnalysis(patientId, imageId, dots = null, guideLine = null) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.isLoadingPromise(
          baseAPI.post('/v1/analysis/result', {
            patient_id: patientId,
            face_image_id: imageId,
            last_width: dotsStore.lastWidth,
            professional_id: authStore.professional.id,
            analysis_dots: dots || dotsStore.dots,
            analysis_rulers: guideLine,
          }),
        );

        if (res.data != null) {
          if (res.data.data.analysis_dots != null) {
            this.isFullDots = true;
          }

          this.updateDotsAndAnalysis(res.data.data);
          galleryStore.setAnalysisImage(new Analysis(res.data.data));

          return resolve(res);
        }

        return resolve(res);
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data.Message);
        }
        return reject(e);
      }
    });
  }

  *updateAnalysis(patientId, imageId) {
    try {
      const res = yield baseAPI.put(
        `/v1/analysis/result/${this.analysisResult.id}`,
        {
          patient_id: patientId,
          face_image_id: imageId,
          last_width: dotsStore.lastWidth,
          professional_id: authStore.professional.id,
          analysis_dots: dotsStore.dots,
        },
      );

      if (res.data != null) {
        yield this.updateDotsAndAnalysis(res.data.data);
      }
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.Message);
      }
    }
  }

  addOrUpdateGuideLines(option, index = null) {
    if (index !== null) {
      this.guideLines = [
        ...this.guideLines.slice(0, index),
        option,
        ...this.guideLines.slice(index + 1),
      ];
      return true;
    }

    this.guideLines.push(option);
    return true;
  }

  removeGuideLines(index) {
    if (index === 0) {
      this.guideLines.shift();
    }
    if (index > 0) {
      this.guideLines = [
        ...this.guideLines.slice(0, index),
        ...this.guideLines.slice(index + 1),
      ];
    }
    return true;
  }
}

export default new AnalysisStore();
