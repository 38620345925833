const Analitic = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'blue', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.25 3.84375H7.6875C6.32827 3.84375 5.0247 4.3837 4.06358 5.34483C3.10245 6.30595 2.5625 7.60952 2.5625 8.96875V35.875C2.5625 37.2342 3.10245 38.5378 4.06358 39.4989C5.0247 40.46 6.32827 41 7.6875 41H33.3125C34.6717 41 35.9753 40.46 36.9364 39.4989C37.8975 38.5378 38.4375 37.2342 38.4375 35.875V8.96875C38.4375 7.60952 37.8975 6.30595 36.9364 5.34483C35.9753 4.3837 34.6717 3.84375 33.3125 3.84375H30.75V6.40625H33.3125C33.9921 6.40625 34.6439 6.67623 35.1245 7.15679C35.605 7.63735 35.875 8.28913 35.875 8.96875V35.875C35.875 36.5546 35.605 37.2064 35.1245 37.687C34.6439 38.1675 33.9921 38.4375 33.3125 38.4375H7.6875C7.00788 38.4375 6.3561 38.1675 5.87554 37.687C5.39498 37.2064 5.125 36.5546 5.125 35.875V8.96875C5.125 8.28913 5.39498 7.63735 5.87554 7.15679C6.3561 6.67623 7.00788 6.40625 7.6875 6.40625H10.25V3.84375Z'
      fill={color}
      fillOpacity='0.8'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.3438 2.5625H16.6562C16.3164 2.5625 15.9906 2.69749 15.7503 2.93777C15.51 3.17805 15.375 3.50394 15.375 3.84375V6.40625C15.375 6.74606 15.51 7.07195 15.7503 7.31223C15.9906 7.55251 16.3164 7.6875 16.6562 7.6875H24.3438C24.6836 7.6875 25.0095 7.55251 25.2497 7.31223C25.49 7.07195 25.625 6.74606 25.625 6.40625V3.84375C25.625 3.50394 25.49 3.17805 25.2497 2.93777C25.0095 2.69749 24.6836 2.5625 24.3438 2.5625ZM16.6562 0C15.6368 0 14.6592 0.404965 13.9383 1.12581C13.2175 1.84665 12.8125 2.82432 12.8125 3.84375V6.40625C12.8125 7.42568 13.2175 8.40335 13.9383 9.12419C14.6592 9.84503 15.6368 10.25 16.6562 10.25H24.3438C25.3632 10.25 26.3408 9.84503 27.0617 9.12419C27.7825 8.40335 28.1875 7.42568 28.1875 6.40625V3.84375C28.1875 2.82432 27.7825 1.84665 27.0617 1.12581C26.3408 0.404965 25.3632 0 24.3438 0L16.6562 0Z'
      fill={color}
      fillOpacity='0.8'
    />
    <path
      d='M10.25 28.1875C10.25 27.5079 10.52 26.8561 11.0005 26.3755C11.4811 25.895 12.1329 25.625 12.8125 25.625C13.4921 25.625 14.1439 25.895 14.6245 26.3755C15.105 26.8561 15.375 27.5079 15.375 28.1875V30.75C15.375 31.4296 15.105 32.0814 14.6245 32.562C14.1439 33.0425 13.4921 33.3125 12.8125 33.3125C12.1329 33.3125 11.4811 33.0425 11.0005 32.562C10.52 32.0814 10.25 31.4296 10.25 30.75V28.1875ZM25.625 17.9375C25.625 17.2579 25.895 16.6061 26.3755 16.1255C26.8561 15.645 27.5079 15.375 28.1875 15.375C28.8671 15.375 29.5189 15.645 29.9995 16.1255C30.48 16.6061 30.75 17.2579 30.75 17.9375V30.75C30.75 31.4296 30.48 32.0814 29.9995 32.562C29.5189 33.0425 28.8671 33.3125 28.1875 33.3125C27.5079 33.3125 26.8561 33.0425 26.3755 32.562C25.895 32.0814 25.625 31.4296 25.625 30.75V17.9375ZM17.9375 23.0625C17.9375 22.3829 18.2075 21.7311 18.688 21.2505C19.1686 20.77 19.8204 20.5 20.5 20.5C21.1796 20.5 21.8314 20.77 22.312 21.2505C22.7925 21.7311 23.0625 22.3829 23.0625 23.0625V30.75C23.0625 31.4296 22.7925 32.0814 22.312 32.562C21.8314 33.0425 21.1796 33.3125 20.5 33.3125C19.8204 33.3125 19.1686 33.0425 18.688 32.562C18.2075 32.0814 17.9375 31.4296 17.9375 30.75V23.0625Z'
      fill={color}
      fillOpacity='0.8'
    />
  </svg>
);

export default Analitic;
