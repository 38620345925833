import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.name
 * @param {string} props.label
 * @param {string | number} props.value
 * @param {boolean} [props.checked=false]
 * @param {string} [props.inputClass=null]
 * @param {string} [props.labelClass=null]
 * @param {boolean} [props.inline=false]
 * @param {boolean} [props.disabled=false]
 * @param {function} [props.onChange=null]
 * @returns
 */
export default function Radio(props) {
  const {
    id,
    name,
    label,
    checked = false,
    inputClass,
    labelClass,
    inline,
    value,
    disabled,
    onChange = null,
  } = props;

  const _formClass = clsx('form-check', { 'form-check-inline': inline });
  const _inputClass = clsx('form-check-input', inputClass);
  const _labelClass = clsx('form-check-label', labelClass);

  const handleChange = (e) => onChange?.(e);

  return (
    <div className={_formClass}>
      <input
        data-testid='radio-option'
        id={id}
        name={name}
        type='radio'
        className={_inputClass}
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={handleChange}
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={_labelClass} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  id: null,
  name: null,
  label: null,
  checked: false,
  inline: false,
  disabled: false,
  inputClass: null,
  labelClass: null,
  value: null,
  onChange: null,
};
