import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useDebounce, useMeasure, useToggle } from 'react-use';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { COLORS_TEXT, COLORS } from '../../../../constants/Colors';
import { useStore } from '../../../../hooks';
import FacemeshMobx from '../../Analysis/Facemesh/FacemeshMobx';
import InputSelect from '../../../Styleguide/InputSelect/InputSelect';
import ExportComparativeModal from '../../Analysis/ExportComparativeModal/ExportComparativeModal';
import ComparativeDimmer from '../ComparativeDimmer/ComparativeDimmer';
import { ANALYSIS_LIST } from '../../../../constants/ToolsConstants';
import LoaderModal from '../../../LoaderModal/LoaderModal';
import LoadingSpinner from '../../../Styleguide/LoadingSpinner/LoadingSpinner';

const ComparativeViewDesktop = () => {
  const history = useHistory();
  const store = useStore();
  const [isReady, setIsReady] = useState(false);
  const [isDotsVisible, toggleIsDotVisible] = useToggle(true);
  const [isOpenComparative, toggleOpenComparative] = useToggle(false);
  const { id: patientId } = useParams();

  if (store.toolsStore.imagesComparative?.length < 2) {
    history.push(`/patients/${patientId}/comparative`);
  }

  const [ref, { height: containerHeight, width: containerWidth }] =
    useMeasure();
  const [facemeshRef, { width }] = useMeasure();
  const [headerRef, { height: headerHeight }] = useMeasure();
  const [footerRef, { height: footerHeight }] = useMeasure();
  const maxWidth = ((containerHeight - headerHeight - footerHeight) * 3) / 4;

  useDebounce(
    () => {
      setIsReady(true);
    },
    600,
    [],
  );

  useEffect(
    () => () => {
      store.comparativeStore.cleanExportMap();
      store.analysisStore.clearAllAnalysisData();
      store.toolsStore.cleanImageComparative();
    },
    [],
  );



  return (
    <div
      className='position-relative d-flex flex-column w-100 h-100 flex-fill overflow-hidden pb-5'
      ref={ref}
    >
      <LoaderModal
        text='Exportando relatório...'
        id='loader-comparative-desktop'
      />
      {!isReady ? <ComparativeDimmer show /> : null}
      <section
        className='d-flex align-items-center justify-content-between p-4'
        ref={headerRef}
      >
        <div className='d-flex'>
          <IconComponent
            group='standard'
            name='chevronleft'
            size={20}
            color={COLORS_TEXT.muted}
            onClick={() => history.goBack()}
          />
          <div style={{ marginLeft: '18px' }}>
            <h6 className='navbar-header'>Comparativo de fotos</h6>
          </div>
        </div>
        <div>
          <div className='d-flex'>
            <div>
              <IconComponent
                group='standard'
                name={isDotsVisible ? 'eye' : 'eyehide'}
                size={24}
                color={isDotsVisible ? 'gray' : COLORS.danger}
                onClick={toggleIsDotVisible}
                id={isDotsVisible ? 'icon-eye' : 'icon-eyehide'}
              />
            </div>
            <div style={{ marginLeft: '32px' }}>
              <IconComponent
                group='standard'
                name='download'
                size={24}
                onClick={() => {
                  toggleOpenComparative();
                }}
                color='black'
                id='icon-download'
              />
            </div>
          </div>
        </div>
      </section>
      <section className='d-flex flex-fill h-100 w-100'>
        <div
          className='d-flex w-100 h-100 flex-row align-items-center justify-content-center'
          style={{ maxWidth: 'calc(100vw - 60px)' }}
        >
          {store.galleryStore.isFetching ? (
            <LoadingSpinner />
          ) : (
            store.toolsStore.imagesComparative?.map((image, index) => (
              <div
                ref={facemeshRef}
                className='d-flex w-100  position-relative'
                style={{
                  marginRight: index === 0 ? '16px' : 0,
                  maxWidth:
                    containerHeight > containerWidth ? maxWidth / 2 : maxWidth,
                  height: (width / 3) * 4,
                }}
                key={image.id}
                id={image.id}
              >
                <FacemeshMobx
                  show={isDotsVisible}
                  comparative
                  width={width}
                  height={(width / 3) * 4}
                  image={image}
                  patientId={image.patient_id}
                  exporting
                  disableDots
                />
                {isDotsVisible && (
                  <div className='position-absolute bottom-0 bg-dark-50 zi-7 w-100 text-white'>
                    <div className='d-flex flex-row flex-wrap justify-content-between px-4 py-1'>
                      <small className='fs-7 text-truncate text-start col-9'>{image.fileName}</small>
                      <small className='fs-7 text-end col-3'>
                        {format(new Date(image.date), 'dd/MM/yyyy')}
                      </small>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </section>
      <section
        className='d-flex w-100 align-items-center justify-content-center px-4'
        ref={footerRef}
      >
        <div className='py-2 px-3'>
          <InputSelect
            placeholder='Selecione uma análise facial'
            options={ANALYSIS_LIST}
            value={store.analysisStore.analysisActive}
            onChange={(e) => {
              store.analysisStore.setAnalysisActive(e.target.value);
            }}
            id='select-comparative-analysis'
          />
        </div>
      </section>
      <ExportComparativeModal
        isOpen={isOpenComparative}
        setIsOpen={toggleOpenComparative}
      />
    </div>
  );
};

export default observer(ComparativeViewDesktop);
