const Advanced = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 14 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.710972 1.08634C0.798058 0.999033 0.901512 0.929765 1.01541 0.882503C1.12931 0.835241 1.25141 0.810913 1.37472 0.810913C1.49804 0.810913 1.62014 0.835241 1.73404 0.882503C1.84793 0.929765 1.95139 0.999033 2.03847 1.08634L13.2885 12.3363C13.3758 12.4234 13.445 12.5269 13.4923 12.6408C13.5396 12.7547 13.5639 12.8768 13.5639 13.0001C13.5639 13.1234 13.5396 13.2455 13.4923 13.3594C13.445 13.4733 13.3758 13.5768 13.2885 13.6638L2.03847 24.9138C1.86244 25.0899 1.62368 25.1888 1.37472 25.1888C1.12577 25.1888 0.88701 25.0899 0.710972 24.9138C0.534935 24.7378 0.436038 24.499 0.436038 24.2501C0.436038 24.0011 0.534935 23.7624 0.710972 23.5863L11.2991 13.0001L0.710972 2.41384C0.623666 2.32675 0.554398 2.2233 0.507136 2.1094C0.459874 1.9955 0.435547 1.8734 0.435547 1.75009C0.435547 1.62678 0.459874 1.50467 0.507136 1.39078C0.554398 1.27688 0.623666 1.17342 0.710972 1.08634Z'
      fill={color}
    />
  </svg>
);

export default Advanced;
