import React from 'react';

import PropTypes from 'prop-types';
import ANALYSIS_TRANSLATION from '../../../../../constants/AnalysisTranslation';

const FacialOpening = ({ showText, analysis }) => (
  <div className='d-flex flex-column '>
    <div className='flex-fill p-2'>
      {showText && (
        <>
          <div className='text-primary d-flex flex-row justify-content-between'>
            <div className='text-white'>Obtido:</div>
          </div>
          <div className='d-flex flex-column '>
            {Object.keys(analysis.proportion).map((v) => (
              <div key={v}>
                <span className='text-white'>
                  {`${ANALYSIS_TRANSLATION[v]}`}:
                </span>
                <span className='text-success'>{`${analysis.proportion[v]}°`}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  </div>
);
FacialOpening.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

FacialOpening.defaultProps = {
  showText: false,
  analysis: null,
};

export default FacialOpening;
