const Computer = ({
    // eslint-disable-next-line react/prop-types
    iconStyleProps: { size = '24', color = 'white', ...rest },
  }) => (
    <svg width={size} height={size} viewBox="0 0 24 25" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M8.625 20.75C8.8755 20.2505 9 19.5005 9 18.5H15C15 19.5005 15.1245 20.2505 15.375 20.75H16.5C16.6989 20.75 16.8897 20.829 17.0303 20.9697C17.171 21.1103 17.25 21.3011 17.25 21.5C17.25 21.6989 17.171 21.8897 17.0303 22.0303C16.8897 22.171 16.6989 22.25 16.5 22.25H7.5C7.30109 22.25 7.11032 22.171 6.96967 22.0303C6.82902 21.8897 6.75 21.6989 6.75 21.5C6.75 21.3011 6.82902 21.1103 6.96967 20.9697C7.11032 20.829 7.30109 20.75 7.5 20.75H8.625Z" fill="#F7F7F7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.9865 5H3C2.5125 5 2.247 5.117 2.097 5.2175C1.93223 5.33221 1.80078 5.48851 1.716 5.6705C1.58814 5.93468 1.51468 6.22187 1.5 6.515V15.5C1.5 15.9875 1.617 16.253 1.7175 16.403C1.8225 16.5605 1.9725 16.685 2.1705 16.784C2.42331 16.9065 2.69726 16.9793 2.9775 16.9985L3.015 17H21C21.4875 17 21.753 16.883 21.903 16.7825C22.0678 16.6678 22.1992 16.5115 22.284 16.3295C22.4065 16.0767 22.4793 15.8027 22.4985 15.5225L22.5 15.485V6.5C22.5 6.0125 22.383 5.747 22.2825 5.597C22.1678 5.43218 22.0115 5.30072 21.8295 5.216C21.5653 5.08814 21.2781 5.01468 20.985 5H20.9865ZM21 3.5H3C0 3.5 0 6.5 0 6.5V15.5C0 18.5 3 18.5 3 18.5H21C24 18.5 24 15.5 24 15.5V6.5C24 3.5 21 3.5 21 3.5Z" fill="#F7F7F7"/>
    </svg>
  );
  export default Computer;
  