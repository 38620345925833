import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Select = ({ handleChange, options, selectId, selectLabel, disabled }) => (
	<Form.Select
		disabled={disabled}
		onChange={(e) => {
			handleChange(e.target.value);
		}}
		id={`slct-filter-${selectId}`}
	>
		<option value='' id={`slct-${selectLabel}-${selectId}`}>
			{selectLabel}
		</option>
		{options.map((p, i) => (
			<option key={p.key} value={p.value} id={`${p.key}@${i}`}>
				{p.text}
			</option>
		))}
	</Form.Select>
);

Select.propTypes = {
	handleChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	selectId: PropTypes.string.isRequired,
	selectLabel: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

Select.defaultProps = {
	disabled: false,
};

export default Select;
