/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';

const Input = React.forwardRef(
	/**
	 * @param {import('react-bootstrap').FormControlProps} props
	 * @param {boolean} [props.centeredLabel=false]
	 * @returns {JSX.Element}
	 */
	(
		{
			centeredLabel,
			label,
			error,
			className,
			children,
			inputClassName,
			labelClassName,
			isInvalid,
			...rest
		},
		ref,
	) => (
		<Form.Group className={`${className}`}>
			<Form.Label
				className={clsx(
					'd-flex w-100 ',
					centeredLabel && 'justify-content-center ',
					labelClassName,
				)}
			>
				{label}
			</Form.Label>
			<div className='position-relative'>
				<Form.Control
					isInvalid={isInvalid}
					ref={ref}
					type='text'
					{...rest}
					className={inputClassName}
				/>
				{children}
			</div>
			<Form.Control.Feedback
				type='invalid'
				className={isInvalid ? 'd-block' : 'd-none'}
			>
				{error}
			</Form.Control.Feedback>
		</Form.Group>
	),
);

export default Input;

Input.propTypes = {
	centeredLabel: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	inputClassName: PropTypes.string,
	labelClassName: PropTypes.string,
};

Input.defaultProps = {
	centeredLabel: false,
	className: 'mb-4 w-100 ',
	inputClassName: '',
	labelClassName: '',
	children: <></>,
};
