import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

import ButtonInfoDrop from '../../Styleguide/ButtonInfoDrop/ButtonInfoDrop';
import { useIsMobile } from '../../../hooks';

/**
 * @typedef FormBlockProps
 * @property {string | undefined} [title = '']
 * @property {boolean | undefined} [isActive = false]
 * @property {function | undefined} [onClick = null]
 * @property {JSX.Element | JSX.Element[] | undefined} [children = null]
 */

/**
 *
 * @param {boolean} isVisible - If its is visible or not
 * @param {boolean} isMobile - If window is mobile
 * @returns
 */
function renderChevron(isVisible, isMobile) {
  if (!isMobile) return 'chevronright';

  return isVisible ? 'chevronup' : 'chevrondown';
}

/**
 * @param {FormBlockProps} props
 * @returns {JSX.Element}
 */
export default function FormBlock({ children, title, isActive, onClick }) {
  const isMobile = useIsMobile();

  const isVisible = isMobile ? isActive : true;

  return (
    <>
      <ButtonInfoDrop
        onClick={() => (isMobile ? onClick?.() : null)}
        title={title}
        icon={{
          group: 'standard',
          name: renderChevron(isActive, isMobile),
          size: '24',
        }}
        isActive={isActive}
      />

      <Collapse in={isVisible}>
        <div>
          <div className='px-1 pb-1'>
            <div className='px-2 pb-2'>{children}</div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

FormBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

FormBlock.defaultProps = {
  children: null,
  title: '',
  isActive: false,
  onClick: null,
};
