import { observer } from 'mobx-react';
import React, { Suspense } from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Loader from '../Loader/Loader';

import {
	AnalysisListScreen,
	AnalysisSelectScreen,
	CodePasswordScreen,
	CustomComparativeScreen,
	ComparativeMobileWithFirstScreen,
	ConfirmationFeedbackScreen,
	ConfirmationScreen,
	DotsScreen,
	EditImageScreen,
	ForgotPasswordScreen,
	HomeScreen,
	LibraryScreen,
	LoginScreen,
	PatientDataScreen,
	PatientsScreen,
	RegisterScreen,
	ResetFeedbackScreen,
	ResetPasswordScreen,
	TimelapseScreen,
	TimelapseSelectScreen,
	ToolsScreen,
	PhotoToolsScreen,
	ProfileStorageScreen,
	UpgradeScreen,
	UserProfileScreen,
	ComparativeViewScreen,
	CustomComparativeSelectScreen,
	CustomComparativeSelectAlbumScreen,
	FileScreen,
	UserDocumentFilesScreen,
	UserDocumentScreen,
} from '../LoadableComponent/LoadableComponent';

import {
	HomeLayout,
	// LibraryLayout,
	MainLayout,
	PatientDataLayout,
	PatientDataRegisterLayout,
	PatientLayout,
	PhotoToolsLayout,
	LibraryListLayout,
	EditImageLayout,
	TimelapseLayout,
	ComparativeLayout,
	AnalysisLayout,
	ProfileLayout,
	ComparativeStartLayout,
	ComparativeViewLayout,
	UpgradeLayout,
	CheckoutLayout,
	DocumentLayout,
	LibraryLayout,
} from '../Layout';

import PlanCheckoutScreen from '../../screens/PlanCheckoutScreen/PlanCheckoutScreen';
import FilesLayout from '../Layout/FilesLayout/FilesLayout';

// 

const Router = () => (
	<BrowserRouter>
		<Switch>
			<Suspense fallback={<Loader />}>
				<PrivateRoute
					exact
					path='/'
					params={{ title: 'Haiah App | Home' }}
					component={HomeScreen}
					layout={HomeLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/tools/'
					params={{ title: 'Haiah App | Análise facial' }}
					component={ToolsScreen}
					layout={MainLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/tools/:imageId'
					params={{ title: 'Haiah App | Ferramentas' }}
					component={PhotoToolsScreen}
					layout={PhotoToolsLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/analysis/:imageId'
					params={{ title: 'Haiah App | Análise facial' }}
					component={DotsScreen}
					layout={AnalysisLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/analysis/:imageId/view'
					params={{ title: 'Haiah App | Análise facial' }}
					component={AnalysisListScreen}
					layout={AnalysisLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/timelapse/view'
					params={{ title: 'Haiah App | Timelapse' }}
					component={TimelapseScreen}
					layout={TimelapseLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/timelapse'
					params={{ title: 'Haiah App | Timelapse' }}
					component={TimelapseSelectScreen}
					layout={MainLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/analysis'
					params={{ title: 'Haiah App | Análise facial' }}
					component={AnalysisSelectScreen}
					layout={MainLayout}
				/>

				<PrivateRoute
					exact
					path='/patients/:id/select-album'
					params={{ title: 'Haiah App | Comparativo' }}
					component={CustomComparativeSelectAlbumScreen}
					layout={ComparativeStartLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/select-photos/:albumId'
					params={{ title: 'Haiah App | Comparativo' }}
					component={CustomComparativeSelectScreen}
					layout={ComparativeStartLayout}
				/>

				<PrivateRoute
					exact
					path='/patients/:id/comparative'
					params={{ title: 'Haiah App | Comparativo' }}
					component={CustomComparativeScreen}
					layout={ComparativeStartLayout}
				/>

				<PrivateRoute
					exact
					path='/patients/:id/comparative/:firstImage'
					params={{ title: 'Haiah App | Comparativo' }}
					component={ComparativeMobileWithFirstScreen}
					layout={ComparativeStartLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/comparative/view'
					params={{ title: 'Haiah App | Comparativo' }}
					component={ComparativeMobileWithFirstScreen}
					layout={ComparativeLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/comparative-view'
					params={{ title: 'Haiah App | Comparativo' }}
					component={ComparativeViewScreen}
					layout={ComparativeViewLayout}
				/>
				<PrivateRoute
					exact
					path='/patients'
					params={{ title: 'Haiah App | Pacientes' }}
					component={PatientsScreen}
					layout={PatientLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/profile'
					params={{ title: 'Haiah App | Perfil' }}
					component={PatientDataScreen}
					layout={PatientDataLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/register'
					params={{ title: 'Haiah App | Cadastrar paciente' }}
					component={PatientDataScreen}
					layout={PatientDataRegisterLayout}
				/>
				<PrivateRoute
					exact
					path='/user/documents'
					params={{ title: 'Haiah App | Meus documentos' }}
					component={UserDocumentScreen}
					layout={FilesLayout}
				/>
				<PrivateRoute
					exact
					path='/user/documents/:albumId'
					params={{ title: 'Haiah App | Meus documentos' }}
					component={UserDocumentFilesScreen}
					layout={FilesLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/library'
					params={{ title: 'Haiah App | Biblioteca' }}
					component={LibraryScreen}
					layout={LibraryListLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/appointment/:albumId'
					params={{ title: 'Haiah App | Biblioteca' }}
					component={FileScreen}
					layout={LibraryLayout}
				/>
				<PrivateRoute
					exact
					path='/patients/:id/edit/:imageId'
					component={EditImageScreen}
					params={{ title: 'Haiah App | Editar imagem' }}
					layout={EditImageLayout}
				/>
				<PrivateRoute
					exact
					path='/user/status'
					component={ProfileStorageScreen}
					params={{ title: 'Haiah App | Status' }}
					layout={ProfileLayout}
				/>
				<PrivateRoute
					exact
					path='/user/upgrade/plans'
					component={UpgradeScreen}
					params={{ title: 'Haiah App | Upgrade' }}
					layout={UpgradeLayout}
				/>
				<PrivateRoute
					exact
					path='/user/upgrade/checkout/:planId'
					component={PlanCheckoutScreen}
					params={{ title: 'Haiah App | Checkout' }}
					layout={CheckoutLayout}
				/>
				<PrivateRoute
					exact
					path='/user/profile'
					component={UserProfileScreen}
					params={{ title: 'Haiah App | Profile' }}
					layout={ProfileLayout}
				/>
				<PublicRoute
					exact
					path='/login'
					component={LoginScreen}
					params={{ title: 'Haiah App | Login' }}
				/>
				<PublicRoute
					path='/register'
					component={RegisterScreen}
					params={{ title: 'Haiah App | Crie sua conta' }}
				/>
				<PublicRoute
					path='/confirmation'
					component={ConfirmationScreen}
					params={{ title: 'Haiah App | Crie sua conta' }}
				/>
				<PublicRoute
					path='/confirmation-feedback'
					component={ConfirmationFeedbackScreen}
					params={{ title: 'Haiah App | Crie sua conta' }}
				/>
				<PublicRoute
					path='/forgot-password'
					component={ForgotPasswordScreen}
					params={{ title: 'Haiah App | Recuperar senha' }}
				/>
				<PublicRoute
					path='/password-code'
					component={CodePasswordScreen}
					params={{ title: 'Haiah App | Recuperar senha' }}
				/>
				<PublicRoute
					path='/reset-password'
					component={ResetPasswordScreen}
					params={{ title: 'Haiah App | Alterar senha' }}
				/>
				<PublicRoute
					path='/reset-feedback'
					component={ResetFeedbackScreen}
					params={{ title: 'Haiah App | Alterar senha' }}
				/>
			</Suspense>
		</Switch>
	</BrowserRouter>
);

export default observer(Router);
