import React from 'react';
import { observer } from 'mobx-react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { imagePlaceholder } from '../../../assets/images/authentication';
import FileBox from '../../PhotoLibrary/ImageList/FileBox';

const EmptyStateTools = () => {
	const placeholder = [
		{
			src: imagePlaceholder,
			id: 1,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 2,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 3,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 4,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 5,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 6,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 7,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 8,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 9,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 10,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 11,
			name: 'placeholder',
			type: 'png',
		},
		{
			src: imagePlaceholder,
			id: 12,
			name: 'placeholder',
			type: 'png',
		},
	];

	return placeholder.map((v) => (
		<Col
			key={v.id}
			className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 mb-4 position-relative'
		>
			<FileBox
				fileBoxProps={{
					handleClickFile: true,
					hasFileMenu: false,
				}}
				key={v.id}
				fileName={v.name}
				src={v.src}
				fileType={v.type}
				select={() => {}}
				id={v.id}
			/>
		</Col>
	));
};

export default observer(EmptyStateTools);

EmptyStateTools.propTypes = {
	title: PropTypes.string,
	selectAction: PropTypes.func,
};

EmptyStateTools.defaultProps = {
	title: '',
	selectAction: () => {},
};
