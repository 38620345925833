import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ThirdsProportion from '../Analysis/ThirdsProportion';
import Angle from '../Analysis/Angle';
import FaceType from '../Analysis/FaceType';
import JawAngle from '../Analysis/JawAngle';
import MouthToEye from '../Analysis/MouthToEye';
import NoseToEye from '../Analysis/NoseToEye';
import HorizontalPropotion from '../Analysis/HorizontalPropotion';
import VerticalXHorizontal from '../Analysis/VerticalXHorizontal';
import FacialOpening from '../Analysis/FacialOpening';
import AngleEyebrow from '../Analysis/AngleEyebrow';

const Analysis = (props) => {
  const {
    filteredDots,
    width,
    analysis,
    analysisType,
    strokeWidth,
    imageType,
  } = props;

  const startPoint = defineStartPoint();

  function defineStartPoint() {
    let value;
    const right = 0.93,
      left = 0.06;

    switch (imageType) {
      case 'left':
        value = left;
        break;
      case 'right':
        value = right;
        break;
      case 'front':
        value = left;
        break;
      default:
        value = left;
        break;
    }

    return width * value;
  }

  return useMemo(() => {
    if (filteredDots?.length < 3) {
      return null;
    }
    switch (analysisType) {
      case 'proportion_face_front':
      case 'proportion_lips_front':
      case 'proportion_face_side':
      case 'proportion_lips_side':
      case 'bottom_third_proportion_front':
      case 'bottom_third_proportion_side':
        return (
          <ThirdsProportion
            filteredDots={filteredDots}
            strokeWidth={strokeWidth}
            startPoint={startPoint}
          />
        );
      case 'face_type':
        return (
          <FaceType filteredDots={filteredDots} strokeWidth={strokeWidth} />
        );
      case 'vertical_vs_horizontal_facial':
        return (
          <VerticalXHorizontal
            filteredDots={filteredDots}
            strokeWidth={strokeWidth}
          />
        );
      case 'chin_facial_opening':
      case 'glabella_facial_opening':
        return (
          <FacialOpening
            filteredDots={filteredDots}
            strokeWidth={strokeWidth}
            target={analysisType === 'chin_facial_opening' ? 'Pg' : 'GL'}
          />
        );
      case 'horizontal_proportion_third':
      case 'horizontal_proportion_fifth':
        return (
          <HorizontalPropotion
            filteredDots={filteredDots}
            strokeWidth={strokeWidth}
          />
        );
      case 'eyebrow_angle':
        return (
          <AngleEyebrow filteredDots={filteredDots} strokeWidth={strokeWidth} />
        );
      case 'nose_frontal_angle':
      case 'nose_lips_angle':
      case 'profile_type':
        return <Angle filteredDots={filteredDots} strokeWidth={strokeWidth} />;
      case 'jaw_angle':
        return (
          <JawAngle filteredDots={filteredDots} strokeWidth={strokeWidth} />
        );
      case 'mouth_to_eyes':
        return (
          <MouthToEye filteredDots={filteredDots} strokeWidth={strokeWidth} />
        );
      case 'nose_to_eyes':
        return (
          <NoseToEye filteredDots={filteredDots} strokeWidth={strokeWidth} />
        );

      default:
        return null;
    }
  }, [analysisType, analysis, filteredDots]);
};

Analysis.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  width: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  analysisType: PropTypes.string,
  imageType: PropTypes.string,
};

Analysis.defaultProps = {
  analysisType: null,
  filteredDots: [],
  analysis: {},
  strokeWidth: 1,
};

export default Analysis;
