import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useStore } from '../../../hooks';
import Input from '../../Styleguide/Input/Input';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';

const DeletePatient = ({ open, toggleOpen }) => {
  const store = useStore();
  const [value, setValue] = useState('');
  const history = useHistory();

  const [isInvalid, setIsInvalid] = useState(false);
  const validationDelete = useMemo(
    () => value === store.patientsStore.patientSelected?.name,
    [store.patientsStore.patientSelected?.name, value],
  );

  const handleDeletePatient = async () => {
    if (validationDelete) {
      await store.patientsStore.deletePatient(store.authStore.user.id);
      history.push('/patients');
      return;
    }

    setIsInvalid(true);

    toast.error('Confirmação inválida');
  };

  return (
    <>
      <ModalComponent
        show={open}
        onClose={() => toggleOpen()}
        isLoading={store.patientsStore.isFetching}
        title='Excluir paciente'
        subTitle='Você tem certeza que deseja excluir o paciente?'
        text='Essa ação não poderá ser desfeita.'
        nameButton='Excluir'
        idButton='delete-patient'
        onClickButton={handleDeletePatient}
        isDelete
        disabled={!validationDelete}
      >
        <Input
          value={value}
          label='Digite abaixo o nome completo do paciente para excluir:'
          name='value'
          id='value'
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder={store.patientsStore.patientSelected?.name}
          isInvalid={isInvalid}
          error='* Nome não corresponde'
          maxLength={60}
        />
      </ModalComponent>
    </>
  );
};

export default observer(DeletePatient);

DeletePatient.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

DeletePatient.defaultProps = {
  open: false,
  toggleOpen: () => {},
};
