import React from 'react';
import { observer } from 'mobx-react';
import MobileNavBar from './MobileNavBar/MobileNavBar';
import NonMobileNavBar from './NonMobileNavBar/NonMobileNavBar';

/**
 *
 * @typedef {Object} NavbarComponentProps
 * @property {boolean} isSearching
 */

const NavBarComponent = () => (
  <div className='d-flex col-12'>
    <div className='d-flex d-none d-sm-block flex-fill justify-content-center'>
      <NonMobileNavBar />
    </div>
    <div className='d-flex justify-content-center d-sm-none flex-fill w-100'>
      <MobileNavBar />
    </div>
  </div>
);

export default observer(NavBarComponent);
