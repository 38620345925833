const Minimize = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '12', color = '#D7DCE2', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.125 1.52698C7.22446 1.52698 7.31984 1.56649 7.39017 1.63681C7.46049 1.70714 7.5 1.80252 7.5 1.90198V4.52698H10.125C10.2245 4.52698 10.3198 4.56649 10.3902 4.63681C10.4605 4.70714 10.5 4.80252 10.5 4.90198C10.5 5.00143 10.4605 5.09682 10.3902 5.16714C10.3198 5.23747 10.2245 5.27698 10.125 5.27698H7.125C7.02554 5.27698 6.93016 5.23747 6.85983 5.16714C6.78951 5.09682 6.75 5.00143 6.75 4.90198V1.90198C6.75 1.80252 6.78951 1.70714 6.85983 1.63681C6.93016 1.56649 7.02554 1.52698 7.125 1.52698Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.766 1.2345C10.8009 1.26933 10.8286 1.31071 10.8475 1.35627C10.8664 1.40183 10.8761 1.45067 10.8761 1.5C10.8761 1.54932 10.8664 1.59817 10.8475 1.64372C10.8286 1.68928 10.8009 1.73066 10.766 1.7655L7.39097 5.1405C7.35611 5.17536 7.31472 5.20302 7.26916 5.22189C7.22361 5.24076 7.17478 5.25047 7.12547 5.25047C7.07617 5.25047 7.02734 5.24076 6.98179 5.22189C6.93623 5.20302 6.89484 5.17536 6.85997 5.1405C6.82511 5.10563 6.79745 5.06424 6.77858 5.01869C6.75971 4.97313 6.75 4.92431 6.75 4.875C6.75 4.82569 6.75971 4.77687 6.77858 4.73131C6.79745 4.68576 6.82511 4.64436 6.85997 4.6095L10.235 1.2345C10.2698 1.19958 10.3112 1.17187 10.3567 1.15296C10.4023 1.13406 10.4511 1.12433 10.5005 1.12433C10.5498 1.12433 10.5986 1.13406 10.6442 1.15296C10.6898 1.17187 10.7311 1.19958 10.766 1.2345V1.2345ZM5.14097 6.8595C5.1759 6.89433 5.2036 6.93571 5.22251 6.98127C5.24141 7.02683 5.25114 7.07567 5.25114 7.125C5.25114 7.17432 5.24141 7.22316 5.22251 7.26872C5.2036 7.31428 5.1759 7.35566 5.14097 7.3905L1.76597 10.7655C1.73111 10.8004 1.68972 10.828 1.64416 10.8469C1.59861 10.8658 1.54978 10.8755 1.50047 10.8755C1.45117 10.8755 1.40234 10.8658 1.35679 10.8469C1.31123 10.828 1.26984 10.8004 1.23497 10.7655C1.20011 10.7306 1.17245 10.6892 1.15358 10.6437C1.13471 10.5981 1.125 10.5493 1.125 10.5C1.125 10.4507 1.13471 10.4019 1.15358 10.3563C1.17245 10.3108 1.20011 10.2694 1.23497 10.2345L4.60997 6.8595C4.64481 6.82458 4.68619 6.79687 4.73175 6.77796C4.77731 6.75906 4.82615 6.74933 4.87547 6.74933C4.9248 6.74933 4.97364 6.75906 5.0192 6.77796C5.06476 6.79687 5.10614 6.82458 5.14097 6.8595Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.52734 7.125C1.52734 7.02554 1.56685 6.93016 1.63718 6.85983C1.7075 6.78951 1.80289 6.75 1.90234 6.75H4.90234C5.0018 6.75 5.09718 6.78951 5.16751 6.85983C5.23784 6.93016 5.27734 7.02554 5.27734 7.125V10.125C5.27734 10.2245 5.23784 10.3198 5.16751 10.3902C5.09718 10.4605 5.0018 10.5 4.90234 10.5C4.80289 10.5 4.7075 10.4605 4.63718 10.3902C4.56685 10.3198 4.52734 10.2245 4.52734 10.125V7.5H1.90234C1.80289 7.5 1.7075 7.46049 1.63718 7.39017C1.56685 7.31984 1.52734 7.22446 1.52734 7.125V7.125Z'
      fill={color}
    />
  </svg>
);

export default Minimize;
