const ChevronRight = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 6 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.484682 0.234194C0.519516 0.199271 0.560898 0.171564 0.606457 0.152659C0.652016 0.133755 0.700856 0.124023 0.750182 0.124023C0.799507 0.124023 0.848348 0.133755 0.893907 0.152659C0.939466 0.171564 0.980848 0.199271 1.01568 0.234194L5.51568 4.73419C5.5506 4.76903 5.57831 4.81041 5.59722 4.85597C5.61612 4.90153 5.62585 4.95037 5.62585 4.99969C5.62585 5.04902 5.61612 5.09786 5.59722 5.14342C5.57831 5.18898 5.5506 5.23036 5.51568 5.26519L1.01568 9.76519C0.945267 9.83561 0.849764 9.87517 0.750182 9.87517C0.6506 9.87517 0.555097 9.83561 0.484682 9.76519C0.414267 9.69478 0.374708 9.59927 0.374708 9.49969C0.374708 9.40011 0.414267 9.30461 0.484682 9.23419L4.71993 4.99969L0.484682 0.765194C0.449759 0.73036 0.422052 0.688978 0.403147 0.643419C0.384243 0.59786 0.374512 0.549019 0.374512 0.499694C0.374512 0.450368 0.384243 0.401527 0.403147 0.355969C0.422052 0.31041 0.449759 0.269028 0.484682 0.234194V0.234194Z'
      fill={color}
    />
  </svg>
);
export default ChevronRight;
