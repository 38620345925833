import axios from 'axios';

const refreshFileSignature = (signedLink, refreshCallback) =>
  // eslint-disable-next-line
  new Promise(async (resolve, reject) => {
    const imageRequest = axios.create({
      baseURL: signedLink,
    });

    try {
      const response = await imageRequest.get();

      const isInvalidSignature = response.status === 400;

      if (isInvalidSignature) {
        refreshCallback();
      }

      return resolve(isInvalidSignature);
    } catch (error) {
      if (error) {
        refreshCallback();
      }

      return reject(error);
    }
  });

export default refreshFileSignature;
