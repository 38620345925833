import { makeAutoObservable } from 'mobx';

class UIStore {
  sideBarIsActive = false;

  constructor() {
    makeAutoObservable(this);
  }

  get sideBarStatus() {
    return this.sideBarIsActive;
  }

  setSideBarIsActive(value) {
    this.sideBarIsActive = value;
  }
}

export default new UIStore();
