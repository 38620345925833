import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { COLORS } from '../../../../constants/Colors';

import IconComponent from '../../../Svg/IconComponent/IconComponent';

/**
 *
 * @param {Object[]} images
 */

const ComparativeLoadFeedback = ({ transparent, isThumbnail, images }) => (
  <div className='d-flex flex-row justify-content-center align-items-center'>
    <div className='d-flex justify-content-center align-content-center mx-1'>
      {images?.length > 0 && images[0] != null ? (
        <div className='d-block'>
          <Image
            className='of-contain'
            src={images[0]?.media}
            width={32}
            height={42}
            rounded
          />
        </div>
      ) : (
        <IconComponent
          group='standard'
          name='photo'
          size={isThumbnail ? '30' : '130'}
          color={COLORS.grayHelper}
          background={transparent ? '' : COLORS.greyIcon}
        />
      )}
    </div>
    <div className='d-flex justify-content-center align-content-center  mx-1'>
      <IconComponent
        group='standard'
        name='doubleArrow'
        size='30'
        color={COLORS.greyEmpty}
      />
    </div>
    <div className='d-flex justify-content-center align-content-center  mx-1'>
      {images?.length > 1 && images[1] != null ? (
        <div className='d-block '>
          <Image
            className='of-contain'
            src={images[1]?.media}
            width={32}
            height={42}
            rounded
          />
        </div>
      ) : (
        <IconComponent
          group='standard'
          name='photo'
          size={isThumbnail ? '30' : '130'}
          color={COLORS.grayHelper}
          background={transparent ? '' : COLORS.greyIcon}
        />
      )}
    </div>
  </div>
);

export default observer(ComparativeLoadFeedback);

ComparativeLoadFeedback.propTypes = {
  transparent: PropTypes.bool,
  isThumbnail: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.object),
};

ComparativeLoadFeedback.defaultProps = {
  transparent: true,
  isThumbnail: false,
  images: [],
};
