import { makeAutoObservable } from 'mobx';

class TimelapseStore {
  isFetching = false;

  image = null;

  imageList = [];

  filterImage = [];

  imageTimelapse = null;

  constructor() {
    makeAutoObservable(this);
  }

  get imageId() {
    return this.image?.id || '';
  }

  findImage(id) {
    return this.imageList?.find((img) => img.id === id);
  }

  setImageTimelapse(select) {
    this.imageTimelapse = select;
  }

  cleanImageListAndSelected() {
    this.imageList = [];
    this.imageTimelapse = null;
  }

  addOrRemoveImage(index) {
    if (!this.imageList.find((img) => img === index)) {
      this.imageList = [...this.imageList, index];
    } else {
      const i = this.imageList.findIndex((img) => img === index);
      this.imageList = [
        ...this.imageList.slice(0, i),
        ...this.imageList.slice(i + 1),
      ];
    }
  }
}

export default new TimelapseStore();
