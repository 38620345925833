import { useWindowSize } from 'react-use';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import useToggle from '../../../hooks/useToggle';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import { useStore } from '../../../hooks';
import RemoveUserAvatarModal from '../../UserProfile/RemoveUserAvatarModal/RemoveUserAvatarModal';

const ProfileNavBar = () => {
  const store = useStore();
  const history = useHistory();
  const { width } = useWindowSize();

  const [avatarModal, toggleAvatarModal] = useToggle();

  const menu = [
    {
      label: 'Dados do usuário',
      key: '1',
      action: () => history.push('/user/profile'),
    },
    {
      label: 'Alterar senha',
      key: '2',
      action: () =>
        history.push('/user/profile?action=password#password-block'),
    },
    {
      label: 'Fazer upgrade',
      key: '3',
      action: () => history.push('/user/upgrade/plans'),
    },
    {
      label: 'Cancelar conta',
      key: '4',
      action: () => history.push('/user/profile?action=cancel#cancel-block'),
    },
    {
      label: 'Remover foto de perfil',
      key: '5',
      action: () => toggleAvatarModal(),
    },
  ];

  return useMemo(() => {
    if (width < 768)
      return (
        <>
          <NavbarHeaderMobile content='user' hasMenu menuOptions={menu} />
          <RemoveUserAvatarModal
            open={avatarModal}
            toggleOpen={toggleAvatarModal}
          />
        </>
      );

    return (
      <NavbarHeader
        hasHelper
        content='user'
        hasTitle
        title={store.authStore.user?.name}
      />
    );
  }, [width, avatarModal, store.authStore.user?.name]);
};

export default observer(ProfileNavBar);
