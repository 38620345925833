import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModalComponent from '../Styleguide/ModalComponent/ModalComponent';

const RedefineModal = ({ handleRedefineDots }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button
        id='btn-toggle-redefine-modal'
        className='d-none'
        onClick={() => handleShow()}
      >
        a
      </Button>
      <ModalComponent
        isDelete
        hasSecondButton
        idSecondButton='close-modal-redefine-dots'
        secondButtonAction={() => setShowModal(false)}
        secondButtonLabel='Cancelar'
        show={showModal}
        subTitle='Se realizar esta ação todos os pontos e análises da foto serão apagados'
        onClose={() => setShowModal(false)}
        onClickButton={handleRedefineDots}
        nameButton='Redefinir'
        idButton='redefine-dots'
      />
    </>
  );
};

export default RedefineModal;

RedefineModal.propTypes = {
  handleRedefineDots: PropTypes.func,
};

RedefineModal.defaultProps = {
  handleRedefineDots: () => {},
};
