import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function useOutsideClickObserver(ref, setter, state) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref && !ref.current.contains(event.target)) {
				setter({ state });
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

function OutsideWrapper({ children }) {
	const wrapperRef = useRef(null);
	useOutsideClickObserver(wrapperRef);

	return <div ref={wrapperRef}>{children}</div>;
}

OutsideWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export { useOutsideClickObserver, OutsideWrapper };
