import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import LibraryListNavBar from './LibraryListNavBar';

const LibraryListLayout = ({ children }) => (
  <MainLayout customNavBar={<LibraryListNavBar />}>{children}</MainLayout>
);

LibraryListLayout.propTypes = {
  children: PropTypes.node,
};
LibraryListLayout.defaultProps = {
  children: '',
};

export default observer(LibraryListLayout);
