import { flow, makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
	AUTH_TOKEN_KEY,
	EMAIL_PASSWORD,
} from '../../constants/StorageConstants';
import User from '../models/User';
import Professional from '../models/Professional';
import baseAPI, { baseURL } from '../../services/baseAPI';
import convertHeicToJpg from '../../utils/convertHeicToJpg';

class AuthStore {
	/** @type {User} */
	user = null;

	professional = null;

	status = 'unauthenticated';

	storage = 0;

	history = [];

	loading = true;

	fetching = false;

	fetchingStatus = 'idle';

	isAvatarLoading = false;

	currentPage = 1;

	lastPage = 1;

	googleAuthUrl = '';

	googleOauthToken = '';

	constructor() {
		makeAutoObservable(this);
	}

	get isAuthenticated() {
		return this.status === 'authenticated';
	}

	get planName() {
		return this.user?.plan.plan_name;
	}

	get benefits() {
		return this.user?.plan.benefits;
	}

	get activePlan() {
		return this.user?.plan;
	}

	
	setShowOnboardingVideosModal(mustShowOnboardingVideos) {
		// seta para modal aberto ou fechado
		this.user.show_onboarding_videos = mustShowOnboardingVideos;

		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.editUser(
					this.user,
					this.professional.address,
					this.professional.socialLinks,
				);

				return resolve(res);
			} catch (error) {
				return reject(error);
			}
		});
	}

	setGoToUserFormModalOpen(notShowModal) {
		// seta para modal aberto ou fechado
		this.user.not_show_modal = notShowModal;

		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.editUser(
					this.user,
					this.professional.address,
					this.professional.socialLinks,
				);

				return resolve(res);
			} catch (error) {
				return reject(error);
			}
		});
	}

	setUser(user) {
		this.user = User.fromApi(user);
	}

	setFetching(fetching) {
		this.fetching = fetching;
	}

	setProfessional(professional) {
		this.professional = Professional.fromApi(professional);
	}

	setStatus = (val) => {
		this.status = val;
	};

	setLoading = (val) => {
		this.loading = val;
	};

	setIsAvatarLoading = (status) => {
		this.isAvatarLoading = status;
	};

	setFetchingStatus(status) {
		this.fetchingStatus = status;
	}

	setGoogleAuthUrl(googleAuthUrl) {
		this.googleAuthUrl = googleAuthUrl;
	}

	setGoogleOauthToken(googleOauthToken) {
		this.googleOauthToken = googleOauthToken;
	}

	unauthenticate() {
		this.status = 'unauthenticated';
		this.user = null;
		localStorage.clear();
		delete baseAPI.defaults.headers.Authorization;
	}

	*getStorageUsage() {
		this.fetching = true;
		try {
			const res = yield baseAPI.get('/v1/user/storage/status');

			if (res.data != null) {
				this.storage = res.data.data;
				this.storage = {
					...this.storage,
					available: (this.benefits?.storage - this.storage?.total) > 0 ? (this.benefits?.storage - this.storage?.total) : 0
				}
			}
			this.fetching = false;
		} catch (e) {
			this.fetching = false;
		}
	}

	*getSubscriptionHistory() {
		this.fetching = true;
		try {
			const res = yield baseAPI.get('/v1/user/subscription/history');
			const { meta } = res.data.data['0'];

			this.currentPage = meta.current_page;
			this.lastPage = meta.last_page;

			if (res.data != null) {
				this.history = res.data.data['0'].data;
			}

			this.fetching = false;
		} catch (e) {
			this.fetching = false;
		}
	}

	*getSubscriptionHistoryByPage() {
		try {
			this.fetching = true;
			const res = yield baseAPI.get(
				`/v1/user/subscription/history?page=${this.currentPage + 1}`,
			);

			const { meta } = res.data;
			this.currentPage = meta.current_page;
			this.lastPage = meta.last_page;

			this.history = [...this.history, ...res.data.data];
			this.fetching = false;
			return true;
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			this.fetching = false;
			return false;
		}
	}

	authenticate(payload) {
		return new Promise(async (resolve, reject) => {
			this.fetching = true;
			try {
				/** GET - ME  */
				if (payload?.token != null) {
					baseAPI.defaults.headers.Authorization = `Bearer ${payload.token}`;

					const response = await baseAPI.get('/v1/user/get-me');
					if (response.data.data.user) {
						this.setUser(response.data.data.user);
						this.setProfessional(response.data.data.professional);
						this.status = 'authenticated';
						this.loading = false;
						this.fetching = false;
						return true;
					}
					this.loading = false;
				} else {
					this.loading = false;
				}

				/** Login */

				const sanitize = {
					...payload,
					email: payload.email.toLowerCase(),
				};

				const response = await baseAPI.post('/v1/login', sanitize);

				if (response.status === 203) {
					localStorage.setItem(
						`haiah_app@${EMAIL_PASSWORD}`,
						JSON.stringify(response.data.data.email),
					);

					localStorage.setItem(
						'haiah_app@58a0e8deab1d12f003233082c39dafaf',
						JSON.stringify(response.data.data.id),
					);

					window.location.href = '/confirmation';

					return resolve(203);
				}

				if (response?.data.data.token && response?.data?.data.user) {
					const { token, user, professional } = response.data.data;
					localStorage.setItem(
						`haiah_app@${AUTH_TOKEN_KEY}`,
						JSON.stringify(token),
					);
					this.setUser(user);
					this.setProfessional(professional);
					this.status = 'authenticated';
					baseAPI.defaults.headers.Authorization = `Bearer ${token}`;

					return resolve(200);
				}
				this.unauthenticate();

				this.fetching = false;
				return resolve(true);
			} catch (error) {
				this.fetching = false;
				if (error.response) {
					toast(error.response.data.Message, {
						toastId: 'toast_invalid_credentials_error',
						type: 'error'
					})
				}
				this.unauthenticate();

				return reject(error);
			}
		});
	}

	editUser = flow(function editUser(updateUser, address, socialLinks) {
		return new Promise(async (resolve, reject) => {
			const form = new FormData();

			try {
				this.setFetchingStatus('pending');
				this.fetching = true;

				const userData = {
					address: JSON.stringify(address ? address : {}),
					social_links: JSON.stringify(socialLinks),
					...updateUser,
				};

				Object.keys(userData).forEach((v) => form.append(v, userData[v]));

				form.append('_method', 'PUT');

				const jpgImg = await convertHeicToJpg(userData.avatar);

				if (jpgImg) {
					form.set('avatar', jpgImg);
				}

				const res = await baseAPI.post('/v1/user/update', form, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
					},
					timeout: 7200000,
				});

				if (res.data.Status === 'Success') {
					const { user, professional } = res.data.data;

					this.setUser(user);
					this.setProfessional(professional);
					this.fetching = false;
					this.setFetchingStatus('resolved');
				}
				this.fetching = false;
				this.setIsAvatarLoading(false);
				this.setFetchingStatus('resolved');

				return resolve(res);
			} catch (error) {
				this.fetching = false;
				this.setFetchingStatus('rejected');
				return reject(error);
			}
		});
	});

	changePassword = flow(function* changePassword(payload) {
		try {
			this.fetching = true;

			const res = yield baseAPI.post('/v1/user/update-password', payload);
			if (res.data.Status === 'Success') {
				toast.success('Senha alterada');
			}
			this.fetching = false;
			return true;
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			this.fetching = false;
			return false;
		}
	});

	googleLogin = flow(function* googleLogin(payload) {
		try {
			const res = yield baseAPI.post('/v1/auth/google', payload);
			if (res.status === 201) {
				this.setUser(res.data.data);
				localStorage.setItem(
					`haiah_app@${EMAIL_PASSWORD}`,
					JSON.stringify(res.data.data.email),
				);

				return {
					status: true,
					path: '/confirmation',
				};
			}
			if (res.status === 200) {
				localStorage.setItem(
					`haiah_app@${AUTH_TOKEN_KEY}`,
					JSON.stringify(res.data.data.token),
				);
				this.setUser(res.data.data.user);
				this.setProfessional(res.data.data.professional);
				this.status = 'authenticated';
				baseAPI.defaults.headers.Authorization = `Bearer ${res.data.data.token}`;

				return {
					status: true,
					path: '/',
				};
			}

			return false;
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.Message);
			}

			return false;
		}
	});

	register = flow(function* register(payload) {
		const sanitize = {
			...payload,
			email: payload.email.toLowerCase(),
		};
		this.fetching = true;
		try {
			const res = yield baseAPI.post('/v1/register', sanitize);
			if (res.data.Status === 'Success') {
				localStorage.setItem(
					`haiah_app@${EMAIL_PASSWORD}`,
					JSON.stringify(res.data.data.email),
				);

				toast(res.data.Message);

				this.setUser(res.data.data);
				this.fetching = false;
				return true;
			}
			this.setIsAvatarLoading(false);
		} catch (error) {
			this.fetching = false;
			if (error.response) {
				toast.error(error.response.data.Message);
			}

			return false;
		}
		return false;
	});

	confirmCode(payload) {
		return new Promise(async (resolve, reject) => {
			this.fetching = true;

			const userId = localStorage.getItem(
				'haiah_app@58a0e8deab1d12f003233082c39dafaf',
			);

			try {
				const res = await baseAPI.post(
					`/v1/email/verify/${userId ? userId : this.user.id}`,
					payload,
				);

				this.fetching = false;
				if (res.data.Status === 'Success') {
					toast(res.data.Message);
					return resolve(res);
				}
				return resolve(res);
			} catch (error) {
				this.fetching = false;
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				return reject(error);
			}
		});
	}

	async logout() {
		this.fetching = true;
		try {
			const res = await baseAPI.post(`/v1/user/logout`, {
				headers: {
					Accept: 'application/json',
				},
			});

			this.unauthenticate();

			if (res.data.Status === 'Success') {
				window.location.href = '/login';

				this.fetching = false;

				return true;
			}
			this.fetching = false;

			window.location.href = '/login';
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			window.location.href = '/login';

			return false;
		}
		window.location.href = '/login';

		return true;
	}

	async resendEmail() {
		try {
			// this.isFecthing = true;
			this.setFetching(true);
			const res = await baseAPI.post('/v1/email/resend', {
				email: JSON.parse(localStorage.getItem('haiah_app@passMail')),
			});

			if (res.data.Status === 'Success') {
				toast(res.data.Message);

				this.setFetching(false);

				return Promise.resolve(res.data.Status);
			}

			this.setFetching(false);

			return Promise.resolve(res);
		} catch (error) {
			this.setFetching(false);
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			return Promise.reject(error);
		}
	}

	cancelUser = flow(function* resendEmail(reason) {
		try {
			this.fetching = true;
			const res = yield baseAPI.post('/v1/user', {
				reason,
				_method: 'DELETE',
			});

			if (res.data.Status === 'Success') {
				toast(res.data.Message);
				this.fetching = false;
				return true;
			}
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			return false;
		} finally {
			this.fetching = false;
		}
		return true;
	});

	forgetPassword = flow(function* forgetPassword(confEmail) {
		this.fetching = true;
		try {
			const response = yield baseAPI.post('/v1/forgot-password', {
				email: confEmail.toLowerCase(),
			});
			if (response.data) {
				localStorage.setItem(
					`haiah_app@${EMAIL_PASSWORD}`,
					JSON.stringify(response.data.data.email),
				);
			}
			toast(response.data.Message);
			this.fetching = false;

			return true;
		} catch (error) {
			this.fetching = false;
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			return false;
		}
	});

	passwordCode = flow(function* passwordCode(payload) {
		this.fetching = true;
		try {
			const email = JSON.parse(
				localStorage.getItem(`haiah_app@${EMAIL_PASSWORD}`),
			);
			const res = yield baseAPI.post(`/v1/password-code`, {
				...payload,
				email,
			});
			this.fetching = false;
			if (res.status === 200) {
				return true;
			}
			return false;
		} catch (error) {
			this.fetching = false;
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			return false;
		}
	});

	async resetPassword(payload) {
		this.fetching = true;
		try {
			const email = JSON.parse(
				localStorage.getItem(`haiah_app@${EMAIL_PASSWORD}`),
			);

			const response = await baseAPI.post(`/v1/reset-password`, {
				...payload,
				email,
			});

			toast(response.data.Message);

			this.fetching = false;

			return Promise.resolve(response);
		} catch (error) {
			this.fetching = false;

			if (error.response) {
				toast.error(error.response.data.Message);
			}

			return Promise.reject(error);
		}
	}

	*resetUserAvatar() {
		try {
			this.fetching = true;
			const res = yield baseAPI.post('/v1/user/reset/image');

			if (res.status === 204) {
				toast('Imagem já removida');
				return true;
			}

			if (res.data.Status === 'Success') {
				if (res.data.data) {
					const { user, professional } = res.data.data;
					this.setProfessional(professional);
					this.user.fillFields(user);
					this.user.noCacheImage();
				}

				toast(res.data.Message);
				this.fetching = false;
				return true;
			}
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.Message);
			}
			return false;
		} finally {
			this.fetching = false;
		}
		return true;
	}

	
	async getGoogleOauthToken(){
            try{
                const res = await baseAPI.get(`${baseURL}/v1/imports/get-oauth-token`);

                if (res.data){
					localStorage.setItem('haiah_app@oauthToken', res.data.data?.oauthAccessToken?.access_token);
                }


                return true;
            }catch(err){                
				this.setGoogleOauthToken('');

                return false;
            }
    }
	
	getGoogleAuthUrl(){
        return new Promise(async (resolve, reject) => {
            try{
                const res = await baseAPI.get(`${baseURL}/v1/imports/get-authorization-url`)

                if (res.data){
					toast.info('Permissão necessária. Você está sendo redirecionado.');
                    this.setGoogleAuthUrl(res.data.data);
                }
                
                return resolve(res);
            }catch(err){
                return reject(err);
            }
        })
    }
}

export default new AuthStore();
