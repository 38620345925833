/* eslint-disable */
import { makeAutoObservable } from 'mobx';
import DotModel from './Dot';

export default class Analysis {
  /** @type {Object} */
  dots = {};

  /** @type {Array} */
  guideLines = [];

  /** @type {Object} */
  analysis = {};

  /** @type {number} */
  lastWidth = 0;

  /** @type {boolean} */
  hasAnalysis;

  constructor({ analysis_dots, results, last_width, analysis_rulers } = {}) {
    makeAutoObservable(this, {});
    this.guideLines = this.setGuideList(analysis_rulers);
    this.dots = this.setDotList(analysis_dots);
    this.analysis = this.setAnalysisList(results);
    this.lastWidth = +last_width;
    this.hasAnalysis = this.verifyAnalisysList();
  }

  setGuideList(guideLines) {
    if (guideLines == null) return this.guideLines = null;
    if (typeof guideLines === 'string') {
      return (this.guideLines = JSON.parse(guideLines));
    }
    return (this.guideLines = guideLines); 
  }

  setDotList(dots) {
    if (dots == null) return this.dots = null;
    if (typeof dots === 'string') {
      const list = JSON.parse(dots);
      return (this.dots = list.map((val) => new DotModel(val)));
    }
    return (this.dots = dots.map((val) => new DotModel(val)));
  }

  verifyAnalisysList() {
    if (this.analysis == null) {
      return false;
    }

    if (Object.keys(this.analysis).length === 0) {
      return false;
    }

    return true;
  }

  get analysisList() {
    if (this.analysis != null) {
      return Object.keys(this.analysis);
    }
    return null;
  }

  setAnalysisList(analysis) {
    if (analysis == null) return null;
    const result = {};
    if (typeof analysis === 'string') {
      const list = JSON.parse(analysis);

      Object.keys(list).forEach((v) => Object.assign(result, { [v]: list[v] }));
      return result;
    }

    Object.keys(analysis).forEach((v) =>
      Object.assign(result, { [v]: analysis[v] }),
    );

    return result;
  }

  static fromApi({ analysis_dots, last_width, results } = {}) {
    return new Analysis({
      dots: analysis_dots,
      lastWidth: last_width,
      analysis: results,
    });
  }
}

export {};
