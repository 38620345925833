/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { useStore, useToggle } from '../../../../hooks';
import Dimmer from '../../../Styleguide/Dimmer/Dimmer';
import ComparativeSelect from '../ComparativeSelect';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import FacemeshMobx from '../../Analysis/Facemesh/FacemeshMobx';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import InputSelect from '../../../Styleguide/InputSelect/InputSelect';

import ExportComparativeModal from '../../Analysis/ExportComparativeModal/ExportComparativeModal';
import { ANALYSIS_LIST } from '../../../../constants/ToolsConstants';
import LoaderModal from '../../../LoaderModal/LoaderModal';

const ComparativeViewMobile = () => {
  const store = useStore();
  const { id: patientId } = useParams();

  const history = useHistory();
  const [orientation, setOrientation] = useState('portrait');
  const comparativeRef = useRef([]);
  const { width, height } = useWindowSize();

  const [isVisible, toggleVisibility] = useToggle(true);
  const [isOpen, toggleOpen] = useToggle(false);
  const [isOpenComparative, toggleOpenComparative] = useToggle(false);
  /** migrar para modal ! => usar constante de tamanho */

  if (store.toolsStore.imagesComparative?.length < 2) {
    history.push(`/patients/${patientId}/comparative`);
  }

  useEffect(() => {
    if (width <= height) {
      setOrientation('portrait');
    } else {
      setOrientation('landscape');
    }
  }, [width, height]);

  useEffect(() => {
    comparativeRef.current = comparativeRef.current.slice(
      0,
      store.toolsStore.imagesComparative.length,
    );
  }, [store.toolsStore.imagesComparative]);

  const setRef = (ref, index) => {
    comparativeRef.current[index] = ref;
  };

  useEffect(
    () => () => {
      store.toolsStore.cleanImageComparative();
      store.comparativeStore.cleanExportMap();
      store.analysisStore.clearAllAnalysisData();
      store.toolsStore.cleanImageComparative();
    },
    [],
  );

  const handleGoBack = () => {
    if (store.galleryStore.imageId !== '') {
      return history.push(
        `/patients/${patientId}/tools/${store.galleryStore.imageId}`,
      );
    }
    return history.push(`/patients/${patientId}/comparative`);
  };

  return (
    <>
      <LoaderModal
        text='Exportando relatório...'
        id='loader-comparative-desktop'
      />
      {orientation === 'portrait' ? (
        <div className='d-flex position-relative h-100 flex-fill flex-column'>
          <Dimmer
            icon={{ group: 'standard', name: 'arrowrepeat' }}
            message='Gire o telefone na horizontal para visualizar a comparação'
          />
          <ComparativeSelect />
        </div>
      ) : (
        <>
          <div className='border-top bg-white border-light d-flex flex-row w-100 vh-100'>
            <div className=' my-4 flex-fill'>
              <div
                className='vw-10 h-100 d-flex flex-column justify-content-between align-items-center'
                style={{ width: 60 }}
              >
                <div>
                  <div className='mb-4'>
                    <IconComponent
                      group='standard'
                      name='back'
                      color='gray'
                      size={24}
                      onClick={handleGoBack}
                    />
                  </div>
                  <div className='mb-4'>
                    <IconComponent
                      group='analysis'
                      name='comparePortrait'
                      size={24}
                      onClick={() => {
                        toggleOpen();
                      }}
                    />
                  </div>
                  <div className='mb-4'>
                    <IconComponent
                      group='standard'
                      name={isVisible ? 'eye' : 'eyehide'}
                      size={24}
                      color={isVisible ? 'gray' : 'red'}
                      onClick={toggleVisibility}
                    />
                  </div>
                  <div className='mb-4'>
                    <IconComponent
                      group='standard'
                      name='download'
                      size={24}
                      onClick={() => toggleOpenComparative()}
                      color='black'
                    />
                  </div>
                </div>

                <div>
                  <IconComponent
                    group='standard'
                    name='question'
                    size={24}
                    color='#EAEAEA'
                  />
                </div>
              </div>
            </div>
            <div
              className={clsx(
                'flex-row w-100 flex-fill',
                isOpenComparative ? 'd-none' : 'd-flex',
              )}
            >
              {store.toolsStore.imagesComparative?.map((i, index) => (
                <div
                  key={i.id}
                  className='w-50 flex-fill mx-2 my-2 position-relative'
                >
                  <FacemeshMobx
                    timeInterval={index}
                    ref={(ref) => setRef(ref, index)}
                    show={isVisible}
                    comparative
                    image={i}
                    patientId={i.patient_id}
                    disableDots
                  />

                  {isVisible && (
                    <div className='  position-absolute bottom-0  bg-dark-50 zi-7 w-100 text-white'>
                      <div className='d-flex flex-row justify-content-between px-2'>
                        <small className='fs-7 text-truncate text-start col-9'>{i.fileName}</small>
                        <small className='fs-7 text-end col-3'>
                          {format(new Date(i.date), 'dd/MM/yyyy')}
                        </small>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <ModalComponent
            title='Escolher análise facial'
            onClose={() => toggleOpen()}
            show={isOpen}
            noFooter
            size='sm'
          >
            <div className='d-flex h-100 flex-column flex-wrap py-1 px-2 align-items-center'>
              <InputSelect
                placeholder='Selecione uma análise facial'
                options={ANALYSIS_LIST}
                value={store.analysisStore.analysisActive}
                onChange={(e) => {
                  store.analysisStore.setAnalysisActive(e.target.value);
                  toggleOpen();
                }}
              />
            </div>
          </ModalComponent>
          <ExportComparativeModal
            isOpen={isOpenComparative}
            setIsOpen={toggleOpenComparative}
          />
        </>
      )}
    </>
  );
};

export default observer(ComparativeViewMobile);
