import PropTypes from 'prop-types';
import IconComponent from "../../Svg/IconComponent/IconComponent";

const TodaySelector = ({handleClick}) => (
  <button type='button' className="position-absolute top-50 start-md-80 start-lg-70 start-xs-50 translate-middle reset-button d-md-flex" onClick={handleClick}>
    <IconComponent
      group='standard'
      name='todayCalendar'
      size='24'
      color='#000000'
    />
    <span className='ms-2 d-none d-lg-inline-block fs-6'>Hoje</span>
  </button>
);

export default TodaySelector;

TodaySelector.propTypes = {
  handleClick: PropTypes.func.isRequired,
}


