import { observer } from 'mobx-react';
import React from 'react';
import { PropTypes } from 'prop-types';
import LibraryNavBar from './LibraryNavBar';
import MainLayout from '../MainLayout/MainLayout';

const LibraryLayout = ({ children }) => (
  <MainLayout customNavBar={<LibraryNavBar />}>{children}</MainLayout>
);

LibraryLayout.propTypes = {
  children: PropTypes.node,
};
LibraryLayout.defaultProps = {
  children: '',
};

export default observer(LibraryLayout);
