const Movie = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.0005 5.25C3.033 5.25 2.25 6.033 2.25 7.0005V16.9995C2.25 17.967 3.033 18.75 4.0005 18.75H13.9995C14.967 18.75 15.75 17.967 15.75 16.9995V7.0005C15.75 6.033 14.967 5.25 13.9995 5.25H4.0005ZM0.75 7.0005C0.75 5.205 2.205 3.75 4.0005 3.75H13.9995C15.795 3.75 17.25 5.205 17.25 7.0005V16.9995C17.25 18.795 15.795 20.25 13.9995 20.25H4.0005C3.13841 20.25 2.31164 19.9075 1.70205 19.298C1.09246 18.6884 0.75 17.8616 0.75 16.9995V7.0005Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.875 8.4747L21.027 6.0672C21.0984 6.0242 21.1797 6.00064 21.263 5.99885C21.3463 5.99707 21.4286 6.01712 21.5017 6.05702C21.5748 6.09692 21.6362 6.15527 21.6798 6.22627C21.7234 6.29727 21.7476 6.37843 21.75 6.4617V17.5377C21.75 17.8797 21.36 18.1272 21.027 17.9337L16.8765 15.5262L16.1235 16.8237L20.274 19.2312C21.5625 19.9782 23.25 19.0887 23.25 17.5377V6.4617C23.25 4.9122 21.5625 4.0227 20.274 4.7697L16.125 7.1772L16.878 8.4747H16.875Z'
      fill={color}
    />
  </svg>
);
export default Movie;
