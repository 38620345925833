import { observer } from 'mobx-react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InfoThumb = ({ src }) => (
  <>
    <Image
      alt=''
      className='border-0 p-0'
      height='200'
      width='240'
      src={src}
      thumbnail
    />
  </>
);

export default observer(InfoThumb);

InfoThumb.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

InfoThumb.defaultProps = {
  src: '',
};
