import PropTypes from 'prop-types';

const CalendarPlus = ({
	iconStyleProps: { size, color = '#383C42' },
	...rest
}) => (
	<svg
		width={size}
		height={size}
		color={color}
		viewBox='0 0 34 34'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...rest}
	>
		<g clipPath='url(#clip0_251_60908)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M28.9725 0.958008H4.80188C3.73347 0.958008 2.70882 1.38243 1.95334 2.13791C1.19786 2.89339 0.773438 3.91804 0.773438 4.98645L0.773438 29.1571C0.773438 30.2255 1.19786 31.2502 1.95334 32.0056C2.70882 32.7611 3.73347 33.1856 4.80188 33.1856H28.9725C30.041 33.1856 31.0656 32.7611 31.8211 32.0056C32.5766 31.2502 33.001 30.2255 33.001 29.1571V4.98645C33.001 3.91804 32.5766 2.89339 31.8211 2.13791C31.0656 1.38243 30.041 0.958008 28.9725 0.958008V0.958008ZM2.78766 8.72686C2.78766 7.77413 3.69003 7.00067 4.80188 7.00067H28.9725C30.0844 7.00067 30.9868 7.77413 30.9868 8.72686V29.4451C30.9868 30.3979 30.0844 31.1713 28.9725 31.1713H4.80188C3.69003 31.1713 2.78766 30.3979 2.78766 29.4451V8.72686Z'
				fill='black'
			/>
			<path
				d='M17.8374 12.0935C17.5856 11.8417 17.244 11.7002 16.8879 11.7002C16.5318 11.7002 16.1902 11.8417 15.9384 12.0935C15.6866 12.3453 15.5451 12.6869 15.5451 13.043V17.0715H11.5166C11.1605 17.0715 10.819 17.2129 10.5671 17.4648C10.3153 17.7166 10.1738 18.0581 10.1738 18.4143C10.1738 18.7704 10.3153 19.112 10.5671 19.3638C10.819 19.6156 11.1605 19.7571 11.5166 19.7571H16.8879C17.244 19.7571 17.5856 19.6156 17.8374 19.3638C18.0892 19.112 18.2307 18.7704 18.2307 18.4143V13.043C18.2307 12.6869 18.0892 12.3453 17.8374 12.0935Z'
				fill='black'
			/>
			<path
				d='M15.938 24.7356C16.1898 24.9874 16.5314 25.1289 16.8875 25.1289C17.2436 25.1289 17.5852 24.9874 17.837 24.7356C18.0888 24.4838 18.2303 24.1422 18.2303 23.7861L18.2303 19.7576L22.2587 19.7576C22.6149 19.7576 22.9564 19.6162 23.2083 19.3643C23.4601 19.1125 23.6016 18.771 23.6016 18.4148C23.6016 18.0587 23.4601 17.7171 23.2083 17.4653C22.9564 17.2135 22.6149 17.072 22.2587 17.072L16.8875 17.072C16.5314 17.072 16.1898 17.2135 15.938 17.4653C15.6861 17.7171 15.5447 18.0587 15.5447 18.4148L15.5447 23.7861C15.5447 24.1422 15.6861 24.4838 15.938 24.7356Z'
				fill='black'
			/>
		</g>
		<defs>
			<clipPath id='clip0_251_60908'>
				<rect
					width='32.2275'
					height='32.2275'
					fill='white'
					transform='translate(0.773438 0.958008)'
				/>
			</clipPath>
		</defs>
	</svg>
);

export default CalendarPlus;

CalendarPlus.propTypes = {
	iconStyleProps: PropTypes.shape({
		size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		color: PropTypes.string,
	}),
};

CalendarPlus.defaultProps = {
	iconStyleProps: {
		size: '30',
		color: '#383C42',
	},
};
