import { Spinner } from 'react-bootstrap';
import React, { useEffect, useReducer, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import FacemeshMobx from '../Facemesh/FacemeshMobx';
import {
  IMAGE_HEIGHT_EXPORT,
  IMAGE_WIDTH_EXPORT,
} from '../../../../constants/ToolsConstants';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../../constants/Colors';
import FeedbackBadge from '../../../Styleguide/FeedbackBadge/FeedbackBadge';

const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  LOADING: 'loading',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

const init = {
  status: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'IDLE':
      return { status: null };
    case 'PENDING':
      return { status: REQUEST_STATUS.PENDING };
    case 'LOADING':
      return { status: REQUEST_STATUS.LOADING };
    case 'RESOLVED':
      return { status: REQUEST_STATUS.RESOLVED };
    case 'REJECTED':
      return { status: REQUEST_STATUS.REJECTED };
    default:
      return state;
  }
};

const ExportModal = () => {
  const store = useStore();
  const { id: patientId, imageId } = useParams();

  const history = useHistory();

  const facemeshRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, init);

  const { analysisStore, defaultStore } = store;

  const handleClickButton = async () => {
    
    if (imageId) {
      const analyzedImage = await store.galleryStore.getImage(imageId);

      if (analyzedImage.data) {
        history.push(
          `/patients/${patientId}/appointment/${analyzedImage.data.data.album_id}`,
        );
      }

      document.getElementById('btn-close-modal-hidden-request').click();

      document
        .getElementById('hidden-request-container')
        .classList?.add('d-block');
    }
  };

  const exportFacemesh = async () => {
    store.analysisStore.setIsFetching(true);

    document.getElementById('loader-photo-tools')?.classList.add('d-block');
    document
      .getElementById('hidden-request-container')
      ?.classList.add('d-none');

    const link = document.getElementById('a-to-download-report');

    try {
      const { data } = await store.patientsStore.getPatientsById(patientId);

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yy = today.getFullYear();
      const hh = String(today.getHours()).padStart(2, '0');
      const min = String(today.getMinutes()).padStart(2, '0');
      const ss = String(today.getSeconds()).padStart(2, '0');

      const separateName = data.name.replace(/\s/g, '-');

      link.download = `relatorio-analise-${separateName}-${dd}-${mm}-${yy}-${hh}-${min}-${ss}`;

      const handleClick = async () => {
        link.href = window.URL.createObjectURL(analysisStore.blobToDonwload);

        link.click();
      };

      dispatch({ type: 'LOADING' });

      defaultStore.setTransferData('Exportação de relatório do paciente');

      defaultStore.setHiddenRequestModalProps({
        title: 'Exportação de relatório do paciente',
      });

      const result = await facemeshRef.current?.export();

      if (result) {
        defaultStore.setTransferData(null, {
          done: true,
          name: 'Exportação de relatório do paciente',
        });

        defaultStore.setHiddenRequestModalProps({
          text: (
            <FeedbackBadge
              icon={{
                group: 'standard',
                name: 'done',
                color: COLORS.success,
                size: 80,
              }}
              text={
                <>
                  Relatório exportado <br /> com sucesso!
                </>
              }
              textColor='dark'
            />
          ),
          buttonLabel: 'Ver relatório',
          buttonAction: handleClickButton,
          secondButtonLabel: 'Fazer download',
          secondButtonAction: handleClick,
        });

        dispatch({ type: 'RESOLVED' });

        document
          .getElementById('loader-photo-tools')
          ?.classList.remove('d-block');

        store.analysisStore.setIsFetching(false);
      } else {
        store.defaultStore.showExportationError('loader-photo-tools');

        store.analysisStore.setIsFetching(false);
      }
    } catch (e) {
      store.defaultStore.showExportationError('loader-photo-tools');

      store.analysisStore.setIsFetching(false);

      dispatch({ type: 'REJECTED' });
    }
  };

  useEffect(() => {
    if (analysisStore.modal && state.status != null) {
      dispatch({ type: 'IDLE' });
    }
  }, [analysisStore.modal]);

  if (!analysisStore.modal) {
    return null;
  }

  return (
    <>
      {state.status == null ? (
        <ModalComponent
          onClose={() => store.analysisStore.toggleModal(false)}
          title='Exportar análises'
          show
          hasButton
          disabled={store.analysisStore.isFetching === true}
          onClickButton={() => exportFacemesh()}
          nameButton={
            <div>
              {store.analysisStore.isFetching !== true ? (
                <>
                  <IconComponent
                    group='standard'
                    name='download'
                    size='16'
                    color={COLORS.white}
                  />{' '}
                </>
              ) : (
                <>
                  <Spinner animation='border' role='status' size='sm' />{' '}
                </>
              )}
              Exportar agora
            </div>
          }
          idButton='comparative-export-now'
        >
          <>
            <p className='p-4 m-0'>Iniciar exportação de análises</p>
          </>
        </ModalComponent>
      ) : null}
      <a id='a-to-download-report' href='/' className='d-none'>
        a
      </a>
      <div className='d-none'>
        <FacemeshMobx
          width={IMAGE_WIDTH_EXPORT}
          height={IMAGE_HEIGHT_EXPORT}
          image={store.galleryStore.image}
          exporting
          patientId={patientId}
          ref={facemeshRef}
        />
      </div>
    </>
  );
};

export default observer(ExportModal);
