const ArrowBack = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.031 2.469C17.1008 2.53867 17.1563 2.62143 17.1941 2.71255C17.2319 2.80367 17.2513 2.90135 17.2513 3C17.2513 3.09865 17.2319 3.19633 17.1941 3.28745C17.1563 3.37857 17.1008 3.46133 17.031 3.531L8.5605 12L17.031 20.469C17.1718 20.6098 17.2509 20.8008 17.2509 21C17.2509 21.1992 17.1718 21.3902 17.031 21.531C16.8902 21.6718 16.6992 21.7509 16.5 21.7509C16.3008 21.7509 16.1098 21.6718 15.969 21.531L6.969 12.531C6.89915 12.4613 6.84374 12.3786 6.80593 12.2874C6.76812 12.1963 6.74866 12.0986 6.74866 12C6.74866 11.9013 6.76812 11.8037 6.80593 11.7125C6.84374 11.6214 6.89915 11.5387 6.969 11.469L15.969 2.469C16.0387 2.39915 16.1214 2.34374 16.2125 2.30593C16.3037 2.26812 16.4013 2.24866 16.5 2.24866C16.5986 2.24866 16.6963 2.26812 16.7874 2.30593C16.8786 2.34374 16.9613 2.39915 17.031 2.469Z'
      fill={color}
    />
  </svg>
);
export default ArrowBack;
