import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useStore } from '../../../hooks';

const RefreshPageButton = ({ label }) => {
	const { defaultStore, patientsStore, authStore } = useStore();

    function refreshPage() {
		patientsStore.resetPatientList();
		defaultStore.setShowRefreshButton(false);
		defaultStore.setIsImporting(false);
        patientsStore.getPatientsByPage(authStore.user.id);
    }

	return defaultStore.showRefreshButton ? (
		<button className='reset-button fw-bold' type='button' onClick={refreshPage}>
			{label}
		</button>
	) : null;
};

RefreshPageButton.propTypes = {
	label: PropTypes.string.isRequired,
};

export default observer(RefreshPageButton);
