import React from 'react';
import { observer } from 'mobx-react';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import { useStore } from '../../../hooks';

const CheckoutNavbar = () => {
  const store = useStore();
  return (
    <NavbarHeaderMobile
      hasGoBack
      content='title'
      title={`Plano ${store.checkoutStore.selectedPlan?.name}`}
      topSubtitle='Upgrade'
      hasBottomBorder={false}
    />
  );
};

export default observer(CheckoutNavbar);
