import { PropTypes } from 'prop-types';
import { Container } from 'react-bootstrap';
import matchExpression from '../../../utils/matchExpression';

const Carousel = ({ array, index, changeIndex, cases, ...rest }) => (
	<Container {...rest}>
		{matchExpression(array[index].type, cases)?.component}
		<div className='d-flex gap-3 mt-4 justify-content-center'>
			{array.map((el, i) => (
				<button
					type='button'
					style={{ width: 10, height: 10 }}
					className={`reset-button rounded-circle ${
						i === index ? 'bg-primary' : 'bg-grey-header'
					}`}
					onClick={() => changeIndex(i)}
				>
					{' '}
				</button>
			))}
		</div>
	</Container>
);

export default Carousel;

Carousel.propTypes = {
	cases: PropTypes.object.isRequired,
	array: PropTypes.array.isRequired,
	index: PropTypes.number.isRequired,
	changeIndex: PropTypes.func.isRequired,
};
