import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../../constants/Colors';
import InfoThumb from '../../../Styleguide/InfoThumb/InfoThumb';
import DotsTableRowIcon from './DotsTableRowIcon';

const DotsTableRow = React.forwardRef(
  (
    { isLoading, isActive, isSelect, setActive, nodeName, removeDot, media },
    ref,
  ) => (
    <>
      <tr
        className={clsx(
          'border-bottom ',
          isSelect ? 'bg-grey-border ' : 'bg-white',
        )}
      >
        <td className='text-start  d-flex align-items-center border-0 p-2 '>
          <OverlayTrigger
            trigger='click'
            rootClose
            container={ref}
            className='zi-2'
            placement='right'
            overlay={
              <Popover className='zi-1'>
                <Popover.Body className='p-0 border-0'>
                  <InfoThumb src={media} />
                </Popover.Body>
              </Popover>
            }
          >
            <div className='d-flex px-2'>
              <IconComponent
                group='standard'
                size='16'
                name='info'
                color={isSelect ? COLORS.primary : COLORS.dark}
              />
            </div>
          </OverlayTrigger>

          {nodeName}
        </td>
        <td className='text-end p-2 align-middle'>
          <DotsTableRowIcon
            isLoading={isLoading}
            isActive={isActive >= 0 ? true : false}
            setActive={setActive}
            removeDot={removeDot}
            nodeName={nodeName}
          />
        </td>
      </tr>
    </>
  ),
);

export default observer(DotsTableRow);

DotsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelect: PropTypes.bool,
  setActive: PropTypes.func,
  nodeName: PropTypes.string,
  removeDot: PropTypes.func,
  media: PropTypes.string,
};

DotsTableRow.defaultProps = {
  isLoading: false,
  isActive: false,
  isSelect: false,
  media: '',
  setActive: () => null,
  removeDot: () => null,
  nodeName: '',
};
