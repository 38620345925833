
const Saturation = ({
                  // eslint-disable-next-line react/prop-types
                  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
                }) => (
  <svg {...rest} width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5088 20C12.4979 20 14.4056 19.2098 15.8121 17.8033C17.2186 16.3968 18.0088 14.4891 18.0088 12.5C18.0088 10.4313 16.6063 8.87 14.9688 7.0475C13.3263 5.22 11.4463 3.12875 10.5088 0C10.5088 0 3.00879 7.1075 3.00879 12.5C3.00879 14.4891 3.79897 16.3968 5.20549 17.8033C6.61201 19.2098 8.51967 20 10.5088 20ZM8.81629 5.8075C8.34629 6.27875 7.22629 7.66875 6.20004 9.72L7.31754 10.28C8.29254 8.33125 9.33754 7.055 9.70129 6.6925L8.81629 5.8075Z" fill={color}/>
  </svg>



);
export default Saturation;
