const QuestionIcon = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_651_103473)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875C5.37555 0.875 3.81763 1.52031 2.66897 2.66897C1.52031 3.81763 0.875 5.37555 0.875 7C0.875 8.62445 1.52031 10.1824 2.66897 11.331C3.81763 12.4797 5.37555 13.125 7 13.125V13.125ZM7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14V14Z'
				fill={color}
			/>
			<path
				d='M4.59375 5.27887H5.74875C5.74875 4.5955 6.1495 4.06788 6.93875 4.06788C7.53812 4.06788 8.08763 4.368 8.08763 5.08987C8.08763 5.6455 7.76037 5.901 7.24325 6.2895C6.65437 6.71738 6.188 7.217 6.22125 8.02813L6.22738 8.43325H7.371V8.12262C7.371 7.49437 7.60988 7.3115 8.25475 6.82237C8.78763 6.41725 9.34325 5.9675 9.34325 5.02338C9.34325 3.70125 8.22675 3.0625 7.00438 3.0625C5.84413 3.0625 4.56662 3.62862 4.59462 5.27887H4.59375ZM5.9605 10.1054C5.9605 10.5717 6.33238 10.9165 6.84425 10.9165C7.37713 10.9165 7.74375 10.5717 7.74375 10.1054C7.74375 9.62237 7.37625 9.28288 6.84338 9.28288C6.33238 9.28288 5.9605 9.62237 5.9605 10.1054V10.1054Z'
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id='clip0_651_103473'>
				<rect width={size} height={size} fill={color} />
			</clipPath>
		</defs>
	</svg>
);
export default QuestionIcon;
