import { action, makeObservable, observable, override } from 'mobx';
import { toast } from 'react-toastify';
import baseAPI from '../../services/baseAPI';
// import getMessageErrors from '../../utils/getMessageErrors';
import Default from './defaultStore';

class AlbumStore extends Default {
	/** @type {Array<Object> } */
	albumList = [];

	filterAlbum = [];

	isFilter = false;

	albumSelected = {
		album_name: '',
		date: '',
		id: 0,
		obs: '',
		patient_id: 0,
	};

	albumId = null;

	column = null;

	order = null;

	isFetchingStatus = 'idle';

	isEditingTitle = false;

	constructor() {
		super();
		makeObservable(this, {
			isFetching: override,
			setIsFetching: override,
			isLoadingPromise: override,
			currentPage: override,
			lastPage: override,
			/** @type {Array<Object> } */
			albumList: observable,
			filterAlbum: observable,
			isFilter: observable,
			albumSelected: observable,
			albumId: observable,
			column: observable,
			order: observable,
			isFetchingStatus: observable,
			resetAlbumList: action,
			setAlbumList: action,
			getAlbumsByPage: action,
			searchAlbum: action,
			setNewNote: action,
			editNameAlbum: action,
			deleteAlbum: action,
			isEditingTitle: observable,
			setIsEditingTitle: action,
		});
	}

	resetAlbumList() {
		this.albumList = [];
	}

	setIsEditingTitle(isEditingTitle) {
		this.isEditingTitle = isEditingTitle;
	}

	get albumSelectedId() {
		return this.albumSelected?.id || '';
	}

	// métodos síncronos
	setFilters(column) {
		this.currentPage = 1;
		if (this.column !== column) {
			this.column = column;
			this.order = 'asc';
			return;
		}

		this.column = column;
		if (this.order == null) {
			this.order = 'asc';
			return;
		}
		if (this.order === 'asc') {
			this.order = 'desc';
			return;
		}
		if (this.order === 'desc') {
			this.order = null;
			this.column = null;
		}
	}

	findAlbum(id) {
		return this.albumList.find((v) => v.id === parseInt(id, 10));
	}

	setFetchingStatus = (val) => {
		this.isFetchingStatus = val;
	};

	// métodos assíncronos
	setAlbumSelected(selected) {
		return new Promise(async (resolve, reject) => {
			try {
				if (selected == null) {
					this.albumSelected = null;
					return resolve();
				}

				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/image-album/${selected}`),
				);

				this.albumSelected = { ...this.albumSelected, ...res.data };

				if (res.data == null) return resolve();
				this.setIsFetching(false);
				return resolve(res.data);
			} catch (error) {
				this.setIsFetching(false);
				if (error?.response) {
					toast.error(error.response.data.Message);
				}
				return reject(error);
			}
		});
	}

	setNewAlbum(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.post(`/v1/analysis/image-album`, payload),
				);

				if (res.data.data == null) {
					this.setIsFetching(false);
					return resolve(false);
				}

				this.albumId = res.data.data;

				this.albumList.push(res.data.data);

				this.setIsFetching(false);

				toast(res.data.Message);

				return resolve(res.data.data);
			} catch (error) {
				this.setIsFetching(false);

				return reject(error);
			}
		});
	}

	setAlbumList(patientId) {
		return new Promise(async (resolve, reject) => {
			try {
				const id = patientId;

				const res = await this.isLoadingPromise(
					baseAPI.get(
						`/v1/analysis/image-album/list/${id}?keyword=null&column=${this.column}&order=${this.order}&page=1`,
					),
				);

				const { meta } = res.data;
				this.currentPage = meta.current_page;
				this.lastPage = meta.last_page;
				this.albumSelected = null;
				this.albumList = res.data.data;

				this.setIsFetching(false);
				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);
				return reject(error);
			}
		});
	}

	getAlbumsByPage(keyword, patientId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await await this.isLoadingPromise(
					baseAPI.get(
						`/v1/analysis/image-album/list/${patientId}?keyword=${keyword}&column=${
							this.column
						}&order=${this.order}&page=${this.currentPage + 1}`,
					),
				);

				const { meta } = res.data;

				this.currentPage = meta.current_page;

				this.lastPage = meta.last_page;

				this.albumList = [...this.albumList, ...res.data.data];

				this.setIsFetching(false);

				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);

				return reject(error);
			}
		});
	}

	searchAlbum(keyword = '', id) {
		return new Promise(async (resolve, reject) => {
			try {
				let endpoint = '';

				if (keyword != null || (this.order != null && this.column != null)) {
					endpoint = `/v1/analysis/image-album/list/${id}?keyword=${keyword}&column=${this.column}&order=${this.order}&page=1`;
				} else {
					endpoint = `/v1/analysis/image-album/list/${id}`;
				}

				const res = await this.isLoadingPromise(baseAPI.get(endpoint));

				const { meta } = res.data;

				this.currentPage = meta.current_page;
				this.lastPage = meta.last_page;
				this.albumList = res.data.data;

				this.setIsFetching(false);

				return resolve(res);
			} catch (error) {
				this.setIsFetching(false);
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				return reject(error);
			}
		});
	}

	setNewNote(payload, albumId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/analysis/image-album/${albumId}`, {
						obs: payload.obs,
					}),
				);

				const findIndex = this.albumList.findIndex((v) => v.id === albumId);

				if (findIndex > -1) {
					this.albumList = [
						...this.albumList.slice(0, findIndex),
						res.data.data,
						...this.albumList.slice(findIndex + 1),
					];
				}

				toast(res.data.Message);

				this.setIsFetching(false);

				return resolve(res);
			} catch (error) {
				this.setIsFetching(false);
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				return reject(error);
			}
		});
	}

	deleteAlbum(albumId, patientId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.delete(`/v1/analysis/image-album/${albumId}`),
				);

				if (res.data) {
					toast(res.data.Message);
				}

				this.albumSelected = null;

				await this.isLoadingPromise(this.setAlbumList(patientId));

				this.setIsFetching(false);
				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				this.setIsFetching(false);
				return reject(error);
			}
		});
	}

	editNameAlbum(payload, albumId, patientId) {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsEditingTitle(true);

				const correctProperties = {
					album_name: payload.name,
					color: payload.bgColor,
					date_start: `${payload.date} ${payload.startTime}`,
					date_end: `${payload.date} ${payload.endTime}`,
					type: payload.type,
					location: payload.place,
				};

				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/analysis/image-album/${albumId}`, dictToApi), correctProperties
				);

				if (res.data == null) {
					this.setIsFetching(false);
					this.setIsEditingTitle(false);
					return resolve(res);
				}

				const findIndex = this.albumList.findIndex((v) => v.id === albumId);

				if (findIndex > -1) {
					this.albumList = [
						...this.albumList.slice(0, findIndex),
						res.data.data,
						...this.albumList.slice(findIndex + 1),
					];
				}

				toast(res.data.Message);

				await this.isLoadingPromise(this.setAlbumList(patientId));

				await this.isLoadingPromise(this.setAlbumSelected(res.data.data.id));

				this.setIsEditingTitle(false);

				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				this.setIsFetching(false);
				this.setIsEditingTitle(false);

				return reject(error);
			}
		});
	}
}

export default new AlbumStore();
