const TOOLS_KEY = {
  info: 'Informações',
  adjusts: 'Ajustes',
  analysis: 'Análise facial',
  comparative: 'Comparativo',
  timelapse: 'Timelapse',
};

const IMAGE_EXTENSIONS = ['jpg', 'png', 'jpeg', 'gif', 'webp'];

const TOOLS_ICONS = {
  info: { icon: 'info', group: 'standard' },
  adjusts: { icon: 'personbox', group: 'analysis' },
  analysis: { icon: 'textarea', group: 'standard' },
  comparative: { icon: 'squarehalf', group: 'standard' },
  timelapse: { icon: 'clockhistory', group: 'standard' },
};

const BASE3x4 = 0.75;

const checkSize = (width, height) => +(+width / +height).toFixed(2) !== BASE3x4;

const IMAGE_WIDTH_EXPORT = 450;
const IMAGE_HEIGHT_EXPORT = 600;

const ANALYSIS_LIST = [
  {
    front: [
      {
        key: 'proportion_face_front',
        value: 'proportion_face_front',
        text: 'Proporção facial ',
      },
      {
        key: 'bottom_third_proportion_front',
        value: 'bottom_third_proportion_front',
        text: 'Proporção do terço inferior ',
      },
      {
        key: 'proportion_lips_front',
        value: 'proportion_lips_front',
        text: 'Proporção labial ',
      },
      {
        key: 'horizontal_proportion_third',
        value: 'horizontal_proportion_third',
        text: 'Proporção do terço horizontal ',
      },
      {
        key: 'horizontal_proportion_fifth',
        value: 'horizontal_proportion_fifth',
        text: 'Proporção do quinto horizontal ',
      },
      {
        key: 'vertical_vs_horizontal_facial',
        value: 'vertical_vs_horizontal_facial',
        text: 'Largura x Altura ',
      },
      {
        key: 'mouth_to_eyes',
        value: 'mouth_to_eyes',
        text: 'Comissura ocular x Largura da boca ',
      },
      {
        key: 'nose_to_eyes',
        value: 'nose_to_eyes',
        text: 'Comissura ocular x Largura do nariz ',
      },
      {
        key: 'glabella_facial_opening',
        value: 'glabella_facial_opening',
        text: 'Abertura facial glabela',
      },
      {
        key: 'chin_facial_opening',
        value: 'chin_facial_opening',
        text: 'Abertura facial do queixo',
      },
      {
        key: 'face_type',
        value: 'face_type',
        text: 'Tipo de rosto ',
      },
      {
        key: 'eyebrow_angle',
        value: 'eyebrow_angle',
        text: 'Angulação sobrancelha',
      },
    ],
  },
  {
    side: [
      {
        key: 'nose_frontal_angle',
        value: 'nose_frontal_angle',
        text: 'Ângulo nasofrontal ',
      },
      {
        key: 'jaw_angle',
        value: 'jaw_angle',
        text: 'Ângulo mandibular ',
      },
      {
        key: 'nose_lips_angle',
        value: 'nose_lips_angle',
        text: 'Ângulo nasolabial ',
      },
      {
        key: 'profile_type',
        value: 'profile_type',
        text: 'Tipo de Perfil ',
      },
      {
        key: 'proportion_face_side',
        value: 'proportion_face_side',
        text: 'Proporção facial ',
      },
      {
        key: 'bottom_third_proportion_side',
        value: 'bottom_third_proportion_side',
        text: 'Proporção do terço inferior ',
      },
      {
        key: 'proportion_lips_side',
        value: 'proportion_lips_side',
        text: 'Proporção labial ',
      },
    ],
  },
];

export {
  ANALYSIS_LIST,
  TOOLS_KEY,
  TOOLS_ICONS,
  IMAGE_WIDTH_EXPORT,
  IMAGE_HEIGHT_EXPORT,
  BASE3x4,
  checkSize,
  IMAGE_EXTENSIONS,
};
