import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import DotsTableRow from './DotsTableRow';
import { useStore } from '../../../../hooks';
import StandardLoader from '../../../Styleguide/StandardLoader/StandardLoader';

const DotsTable = ({ closeAction }) => {
  const store = useStore();
  const { id: patientId } = useParams();
  const { analysisStore, dotsStore, galleryStore } = store;
  const [isSavingDots, setIsSavingDots] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (store.dotsStore.dotCategories?.length === 0) {
      store.dotsStore.getListDots();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (
        dotsStore.dots != null &&
        galleryStore.image?.analysis?.dots?.length > 0
      ) {
        setIsSavingDots(true);
        await analysisStore.saveAnalysis(
          patientId,
          galleryStore.imageId,
        );
        setIsSavingDots(false);
      }
    })();
  }, [dotsStore.dots?.length]);

  if (store.dotsStore.dotCategories?.length === 0) {
    return <StandardLoader />;
  }

  return (
    <Table
      hover
      size='md'
      className='fs-6 text-grey zi-3 position-relative '
      ref={ref}
    >
      <tbody className='text-start'>
        {store.dotsStore.dotCategories.map((i) => (
          <DotsTableRow
            ref={ref}
            key={i.node_name}
            isLoading={
              analysisStore.isFetching === 'pending' ||
              analysisStore.isFetching === true ||
              isSavingDots
            }
            isActive={dotsStore.isPresentOnKonva(i.node_name)}
            isSelect={dotsStore.activeDot === i.node_name}
            setActive={() => {
              closeAction();
              dotsStore.setActiveCategory(i.node_name);
            }}
            removeDot={() => dotsStore.removeDot(i.node_name)}
            nodeName={`${i.node_name} - ${i.dot_fullname}`}
            media={i.media}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default observer(DotsTable);

DotsTable.propTypes = {
  closeAction: PropTypes.func,
};
DotsTable.defaultProps = {
  closeAction: () => {},
};
