import React from 'react';

import PropTypes from 'prop-types';
import { Line } from 'react-konva';

const FacialOpening = ({ filteredDots, stroke, strokeWidth, target }) => {
  const GL = filteredDots.find((v) => v.categoryId === 'GL');
  const EXOE = filteredDots.find((v) => v.categoryId === 'EXOE');
  const EXOD = filteredDots.find((v) => v.categoryId === 'EXOD');
  const Pg = filteredDots.find((v) => v.categoryId === 'Pg');
  const _target = filteredDots.find((v) => v.categoryId === target);

  return (
    <>
      <Line
        stroke={stroke}
        dash={[10, 5]}
        strokeWidth={strokeWidth}
        points={[GL?.x, GL?.y, _target?.x, Pg?.y]}
      />
      <Line
        stroke={stroke}
        strokeWidth={strokeWidth}
        points={[EXOE?.x, EXOE?.y, EXOD?.x, EXOD?.y]}
      />
      <Line
        stroke={stroke}
        strokeWidth={strokeWidth}
        points={[EXOE?.x, EXOE?.y, _target?.x, Pg?.y]}
      />
      <Line
        stroke={stroke}
        strokeWidth={strokeWidth}
        points={[EXOD?.x, EXOD?.y, _target?.x, Pg?.y]}
      />
    </>
  );
};
FacialOpening.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  target: PropTypes.oneOf(['GL', 'Pg']).isRequired,
};

FacialOpening.defaultProps = {
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default FacialOpening;
