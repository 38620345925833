import PropTypes from 'prop-types';
import { Line } from 'react-konva';
import React from 'react';

const AngleEyebrow = ({ filteredDots, stroke, strokeWidth }) => {
  const leftSideDots = filteredDots.filter((v) =>
    ['EBEL', 'EBSL', 'EBIL'].includes(v.categoryId),
  );
  const rightSideDots = filteredDots.filter((v) =>
    ['EBER', 'EBSR', 'EBIR'].includes(v.categoryId),
  );
  const NADS = filteredDots.find((v) => v.categoryId === 'NADS');
  const NAES = filteredDots.find((v) => v.categoryId === 'NAES');

  return (
    <>
      {rightSideDots.map((v) => (
        <Line
          key={v.id}
          stroke={stroke}
          strokeWidth={strokeWidth}
          points={[v.x, v.y, NADS.x, NADS.y]}
        />
      ))}
      {leftSideDots.map((v) => (
        <Line
          key={v.id}
          stroke={stroke}
          strokeWidth={strokeWidth}
          points={[v.x, v.y, NAES.x, NAES.y]}
        />
      ))}
    </>
  );
};

AngleEyebrow.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

AngleEyebrow.defaultProps = {
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default AngleEyebrow;
