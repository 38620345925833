import { observer } from 'mobx-react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';

const IconNavbar = ({
  redirect,
  nameButton,
  isActive,
  icon: { group, name },
  id
}) => (
  <Nav.Item className='mx-3 mx-md-2'>
    <Nav.Link
      exact
      as={NavLink}
      to={redirect}
      className={clsx(
        'px-1 fs-sm-7 fs-md-6 shadow-none d-flex flex-row justify-content-center align-items-center',
        isActive ? 'border-bottom border-primary text-primary' : 'text-dark',
      )}
      id={id}
    >
      {group && name ? (
        <div className="h-100">
          <IconComponent
            group={group}
            name={name}
            color={isActive ? COLORS.primary : COLORS.dark}
          />
        </div>
      ) : (
        ''
      )}
      <span className={clsx({ 'mx-2 d-none d-sm-block': nameButton !== '' })}>{nameButton}</span>
    </Nav.Link>
  </Nav.Item>
);

IconNavbar.propTypes = {
  redirect: PropTypes.string,
  nameButton: PropTypes.string,
  icon: PropTypes.shape({
    group: PropTypes.string,
    name: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  id: PropTypes.string,
};

IconNavbar.defaultProps = {
  redirect: '',
  nameButton: '',
  icon: {},
  isActive: false,
  id: '',
};

export default observer(IconNavbar);
