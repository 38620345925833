import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import EditImageNavBar from './EditImageNavBar';

const EditImageLayout = ({ children }) => (
  <MainLayout customNavBar={<EditImageNavBar />}>{children}</MainLayout>
);

EditImageLayout.propTypes = {

  children: PropTypes.node,
};
EditImageLayout.defaultProps = {
  children: '',
};
export default observer(EditImageLayout);
