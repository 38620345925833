import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useStore } from '../../../hooks';

import IconComponent from '../../Svg/IconComponent/IconComponent';

import IconNavbar from '../IconNavbar/IconNavbar';
import AsyncStatus from '../../AsyncStatus/AsyncStatus';

const ToggleDropdown = React.forwardRef(({ onClick }, ref) => (
	<>
		<div ref={ref}>
			<IconComponent
				group='standard'
				name='dotsY'
				onClick={(e) => onClick(e)}
				id='navbar-menu-mobile-toggle'
			/>
		</div>
	</>
));

const NavbarHeaderMobile = ({
	hasGoBack,
	goBackAction,
	content,
	hasMenu,
	title,
	subtitle,
	topSubtitle,
	status,
	menuOptions,
	hasBottomBorder = true,
}) => {
	const history = useHistory();
	const store = useStore();
	const location = useLocation();
	const { id: patientId, imageId } = useParams();

	const middle = useMemo(() => {
		switch (content) {
			case 'nav':
				return (
					<Col className='d-flex justify-content-center align-items-center '>
						<IconNavbar
							redirect={`/patients/${
								store.patientsStore.patientId || patientId
							}/profile`}
							icon={{ group: 'standard', name: 'personfill' }}
							isActive={
								location.pathname ===
								`/patients/${store.patientsStore.patientId}/profile`
							}
						/>
						<IconNavbar
							redirect={`/patients/${
								store.patientsStore.patientId || patientId
							}/appointment/${store.albumStore.albumSelectedId}`}
							icon={{ group: 'standard', name: 'file' }}
							isActive={/(library|appointment)/.test(location.pathname)}
						/>
						<IconNavbar
							redirect={`/patients/${
								store.patientsStore.patientId || patientId
							}/comparative`}
							icon={
								location.pathname ===
								`/patients/${
									store.patientsStore.patientId || patientId
								}/tools/${imageId}`
									? { group: 'standard', name: 'tools' }
									: { group: 'standard', name: 'squarehalf' }
							}
							isActive={/(comparative|tools|analysis|timelapse|album|photos)/.test(
								location.pathname,
							)}
						/>
					</Col>
				);
			case 'user':
				return (
					<Col className='d-flex justify-content-center align-items-center '>
						<IconNavbar
							redirect='/user/status'
							icon={{ group: 'standard', name: 'analyticProfile' }}
							isActive={/(status|upgrade)/.test(location.pathname)}
						/>
						<IconNavbar
							redirect='/user/profile'
							icon={{ group: 'standard', name: 'adminUser' }}
							isActive={/(profile)/.test(location.pathname)}
						/>
						<IconNavbar
							redirect='/user/documents'
							icon={{ group: 'standard', name: 'folderOpen' }}
							isActive={/(documents)/.test(location.pathname)}
							id='btn-user-data'
						/>
					</Col>
				);
			case 'title':
				return (
					<Col
						sm={8}
						xs={8}
						className='d-flex flex-column justify-content-start align-items-center min-100 text-nowrap'
					>
						<small className='m-0 fw-light fs-7'>{topSubtitle}</small>
						<h6
							className='fs-6 m-0 text-center text-truncate'
							style={{ maxWidth: '260px' }}
						>
							{title}
						</h6>
						<p className='text-truncate m-0' style={{ maxWidth: '100%' }}>
							{subtitle}
						</p>
					</Col>
				);
			default:
				return <Col />;
		}
	}, [content, title, subtitle]);
	return (
		<div
			className={clsx(
				'd-flex w-100 justify-content-center align-items-center px-0',
				{ 'border-bottom': hasBottomBorder },
			)}
			style={{ height: '70px' }}
		>
			<Row className='d-flex w-100 py-2 justify-content-between align-items-center d-md-none '>
				{hasGoBack ? (
					<Col className='d-flex w-100'>
						<IconComponent
							name='chevronleft'
							group='standard'
							size='24'
							color='black'
							// eslint-disable-next-line
							onClick={() => (goBackAction ? goBackAction() : history.goBack())}
						/>
					</Col>
				) : (
					<Col />
				)}
				{middle}
				{hasMenu ? (
					<Col className='d-flex w-100 justify-content-end'>
						{<AsyncStatus status={status} />}
						{menuOptions.length > 0 ? (
							<Dropdown id='navbar-menu-mobile' align='end'>
								<Dropdown.Toggle as={ToggleDropdown} />
								<Dropdown.Menu align='end' className='p-0'>
									{menuOptions.map((m, i) => (
										<Dropdown.Item
											className={clsx('py-2 border-bottom ', {
												'border-bottom-0': i === menuOptions.length - 1,
											})}
											key={m.key}
											onClick={() => m.action()}
										>
											{m.label}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						) : null}
					</Col>
				) : (
					<Col />
				)}
			</Row>
		</div>
	);
};

export default observer(NavbarHeaderMobile);

NavbarHeaderMobile.propTypes = {
	title: PropTypes.string,
	content: PropTypes.oneOf(['nav', 'title']),
	subtitle: PropTypes.string,
	topSubtitle: PropTypes.string,
	hasGoBack: PropTypes.bool,
	hasMenu: PropTypes.bool,
	hasBottomBorder: PropTypes.bool,
	status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	menuOptions: PropTypes.arrayOf(PropTypes.object),
	goBackAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

NavbarHeaderMobile.defaultProps = {
	menuOptions: [],
	content: '',
	title: '',
	subtitle: '',
	topSubtitle: '',
	hasMenu: false,
	hasGoBack: false,
	hasBottomBorder: true,
	status: false,
	goBackAction: false,
};

ToggleDropdown.propTypes = {
	onClick: PropTypes.func.isRequired,
};
