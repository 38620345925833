const CompareBoard = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M15 28.125V1.875H26.25C26.7473 1.875 27.2242 2.07254 27.5758 2.42417C27.9275 2.77581 28.125 3.25272 28.125 3.75V26.25C28.125 26.7473 27.9275 27.2242 27.5758 27.5758C27.2242 27.9275 26.7473 28.125 26.25 28.125H15ZM26.25 30C27.2446 30 28.1984 29.6049 28.9016 28.9016C29.6049 28.1984 30 27.2446 30 26.25V3.75C30 2.75544 29.6049 1.80161 28.9016 1.09835C28.1984 0.395088 27.2446 0 26.25 0L3.75 0C2.75544 0 1.80161 0.395088 1.09835 1.09835C0.395088 1.80161 0 2.75544 0 3.75L0 26.25C0 27.2446 0.395088 28.1984 1.09835 28.9016C1.80161 29.6049 2.75544 30 3.75 30H26.25Z'
      fill={color}
    />
  </svg>
);

export default CompareBoard;
