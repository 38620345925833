const FolderOpenOutline = ({
    // eslint-disable-next-line react/prop-types
    iconStyleProps: { size = '24', color = 'white', ...rest },
  }) => (
    <svg width={size} height={size} viewBox="0 0 26 18" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M23.8308 7.55622H21.6667V5.38955C21.6667 4.19291 20.6966 3.22288 19.5 3.22288H12.2778L9.3889 0.333984H2.16667C0.970035 0.333984 0 1.30402 0 2.50065V15.5007C0 16.6973 0.970035 17.6673 2.16667 17.6673H20.2222C20.5897 17.6673 20.9512 17.5739 21.2726 17.3957C21.594 17.2176 21.8648 16.9606 22.0596 16.649L25.6681 10.8712C26.5685 9.43052 25.5309 7.55622 23.8308 7.55622ZM2.4375 2.50065H8.49144L11.3803 5.38955H19.2292C19.301 5.38955 19.3699 5.41808 19.4207 5.46887C19.4715 5.51967 19.5 5.58855 19.5 5.66038V7.55622H6.86112C6.48788 7.55622 6.12097 7.65264 5.79595 7.83613C5.47093 8.01962 5.19882 8.28396 5.006 8.60353L2.16667 13.3091V2.77117C2.16667 2.73562 2.17368 2.70042 2.18729 2.66758C2.2009 2.63474 2.22086 2.6049 2.24601 2.57978C2.27117 2.55465 2.30102 2.53473 2.33388 2.52116C2.36674 2.50758 2.40195 2.50061 2.4375 2.50065ZM20.2222 15.5007H3.25L6.73626 9.72289H23.8333L20.2222 15.5007Z" fill="#383C42"/>
    </svg>
  );
  
  export default FolderOpenOutline;
  