import { observer } from 'mobx-react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { useStore } from '../../../../hooks';

const SideBarIcon = (props) => {
	const {
		icon: { group, name },
		redirect,
		title,
		isActive,
		activeClassName,
		isOpen,
	} = props;

	const store = useStore();

	const clearInfoPatient = () => {
		store.patientsStore.setPatientClear();
	};

	return (
		<Nav.Item onClick={() => clearInfoPatient()}>
			<Nav.Link
				exact
				as={NavLink}
				to={redirect}
				activeClassName={activeClassName}
				className={` py-sm-3 px-3 my-2 text-white text-decoration-none flex-row d-flex ${
					store.uiStore.sideBarIsActive ? '' : 'justify-content-sm-center'
				} `}
			>
				<IconComponent
					group={group}
					name={name}
					color={isActive ? '#00F1D0' : 'white'}
				/>
				{isOpen && <p className='m-0 ps-3 pt-1'>{title}</p>}

				<span className='d-sm-none ps-2'>{title}</span>
			</Nav.Link>
		</Nav.Item>
	);
};

export default observer(SideBarIcon);

SideBarIcon.propTypes = {
	title: PropTypes.string,
	redirect: PropTypes.string,
	isActive: PropTypes.bool,
	icon: PropTypes.shape({
		group: PropTypes.string,
		name: PropTypes.string,
	}),
	activeClassName: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
};

SideBarIcon.defaultProps = {
	title: '',
	redirect: '',

	isActive: false,
	icon: {},
	activeClassName: '',
};
