import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-konva';

const FaceType = ({ filteredDots, stroke, strokeWidth }) => {
  const Trichio = filteredDots.find((d) => d.categoryId === 'Trichio');
  const ZiD = filteredDots.find((d) => d.categoryId === 'Zi_D');
  const ZiE = filteredDots.find((d) => d.categoryId === 'Zi_E');
  const Pg = filteredDots.find((d) => d.categoryId === 'Pg');
  return (
    <>
      <Line
        stroke={stroke}
        points={[Trichio?.x, Trichio?.y, Pg?.x, Pg?.y]}
        strokeWidth={strokeWidth}
      />
      <Line
        stroke={stroke}
        points={[ZiD?.x, ZiD?.y, ZiE?.x, ZiE?.y]}
        strokeWidth={strokeWidth}
      />
    </>
  );
};

FaceType.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

FaceType.defaultProps = {
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default FaceType;
