import { COLORS } from '../../../../../constants/Colors';
import { authStore } from '../../../../../stores/containers';
import FakeRadioButtonUpdateAllShowVideosFlags from '../../../../Styleguide/ButtonUpdateAllShowVideosFlags/FakeRadioButtonUpdateAllShowVideosFlags';


const sidebarDropdownData = [
	{
		name: 'Fale conosco',
		iconName: 'chatOutline',
		stateName: 'contact',
		navItems: [
			{
				href: 'https://api.whatsapp.com/send/?phone=554832113174&text&type=phone_number&app_absent=0',
				itemName: 'Falar com suporte',
				icon: {
					group: 'standard',
					name: 'cellPhoneOutline',
					size: '14',
					color: COLORS.dropdownIcon,
				},
			},
			{
				href: 'https://forms.gle/iJXLDHK5panhmPZS6',
				itemName: 'Deixar sugestão',
				icon: {
					group: 'standard',
					name: 'doubleQuoteOutline',
					size: '14',
					color: COLORS.dropdownIcon,
				},
			},
			{
				href: 'https://forms.gle/zpnBFb6MiYhD1MpZA',
				itemName: 'Reportar erro',
				icon: {
					group: 'standard',
					name: 'errorReport',
					size: '14',
					color: COLORS.dropdownIcon,
				},
			},
			{
				href: 'https://forms.gle/Az3YGndHHj1eVV7L9',
				itemName: 'Enviar feedback',
				icon: {
					group: 'standard',
					name: 'mailOutline',
					size: '14',
					color: COLORS.dropdownIcon,
				},
			},
		],
	},
	{
		name: 'Ajuda',
		iconName: 'question',
		stateName: 'help',
		defaultOnClick: true,
		navItems: [
			{
				href: '',
				itemName: 'Primeiros passos',
				icon: {
					group: 'standard',
					name: 'multipleVideos',
					size: '14',
					color: COLORS.dropdownIcon,
				},
				disabledLink: true,
				hasButton: true,
				onClick: () =>
					authStore.setShowOnboardingVideosModal(
						!authStore.user.show_onboarding_videos,
					),
				button: <FakeRadioButtonUpdateAllShowVideosFlags />,
			},
			{
				href: 'https://sites.google.com/jdrel.com.br/guia-de-uso-haiah/inicio',
				itemName: 'Guia de usuário',
				icon: {
					group: 'standard',
					name: 'textPaper',
					size: '14',
					color: COLORS.dropdownIcon,
				},
			},
			{
				href: 'https://sites.google.com/jdrel.com.br/central-de-ajuda-haiah/inicio',
				itemName: 'Central de ajuda',
				icon: {
					group: 'standard',
					name: 'questionOutline',
					size: '14',
					color: COLORS.dropdownIcon,
				},
			},
		],
	},
];

export default sidebarDropdownData;
