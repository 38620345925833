export const MENU_VALUES = {
  zoom: {
    max: 2,
    min: -2,
    step: 0.01,
  },
  rotate: {
    max: 180,
    min: -180,
    step: 1,
  },
  bright: {
    max: 1,
    min: -1,
    step: 0.01,
  },
  contrast: {
    max: 1,
    min: -1,
    step: 0.01,
  },
  // saturate: {
  //   max: 2,
  //   min: 0,
  //   step: 0.01,
  // },
};

export const EDIT_TOOLS = {
  zoom: 0,
  rotate: 0,
  bright: 0,
  contrast: 0,
  crop: {},
  // saturate: 1,
};
export const DIMMER_MESSAGE = {
  zoom: 'Use a barra abaixo para ajustar o recorte da foto',
  rotate: 'Use a barra abaixo para ajustar a rotação da foto',
  bright: 'Use a barra abaixo para ajustar o brilho da foto',
  contrast: 'Use a barra abaixo para ajustar o contraste da foto',
  // saturate: 'Use a barra abaixo para ajustar o saturação da foto',
};

export const TOOL_TRANSLATE = {
  zoom: 'recorte',
  rotate: 'rotação',
  bright: 'brilho',
  contrast: 'contraste',
};
