import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from '../../hooks';
import RefreshPageButton from '../PatientsImport/RefreshPageButton/RefreshPageButton';
import LoadingSpinner from '../Styleguide/LoadingSpinner/LoadingSpinner';

const PatientsImportLoader = () => {
	const { defaultStore } = useStore();

	useEffect(() => {
		if (defaultStore.importFilesProgress === 100 && defaultStore.isImporting) {
			defaultStore.setShowRefreshButton(true);
		}
	}, [defaultStore.importFilesProgress, defaultStore.isImporting]);

	return (
		<div
			className='d-flex justify-content-end align-content-center align-items-center gap-2 col-sm-3 pe-lg-3'
			id='import-files-loader'
		>
			{defaultStore.importFilesProgress < 100 && defaultStore.isImporting ? (
				<LoadingSpinner
					size='sm'
					desc={`Importando arquivos ${defaultStore.importFilesProgress}%`}
				/>
			) : (
				<RefreshPageButton label='Importação concluída! Atualizar' />
			)}
		</div>
	);
};

export default observer(PatientsImportLoader);
