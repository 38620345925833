const HomeOutline = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.469 1.719C11.5387 1.64915 11.6214 1.59374 11.7126 1.55593C11.8037 1.51812 11.9014 1.49866 12 1.49866C12.0987 1.49866 12.1963 1.51812 12.2875 1.55593C12.3786 1.59374 12.4613 1.64915 12.531 1.719L21.531 10.719C21.6006 10.7888 21.6558 10.8716 21.6933 10.9627C21.7309 11.0538 21.7502 11.1514 21.75 11.25V21.75C21.75 21.9489 21.671 22.1397 21.5303 22.2803C21.3897 22.421 21.1989 22.5 21 22.5H14.25C14.0511 22.5 13.8603 22.421 13.7197 22.2803C13.579 22.1397 13.5 21.9489 13.5 21.75V15.75H10.5V21.75C10.5 21.9489 10.421 22.1397 10.2803 22.2803C10.1397 22.421 9.94891 22.5 9.75 22.5H3C2.80109 22.5 2.61032 22.421 2.46967 22.2803C2.32902 22.1397 2.25 21.9489 2.25 21.75V11.25C2.24983 11.1514 2.26908 11.0538 2.30665 10.9627C2.34423 10.8716 2.3994 10.7888 2.469 10.719L11.469 1.719ZM3.75 11.5605V21H9V15C9 14.8011 9.07902 14.6103 9.21967 14.4697C9.36032 14.329 9.55109 14.25 9.75 14.25H14.25C14.4489 14.25 14.6397 14.329 14.7803 14.4697C14.921 14.6103 15 14.8011 15 15V21H20.25V11.5605L12 3.3105L3.75 11.5605Z'
      fill={color}
    />
  </svg>
);
export default HomeOutline;
