

const Dialog = ({
               // eslint-disable-next-line react/prop-types
               iconStyleProps: { size = '80', color = 'black', ...rest },
             }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100  96" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M87.5 6.25C89.1576 6.25 90.7473 6.90848 91.9194 8.08058C93.0915 9.25268 93.75 10.8424 93.75 12.5V62.5C93.75 64.1576 93.0915 65.7473 91.9194 66.9194C90.7473 68.0915 89.1576 68.75 87.5 68.75H27.5875C24.2726 68.7507 21.0937 70.0681 18.75 72.4125L6.25 84.9125V12.5C6.25 10.8424 6.90848 9.25268 8.08058 8.08058C9.25268 6.90848 10.8424 6.25 12.5 6.25H87.5ZM12.5 0C9.18479 0 6.00537 1.31696 3.66117 3.66117C1.31696 6.00537 0 9.18479 0 12.5L0 92.4563C0.000131343 93.0746 0.183704 93.679 0.527483 94.193C0.871262 94.7069 1.35979 95.1074 1.93125 95.3436C2.5027 95.5798 3.13138 95.6412 3.73773 95.5199C4.34407 95.3987 4.90082 95.1003 5.3375 94.6625L23.1688 76.8313C24.3406 75.6591 25.93 75.0004 27.5875 75H87.5C90.8152 75 93.9946 73.683 96.3388 71.3388C98.683 68.9946 100 65.8152 100 62.5V12.5C100 9.18479 98.683 6.00537 96.3388 3.66117C93.9946 1.31696 90.8152 0 87.5 0L12.5 0Z" fill={color}/>

  </svg>
);
export default Dialog;
