import { observer } from 'mobx-react';
import { Container, Navbar } from 'react-bootstrap';
import React from 'react';
import clsx from 'clsx';

import { useStore, useToggle } from '../../../hooks';
import SideBarMobile from './SideBarMobile/SideBarMobile';

import SideBarDesktop from './SideBarDesktop/SideBarDesktop';

import './SideBar.scss';

const SideBar = () => {
  const store = useStore();
  const [isOpen, toggleIsOpen] = useToggle(false);

  const handleToggle = (status = false) => {
    toggleIsOpen(status);
    // Workaround to persist the state of the sidebar when has a navigation;
    store.uiStore.setSideBarIsActive(status);
  };

  return (
    <div className='wd-100 p-0 m-0 zi-1050'>
      <Navbar
        bg='dark'
        variant='dark'
        expand='sm'
        id='sidebar'
        className={clsx('sidebar-desktop mw-70px h-100 m-0 pb-0', {
          'sidebar-desktop-active': store.uiStore.sideBarStatus || isOpen,
        })}
      >
        <Container
          fluid
          className='d-flex flex-sm-column flex-row-reverse flex-grow-1 p-2 p-sm-0 h-100 col-12 '
        >
          <SideBarMobile isOpen={store.uiStore.sideBarStatus || isOpen} />

          <SideBarDesktop
            isOpen={store.uiStore.sideBarStatus || isOpen}
            toggleIsOpen={handleToggle}
          />
        </Container>
      </Navbar>
      <div className='d-sm-none pb-1 w-100 d-flex bg-navbar-gradient' />
    </div>
  );
};

export default observer(SideBar);
