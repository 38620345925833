import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import NavbarHeader from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import { useStore } from '../../../hooks';
import { EDIT_TOOLS, TOOL_TRANSLATE } from '../../../constants/EditMenu';
import useToggle from '../../../hooks/useToggle';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';
import EditModalNotSave from '../../Tools/EditImage/Modal/EditModalNotSave';
import getCroppedImg from '../../../utils/getCroppedImg';

const EditImageNavBar = () => {
  const store = useStore();
  const history = useHistory();
  const { id: patientId, imageId } = useParams();
  const [modal, toggleModal] = useToggle();
  const { galleryStore } = store;

  const menu = [
    {
      key: 1,
      label: 'Cortar',
      action: () => galleryStore.setEditTool('zoom'),
    },
    {
      key: 2,
      label: 'Rotacionar',
      action: () => galleryStore.setEditTool('rotate'),
    },
    {
      key: 3,
      label: 'Brilho',
      action: () => galleryStore.setEditTool('bright'),
    },
    {
      key: 4,
      label: 'Contraste',
      action: () => galleryStore.setEditTool('contrast'),
    },
    {
      key: 5,
      label: 'Reiniciar ajustes',
      action: () => toggleModal(),
    },
  ];

  const tool = useMemo(() => {
    if (galleryStore.editTool != null) {
      return `Ajuste de ${TOOL_TRANSLATE[galleryStore.editTool]}`;
    }
    
    return 'Ajustes de foto';
  }, [galleryStore.editTool]);

  const handleGoBack = useCallback(() => {
    if (galleryStore.getEditConfig('validateValues')) {
      return galleryStore.setEditConfig('modal', true);
    }
    return history.push(`/patients/${patientId}/appointment/${galleryStore.image.albumId}`);
  }, [galleryStore.getEditConfig('validateValues')]);

  useEffect(() => {
    if (!galleryStore.editConfigMap.has('validateValues')) {
      Object.keys(EDIT_TOOLS).forEach((v) => {
        if (
          galleryStore.getEditConfig(v) != null &&
          galleryStore.getEditConfig(v) !== EDIT_TOOLS[v] &&
          typeof galleryStore.getEditConfig(v) !== 'object'
        ) {
          galleryStore.setEditConfig('validateValues', true);
        }
      });
    }
  }, [
    galleryStore.getEditConfig('touched'),
    galleryStore.getEditConfig('zoom'),
    galleryStore.getEditConfig('rotate'),
    galleryStore.getEditConfig('bright'),
    galleryStore.getEditConfig('contrast'),
  ]);

  const closeModalAction = () => {
    galleryStore.setEditConfig('modal', false);
    galleryStore.deleteEditConfig('touched');
    galleryStore.setEditTool(null);
  };

  const saveImage = useCallback(async () => {
    const imageCropped = await getCroppedImg(
      galleryStore.image?.media,
      galleryStore.editConfigMap.get('crop'),
      galleryStore.editConfigMap.get('rotate'),
      galleryStore.editConfigMap.get('bright'),
      galleryStore.editConfigMap.get('contrast'),
      galleryStore.editConfigMap.get('saturate'),
    );
    const result = await galleryStore.cropImage(imageCropped);
    if (result) {
      history.push(`/patients/${patientId}/tools/${imageId}`);
    }
  }, [galleryStore.image?.media, galleryStore.editConfigMap.get('crop')]);

  return (
    <>
      <NavbarHeader
        title={tool}
        subtitle={galleryStore.image?.fileName}
        content='title'
        hasGoBack
        goBackAction={handleGoBack}
        hasMenu
        menuOptions={menu}
      />
      <ModalComponent
        show={modal}
        onClose={toggleModal}
        isLoading={galleryStore.isFetching}
        disabled={galleryStore.isFetching}
        nameButton='Reiniciar'
        idButton='reset-adjusts'
        title='Reiniciar ajustes'
        subTitle=' '
        isDelete
        onClickButton={async () => {
          toggleModal();
          
          galleryStore.image.setIsReseting(true);

          await galleryStore.resetImage();

          galleryStore.image.setIsReseting(false);

          galleryStore.resetEditValues();
        }}
      >
        Você tem certeza que deseja reiniciar os ajustes?
      </ModalComponent>
      <EditModalNotSave
        isLoading={galleryStore.isFetching}
        disabled={galleryStore.isFetching}
        show={galleryStore.getEditConfig('modal')}
        onClose={closeModalAction}
        clickButton={saveImage}
        undoButton={() => {
          galleryStore.resetEditValues();
          galleryStore.setEditConfig(
            'modal',
            galleryStore.getEditConfig('modal'),
          );
          history.push(`/patients/${patientId}/tools/${imageId}`);
        }}
      />
    </>
  );
};

export default observer(EditImageNavBar);
