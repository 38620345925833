const Error = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#DC3545', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 34 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <g clipPath='url(#clip0_201_69871)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.0039 3H8.00391C7.60608 3 7.22455 3.15804 6.94325 3.43934C6.66194 3.72064 6.50391 4.10218 6.50391 4.5V19.5C6.50391 19.8978 6.66194 20.2794 6.94325 20.5607C7.22455 20.842 7.60608 21 8.00391 21H26.0039C26.4017 21 26.7833 20.842 27.0646 20.5607C27.3459 20.2794 27.5039 19.8978 27.5039 19.5V4.5C27.5039 4.10218 27.3459 3.72064 27.0646 3.43934C26.7833 3.15804 26.4017 3 26.0039 3V3ZM8.00391 1.5C7.20826 1.5 6.4452 1.81607 5.88259 2.37868C5.31998 2.94129 5.00391 3.70435 5.00391 4.5V19.5C5.00391 20.2956 5.31998 21.0587 5.88259 21.6213C6.4452 22.1839 7.20826 22.5 8.00391 22.5H26.0039C26.3979 22.5 26.788 22.4224 27.152 22.2716C27.5159 22.1209 27.8467 21.8999 28.1252 21.6213C28.4038 21.3427 28.6248 21.012 28.7755 20.648C28.9263 20.2841 29.0039 19.894 29.0039 19.5V4.5C29.0039 3.70435 28.6878 2.94129 28.1252 2.37868C27.5626 1.81607 26.7996 1.5 26.0039 1.5H8.00391V1.5Z'
        fill={color}
      />
      <path
        d='M20.9729 11.4689C21.0843 11.3578 21.2278 11.2846 21.3832 11.2596C21.5385 11.2345 21.6977 11.259 21.8384 11.3294L27.5039 14.2499V20.9999H6.50391V17.9999L10.4729 14.4689C10.5955 14.3468 10.7566 14.2708 10.9288 14.2538C11.101 14.2369 11.2738 14.28 11.4179 14.3759L15.4079 17.0354L20.9729 11.4704V11.4689Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7539 10.5C12.0494 10.5 12.342 10.4418 12.6149 10.3287C12.8879 10.2157 13.136 10.0499 13.3449 9.84099C13.5538 9.63206 13.7196 9.38402 13.8326 9.11104C13.9457 8.83806 14.0039 8.54547 14.0039 8.25C14.0039 7.95453 13.9457 7.66194 13.8326 7.38896C13.7196 7.11598 13.5538 6.86794 13.3449 6.65901C13.136 6.45008 12.8879 6.28434 12.6149 6.17127C12.342 6.0582 12.0494 6 11.7539 6C11.1572 6 10.5849 6.23705 10.1629 6.65901C9.74096 7.08097 9.50391 7.65326 9.50391 8.25C9.50391 8.84674 9.74096 9.41903 10.1629 9.84099C10.5849 10.2629 11.1572 10.5 11.7539 10.5V10.5Z'
        fill={color}
      />
    </g>
    <path d='M33 1L1 23' stroke={color} strokeWidth='2' />
    <defs>
      <clipPath id='clip0_201_69871'>
        <rect width='24' height='24' fill='white' transform='translate(5)' />
      </clipPath>
    </defs>
  </svg>
);
export default Error;
