import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useWindowSize } from 'react-use';
import clsx from 'clsx';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import useToggle from '../../../hooks/useToggle';
import DeletePatient from '../../Patients/DeletePatient/DeletePatient';
import { useStore } from '../../../hooks';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import RemovePatientAvatar from '../../Patients/RemovePatientAvatar/RemovePatientAvatar';

const PatientDataNavBar = () => {
  const store = useStore();
  const history = useHistory();

  const { patientsStore } = store;
  const { width } = useWindowSize();
  const [modal, toggleModal] = useToggle(false);
  const [patientAvatar, togglePatientAvatar] = useToggle();
  
  const menu = [
    {
      key: '1',
      label: 'Excluir paciente',
      action: () => toggleModal(),
    },
    {
      key: '2',
      label: 'Remover foto de perfil',
      action: () => togglePatientAvatar(),
    },
  ];

  const navbar = useMemo(() => {
    if (width >= 576) {
      return (
        <>
          <NavbarHeader
            content='nav'
            hasTitle
            title={patientsStore.patientSelected?.name}
          />
        </>
      );
    }
    return (
      <NavbarHeaderMobile content='nav' hasGoBack hasMenu menuOptions={menu} goBackAction={() => history.push('/patients')}  />
    );
  }, [width, patientsStore.patientSelected?.name, patientAvatar]);

  return (
    <div
      className={clsx(
        'd-flex col-12 justify-content-center align-items-center p-0',
        width < 576,
      )}
    >
      {navbar}
      <RemovePatientAvatar
        open={patientAvatar}
        toggleOpen={togglePatientAvatar}
      />
      <DeletePatient toggleOpen={toggleModal} open={modal} />
    </div>
  );
};

export default observer(PatientDataNavBar);
