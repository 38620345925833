const CheckBox = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '13', color = '#0077FF', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <rect width={size} height={size} rx='2' fill={color} fillOpacity='0.8' />
    <rect
      x='0.5'
      y='0.5'
      width='12'
      height='12'
      rx='1.5'
      stroke={color}
      strokeOpacity='0.8'
    />
    <path d='M3 7L5 9L10.5 3' stroke='white' strokeWidth='2' />
  </svg>
);
export default CheckBox;
