import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';

const ButtonInfoDrop = (props) => {
	const { id, icon, title, onClick, isActive, ...rest } = props;
	const { width: windowWidth } = useWindowSize();

	const iconColor = isActive ? COLORS.dropdownText : COLORS.greyBoard;

	return (
		<Button
			{...rest}
			variant='link'
			onClick={onClick}
			className={clsx(
				'd-flex pt-2 pe-2 justify-content-between align-items-start col-12 my-2 p-0 shadow-none text-decoration-none border-top border-bottom-0 border-start-0 rounded-0 border-end-0 lh-4',
				isActive ? 'border-blueBorder' : 'border-greyBorder',
			)}
			id={id}
		>
			<span
				className={clsx(
					'px-3 lh-2 mb-3',
					isActive
						? `text-dropdownText ${windowWidth >= 1024 ? 'fw-bold' : ''}`
						: 'text-dark',
				)}
			>
				{' '}
				{title}
			</span>
			<IconComponent color={iconColor} {...icon} size={icon.size ?? '24'} />
		</Button>
	);
};

export default observer(ButtonInfoDrop);

ButtonInfoDrop.propTypes = {
	icon: PropTypes.shape({
		group: PropTypes.string,
		name: PropTypes.string,
		size: PropTypes.string,
	}),
	title: PropTypes.string,
	onClick: PropTypes.func,
	isActive: PropTypes.bool.isRequired,
	id: PropTypes.string,
};

ButtonInfoDrop.defaultProps = {
	icon: {
		group: 'standard',
		name: 'chevrondown',
		size: '22',
	},
	id: 'btn-info-drop',
	title: '',
	onClick: () => {},
};
