const MultipleVideos = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.6875 11.8125H1.3125C1.19647 11.8125 1.08519 11.7664 1.00314 11.6844C0.921094 11.6023 0.875 11.491 0.875 11.375V5.25C0.875 5.13397 0.921094 5.02269 1.00314 4.94064C1.08519 4.85859 1.19647 4.8125 1.3125 4.8125H12.6875C12.8035 4.8125 12.9148 4.85859 12.9969 4.94064C13.0789 5.02269 13.125 5.13397 13.125 5.25V11.375C13.125 11.491 13.0789 11.6023 12.9969 11.6844C12.9148 11.7664 12.8035 11.8125 12.6875 11.8125ZM1.3125 12.6875C0.964403 12.6875 0.630564 12.5492 0.384422 12.3031C0.138281 12.0569 0 11.7231 0 11.375L0 5.25C0 4.9019 0.138281 4.56806 0.384422 4.32192C0.630564 4.07578 0.964403 3.9375 1.3125 3.9375H12.6875C13.0356 3.9375 13.3694 4.07578 13.6156 4.32192C13.8617 4.56806 14 4.9019 14 5.25V11.375C14 11.7231 13.8617 12.0569 13.6156 12.3031C13.3694 12.5492 13.0356 12.6875 12.6875 12.6875H1.3125ZM1.75 2.625C1.75 2.74103 1.79609 2.85231 1.87814 2.93436C1.96019 3.01641 2.07147 3.0625 2.1875 3.0625H11.8125C11.9285 3.0625 12.0398 3.01641 12.1219 2.93436C12.2039 2.85231 12.25 2.74103 12.25 2.625C12.25 2.50897 12.2039 2.39769 12.1219 2.31564C12.0398 2.23359 11.9285 2.1875 11.8125 2.1875H2.1875C2.07147 2.1875 1.96019 2.23359 1.87814 2.31564C1.79609 2.39769 1.75 2.50897 1.75 2.625ZM3.5 0.875C3.5 0.991032 3.54609 1.10231 3.62814 1.18436C3.71019 1.26641 3.82147 1.3125 3.9375 1.3125H10.0625C10.1785 1.3125 10.2898 1.26641 10.3719 1.18436C10.4539 1.10231 10.5 0.991032 10.5 0.875C10.5 0.758968 10.4539 0.647688 10.3719 0.565641C10.2898 0.483594 10.1785 0.4375 10.0625 0.4375H3.9375C3.82147 0.4375 3.71019 0.483594 3.62814 0.565641C3.54609 0.647688 3.5 0.758968 3.5 0.875Z'
			fill={color}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M5.47575 5.74238C5.54399 5.70454 5.62111 5.68564 5.69911 5.68764C5.77711 5.68964 5.85316 5.71247 5.91937 5.75375L9.41938 7.94125C9.4823 7.98058 9.53419 8.03527 9.57016 8.10017C9.60613 8.16507 9.625 8.23805 9.625 8.31225C9.625 8.38646 9.60613 8.45944 9.57016 8.52434C9.53419 8.58924 9.4823 8.64393 9.41938 8.68325L5.91937 10.8708C5.85315 10.9121 5.77705 10.9351 5.69898 10.9371C5.62091 10.9392 5.54372 10.9203 5.47541 10.8824C5.40711 10.8446 5.35018 10.7891 5.31054 10.7218C5.2709 10.6545 5.25 10.5778 5.25 10.4998V6.12475C5.25008 6.04667 5.27106 5.97003 5.31076 5.9028C5.35046 5.83556 5.40742 5.78017 5.47575 5.74238Z'
			fill={color}
		/>
	</svg>
);
export default MultipleVideos;
