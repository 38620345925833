import { makeAutoObservable } from 'mobx';
// import { computedFn } from 'mobx-utils';
import { toast } from 'react-toastify';
import DotModel from '../models/Dot';
import baseAPI from '../../services/baseAPI';
import { analysisStore, galleryStore } from '.';

class DotsStore {
  /** @type {Map<number|string,DotModel>} */
  dotsMap = new Map();

  dotsReceived = new Map();

  fullDots = 0;

  /** Adicionar um mapa separado
   *  pelo ID da imagem para receber
   *  a lista de pontos no formato correto */

  /**
   *  Criar um computedFn para retornar o valor do
   *  mapa acima com a referência correta
   */

  /** @type {string} */
  activeDot = null;

  /** @type {Map} */
  categoryMap = new Map();

  /** @type {number} */
  lastWidth = null;

  imageWidth = null;

  isFetching = false;

  isPresentOnKonva(id) {
    if (this.dots.length > 0) {
      return this.dots?.findIndex((dot) => dot.categoryId === id);
    }

    return galleryStore.image.analysis?.dots?.findIndex(
      (dot) => dot.categoryId === id,
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  get dots() {
    return [...this.dotsMap].map(([, dot]) => ({ ...dot }));
  }

  get dotCategories() {
    return [...this.categoryMap].map(([, category]) => ({ ...category }));
  }

  setFullDots(fullDots) {
    if (fullDots > 0) {
      this.fullDots = fullDots;
    }
  }

  setImageWidth(width) {
    this.imageWidth = width;
  }

  ruleOfThree(oldWidth) {
    return +this.lastWidth / +oldWidth;
  }

  setLastWidth(width) {
    this.lastWidth = typeof width === 'string' ? parseInt(width, 10) : width;
  }

  clearAllDots() {
    this.dotsMap.clear();
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  setActiveCategory(category) {
    const hasDotInMap = this.dotsMap.get(category) != null;
    const hasActiveCategory = category === this.activeDot;
    if (hasDotInMap) {
      if (hasActiveCategory) {
        this.activeDot = null;
      } else {
        this.activeDot = category;
      }
    } else {
      this.activeDot = category;
    }
  }

  dotListSetter(dotsData, receivedOrList) {
    if (dotsData != null) {
      if (dotsData.length && dotsData.length > 0) {
        dotsData.forEach((dotData) => {
          const newDot = new DotModel(dotData);
          if (newDot.categoryId && newDot.categoryId != null) {
            receivedOrList.set(newDot.categoryId, newDot);
          }
        });

        return receivedOrList;
      }

      const newDot = new DotModel({
        ...dotsData,
        name: this.activeDot,
        categoryId: dotsData.categoryId || this.activeDot,
      });

      if (newDot.categoryId && newDot.categoryId != null) {
        receivedOrList.set(newDot.categoryId, newDot);
      }

      return receivedOrList;
    }

    receivedOrList.set(null);
    return null;
  }

  removeDot(category) {
    this.dotsMap.delete(category);
    analysisStore.setAnalysisActive('');
  }

  updateDotsMap(imageId, toggle) {
    return new Promise(async (resolve, reject) => {
      try {
        const dotsData = await this.getLandmarks(imageId, toggle);
        if (dotsData) {
          const resizedDots = this.recalculateDotsWidth(
            dotsData.image_width,
            dotsData.dots,
          );

          if (resizedDots) {
            this.dotListSetter(resizedDots, this.dotsReceived);
            this.dotListSetter(resizedDots, this.dotsMap);

            return resolve(resizedDots);
          }

          return resolve(resizedDots);
        }

        return resolve(dotsData);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.Message);
        }

        return reject(error);
      }
    });
  }

  *getListDots() {
    this.setIsFetching(true);
    try {
      const res = yield baseAPI.get(`/v1/analysis/dot-category`);

      if (res.data !== null) {
        this.setIsFetching(false);

        res.data.forEach((dotCategory) => {
          this.categoryMap.set(dotCategory.node_name, dotCategory);
        });
        return true;
      }
      this.setIsFetching(false);
      return false;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.Message);
      }

      this.setIsFetching(false);
      return false;
    }
  }

  // Recalcular posicionamento dos pontos de acordo com o tamanho da foto
  recalculateDotsWidth(imageWidth, dots) {
    const widthProportion = this.ruleOfThree(imageWidth);

    const resizedDots = dots.map((dot) => {
      /**
       * [ 0 ] => é a label do ponto.
       * [ 1 ] => é o plano cartesiano.
       */
      
      this.setIsFetching(false);
      return {
        x: dot[1].x * widthProportion,
        y: dot[1].y * widthProportion,
        categoryId: dot[0],
      };
    });

    return resizedDots;
  }

  // Buscar a listagem de pontos para uma imagem específica
  getLandmarks(imageId, toggleShowModalNoDots) {
    return new Promise(async (resolve, reject) => {
      try {
        this.setIsFetching(true);

        const res = await baseAPI.get(
          `/v1/analysis/gallery/landmarks/${imageId}`,
        );

        if (res.data != null) {
          if (res.data.data.dots === false) {
            toggleShowModalNoDots();
          }

          this.setFullDots(res.data.data.dots.length);

          this.setIsFetching(false);

          return resolve(res.data.data);
        }
        this.setIsFetching(false);
        return resolve(null);
      } catch (e) {
        toast.error(e.response);
        this.setIsFetching(false);
        return reject(e);
      }
    });
  }
}

export default new DotsStore();
