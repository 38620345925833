import axios from 'axios';
import { toast } from 'react-toastify';
import { AUTH_TOKEN_KEY, HAIAH_PREFIX } from '../constants/StorageConstants';

function unsetToken() {
  localStorage.clear();
  delete baseAPI.defaults.headers.Authorization;
}

const baseURL = process.env.REACT_APP_API_BASE_URL;

const baseAPI = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
      Accept: 'application/json',
      ContentType: 'application/json',

  },
});

const { CancelToken } = axios;
const source = CancelToken.source();
  
const errorHandler = (error) => Promise.reject(error);

baseAPI.interceptors.request.use((request) => {
  if (localStorage.getItem(`${HAIAH_PREFIX}@${AUTH_TOKEN_KEY}`) != null) {
    request.headers.Authorization = 'Bearer '.concat(
      JSON.parse(localStorage.getItem(`${HAIAH_PREFIX}@${AUTH_TOKEN_KEY}`)),
    );
  }
  return request;
});

baseAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status !== 401) {
      errorHandler(error);
      throw error;
    }

    if (error.response?.config?.url?.includes('get-oauth-token')) {
      localStorage.removeItem('haiah_app@oauthToken');
      errorHandler(error);
      throw error;
    }

    if (window.location.pathname !== '/login') {
      window.location.href = '/';
    }

    toast(error.response.data.Message, {
      toastId: 'toast_invalid_credentials_error',
      type: 'error'
    })

    unsetToken();
    
    return true;
  },
);

export { baseURL, CancelToken, source };
export default baseAPI;
