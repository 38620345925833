import { Line } from 'react-konva';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ThirdsProportion = ({
  startPoint,
  filteredDots,
  stroke,
  strokeWidth,
}) => (
  <>
    {filteredDots.map((_, i) => {
      const thisX = filteredDots[i]?.x;
      const thisY = filteredDots[i]?.y;
      const nextX = filteredDots[i + 1]?.x;
      const nextY = filteredDots[i + 1]?.y;

      return (
        <Fragment key={`${thisX}-${thisY}-${nextX}-${nextY}`}>
          {i !== filteredDots.length - 1 ? (
            <>
              <Line
                stroke={stroke}
                strokeWidth={strokeWidth}
                points={[thisX, thisY, nextX, nextY]}
              />
            </>
          ) : null}
          <Line
            stroke={stroke}
            strokeWidth={strokeWidth}
            points={[startPoint, thisY, thisX, thisY]}
          />
          {i === filteredDots.length - 1 && (
            <>
              <Line
                stroke={stroke}
                strokeWidth={strokeWidth}
                points={[
                  startPoint,
                  filteredDots[0]?.y,
                  startPoint,
                  filteredDots[filteredDots.length - 1]?.y,
                ]}
              />
            </>
          )}
        </Fragment>
      );
    })}
  </>
);

ThirdsProportion.propTypes = {
  startPoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

ThirdsProportion.defaultProps = {
  startPoint: 40,

  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default ThirdsProportion;
