import { makeAutoObservable } from 'mobx';
import Files from '../models/Files';
import galleryStore from './galleryStore';

class ToolsStore {
  toolActive = null;

  imagesComparative = [];

  constructor() {
    makeAutoObservable(this);
  }

  setToolActive(tool) {
    this.toolActive = tool;
  }

  cleanImageComparative() {
    this.imagesComparative = [];
  }

  /**
   *
   * @param {Files} data
   * @returns {Object|null}
   */
  async setImagesComparative(data) {
    // return new Promise(async function (resolve, reject) {
      if (data == null) return (null);
    
      try {
        let image = data;
        
        if (data.media.includes('/min')) {
          const res = await galleryStore.getImage(data.id, true);
          image = new Files(res.data.data);
        }

        if (this.imagesComparative.find((s) => s.id === image.id)) {
          this.imagesComparative = this.imagesComparative.filter(
            (s) => s.id !== image.id,
          );
          return (image);
        }
        
        if (this.imagesComparative.length < 2) {
          this.imagesComparative = [...this.imagesComparative, image];
          return (image);
        }
        this.imagesComparative = [...this.imagesComparative.slice(1, 2), image];
        
        return (image);
      } catch (error) {
        return (error);
      }
    
  }
}

export default new ToolsStore();
