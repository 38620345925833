import { observer } from 'mobx-react';
import React from 'react';
import { PropTypes } from 'prop-types';
import SideBar from '../SideBar/SideBar';

const NoNavBarMainLayout = ({ children }) => (
  <div className='d-flex h-100 flex-sm-row flex-column w-100'>
    <SideBar />
    <div className='d-flex h-100 flex-column w-100 '>{children}</div>
  </div>
);

NoNavBarMainLayout.propTypes = {
  children: PropTypes.node,
};
NoNavBarMainLayout.defaultProps = {
  children: '',
};
export default observer(NoNavBarMainLayout);
