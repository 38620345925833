import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks';
import NavbarHeader from '../../../Styleguide/NavbarHeader/NavbarHeader';

const ComparativeViewNavbar = () => {
  const store = useStore();
  const { patientsStore } = store;
  return (
    <NavbarHeader
      content='nav'
      hasTitle
      hasHelper
      title={patientsStore.patientSelected?.name}
    />
  );
};

export default observer(ComparativeViewNavbar);
