import { action, makeObservable, observable } from 'mobx';
import FeedbackBadge from '../../components/Styleguide/FeedbackBadge/FeedbackBadge';
import { COLORS } from '../../constants/Colors';
import baseAPI from '../../services/baseAPI';

class DefaultStore {
	/**
	 * name: '',
	 * error: '',
	 * done: false,
	 */
	transferData = {};

	toTransfer = [];

	promises = [];

	sourceAxios = [];

	hiddenRequestModalProps = {
		title: '',
		text: '',
		buttonLabel: '',
		buttonAction: () => {},
		hasSecondButton: false,
		secondButtonLabel: '',
		secondButtonAction: () => {},
	};

	isCalendarOpen = false;

	isFetching = false;

	isHiddenRequestOpen = false;

	showModal = false;

	isExportingAnalysis = false;

	currentPage = 0;

	lastPage = 1;

	cancelRequest = false;

	hasError = false;

	importFilesProgress = 0;

	isImporting = false;

	showRefreshButton = false;

	hiddenRequestBlackBox = false;

	constructor() {
		makeObservable(this, {
			isFetching: observable,
			setIsFetching: action,

			isHiddenRequestOpen: observable,
			setIsHiddenRequestOpen: action,

			isExportingAnalysis: observable,
			setIsExportingAnalysis: action,

			isLoadingPromise: action,

			currentPage: observable,
			setCurrentPage: action,

			lastPage: observable,
			setLastPage: action,

			transferData: observable,
			setTransferData: action,
			resetTransferData: action,
			updateTransferData: action,

			hiddenRequestModalProps: observable,
			setHiddenRequestModalProps: action,

			toTransfer: observable,
			setToTransfer: action,

			setPromises: action,
			promises: observable,
			clearPromises: action,

			sourceAxios: observable,
			setSourceAxios: action,
			resetSourceAxios: action,

			addPageToCurrentPage: action,

			showExportationError: action,

			cancelRequest: observable,
			setCancelRequest: action,

			hasError: observable,
			setHasError: action,

			importFilesProgress: observable,
			setImportFilesProgress: action,

			isImporting: observable,
			setIsImporting: action,

			showRefreshButton: observable,
			setShowRefreshButton: action,

			hiddenRequestBlackBox: observable,
			setHiddenRequestBlackBox: action,
		});
	}
	
	setSourceAxios(sourceAxios) {
		this.sourceAxios = [...this.sourceAxios, sourceAxios];
	}

	setCancelRequest(cancelRequest) {
		this.cancelRequest = cancelRequest;
	}

	setCurrentPage(page) {
		this.currentPage = page;
	}

	addPageToCurrentPage(increment = 1) {
		this.currentPage += increment;
	}

	setLastPage(page) {
		this.lastPage = page;
	}

	// MÉTODOS SÍNCRONOS
	setIsFetching(isFetching) {
		this.isFetching = isFetching;
	}

	setIsHiddenRequestOpen(isOpen) {
		this.isHiddenRequestOpen = isOpen;
	}

	setIsExportingAnalysis(isExportingAnalysis) {
		this.isExportingAnalysis = isExportingAnalysis;
	}

	setOnlyLoadingTransfers() {
		this.resetTransferData();

		Object.values(this.transferData).filter((isLoadingTransfer) => {
			if (isLoadingTransfer.loading) {
				this.transferData[isLoadingTransfer.name] = isLoadingTransfer;
				return isLoadingTransfer;
			}
			return isLoadingTransfer;
		});
	}

	// receber um nome de arquivo
	// se o nome não existir, então é um relatório
	// se o valor do nome não existir então é um objeto vazio
	// receber um valor, v, com name, error e done
	// verificar se o name recebido é igual ao name preexistente
	// caso seja, inserir nessa chave o objeto recebido
	setTransferData(k, v) {
		if (v == null) {
			this.transferData[k] = '';
			return;
		}

		Object.keys(this.transferData).forEach((name) => {
			if (name === v.name) {
				this.transferData[name] = v;
			}
		});
	}

	updateTransferData(k, v) {
		this.transferData[k] = v;
	}

	setToTransfer(toTransfer) {
		this.toTransfer.push(toTransfer);
	}

	setHasError(hasError) {
		this.hasError = hasError;
	}

	resetToTransfer() {
		this.toTransfer = [];
	}

	resetTransferData() {
		// meus valores com dados de transferência dos arquivos

		this.transferData = {};
		// "[Object object]": undefined
	}

	resetSourceAxios() {
		this.sourceAxios = [];
	}

	setPromises(promise) {
		this.promises = [];
		this.promises.push(promise);
	}

	clearPromises() {
		this.promises = [];
	}

	setHiddenRequestModalProps(hiddenRequestModalProps) {
		this.hiddenRequestModalProps = hiddenRequestModalProps;
	}

	setShowHiddenRequestModal(showModal) {
		if (!showModal) {
			this.transferData = {};
		}

		this.showModal = showModal;
	}

	showExportationError(loadingModal) {
		this.setTransferData(null, {
			done: true,
			name: 'Exportação de relatório do paciente',
			error: 'Não foi possível exportar o relatório',
		});

		this.setHiddenRequestModalProps({
			text: (
				<FeedbackBadge
					icon={{
						group: 'standard',
						name: 'failCircle',
						color: COLORS.danger,
						size: 80,
					}}
					text='Falha ao realizar a exportação'
					textColor='danger'
				/>
			),
			buttonLabel: 'Fechar',
		});

		document.getElementById(loadingModal)?.classList.remove('d-block');

		document.getElementById('btn-show-modal-hidden-request').click();
	}

	setImportFilesProgress(importFilesProgress) {
		this.importFilesProgress = importFilesProgress;
	}

	setIsImporting(isImporting) {
		this.isImporting = isImporting;
	}

	setShowRefreshButton(showRefreshButton) {
		this.showRefreshButton = showRefreshButton;
	}

	setHiddenRequestBlackBox(hiddenRequestBlackBox) {
		this.hiddenRequestBlackBox = hiddenRequestBlackBox;
	}

	// MÉTODOS ASSÍNCRONOS
	async isLoadingPromise(promise) {
		let load = '';

		try {
			load = setTimeout(() => {
				this.setIsFetching(true);
			}, 200);

			const response = await promise;
			if (load) {
				clearTimeout(load);
			}

			if (load != null) {
				clearTimeout(load);
			}

			this.setIsFetching(false);

			return Promise.resolve(response);
		} catch (error) {
			if (load) {
				clearTimeout(load);
			}

			this.setIsFetching(false);

			return Promise.reject(error);
		}
	}

	updateFlagOfAnything(keyName, flag, id, route){
		return new Promise(async(resolve, reject)=>{
			try{
				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/${route}/${id}`, {[keyName] : flag})
				)

				resolve(res)
			}
			catch(error){
				reject(error)
			}
		})
	}
}


export default DefaultStore;
