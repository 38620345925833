export default function sanatizeNumberField(value, callback, field = null) {
  let test = false;
  test = /^[0-9().\s-\b]+$/.test(value);

  if (value.length > 15) {
    test = /\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}/g.test(value);
  }

  if (test || value === '') {
    return field != null ? callback(field, value) : callback(value);
  }

  return field != null ? callback(field, value) : callback(value);
}
