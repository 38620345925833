import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Circle, Text } from 'react-konva';

const DISTANCE = 5;

const Dot = memo(
  /**
   * @param {Object} props
   * @param {number} props.x - X position
   * @param {number} props.y - Y position
   * @param {number|string} props.clickWidth
   * @param {string} props.text - Text underneat
   * @param {boolean} [props.showText=true] - Is text visible
   * @param {boolean} [props.isActive=false] - Is dot active
   * @param {() => void} props.onDragEnd
   * @param {number} props.categoryId
   * @param {() => void} props.setActiveCategory
   */
  (props) => {
    const {
      x,
      y,
      text,
      showText,
      isActive,
      draggable,
      onDragEnd,
      categoryId,
      setActiveCategory,
      clickWidth,
    } = props;
    const [isDragging, setIsDragging] = useState(false);
    // Circle component props
    const fill = isActive ? '#8BFFAC' : '#008AFF';
    const stroke = 'white';
    const strokeWidth = 2;
    const radius = 2.5;
    const fillAfterStrokeEnabled = true;
    // Text component props
    const fontSize = 10;
    return (
      <>
        <Circle
        zIndex={1000}
          {...{
            x,
            y,
            fill,
            radius,
            stroke,
            strokeWidth,
            fillAfterStrokeEnabled,
          }}
          hitStrokeWidth={clickWidth}
          draggable={draggable}
          onDragStart={() => {
            setActiveCategory(categoryId);
            setIsDragging(true);
          }}
          onDragEnd={(e) => {
            onDragEnd(e);
            setIsDragging(false);
          }}
        />
        {showText && !isDragging ? (
          <Text
            {...{
              x: x - DISTANCE,
              y: y + DISTANCE,
              text,
              fontSize,
            }}
            fill='#008AFF'
          />
        ) : null}
      </>
    );
  },
);

Dot.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  text: PropTypes.string,
  draggable: PropTypes.bool,
  showText: PropTypes.bool,
  isActive: PropTypes.bool,
  onDragEnd: PropTypes.func.isRequired,
  setActiveCategory: PropTypes.func.isRequired,
  clickWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Dot.defaultProps = {
  showText: true,
  draggable: true,
  isActive: false,
  text: '',
  clickWidth: 'auto',
};

export default Dot;
