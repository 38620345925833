import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { defaultStore } from '.';
import FeedbackBadge from '../../components/Styleguide/FeedbackBadge/FeedbackBadge';
import { COLORS } from '../../constants/Colors';
import { baseURL } from '../../services/baseAPI';

class ComparativeStore {
  isFetching = false;

  /** @type {Map} */
  exportMap = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get comparativeImages() {
    return [...this.exportMap].map(([, v]) => v);
  }

  cleanExportMap() {
    this.exportMap.clear();
  }

  setExportMap(key, value) {
    this.exportMap.set(key, value);
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  exportComparative(hasInfo, analysis, images, firstId, secondId) {
    return new Promise(async (resolve, reject) => {
      const key = analysis !== '' && analysis != null ? analysis : 'dots';

      const { CancelToken } = axios;
      
      const source = CancelToken.source();

      defaultStore.setSourceAxios(source);

      const payload = {
        images: [...images.map((v) => ({ [key]: v }))],
        hasInfo,
        firstId,
        secondId,
      };

      try {
        const link = document.getElementById(
          'a-to-download-comparative-report',
        );

        const res = await axios.request({
          url: `${baseURL}/v1/analysis/report/comparative`,
          method: 'post',
          responseType: 'blob',
          data: { ...payload, obs: 'report' },
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('haiah_app@appToken'),
            )}`,
            ContentType: 'application/octet-stream',
            reponseType: 'blob',
          },
          cancelToken: source.token,
        });

        const type = res.headers['content-type'];

        const blob = new Blob([res.data], { type, encoding: 'UTF-8' });

        link.href = window.URL.createObjectURL(blob);

        link.remove();

        this.exporting = false;
        return resolve(true);
      } catch (e) {
        defaultStore.setHiddenRequestModalProps({
          text: (
            <FeedbackBadge
              icon={{
                group: 'standard',
                name: 'failCircle',
                color: COLORS.danger,
                size: 80,
              }}
              text='Falha ao realizar a exportação'
              textColor='danger'
            />
          ),
          buttonLabel: 'Fechar',
        });

        this.exporting = false;
        return reject(e);
      }
    });
  }

  /** Promise.all para carregar ambas as fotos simultaneamente no momento do refresh */
}

export default new ComparativeStore();
