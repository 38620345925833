import { useRef } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { useTutorialContext } from '../../../stores/contexts/TutorialContext';
import TutorialCard from '../TutorialCard/TutorialCard';
import { useStore } from '../../../hooks';

const TutorialButton = () => {
	const {
		isCardOpened,
		setIsCardOpened,
		setIsWelcomeTutorialOpened,
		slug,
		pageHasTutorial,
	} = useTutorialContext();
	const { defaultStore, patientsStore } = useStore();
	const ref = useRef();

	return pageHasTutorial ? (
		<div
			ref={ref}
			id='tut-btn-container'
			className='bg-primary position-fixed bottom-0 end-0 p-1 p-sm-0 rounded-gout-sm-10 m-sm-2 w-xs-100 w-sm-75 w-md-50 w-xl-25 zi-1050'
		>
			<Button
				id='btn-open-tutorial-card'
				className={`d-flex tut-btn-margin-mobile ms-auto mt-n24 position-sm-fixed rounded-top-xs-15 rounded-bottom-xs-0 pt-1 pb-0 p-sm-3 bottom-0 end-sm-0 rounded-gout-sm-100 opacity-sm-50 position-sm-fixed ${
					window.innerWidth < 576
						? 'd-flex'
						: isCardOpened
						? 'd-sm-none'
						: 'd-flex'
				}`}
				style={{
					marginRight:
						Object.values(defaultStore.transferData).length && window.innerWidth > 576
							? `${
								defaultStore.hiddenRequestBlackBox + 10
							  }px`
							: window.innerWidth > 576 ? '8px' : 'auto',
					marginBottom: patientsStore.containerPatientButtonsHeight && window.innerWidth > 576
						? `${
								patientsStore.containerPatientButtonsHeight + 10
						  }px`
						: '8px',
				}}
				onClick={() => {
					if (slug === 'home') {
						setIsWelcomeTutorialOpened(true);
					} else {
						setIsCardOpened(!isCardOpened);
					}
				}}
			>
				{isCardOpened ? (
					<IconComponent
						group='standard'
						name='closex'
						color='white'
						size='14'
					/>
				) : (
					<IconComponent
						group='standard'
						name='tutorialVideo'
						color='white'
						size='18'
					/>
				)}
			</Button>

			<TutorialCard />
		</div>
	) : (
		<></>
	);
};

export default observer(TutorialButton);
