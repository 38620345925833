import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import IconComponent from '../../../../Svg/IconComponent/IconComponent';

const NavbarDropdownItem = ({
	i,
	index,
	disabledLink,
	href,
	icon,
	itemName,
	hasButton,
	button,
}) => (
	<NavDropdown.Item
		key={i}
		className={`${
			index === 0 ? 'rounded-bottom-10' : 'rounded-bottom-0'
		} my-0 py-2 bg-grey-dropdown bg-sm-dropdown-mobile text-lighter-light-grey d-flex align-items-center col-12`}
		href={disabledLink ? '#' : href}
		style={hasButton ? { pointerEvents: 'none' } : null}
	>
		{icon.name ? (
			<div className='col-1 col-sm-1'>
				<IconComponent
					group={icon.group}
					name={icon.name}
					size={icon.size}
					color={icon.color}
				/>
			</div>
		) : null}
		<li
			className={`${hasButton ? 'pe-2 ' : ''}${
				icon.name ? 'col pr-2  ms-2' : 'col-12 '
			}`}
		>
			{itemName}
		</li>
		{hasButton ? button : null}
	</NavDropdown.Item>
);

export default NavbarDropdownItem;

NavbarDropdownItem.propTypes = {
	i: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	href: PropTypes.string.isRequired,
	itemName: PropTypes.string.isRequired,
	icon: PropTypes.shape({
		group: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		size: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}).isRequired,
	button: PropTypes.node,
	hasButton: PropTypes.bool,
	disabledLink: PropTypes.bool,
};

NavbarDropdownItem.defaultProps = {
	button: null,
	hasButton: false,
	disabledLink: false,
};
