import React from 'react';
import PropTypes from 'prop-types';
import IconComponent from '../../Svg/IconComponent/IconComponent';

const Dimmer = ({ message, icon: { group, name } }) => (
  <div
    className='w-100 d-flex d-md-none d-sm-flex justify-content-center align-items-center h-100 position-absolute top-0 end-0 zi-9'
    style={{
      backgroundColor: '#000',
      opacity: '0.6',
    }}
  >
    <div className='d-flex flex-column justify-content-center align-items-center text-center'>
      <div className='d-flex col-sm'>
        <IconComponent group={group} name={name} size='120' />
      </div>
      <div>
        <p
          className='px-5 d-flex text-white col-sm'
          style={{ fontSize: '1.25rem' }}
        >
          {message}
        </p>
      </div>
    </div>
  </div>
);

export default Dimmer;

Dimmer.propTypes = {
  icon: PropTypes.shape({
    group: PropTypes.string,
    name: PropTypes.string,
  }),
  message: PropTypes.string,
};

Dimmer.defaultProps = {
  icon: '',
  message: '',
};
