const Check = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#0091FF', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8541 3.64604C13.9007 3.69248 13.9376 3.74766 13.9629 3.80841C13.9881 3.86915 14.001 3.93427 14.001 4.00004C14.001 4.06581 13.9881 4.13093 13.9629 4.19167C13.9376 4.25242 13.9007 4.30759 13.8541 4.35404L6.85414 11.354C6.8077 11.4006 6.75252 11.4375 6.69178 11.4628C6.63103 11.488 6.56591 11.5009 6.50014 11.5009C6.43438 11.5009 6.36925 11.488 6.30851 11.4628C6.24776 11.4375 6.19259 11.4006 6.14614 11.354L2.64614 7.85404C2.55226 7.76015 2.49951 7.63281 2.49951 7.50004C2.49951 7.36726 2.55226 7.23993 2.64614 7.14604C2.74003 7.05215 2.86737 6.99941 3.00014 6.99941C3.13292 6.99941 3.26026 7.05215 3.35414 7.14604L6.50014 10.293L13.1461 3.64604C13.1926 3.59948 13.2478 3.56253 13.3085 3.53733C13.3693 3.51212 13.4344 3.49915 13.5001 3.49915C13.5659 3.49915 13.631 3.51212 13.6918 3.53733C13.7525 3.56253 13.8077 3.59948 13.8541 3.64604Z'
      fill='white'
      stroke={color}
    />
  </svg>
);
export default Check;
