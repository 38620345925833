import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import IconComponent from '../../Svg/IconComponent/IconComponent';

const SearchInput = ({ placeholder, value, onChange, id }) => (
  <div className='w-100'>
    <InputGroup>
      <Form.Control
        className='search-input'
        type='search'
        aria-label='Search'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        id={id}
      />
      <InputGroup.Text>
        <IconComponent
          group='standard'
          name='search'
          color='#212529'
          size='18'
        />
      </InputGroup.Text>
    </InputGroup>
  </div>
);
export default SearchInput;

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: 'Pesquise...',
  value: '',
  onChange: () => {},
  id: '',
};
