const PersonPlus = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5 18C16.5 18 18 18 18 16.5C18 15 16.5 10.5 9 10.5C1.5 10.5 0 15 0 16.5C0 18 1.5 18 1.5 18H16.5ZM1.533 16.5H16.467C16.474 16.4992 16.481 16.4982 16.488 16.497L16.5 16.494C16.4985 16.125 16.269 15.015 15.252 13.998C14.274 13.02 12.4335 12 9 12C5.565 12 3.726 13.02 2.748 13.998C1.731 15.015 1.503 16.125 1.5 16.494C1.51098 16.4961 1.52198 16.4981 1.533 16.5ZM9 7.5C9.79565 7.5 10.5587 7.18393 11.1213 6.62132C11.6839 6.05871 12 5.29565 12 4.5C12 3.70435 11.6839 2.94129 11.1213 2.37868C10.5587 1.81607 9.79565 1.5 9 1.5C8.20435 1.5 7.44129 1.81607 6.87868 2.37868C6.31607 2.94129 6 3.70435 6 4.5C6 5.29565 6.31607 6.05871 6.87868 6.62132C7.44129 7.18393 8.20435 7.5 9 7.5ZM13.5 4.5C13.5 5.69347 13.0259 6.83807 12.182 7.68198C11.3381 8.52589 10.1935 9 9 9C7.80653 9 6.66193 8.52589 5.81802 7.68198C4.97411 6.83807 4.5 5.69347 4.5 4.5C4.5 3.30653 4.97411 2.16193 5.81802 1.31802C6.66193 0.474106 7.80653 0 9 0C10.1935 0 11.3381 0.474106 12.182 1.31802C13.0259 2.16193 13.5 3.30653 13.5 4.5ZM20.25 4.5C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V8.25C21 8.44891 20.921 8.63968 20.7803 8.78033C20.6397 8.92098 20.4489 9 20.25 9H17.25C17.0511 9 16.8603 8.92098 16.7197 8.78033C16.579 8.63968 16.5 8.44891 16.5 8.25C16.5 8.05109 16.579 7.86032 16.7197 7.71967C16.8603 7.57902 17.0511 7.5 17.25 7.5H19.5V5.25C19.5 5.05109 19.579 4.86032 19.7197 4.71967C19.8603 4.57902 20.0511 4.5 20.25 4.5Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.5 8.25C19.5 8.05109 19.579 7.86032 19.7197 7.71967C19.8603 7.57902 20.0511 7.5 20.25 7.5H23.25C23.4489 7.5 23.6397 7.57902 23.7803 7.71967C23.921 7.86032 24 8.05109 24 8.25C24 8.44891 23.921 8.63968 23.7803 8.78033C23.6397 8.92098 23.4489 9 23.25 9H21V11.25C21 11.4489 20.921 11.6397 20.7803 11.7803C20.6397 11.921 20.4489 12 20.25 12C20.0511 12 19.8603 11.921 19.7197 11.7803C19.579 11.6397 19.5 11.4489 19.5 11.25V8.25Z'
      fill={color}
    />
  </svg>
);
export default PersonPlus;
