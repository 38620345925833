
export default function YoutubeEmbedUrl(url) {
    if (url) {
        if (url.includes('embed')) {
            return url;
        }

        const videoCode = url?.split('v=')[1];
        return `https://www.youtube.com/embed/${videoCode}`;
    }
};

