import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';

const EditRequiredModal = () => {
  const store = useStore();
  const { galleryStore } = store;

  if (!galleryStore.getEditConfig('requiredAdjusts')) {
    return null;
  }

  return (
    <ModalComponent
      title='AJUSTES'
      text='A imagem selecionada necessita de ajustes para permitir utilizar as ferramentas.'
      show
      onClose={() => galleryStore.setEditConfig('requiredAdjusts', false)}
      hasButton={false}
    />
  );
};

export default observer(EditRequiredModal);
