import { observer } from 'mobx-react';
import { Container, Navbar } from 'react-bootstrap';
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SideBarMobile from './SideBarMobile/SideBarMobile';
import SideBarDesktop from './SideBarDesktop/SideBarDesktop';

import './SideBar.scss';

const FilesLayoutSideBar = ({ isOpen, handleToggle, uiStore }) => (
	<div className='wd-100 p-0 m-0 h-sm-100'>
		<Navbar
			bg='dark'
			variant='dark'
			expand='sm'
			id='sidebar'
			className={clsx('sidebar-desktop mw-70px pb-0 h-100 m-0', {
				'sidebar-desktop-active': uiStore.sideBarStatus || isOpen,
			})}
		>
			<Container
				fluid
				className='d-flex flex-sm-column flex-row-reverse p-2 p-sm-0 h-100 col-12 '
			>
				<SideBarMobile isOpen={uiStore.sideBarStatus || isOpen} />

				<SideBarDesktop
					isOpen={uiStore.sideBarIsActive}
					toggleIsOpen={handleToggle}
				/>
			</Container>
		</Navbar>
		<div className='d-sm-none pb-1 w-100 d-flex bg-navbar-gradient' />
	</div>
);

export default observer(FilesLayoutSideBar);

FilesLayoutSideBar.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleToggle: PropTypes.func.isRequired,
	uiStore: PropTypes.object.isRequired,
};
