import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from '../../../hooks';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';
import Input from '../../Styleguide/Input/Input';
import useToggle from '../../../hooks/useToggle';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import AnnotationOverlay from '../../Styleguide/AnnotationOverlay/AnnotationOverlay';

import EditAlbumModal from './EditAlbum/Modal/EditAlbumModal';

const LibraryNavBar = () => {
	const store = useStore();
	const history = useHistory();
	const { id: patientId, albumId } = useParams();
	const [editModalShow, setEditModalShow] = useState(false);
	const [deleteModalShow, setDeleteModalShow] = useState(false);
	const [valueDelete, setValueDelete] = useState('');
	const [isInvalid, setIsInvalid] = useState(false);
	const [overlay, toggleOverlay] = useToggle(false);

	const validationDelete = useMemo(
		() => valueDelete === store.albumStore.albumSelected?.album_name,
		[store.albumStore.albumSelected?.album_name, valueDelete],
	);

	const deleteAlbum = async () => {
		if (validationDelete) {
			await store.albumStore.deleteAlbum(albumId, patientId);
			closeModal();
			history.push(`/patients/${patientId}/library`);
			return;
		}
		setIsInvalid(true);
		toast.error('Confirmação inválida');
	};

	const closeModal = () => {
		setDeleteModalShow(false);
		setValueDelete('');
		setEditModalShow(false);
	};

	const saveNote = async (payload) => {
		await store.albumStore.setNewNote(payload, albumId);
	};

	const menu = [
		{
			key: '1',
			label: 'Editar consulta',
			action: () => {
				setEditModalShow(true);
			},
		},
		{
			key: '2',
			label: 'Anotações',
			action: () => toggleOverlay(),
		},

		{
			key: '3',
			label: 'Excluir consulta',
			action: () => setDeleteModalShow(true),
		},
	];

	return (
		<div className='d-flex col-12 p-0 m-0'>
			<div className=' d-none d-sm-flex  justify-content-center w-100' id='row-files-layout-navbar'>
				<NavbarHeader
					content='nav'
					hasTitle
					title={store.patientsStore.patientSelected?.name}
					hasHelper
				/>
			</div>
			<div className='d-flex d-sm-none w-100 '>
				<NavbarHeaderMobile
					hasGoBack
					hasMenu
					menuOptions={menu}
					content='title'
					title={store.albumStore.albumSelected?.album_name}
					goBackAction={() => history.push(`/patients/${patientId}/library`)}
				/>
			</div>
			<AnnotationOverlay
				overlay={overlay}
				toggleOverlay={toggleOverlay}
				title={store.albumStore.albumSelected?.album_name}
				saveAction={saveNote}
				value={store.albumStore.albumSelected?.obs}
				loading={store.albumStore.isFetching}
			/>

			<ModalComponent
				show={deleteModalShow}
				isLoading={store.albumStore.isFetching}
				onClose={() => closeModal(false)}
				title={`Excluir ${store.albumStore.albumSelected?.album_name} ?`}
				subTitle='Você tem certeza que deseja excluir a consulta? Essa ação não poderá ser desfeita.'
				nameButton='Excluir'
				idButton='delete-appointment'
				isDelete
				onClickButton={() => deleteAlbum()}
				disabled={!validationDelete}
			>
				<Input
					value={valueDelete}
					label='Digite o nome da consulta que você deseja excluir:'
					name='deletAlbum'
					id='deletAlbum'
					onChange={(e) => setValueDelete(e.target.value)}
					placeholder={store.albumStore.albumSelected?.album_name}
					isInvalid={isInvalid}
					error='* Nome do consulta não corresponde'
					maxLength={255}
				/>
			</ModalComponent>

			{/* modal editar nome do album */}
			<EditAlbumModal
				editModalShow={editModalShow}
				handleClose={closeModal}
				albumId={albumId ?? '0'}
				patientId={patientId}
			/>
		</div>
	);
};

export default observer(LibraryNavBar);
