import { createContext, useContext } from 'react';
import {
  albumStore,
  analysisStore,
  authStore,
  checkoutStore,
  comparativeStore,
  dotsStore,
  galleryStore,
  patientsStore,
  timelapseStore,
  toolsStore,
  uiStore,
  defaultStore,
  calendarStore,
  tutorialStore
} from '../stores/containers/index';

const rootStore = Object.freeze({
  authStore,
  analysisStore,
  albumStore,
  checkoutStore,
  comparativeStore,
  dotsStore,
  galleryStore,
  patientsStore,
  timelapseStore,
  toolsStore,
  uiStore,
  defaultStore,
  calendarStore,
  tutorialStore
});

const RootStoreContext = createContext({});

// eslint-disable-next-line react/prop-types
const RootStoreProvider = ({ children }) => (
  <RootStoreContext.Provider value={rootStore}>
    {children}
  </RootStoreContext.Provider>
);

/**
 * @returns {typeof rootStore}
 */
const useStore = () => useContext(RootStoreContext);
export { RootStoreProvider, useStore };

export default rootStore;
