import { useMemo } from 'react';

const HorizontalRuler = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#343A40', ...rest },
}) => {
  const lineNumber = useMemo(() => {
    let length = size;
    const arr = [];
    while (length > 0) {
      arr.push(length);
      length -= 8.5;
    }
    return arr;
  }, [size]);

  return (
    <svg
      width='20'
      height={size}
      viewBox={`0 0 20 ${size}`}
      fill='none'
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.8'
        x='0.560547'
        width='20'
        height={size}
        fill={color}
        fillOpacity='0.8'
      />
      {lineNumber.map((value, i) => (
        <line
          key={`rulerV${value}`}
          x1='0.560547'
          y1={value}
          x2={i % 2 ? 11 : 16}
          y2={value}
          stroke='white'
        />
      ))}
    </svg>
  );
};
export default HorizontalRuler;
