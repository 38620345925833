import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';
import { useStore } from '../../../hooks';

const FakeRadioButtonUpdateAllShowVideosFlags = () => {
	const { authStore } = useStore();

	return (
		<Form>
			<Form.Check
				className='zi-1300'
				checked={authStore.user.show_onboarding_videos}
				disabled={authStore.fetching}
				type='switch'
				id='btn-to-update-all-show-videos-flags'
			/>
		</Form>
	);
};

export default observer(FakeRadioButtonUpdateAllShowVideosFlags);
