const AlbumPlus = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M14.742 6H3.28498C3.07661 5.99999 2.87052 6.04339 2.67985 6.12744C2.48918 6.21149 2.31812 6.33434 2.17756 6.48816C2.037 6.64199 1.93003 6.82341 1.86348 7.02086C1.79692 7.21832 1.77223 7.42747 1.79098 7.635L2.74648 18.135C2.78016 18.5077 2.95193 18.8543 3.22807 19.1069C3.5042 19.3594 3.86477 19.4996 4.23898 19.5H13.5V21H4.23898C3.49054 20.9997 2.76923 20.7197 2.21668 20.2148C1.66412 19.71 1.32021 19.0169 1.25248 18.2715L0.29698 7.7715C0.233059 7.07667 0.414571 6.38141 0.80998 5.8065L0.74998 4.5C0.74998 3.70435 1.06605 2.94129 1.62866 2.37868C2.19127 1.81607 2.95433 1.5 3.74998 1.5H9.25798C10.0536 1.50017 10.8165 1.81635 11.379 2.379L12.621 3.621C13.1835 4.18365 13.9464 4.49983 14.742 4.5H20.715C21.1319 4.49996 21.5442 4.5868 21.9256 4.75499C22.3071 4.92317 22.6493 5.16902 22.9304 5.47683C23.2116 5.78465 23.4255 6.14768 23.5585 6.54276C23.6915 6.93785 23.7407 7.35633 23.703 7.7715L23.319 12H21.81L22.2075 7.635C22.2262 7.4276 22.2016 7.21857 22.1351 7.02122C22.0686 6.82387 21.9618 6.64252 21.8214 6.48872C21.681 6.33491 21.5101 6.21203 21.3196 6.12789C21.1291 6.04375 20.9232 6.0002 20.715 6H14.742ZM10.317 3.4395L11.3805 4.5H3.28498C2.92498 4.5 2.57998 4.563 2.25898 4.68L2.24998 4.47C2.25783 4.0774 2.41933 3.70353 2.69979 3.42868C2.98025 3.15383 3.3573 2.99992 3.74998 3H9.25798C9.65577 3.00008 10.0372 3.15818 10.3185 3.4395H10.317Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.25 15C20.4489 15 20.6397 15.079 20.7803 15.2197C20.921 15.3603 21 15.5511 21 15.75V18.75C21 18.9489 20.921 19.1397 20.7803 19.2803C20.6397 19.421 20.4489 19.5 20.25 19.5H17.25C17.0511 19.5 16.8603 19.421 16.7197 19.2803C16.579 19.1397 16.5 18.9489 16.5 18.75C16.5 18.5511 16.579 18.3603 16.7197 18.2197C16.8603 18.079 17.0511 18 17.25 18H19.5V15.75C19.5 15.5511 19.579 15.3603 19.7197 15.2197C19.8603 15.079 20.0511 15 20.25 15Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.5 18.75C19.5 18.5511 19.579 18.3603 19.7197 18.2197C19.8603 18.079 20.0511 18 20.25 18H23.25C23.4489 18 23.6397 18.079 23.7803 18.2197C23.921 18.3603 24 18.5511 24 18.75C24 18.9489 23.921 19.1397 23.7803 19.2803C23.6397 19.421 23.4489 19.5 23.25 19.5H21V21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5C20.0511 22.5 19.8603 22.421 19.7197 22.2803C19.579 22.1397 19.5 21.9489 19.5 21.75V18.75Z" fill={color} />

  </svg>
);
export default AlbumPlus;


