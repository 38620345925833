import { Image } from 'react-bootstrap';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import haiahblacklogo from '../../../assets/images/icons-sidebar/haiahblacklogo.svg';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';
import { useIsMobile } from '../../../hooks';
import CheckoutNavbar from './CheckoutNavbar';

const CheckoutLayout = ({ children }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  return useMemo(() => {
    if (isMobile) {
      return (
        <div className='d-flex flex-column w-100 align-items-center'>
          <div
            className='d-flex flex-row w-100 justify-content-center justify-content-md-between px-4 align-items-center bg-light'
            style={{ height: '70px' }}
          >
            <IconComponent
              group='standard'
              name='chevronleft'
              onClick={() => history.goBack()}
              color={COLORS.dark}
              className='justify-self-start d-none d-md-block'
            />
            <Image className='p-3' height='70' src={haiahblacklogo} />
            <div className='d-none d-md-block' />
          </div>
          <CheckoutNavbar />
          {children}
        </div>
      );
    }
    return (
      <div className='d-flex flex-column w-100 align-items-center'>
        <div
          className='d-flex flex-row w-100 justify-content-center justify-content-md-between px-4 align-items-center bg-light'
          style={{ height: '70px' }}
        >
          <IconComponent
            group='standard'
            name='chevronleft'
            onClick={() => history.goBack()}
            color={COLORS.dark}
            className='justify-self-start d-none d-md-block'
          />
          <Image className='p-3' height='70' src={haiahblacklogo} />
          <div className='d-none d-md-block' />
        </div>
        {children}
      </div>
    );
  }, [isMobile]);
};

CheckoutLayout.propTypes = {
  children: PropTypes.node,
};
CheckoutLayout.defaultProps = {
  children: '',
};
export default observer(CheckoutLayout);
