import React, { useCallback, useEffect, useState } from 'react';
// import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import { toast } from 'react-toastify';
import { useStore } from '../../../hooks';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';
import Input from '../../Styleguide/Input/Input';
import useToggle from '../../../hooks/useToggle';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import DimmerDetails from '../../Styleguide/DimmerDetails/DimmerDetails';
// import { PHOTO_TYPES } from '../../../constants/PhotoTypes';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import AnnotationOverlay from '../../Styleguide/AnnotationOverlay/AnnotationOverlay';
import CategoryModal from '../../CategoryModal/CategoryModal';

const PhotoToolsNavBar = () => {
  const store = useStore();
  const history = useHistory();
  const { id: patientId, imageId } = useParams();
  const fileDate = store.galleryStore.image?.date?.split('T')[0];
  const [editModalShow, setEditModalShow] = useState(false);
  const [editImage, setEditImage] = useState({
    name: store.galleryStore.image?.fileName,
    date: fileDate,
  });
  const [edit, setEdit] = useState({});
  const [deleteModalShow, toggleDeleteModalShow] = useToggle(false);
  const [overlay, toggleOverlay] = useToggle(false);
  const [dimmer, toggleDimmer] = useToggle(false);
  const [show, toggleShow] = useToggle(false);

  useEffect(() => {
    if (!edit.id && store.galleryStore.image) {
      setEdit({ ...edit, id: store.galleryStore.imageId });
    }
  }, [store.galleryStore.image]);

  const deletePhoto = async () => {
    const res = await store.galleryStore.deleteImage(imageId);
    if (res) {
      toggleDeleteModalShow();
      history.push(`/patients/${patientId}/library`);
      return;
    }
    toast.error('Confirmação inválida');
  };

  const handleImageTypeChange = async () => {
    store.galleryStore.setIsFetching(true);

    toggleShow(false);

    if (!edit.id || !edit.type) return;
    
    const result = await store.galleryStore.changeTypeImage(edit.id, edit.type);
    
    if (result) {
      toast('Alterado com sucesso');
    }

    store.galleryStore.setIsFetching(false);
  };

  const submitEdit = async () => {
    const payload = {
      file_name: editImage.name,
      date: editImage.date,
    };
    if (isAfter(new Date(payload.date), new Date())) {
      toast.error('Data inválida');
      return false;
    }
    await store.galleryStore.updateImage(payload, store.galleryStore.imageId);
    setEditModalShow(false);
    return true;
  };

  const saveNote = useCallback(async (payload) => {
    await store.galleryStore.updateImage(payload);
  }, []);

  const menu = [
    {
      key: '0',
      label: 'Editar arquivo',
      action: () => setEditModalShow(true),
    },
    {
      key: '1',
      label: 'Ver detalhes',
      action: () => toggleDimmer(),
    },
    {
      key: '2',
      label: 'Definir categoria',
      action: () => toggleShow(),
    },
    {
      key: '3',
      label: 'Anotações',
      action: () => toggleOverlay(),
    },
    {
      key: '4',
      label: 'Excluir',
      action: () => toggleDeleteModalShow(),
    },
  ];

  const handleEdit = (e) => {
    setEdit({ ...edit, type: e });
  };

  return (
    <>
      <div className='d-flex col-12 px-0 border-bottom'>
        <div className='d-none d-sm-flex flex-fill justify-content-center'>
          <NavbarHeader
            hasTitle
            title={store.patientsStore.patientSelected?.name}
            content='nav'
            hasHelper
          />
        </div>
        <div className='d-flex justify-content-between d-sm-none flex-fill'>
          <NavbarHeaderMobile
            hasGoBack
            hasMenu
            goBackAction={() =>
              history.push(
                `/patients/${patientId}/appointment/${
                  store.galleryStore?.image?.albumId || ''
                }`,
              )
            }
            menuOptions={menu}
            content='title'
            title={store.galleryStore.image?.fileName}
          />
        </div>

        <AnnotationOverlay
          overlay={overlay}
          toggleOverlay={toggleOverlay}
          title={store.galleryStore.image?.fileName}
          saveAction={saveNote}
          value={store.galleryStore.image?.obs}
          loading={store.galleryStore.isFetching}
        />
        <CategoryModal
          showModal={show}
          handleImageTypeChange={() => handleImageTypeChange()}
          handleEdit={handleEdit}
          toggleModal={toggleShow}
          disabled={store.galleryStore.isFetching}
        />
        <ModalComponent
          show={deleteModalShow}
          isLoading={store.galleryStore.isFetching}
          disabled={store.galleryStore.isFetching}
          onClose={() => toggleDeleteModalShow()}
          title={`Excluir arquivo ${store.galleryStore.image?.fileName} ?`}
          subTitle={`Você tem certeza que deseja excluir  ${store.galleryStore.image?.fileName}? Essa ação não poderá ser desfeita.`}
          nameButton='Excluir'
          idButton='delete-file'
          isDelete
          onClickButton={deletePhoto}
        />

        {/* modal editar nome do album */}
        <ModalComponent
          show={editModalShow}
          onClose={() => setEditModalShow(false)}
          title='Editar arquivo'
          isLoading={store.galleryStore.isFetching}
          disabled={store.galleryStore.isFetching}
          nameButton='Editar'
          idButton='edit-file'
          onClickButton={submitEdit}
        >
          <Input
            value={editImage?.name}
            name='editAlbum'
            id='editAlbum'
            label='Nome do arquivo'
            onChange={(e) =>
              setEditImage((prev) => ({ ...prev, name: e.target.value }))
            }
            placeholder={store.galleryStore.image?.fileName}
            maxLength={255}
          />
          <Input
            value={editImage?.date}
            name='date'
            id='date'
            type='date'
            label='Data do arquivo'
            max={format(new Date(), 'yyyy-MM-dd')}
            onChange={(e) =>
              setEditImage((prev) => ({ ...prev, date: e.target.value }))
            }
            placeholder='DD/MM/AAAA'
          />
        </ModalComponent>
      </div>
      <DimmerDetails onClose={toggleDimmer} show={dimmer}>
        <div>
          <span style={{ fontWeight: 'bold' }}>Detalhes</span>
          <div
            className='text-white p-1  d-flex flex-column'
            style={{ fontSize: '0.8rem' }}
          >
            <span className='mx-2 py-2'>
              {store.galleryStore.image?.date &&
                format(new Date(store.galleryStore.image?.date), 'dd/MM/yyyy')}
            </span>

            <span className='mx-2'>{store.galleryStore.image?.fileName}</span>
          </div>
        </div>
      </DimmerDetails>
    </>
  );
};

export default observer(PhotoToolsNavBar);
