const AlbumFolder = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.742 4.5H20.715C21.1319 4.49996 21.5442 4.5868 21.9256 4.75499C22.3071 4.92317 22.6493 5.16902 22.9304 5.47683C23.2116 5.78465 23.4255 6.14768 23.5585 6.54276C23.6915 6.93785 23.7407 7.35633 23.703 7.7715L22.7475 18.2715C22.6797 19.0169 22.3358 19.71 21.7833 20.2148C21.2307 20.7197 20.5094 20.9997 19.761 21H4.23898C3.49054 20.9997 2.76923 20.7197 2.21668 20.2148C1.66412 19.71 1.32021 19.0169 1.25248 18.2715L0.29698 7.7715C0.233059 7.07667 0.414571 6.38141 0.80998 5.8065L0.74998 4.5C0.74998 3.70435 1.06605 2.94129 1.62866 2.37868C2.19127 1.81607 2.95433 1.5 3.74998 1.5H9.25798C10.0536 1.50017 10.8165 1.81635 11.379 2.379L12.621 3.621C13.1835 4.18365 13.9464 4.49983 14.742 4.5ZM2.25898 4.68C2.57998 4.563 2.92498 4.5 3.28498 4.5H11.379L10.3185 3.4395C10.0372 3.15818 9.65577 3.00008 9.25798 3H3.74998C3.35704 2.99993 2.97977 3.15405 2.69927 3.42922C2.41877 3.70439 2.25745 4.07864 2.24998 4.4715L2.25898 4.68Z'
      fill={color}
      fillOpacity='0.6'
    />
  </svg>
);
export default AlbumFolder;
