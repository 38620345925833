const Clock = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M15.9656 1.91063C15.6443 1.8869 15.3222 1.87502 15 1.875V0C15.3685 0.000179677 15.7369 0.013939 16.1044 0.04125L15.9656 1.91063ZM19.7231 2.75437C19.1221 2.52245 18.5048 2.33506 17.8763 2.19375L18.2869 0.36375C19.005 0.525 19.7119 0.73875 20.3981 1.005L19.7231 2.75437ZM22.2919 4.08562C22.0238 3.90684 21.7492 3.73798 21.4687 3.57938L22.3931 1.94813C23.0342 2.31138 23.6477 2.72123 24.2288 3.17437L23.0756 4.65375C22.8214 4.45543 22.56 4.26655 22.2919 4.0875V4.08562ZM25.7306 7.44187C25.3597 6.91495 24.9505 6.4161 24.5062 5.94938L25.8637 4.65562C26.37 5.19 26.8388 5.76187 27.2644 6.36187L25.7306 7.44187ZM27.1256 9.97688C27.0026 9.67961 26.8687 9.38692 26.7244 9.09938L28.3988 8.25562C28.7299 8.91391 29.0119 9.59577 29.2425 10.2956L27.4613 10.8825C27.3605 10.5766 27.2485 10.2745 27.1256 9.97688ZM28.1194 14.6775C28.1042 14.0331 28.0415 13.3908 27.9319 12.7556L29.7787 12.4369C29.9044 13.1606 29.9775 13.8956 29.9963 14.6306L28.1213 14.6775H28.1194ZM27.8738 17.5613C27.9356 17.2425 27.9862 16.9256 28.0256 16.605L29.8875 16.8356C29.7974 17.5672 29.6533 18.291 29.4563 19.0012L27.6488 18.5006C27.735 18.1912 27.81 17.8781 27.8738 17.5613ZM26.0887 22.0219C26.4337 21.4781 26.7375 20.9081 27 20.3194L28.7137 21.0787C28.4138 21.7537 28.0669 22.4025 27.6731 23.025L26.0887 22.0219ZM24.2812 24.2812C24.51 24.0525 24.7294 23.8162 24.9375 23.5725L26.3587 24.7969C26.1181 25.0759 25.8673 25.3461 25.6069 25.6069L24.2812 24.2812Z'
      fill={color}
    />
    <path
      d='M15 1.875C12.8416 1.87516 10.7166 2.40759 8.81321 3.42514C6.90977 4.44268 5.28665 5.91394 4.0876 7.70859C2.88856 9.50325 2.15061 11.5659 1.93911 13.7139C1.72761 15.8618 2.04909 18.0288 2.87508 20.0228C3.70107 22.0169 5.00606 23.7765 6.67448 25.1457C8.34291 26.515 10.3233 27.4516 12.4401 27.8728C14.557 28.2939 16.745 28.1864 18.8105 27.56C20.8759 26.9335 22.755 25.8074 24.2812 24.2812L25.6069 25.6069C23.8626 27.3521 21.7148 28.6401 19.3538 29.3569C16.9928 30.0736 14.4914 30.1969 12.0714 29.7159C9.65129 29.2349 7.38723 28.1643 5.47978 26.5992C3.57232 25.034 2.08038 23.0225 1.13612 20.7429C0.191873 18.4633 -0.175528 15.986 0.0664742 13.5305C0.308476 11.075 1.15241 8.71706 2.5235 6.66566C3.89459 4.61426 5.7505 2.93271 7.9268 1.77001C10.1031 0.607303 12.5326 -0.000653873 15 5.27734e-07V1.875Z'
      fill={color}
    />
    <path
      d='M14.0625 5.625C14.3111 5.625 14.5496 5.72377 14.7254 5.89959C14.9012 6.0754 15 6.31386 15 6.5625V16.3313L21.09 19.8113C21.2996 19.9377 21.4514 20.1411 21.5131 20.378C21.5747 20.615 21.5412 20.8666 21.4197 21.0791C21.2983 21.2917 21.0985 21.4483 20.8631 21.5155C20.6277 21.5827 20.3754 21.5551 20.16 21.4388L13.5975 17.6888C13.454 17.6068 13.3347 17.4884 13.2518 17.3455C13.1688 17.2025 13.1251 17.0402 13.125 16.875V6.5625C13.125 6.31386 13.2238 6.0754 13.3996 5.89959C13.5754 5.72377 13.8139 5.625 14.0625 5.625Z'
      fill={color}
    />
  </svg>
);

export default Clock;
