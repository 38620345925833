const UserDocsOptions = [
  { key: 'CRM', value: 'CRM', text: 'CRM' },
  { key: 'CRO', value: 'CRO', text: 'CRO' },
];

const UserTranslation = {
  CRM: {
    PTBR: 'Médico',
  },
  CRO: {
    PTBR: 'Dentista',
  },
};
export { UserTranslation, UserDocsOptions };
