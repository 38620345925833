import authStore from './authStore';
import albumStore from './albumStore';
import analysisStore from './analysisStore';
import comparativeStore from './comparativeStore';
import dotsStore from './dotsStore';
import galleryStore from './galleryStore';
import patientsStore from './patientsStore';
import timelapseStore from './timelapseStore';
import toolsStore from './toolsStore';
import uiStore from './uiStore';
import checkoutStore from './checkoutStore';
import DefaultStore from './defaultStore';
import calendarStore from './calendarStore';
import tutorialStore from './tutorialStore';

const defaultStore = new DefaultStore() 

export {
  authStore,
  albumStore,
  analysisStore,
  checkoutStore,
  comparativeStore,
  dotsStore,
  galleryStore,
  patientsStore,
  timelapseStore,
  toolsStore,
  uiStore,
  defaultStore,
  calendarStore,
  tutorialStore
};
