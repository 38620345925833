const Download = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '', color = '#383C42', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.6875 13.6123C0.869836 13.6123 1.0447 13.6847 1.17364 13.8137C1.30257 13.9426 1.375 14.1175 1.375 14.2998V17.7373C1.375 18.102 1.51987 18.4517 1.77773 18.7096C2.03559 18.9674 2.38533 19.1123 2.75 19.1123H19.25C19.6147 19.1123 19.9644 18.9674 20.2223 18.7096C20.4801 18.4517 20.625 18.102 20.625 17.7373V14.2998C20.625 14.1175 20.6974 13.9426 20.8264 13.8137C20.9553 13.6847 21.1302 13.6123 21.3125 13.6123C21.4948 13.6123 21.6697 13.6847 21.7986 13.8137C21.9276 13.9426 22 14.1175 22 14.2998V17.7373C22 18.4666 21.7103 19.1661 21.1945 19.6818C20.6788 20.1976 19.9793 20.4873 19.25 20.4873H2.75C2.02065 20.4873 1.32118 20.1976 0.805456 19.6818C0.289731 19.1661 0 18.4666 0 17.7373V14.2998C0 14.1175 0.0724328 13.9426 0.201364 13.8137C0.330295 13.6847 0.505164 13.6123 0.6875 13.6123Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5131 16.2992C10.577 16.3633 10.6529 16.4141 10.7364 16.4487C10.8199 16.4834 10.9095 16.5012 10.9999 16.5012C11.0903 16.5012 11.1799 16.4834 11.2634 16.4487C11.3469 16.4141 11.4228 16.3633 11.4866 16.2992L15.6116 12.1742C15.7407 12.0452 15.8133 11.8701 15.8133 11.6875C15.8133 11.5049 15.7407 11.3298 15.6116 11.2008C15.4825 11.0717 15.3075 10.9991 15.1249 10.9991C14.9423 10.9991 14.7672 11.0717 14.6381 11.2008L11.6874 14.1529V2.0625C11.6874 1.88016 11.615 1.7053 11.486 1.57636C11.3571 1.44743 11.1822 1.375 10.9999 1.375C10.8176 1.375 10.6427 1.44743 10.5138 1.57636C10.3848 1.7053 10.3124 1.88016 10.3124 2.0625V14.1529L7.36164 11.2008C7.23255 11.0717 7.05746 10.9991 6.87489 10.9991C6.69233 10.9991 6.51724 11.0717 6.38814 11.2008C6.25905 11.3298 6.18652 11.5049 6.18652 11.6875C6.18652 11.8701 6.25905 12.0452 6.38814 12.1742L10.5131 16.2992Z'
      fill={color}
    />
  </svg>
);
export default Download;
