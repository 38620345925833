const convertRouteToCamelCase = (route)=>{
    
    if(route === '/'){
        return 'home'
    }
    
    let routeArray = route.split('/').filter((el)=> el[0] && Number.isNaN(parseInt(el,10)))

    routeArray = routeArray.map((item, i)=>{
     let camelCase = item
     if(i !== 0){
         camelCase = item?.replace(item[0], item[0].toUpperCase())   
     }
     return camelCase
    })

    return routeArray.join('')
 }

 export default convertRouteToCamelCase;