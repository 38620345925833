import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import IconComponent from '../../Svg/IconComponent/IconComponent';


/**
 * @param {import('react-bootstrap').ButtonProps} props
 * @param {string} [props.title='Avançar']
 * @returns {JSX.Element}
 */
export default function NextButton(props) {
  const { title = 'Avançar', ...rest } = props;

  let { style } = rest;

  if (rest.disabled) {
    if (typeof style === 'string') {
      style = `${style};background:gray;border-color:gray;`;
    } else if (typeof style === 'object' || typeof style === 'undefined') {
      style = { ...(style ?? {}), background: 'gray', borderColor: 'gray' };
    }
  }

  return (
    <Button className='text-white' {...rest} {...{ style }}>
      <span className='d-flex justify-content-center'>
        {title}
        <div className='ms-2'>
          {rest.loading ? <Spinner size='sm' animation="border" >  <span className="visually-hidden">Loading...</span></Spinner>: 
          <IconComponent
          width={16}
          height={16}
          group='standard'
          name='chevronright'
          />
        }
        </div>
      </span>
    </Button>
  );
}

NextButton.defaultProps = {
  title: 'Avançar',
};

NextButton.propTypes = {
  title: PropTypes.string,
};
