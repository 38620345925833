import {TutorialContext} from '../../../stores/contexts/TutorialContext'
import TutorialButton from "../TutorialButton/TutorialButton"
import TutorialModal from "../TutorialModal/TutorialModal"
import WelcomeTutorialModal from '../WelcomeTutorialModal/WelcomeTutorialModal'

const TutorialContainer = () => (
        <TutorialContext>
            <TutorialButton />           
            <TutorialModal/>
            <WelcomeTutorialModal/>            
        </TutorialContext>
    )


export default TutorialContainer;