const PHOTO_TYPES = [
  {
    key: 'front',
    value: 'front',
    text: 'Frontal',
  },
  {
    key: 'left',
    value: 'left',
    text: 'Perfil Esquerdo',
  },
  {
    key: 'right',
    value: 'right',
    text: 'Perfil Direito',
  },
];

const PHOTO_TRANSLATION = {
  front: {
    pt: 'Frontal',
  },
  left: {
    pt: 'Perfil Esquerdo',
  },
  right: {
    pt: 'Perfil Direito',
  },
};

export { PHOTO_TRANSLATION, PHOTO_TYPES };