const QuestionIcon = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#6c757d', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM9.855 9.0495H7.875C7.83 6.2205 10.02 5.25 12.009 5.25C14.1045 5.25 16.0185 6.345 16.0185 8.61C16.0185 10.23 15.066 11.001 14.1525 11.6955C13.047 12.534 12.6375 12.8475 12.6375 13.9245V14.457H10.6755L10.665 13.7625C10.608 12.372 11.4075 11.5155 12.417 10.782C13.302 10.116 13.8645 9.678 13.8645 8.7255C13.8645 7.488 12.9225 6.9735 11.8935 6.9735C10.542 6.9735 9.8565 7.878 9.8565 9.0495H9.855ZM11.7315 18.714C10.8555 18.714 10.218 18.123 10.218 17.3235C10.218 16.4955 10.8555 15.9135 11.733 15.9135C12.6465 15.9135 13.275 16.4955 13.275 17.3235C13.275 18.123 12.645 18.714 11.7315 18.714Z'
      fill={color}
    />
  </svg>
);
export default QuestionIcon;
