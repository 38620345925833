import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col, Row } from 'react-bootstrap';
import { useMeasure, useToggle } from 'react-use';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';
import FileBox from '../../PhotoLibrary/ImageList/FileBox';
import { PHOTO_TRANSLATION } from '../../../constants/PhotoTypes';
import EmptyStateTools from '../EmptyStateTools/EmptyStateTools';
import StandardLoader from '../../Styleguide/StandardLoader/StandardLoader';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';

import { useStore } from '../../../hooks';
import LoadingSpinner from '../../Styleguide/LoadingSpinner/LoadingSpinner';
import useFileListData from '../../FileList/useFileListData';

const ScrollImageList = ({
	selectedImages,
	selectAction,
	isLoading,
	selectable,
	margin,
}) => {
	const [ref, { height }] = useMeasure();
	const [infoModal, toggleInfoModal] = useToggle(false);
	const {galleryStore} = useStore()
	const {handleFetchNextPage} = useFileListData()

	
	if (galleryStore.imageList.length === 0 && isLoading) {
		return <StandardLoader />;
	}
	
	return (
		<div
			className='d-flex flex-column align-items-stretch align-content-stretch h-100  mb-5 mb-sm-0'
			ref={ref}
		>

			<InfiniteScroll 
				horizontal={false}
				dataLength={galleryStore.imageList.length}
				height={height - 20 - margin}
				next={handleFetchNextPage}
				hasMore={
					galleryStore.currentPage < galleryStore.lastPage
				}
				className=' bg-light'
				loader={
					<div className='d-flex justify-content-center w-100 py-2'>
						<LoadingSpinner desc='' />
					</div>
				}
			>
				<Row className='col-12 justify-content-start align-items-start d-flex align-content-start py-2'>
					{selectedImages.map((v) => (
						<Col
							key={v.id}
							className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 mb-4 position-relative'
						>
							<FileBox
								fileBoxProps={{
									handleClickFile: true,
									hasFileMenu: false,
								}}
								key={v.id}
								type={PHOTO_TRANSLATION[v.imageType]?.pt}
								src={v.media}
								fileType={v.fileExt}
								date={v.date}
								select={() => selectAction(v)}
								imgSelected={selectable}
								selectable={selectable}
								analysis={!!v.analysis}
								id={v.id}
							/>
						</Col>
					))}
					{galleryStore.imageList?.length > 0
						? galleryStore.imageList
								.filter((v) => v.fileType === 'image')
								.map((v, i) => (
									<Fragment key={v.id}>
										{selectedImages.findIndex((obj) => obj.id === v.id) ===
										-1 ? (
											<Col
												key={v.id}
												className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 mb-4  position-relative'
											>
												<FileBox
													fileBoxProps={{
														handleClickFile: true,
														hasFileMenu: false,
													}}
													key={v.id}
													type={PHOTO_TRANSLATION[v.imageType]?.pt}
													src={v.media}
													fileType={v.fileExt}
													date={v.date}
													select={() =>
														v.hasRightProportion
															? selectAction(v)
															: toggleInfoModal()
													}
													selectable={selectable && v.hasRightProportion}
													analysis={!!v.analysis}
													disabledButton={!v.hasRightProportion}
													id={`id-${i}`}
												/>
											</Col>
										) : null}
									</Fragment>
								))
						: null}

					{(galleryStore.imageList?.length === 0 && !selectable) ||
					(selectable &&
						galleryStore.imageList?.length === 0 &&
						selectedImages?.length === 0) ? (
						<EmptyStateTools />
					) : null}
					
				</Row>
			</InfiniteScroll>

			<ModalComponent
				title='Comparativo de fotos'
				subTitle={
					<span>
						A foto selecionada não pode ser comparada pois não está na proporção
						necessária. <br /> Para utilizá-la, faça ajustes na foto.
					</span>
				}
				hasButton={false}
				show={infoModal}
				onClose={toggleInfoModal}
			/>
		</div>
	);
};

ScrollImageList.propTypes = {
	selectedImages: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool,
	selectAction: PropTypes.func,
	selectable: PropTypes.bool,
	margin: PropTypes.number,
};
ScrollImageList.defaultProps = {
	selectedImages: [],
	isLoading: false,
	selectAction: () => {},
	selectable: false,
	margin: 0,
};

export default observer(ScrollImageList);
