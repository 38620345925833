import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useIsMobile, useStore } from '../../../hooks';
import ScrollImageList from '../ScrollImageList/ScrollImageList';
import SearchInput from '../../Styleguide/SearchInput/SearchInput';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';
import FixedBottomButton from '../../Styleguide/FixedBottomButton/FixedBottomButton';

const ComparativeSelect = ({ selectedId, height }) => {
  const { galleryStore, toolsStore, albumStore } = useStore();

  const history = useHistory();

  const isMobile = useIsMobile();

  const { id: patientId, albumId } = useParams();


  const handleSelect = (obj) => {
    toolsStore.setImagesComparative(obj);
  };

  const handleNext = () => {
    history.push(`/patients/${patientId}/comparative-view`);
  };

  const handleGoToAlbum = () => {
    history.push(`/patients/${patientId}/select-album`);
  };

  useEffect(() => {
    galleryStore.resetCurrentPage()
    galleryStore.setLastPage(1)

    if (albumId != null || selectedId != null) {
      (async () => {
        albumStore.setAlbumSelected(selectedId || albumId);
        
        await galleryStore.getListImage(patientId, albumId);
      })();
    }else{
      galleryStore.getListImage(patientId);

    }
    return () => {
      // toolsStore.cleanImageComparative()
      albumStore.setAlbumSelected(null);
      galleryStore.setImageListSelected(null);
      galleryStore.clearFilteredAlbumImage();
      galleryStore.resetCurrentPage()
    };
  }, [albumId, selectedId]);

  return (
    <>
      <div className='py-3 px-3 p-sm-0 text-center d-flex flex-column align-content-stretch align-items-stretch w-100 h-100'>
        {selectedId === null && isMobile && (
          <div className='mb-2 d-flex flex-fill justify-content-between align-items-center'>
            <IconComponent
              group='standard'
              name='chevronleft'
              onClick={handleGoToAlbum}
              color={COLORS.dark}
            />
            <h4 className='fs-6 py-2 m-0'>{`Selecione a foto ${
              toolsStore.imagesComparative?.length < 1 ? '1 ' : '2'
            } de 2 para o comparativo`}</h4>
            <div />
          </div>
        )}
        {isMobile && (
          <>
            <SearchInput
              value={galleryStore.keyWord}
              placeholder='Buscar por nome da imagem'
              onChange={async(e) => {
                galleryStore.resetCurrentPage()

                galleryStore.setKeyWord(e.target.value)
        
                  await galleryStore.filterAlbumImage(
                    e.target.value,
                    patientId,
                    selectedId || albumId,
                  );
                
              }}
            />
            <hr style={{ height: '1px' }} className='bg-grey mb-0' />
          </>
        )}
            <ScrollImageList
              selectedImages={toolsStore.imagesComparative}
              isLoading={galleryStore.isFetching}
              selectAction={handleSelect}
              selectable
              margin={height}
            />

      </div>
      <div className='d-flex fixed-bottom shadow-lg bg-white justify-content-center zi-2 '>
        <FixedBottomButton
          disabled={toolsStore.imagesComparative?.length < 2}
          label='Iniciar comparativo'
          icon={{
            color: COLORS.white,
            size: 24,
            group: 'analysis',
            name: 'compareBoard',
          }}
          handleClick={() => handleNext()}
        />
      </div>
    </>
  );
};

export default observer(ComparativeSelect);

ComparativeSelect.propTypes = {
  selectedId: PropTypes.number,
  height: PropTypes.number,
};

ComparativeSelect.defaultProps = {
  selectedId: null,
  height: null,
};
