const MagicPencil = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 31 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M11.4205 9.70251C11.4205 10.3468 10.8965 10.8713 10.2474 10.8713C9.59824 10.8713 9.07422 10.3468 9.07422 9.70251C9.07422 9.05821 9.59824 8.53369 10.2474 8.53369C10.8965 8.53369 11.4205 9.05821 11.4205 9.70251Z'
      fill='white'
      stroke={color}
    />
    <path
      d='M20.305 4.30786C21.229 4.30786 21.9781 3.5607 21.9781 2.63904C21.9781 1.71737 21.229 0.970215 20.305 0.970215C19.3809 0.970215 18.6318 1.71737 18.6318 2.63904C18.6318 3.5607 19.3809 4.30786 20.305 4.30786Z'
      fill={color}
    />
    <path
      d='M27.387 11.837C28.3111 11.837 29.0602 11.0899 29.0602 10.1682C29.0602 9.24655 28.3111 8.49939 27.387 8.49939C26.463 8.49939 25.7139 9.24655 25.7139 10.1682C25.7139 11.0899 26.463 11.837 27.387 11.837Z'
      fill={color}
    />
    <path
      d='M26.784 20.2588C28.3312 20.2588 29.5855 19.0077 29.5855 17.4645C29.5855 15.9212 28.3312 14.6702 26.784 14.6702C25.2367 14.6702 23.9824 15.9212 23.9824 17.4645C23.9824 19.0077 25.2367 20.2588 26.784 20.2588Z'
      fill={color}
    />
    <path
      d='M4.91615 30C4.18908 29.9948 3.49211 29.7098 2.97063 29.2044L1.33639 27.5744C0.823144 27.0583 0.535156 26.3608 0.535156 25.6339C0.535156 24.907 0.823144 24.2095 1.33639 23.6934L14.9551 10.11L20.5387 15.524L6.92005 29.1074C6.66141 29.3805 6.35125 29.5999 6.00736 29.7531C5.66348 29.9062 5.29265 29.9902 4.91615 30ZM14.9745 12.7685L2.6788 25.013C2.52977 25.1742 2.44703 25.3855 2.44703 25.6048C2.44703 25.8241 2.52977 26.0354 2.6788 26.1967L4.31304 27.8267C4.47471 27.9753 4.68655 28.0578 4.90643 28.0578C5.12631 28.0578 5.33814 27.9753 5.49981 27.8267L17.7955 15.524L14.9745 12.7685Z'
      fill={color}
    />
    <path
      d='M19.1572 16.8822L13.6514 11.3906L18.165 6.88871C18.6494 6.41114 19.303 6.14331 19.984 6.14331C20.6651 6.14331 21.3187 6.41114 21.8031 6.88871L23.7486 8.8292C23.9898 9.06417 24.1815 9.34486 24.3123 9.65478C24.4432 9.96469 24.5106 10.2976 24.5106 10.6339C24.5106 10.9701 24.4432 11.303 24.3123 11.6129C24.1815 11.9229 23.9898 12.2035 23.7486 12.4385L19.1572 16.8822ZM16.2973 11.3906L19.1572 14.2626L22.3673 11.0608C22.4326 10.9959 22.4844 10.9187 22.5198 10.8338C22.5551 10.7489 22.5733 10.6579 22.5733 10.5659C22.5733 10.474 22.5551 10.383 22.5198 10.298C22.4844 10.2131 22.4326 10.136 22.3673 10.0711L20.4218 8.13062C20.3581 8.06307 20.2813 8.00924 20.1959 7.97244C20.1106 7.93564 20.0186 7.91666 19.9257 7.91666C19.8327 7.91666 19.7408 7.93564 19.6554 7.97244C19.5701 8.00924 19.4932 8.06307 19.4296 8.13062L16.2973 11.3906Z'
      fill={color}
    />
    <path
      d='M12.8149 5.74386C14.4052 5.74386 15.6943 4.45805 15.6943 2.87193C15.6943 1.28581 14.4052 0 12.8149 0C11.2247 0 9.93555 1.28581 9.93555 2.87193C9.93555 4.45805 11.2247 5.74386 12.8149 5.74386Z'
      fill={color}
    />
    <path
      d='M27.6206 5.74386C29.2108 5.74386 30.5 4.45805 30.5 2.87193C30.5 1.28581 29.2108 0 27.6206 0C26.0304 0 24.7412 1.28581 24.7412 2.87193C24.7412 4.45805 26.0304 5.74386 27.6206 5.74386Z'
      fill={color}
    />
  </svg>
);

export default MagicPencil;
