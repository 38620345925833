const FailCircle = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '16', color = '#6c757d', ...rest },
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    fill={color}
    viewBox='0 0 24 24'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5V22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24V24Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.781 6.21897C17.8508 6.28864 17.9062 6.3714 17.944 6.46252C17.9818 6.55363 18.0013 6.65132 18.0013 6.74997C18.0013 6.84862 17.9818 6.9463 17.944 7.03742C17.9062 7.12854 17.8508 7.2113 17.781 7.28097L7.28097 17.781C7.14014 17.9218 6.94913 18.0009 6.74997 18.0009C6.55081 18.0009 6.3598 17.9218 6.21897 17.781C6.07814 17.6401 5.99902 17.4491 5.99902 17.25C5.99902 17.0508 6.07814 16.8598 6.21897 16.719L16.719 6.21897C16.7886 6.14912 16.8714 6.09371 16.9625 6.0559C17.0536 6.01809 17.1513 5.99863 17.25 5.99863C17.3486 5.99863 17.4463 6.01809 17.5374 6.0559C17.6285 6.09371 17.7113 6.14912 17.781 6.21897V6.21897Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.21888 6.21897C6.14903 6.28864 6.09362 6.3714 6.05581 6.46252C6.018 6.55363 5.99854 6.65132 5.99854 6.74997C5.99854 6.84862 6.018 6.9463 6.05581 7.03742C6.09362 7.12854 6.14903 7.2113 6.21888 7.28097L16.7189 17.781C16.8597 17.9218 17.0507 18.0009 17.2499 18.0009C17.449 18.0009 17.64 17.9218 17.7809 17.781C17.9217 17.6401 18.0008 17.4491 18.0008 17.25C18.0008 17.0508 17.9217 16.8598 17.7809 16.719L7.28088 6.21897C7.21121 6.14912 7.12844 6.09371 7.03733 6.0559C6.94621 6.01809 6.84853 5.99863 6.74988 5.99863C6.65122 5.99863 6.55354 6.01809 6.46243 6.0559C6.37131 6.09371 6.28854 6.14912 6.21888 6.21897V6.21897Z'
      fill={color}
    />
  </svg>
);
export default FailCircle;
