import ModalComponent from "../../Styleguide/ModalComponent/ModalComponent"
import SkipTutorialButton from "../SkipTutorialButton/SkipTutorialButton";
import { useTutorialContext } from '../../../stores/contexts/TutorialContext';
import DontShowTutorialCheckbox from '../DontShowTutorialCheckbox/DontShowTutorialCheckbox'
import YoutubeEmbedUrl from "../../../utils/youtubeEmbedUrl";

const TutorialModal = () => {
    const {isModalOpened, setIsModalOpened, tutorialInfo} = useTutorialContext()

    return(
        <>
        <ModalComponent show={isModalOpened} title={tutorialInfo?.name} noFooter closeButton={false} bodyClassName= 'justify-content-center d-flex flex-column' titleClassName='text-center fw-bold w-100 fs-5-6 text-truncate'>
            <div className="p-lg-5">
                <div style={{ height: '250px', overflow: 'hidden', position: 'relative' }} className="w-100">
                    <iframe
                        className="w-100 h-100 position-absolute"
                        src={YoutubeEmbedUrl(tutorialInfo?.link)}
                        title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                        >
                    </iframe>
                </div>
            </div>
            <p className='p-3 fs-7 col-11 mx-auto my-1'>{tutorialInfo?.description}</p>
            <SkipTutorialButton />
            <DontShowTutorialCheckbox videoId={tutorialInfo?.id}/>
        </ModalComponent>
        </>
    )
}

export default TutorialModal;