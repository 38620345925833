import { Button, Container } from "react-bootstrap";
import IconComponent from "../../Svg/IconComponent/IconComponent";
import { useTutorialContext } from '../../../stores/contexts/TutorialContext'
import truncateTextBlock from "../../../utils/truncateTextBlock";
import getTutorialVideoThumb from "../../../utils/getTutorialVideoThumb";

const TutorialCard = () => {


    const {isCardOpened,setIsCardOpened, setIsModalOpened, tutorialInfo} = useTutorialContext()

    return(
        
        <Container className = {isCardOpened ? `d-flex  p-2 col-12 zi-9 rounded-gout-sm-10` : 'd-none'}>
           <Button className='gap-1 p-1 reset-button d-flex' onClick = {() => setIsModalOpened(true)}>
                <img src={getTutorialVideoThumb(tutorialInfo?.link)} alt = 'thumbnail do vídeo de tutorial' className= 'col-4 col-md-2 my-auto'/>
                <p className= 'ps-2 fs-7 text-white text-start col-8 col-sm-9 col-md-10 my-md-auto overflow-hidden'>{truncateTextBlock(tutorialInfo?.description, 100)}</p>
            </Button>
            <IconComponent
                group='standard'
                name='closex'
                color='white'
                size='14'
                className ="col-md-1 mt-0 pb-0 pt-0 text-right d-none d-sm-block"
                onClick={()=> setIsCardOpened(false)}
            /> 
        </Container>

    )
}

export default TutorialCard;

