import React, { useMemo } from 'react';
import { Line } from 'react-konva';
import PropTypes from 'prop-types';

const JawAngle = ({ filteredDots, strokeWidth, stroke }) => {
  const GoD = filteredDots.find((dot) => dot.categoryId === 'Go_D');
  const GoE = filteredDots.find((dot) => dot.categoryId === 'Go_E');
  const TragusD = filteredDots.find((dot) => dot.categoryId === 'Tragus_D');
  const TragusE = filteredDots.find((dot) => dot.categoryId === 'Tragus_E');
  const Me = filteredDots.find((dot) => dot.categoryId === 'Me');
  const draw = useMemo(() => {
    if (GoE != null && TragusE != null && GoD != null && TragusD != null) {
      return null;
    }
    if (
      GoE == null &&
      TragusE == null &&
      GoD != null &&
      TragusD != null &&
      Me != null
    ) {
      return (
        <>
          <Line
            strokeWidth={strokeWidth}
            stroke={stroke}
            points={[Me?.x, Me?.y, GoD?.x, GoD?.y]}
          />
          <Line
            strokeWidth={strokeWidth}
            stroke={stroke}
            points={[GoD?.x, GoD?.y, TragusD?.x, TragusD?.y]}
          />
        </>
      );
    }
    if (
      GoE != null &&
      TragusE != null &&
      GoD == null &&
      TragusD == null &&
      Me != null
    ) {
      return (
        <>
          <Line
            strokeWidth={strokeWidth}
            stroke={stroke}
            points={[Me?.x, Me?.y, GoE?.x, GoE?.y]}
          />
          <Line
            strokeWidth={strokeWidth}
            stroke={stroke}
            points={[GoE?.x, GoE?.y, TragusE?.x, TragusE?.y]}
          />
        </>
      );
    }

    return null;
  }, [GoD, GoE, Me, TragusD, TragusE]);

  return draw;
};

JawAngle.propTypes = {

  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

JawAngle.defaultProps = {
  
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default JawAngle;
