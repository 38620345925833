import clsx from 'clsx';
import PropTypes from 'prop-types';
import { COLORS } from '../../../constants/Colors';
import IconComponent from '../../Svg/IconComponent/IconComponent';

const FeedbackBadge = ({ icon, text, textColor, subTitle, id }) => (
  <div
    className={clsx(
      'd-flex flex-fill flex-column text-center py-4 justify-content-center align-items-center',
      textColor != null && `text-${textColor}`,
    )}
    id = {id}
  >
    <IconComponent
      group={icon.group}
      name={icon.name}
      color={icon.color}
      size={icon.size}
    />
    <span className='my-2'>{text}</span>
    <span className='fs-7 lh-1'>{subTitle}</span>
  </div>
);

export default FeedbackBadge;
FeedbackBadge.propTypes = {
  icon: PropTypes.shape({
    group: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
  }),
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textColor: PropTypes.string,
  subTitle: PropTypes.string,
  id: PropTypes.string,
};

FeedbackBadge.defaultProps = {
  icon: {
    group: 'standard',
    name: 'done',
    color: COLORS.success,
    size: 80,
  },
  text: null,
  textColor: null,
  subTitle: null,
  id: '',
};
