import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ColorSquare from './ColorSquare/ColorSquare';
import useColorPicker from '../HomeScreen/useColorPicker';

const ColorPickerModal = ({ defaultColor = 'blue', handleChange }) => {
	const { handleShow, handleClick, show, selectedColor } =
		useColorPicker(defaultColor);

	return (
		<>
			<button
				alt='Seletor de cor'
				id='btn-color-picker'
				type='button'
				onClick={handleShow}
				className={`reset-button col-2 rounded color-picker-bg-${
					selectedColor ? selectedColor : defaultColor
				} mt-3`}
				style={{ width: '30px', height: '30px' }}
			/>

			<Card className={`border-0 ${show ? 'd-block' : 'd-none'} p-0`}>
				<Card.Body className='container p-0 col-6 col-lg-8'>
					<div className='border rounded  row row-cols-3 d-flex justify-content-center p-0-5'>
						<ColorSquare
							handleClick={(color) => {
								handleClick(color);
								handleChange(color);
							}}
							colors={['blue', 'green', 'gray', 'purple', 'red', 'yellow']}
							selectedColor={selectedColor ? selectedColor : defaultColor}
						/>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};

export default ColorPickerModal;


ColorPickerModal.propTypes = {
	defaultColor: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
};
