import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useStore } from '../../../hooks';

import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';

const RemovePatientAvatar = ({ open, toggleOpen }) => {
  const store = useStore();

  const handleRemoveAvatar = () => {
    const removeAv = store.patientsStore.resetPatientAvatar();

    if (removeAv) {
      toggleOpen();
    }
  };

  return (
    <ModalComponent
      show={open}
      onClose={toggleOpen}
      title='Remover foto de perfil'
      subTitle='Tem certeza que deseja remover a foto de perfil?'
      nameButton='Remover'
      idButton='delete-prf-photo'
      onClickButton={() => handleRemoveAvatar()}
    />
  );
};

export default observer(RemovePatientAvatar);

RemovePatientAvatar.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

RemovePatientAvatar.defaultProps = {
  open: false,
  toggleOpen: () => {},
};
