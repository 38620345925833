import heic2any from 'heic2any';

async function convertHeicToJpg(file) {
	const stringTypeFile = file?.name?.slice(
		file?.name?.indexOf('.') + 1,
		file?.name?.length,
	);

	const fileNameWithouExt = file?.name?.slice(0, file?.name?.indexOf('.'));

	if (
		file?.type === 'image/heic' ||
		file?.type === 'image/heif' ||
		stringTypeFile === 'heic' ||
		stringTypeFile === 'heif'
	) {
		try {
			const resultBlob = await heic2any({
				blob: file,
				toType: 'image/jpg',
			});
			const newFile = new File([resultBlob], `${fileNameWithouExt}.jpg`, {
				type: 'image/jpeg',
				lastModified: new Date().getTime(),
			});

			return Promise.resolve(newFile);
		} catch (x) {
			return Promise.reject(x.message);
		}
	}

	return Promise.resolve(file);
}

export default convertHeicToJpg;
