/* eslint-disable camelcase */
import { action, makeObservable, observable } from 'mobx';
import { mobileAvatar } from '../../assets/images/authentication';

export default class User {
	id = null;

	name = null;

	email = null;

	hash = null;

	image = null;

	plan = {};

	apiToken = null;

	/** @type {number} */
	imageVersion = 0;

	not_show_modal = false;

	personalFolder = NaN;

	activeStatus = null;

	show_onboarding_videos = true;

	constructor(newUser) {
		makeObservable(this, {
			id: observable,
			name: observable,
			email: observable,
			hash: observable,
			image: observable,
			plan: observable,
			apiToken: observable,
			activeStatus: observable,
			imageVersion: observable,
			not_show_modal: observable,
			personalFolder: observable,
			show_onboarding_videos: observable,
		});

		if (newUser == null || newUser.id == null) {
			throw new Error('Invalid user constructor');
		}

		const {
			id,
			name,
			email,
			media,
			plan,
			api_token,
			active_status,
			hash,
			not_show_modal,
			personal_folder,
			show_onboarding_videos,
		} = newUser;

		this.id = id;
		this.name = name || '';
		this.email = email || '';
		this.image = media ? media : mobileAvatar;

		this.plan = plan;
		this.hash = hash || '';
		this.apiToken = api_token;
		this.activeStatus = active_status;
		this.not_show_modal = not_show_modal;
		this.personalFolder = personal_folder;
		this.show_onboarding_videos = show_onboarding_videos;
	}

	fillFields(newUser) {
		const {
			id,
			name,
			email,
			media,
			plan,
			api_token,
			active_status,
			hash,
			not_show_modal,
			personal_folder,
			show_onboarding_videos,
		} = newUser;
		this.id = id;
		this.name = name || '';
		this.email = email || '';
		this.image = media ? media : mobileAvatar;

		this.plan = plan;
		this.hash = hash || '';
		this.apiToken = api_token;
		this.activeStatus = active_status;
		this.not_show_modal = not_show_modal;
		this.personalFolder = personal_folder;
		this.show_onboarding_videos = show_onboarding_videos;
	}

	noCacheImage() {
		const lastVersion = this.imageVersion;
		this.imageVersion += 1;
		this.image = this.image.replace(
			`&no-cache=${lastVersion}`,
			`&no-cache=${this.imageVersion}`,
		);
	}

	static fromApi({
		id,
		name,
		email,
		media,
		api_token,
		plan,
		active_status,
		hash,
		not_show_modal,
		personal_folder,
		show_onboarding_videos,
	} = {}) {
		return new User({
			id,
			name,
			email,
			media,
			hash,
			api_token,
			plan,
			active_status,
			not_show_modal,
			personal_folder,
			show_onboarding_videos,
		});
	}
}
