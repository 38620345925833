import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import ComparativeNavbar from './ComparativeNavbar';

const ComparativeLayout = ({ children }) => (
  <>
    <ComparativeNavbar />
    {children}
  </>
);

ComparativeLayout.propTypes = {
  children: PropTypes.node,
};
ComparativeLayout.defaultProps = {
  children: '',
};
export default observer(ComparativeLayout);
