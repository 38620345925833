import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import { useStore } from '../../../hooks';
import useToggle from '../../../hooks/useToggle';
import DotsModal from '../../Tools/Analysis/DotsModal/DotsModal';
// import useAsync from '../../../hooks/useAsync';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';
import AnnotationOverlay from '../../Styleguide/AnnotationOverlay/AnnotationOverlay';
import RedefineModal from '../../RedefineModal/RedefineModal';

const AnalysisNavBar = () => {
  const store = useStore();
  const history = useHistory();
  const { imageId, id: patientId } = useParams();
  const { galleryStore, dotsStore, analysisStore } = store;
  const [overlay, toggleOverlay] = useToggle();
  const [show, toggleShow] = useToggle();
  const [showModalNoDots, toggleShowModalNoDots] = useToggle(false);
  // const { status, run } = useAsync();

  const handleGoBack = () => {
    history.push(`/patients/${patientId}/tools/${imageId}`);
  };

  const saveNote = useCallback(async (payload) => {
    await galleryStore.updateImage(payload);
  }, []);

  const menuOptions = [
    {
      key: 1,
      label: 'Carregar pontos automáticos',
      action: async () => {
        await dotsStore.updateDotsMap(
          imageId,
          toggleShowModalNoDots,
          store.galleryStore.image.imageType,
        );

        await analysisStore.saveAnalysis(
          patientId,
          imageId,
          dotsStore.dots,
          analysisStore.guideLines,
        );
      },
    },
    {
      key: 2,
      label: 'Gerenciar pontos',
      action: () => {
        analysisStore.setAnalysisActive('');
        toggleShow();
      },
    },
    {
      key: 3,
      label: 'Exportar análises',
      action: () => {
        if (
          store.galleryStore.image?.analysis?.dots?.length === 0 ||
          !store.galleryStore.image?.analysis?.hasAnalysis
        ) {
          return toast.error(
            'Para realizar essa ação, você precisa adicionar pontos',
          );
        }

        analysisStore.toggleModal();
        return true;
      },
    },
    {
      key: 4,
      label: 'Redefinir todos os pontos',
      action: () =>
        document.getElementById('btn-toggle-redefine-modal')?.click(),
    },
    {
      key: 5,
      label: 'Anotações da foto',
      action: () => toggleOverlay(),
    },
  ];

  return (
    <>
      <RedefineModal
        handleRedefineDots={() =>
          store.analysisStore.handleRedefineDots(imageId)
        }
      />
      <AnnotationOverlay
        overlay={overlay}
        toggleOverlay={toggleOverlay}
        title={galleryStore.image?.fileName}
        saveAction={saveNote}
        value={galleryStore.image?.obs}
        loading={galleryStore.isFetching}
      />
      <DotsModal show={show} toggleShow={toggleShow} />
      <NavbarHeaderMobile
        title='Análise facial'
        subtitle={galleryStore.image?.fileName}
        content='title'
        hasGoBack
        goBackAction={handleGoBack}
        hasMenu
        menuOptions={menuOptions}
        status={analysisStore.isFetching}
      />
      <ModalComponent
        show={showModalNoDots}
        onClose={toggleShowModalNoDots}
        title={
          <span>
            Erro ao carregar <br />
            pontos automáticos
          </span>
        }
        subTitle={
          <span>
            Não foi possível carregar <br />
            os pontos automáticos.
          </span>
        }
        titleColor='text-red-light'
        hasButton={false}
        noFooter
      />
    </>
  );
};

export default observer(AnalysisNavBar);
