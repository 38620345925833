import { isAfter } from 'date-fns';
import { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMeasure } from 'react-use';
import { useStore, useToggle } from '../../hooks';

const useFileListData = () => {
	const store = useStore();
	const history = useHistory();
	const [showModal, toggleModal] = useToggle(false);
	const [edit, setEdit] = useState({});
	const [filterPhoto, setFilterPhoto] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [categoryFilter, setCategoryFilter] = useState('')
	const [refFilter, { height: filterHeight }] = useMeasure();
	const location = useLocation();

	const { id: patId, albumId } = useParams();

	const patientId = location.pathname.includes('/user/documents')
		? store.authStore.user?.personalFolder
		: patId;


	const handleChangeFileType = async (fileTypeId) => {
		setIsLoading(true);
		setCategoryFilter(fileTypeId)
		setFilterPhoto('')
		store.galleryStore.resetCurrentPage()
		store.galleryStore.setImageListSelected(null)
		try {
			await store.galleryStore.getListImage(patientId,albumId, fileTypeId,'').then((res) => res);
		} catch (error) {
			console.error(error);
			Promise.reject(error);
		}finally{
			setIsLoading(false);
		}
	};

	const handleChangeCategoryPhoto = async (categoryId) => {
		setIsLoading(true);
		setFilterPhoto(categoryId)
		setCategoryFilter(2)
		store.galleryStore.resetCurrentPage()
		store.galleryStore.setImageListSelected(null)
		try {
			await store.galleryStore.getListImage(patientId,albumId,2,categoryId).then((res) => res);
		} catch (error) {
			console.error(error);
			Promise.reject(error);
		}finally{
			setIsLoading(false);
		}		
	};

	function handleFetchNextPage() {
		if (store.galleryStore.currentPage < store.galleryStore.lastPage) {			
			if(store.galleryStore.keyWord.length > 0){
				
				store.galleryStore.filterAlbumImage(store.galleryStore.keyWord, patientId, albumId)
			}else{
				store.galleryStore.getListImage(patientId,albumId,categoryFilter,filterPhoto).then((res) => res);
			}
			
			
		}
		return true;
	}

	const handleSubmitEdit = async (image) => {
		if (isAfter(new Date(store.galleryStore.selectedImageDate), new Date())) {
			toast.error('Data inválida');
			return false;
		}
		if (image.id != null) {
			await store.galleryStore.updateImage(
				store.galleryStore.getSelectedImageEdition(),
				image.id,
			);
		}
		setEditModalShow(false);
		return true;
	};

	const handleSelectFile = (id) => {
		if (!location.pathname.includes('/user/documents')) {
			store.galleryStore.setImage(id);
			history.push(`/patients/${patientId}/tools/${id}`);
		}
	};

	const imgSelected = (id) => {
		store.galleryStore.setImage(id);
	};

	const handleClick = () => {
		document.getElementById('input-new-file').click();
	};

	const handleChangeTypeImage = (id) => {
		if (!location.pathname.includes('/user/documents')) {
			toggleModal();
			setEdit({ ...edit, id });
		}
	};

	const handleAddFile = async (e) => {
		let filesSize = 0;

		Array.from(e.target.files).forEach((file) => {
			filesSize += file.size;
		});

		if (
			filesSize +
				Number(store.authStore.storage.total) -
				Number(store.authStore.user.plan.benefits.storage) >
			0
		) {
			toast.error('Envio ultrapassa limite de armazenamento disponível');

			return Promise.reject(
				new Error('Envio ultrapassa limite de armazenamento disponível'),
			);
		}
		try {
			Object.values(e.target.files).forEach((file) =>
				store.defaultStore.setToTransfer(file),
			);

			Object.values(store.defaultStore.transferData).forEach(
				(dataToTransfer) => {
					if (!dataToTransfer) {
						store.defaultStore.resetTransferData();
					}
					if (dataToTransfer?.done || dataToTransfer?.error) {
						delete store.defaultStore.transferData[dataToTransfer.name];
					}
				},
			);

			if (document.getElementById('input-new-file') && e.target.files[0]) {
				for (let i = 0; i < store.defaultStore.toTransfer.length; i++) {
					const form = new FormData();

					form.append(
						'patient_id',
						patientId || store.patientsStore.patientSelected?.id,
					);

					form.append(
						'image_album_id',
						store.albumStore.albumSelected?.id || albumId,
					);

					form.append('media', store.defaultStore.toTransfer[i]);

					store.defaultStore.setTransferData(
						store.defaultStore.toTransfer[i].name,
					);

					store.defaultStore.updateTransferData(
						store.defaultStore.toTransfer[i].name,
						{
							name: store.defaultStore.toTransfer[i].name,
							loading: true,
						},
					);

					store.galleryStore.setPromises(
						store.galleryStore.saveImage(form, i),

						store.galleryStore.setImageUploadName(
							store.defaultStore.toTransfer[i].name,
							i,
						),
					);
				}

				// resetar o input:
				e.target.value = ''
			}

			return Promise.resolve(filesSize);
		} catch (err) {
			setIsLoading(false);

			return Promise.reject(err);
		}
	};

	const handleImageTypeChange = async () => {
		toggleModal();

		if (!edit.id || !edit.type) return;

		store.galleryStore.setIsFetching(true);

		await store.galleryStore.changeTypeImage(edit.id, edit.type);

		store.galleryStore.setIsFetching(false);
	};

	const handleDeleteFile = async (id) => {
		await store.galleryStore.deleteImage(id);
	};

	useEffect(() => {
		if (!store.patientsStore.patientSelected) {
			store.albumStore.resetAlbumList();
			store.galleryStore.setImageListSelected(null);
		}

		(async () => {
			if (albumId != null && albumId !== '') {
				setIsLoading(true);

				await store.albumStore.setAlbumList(patientId);
				await store.albumStore.setAlbumSelected(albumId);
				await store.galleryStore.getListImage(patientId,albumId);

				setIsLoading(false);

				return true;
			}

			return () => {
				store.galleryStore.setImageListSelected(null);
				store.galleryStore.promises = [];
			};
		})();

		return () => setFilterPhoto('');
	}, [albumId, store.patientsStore.patientSelected]);

	useEffect(
		() => () => {
			store.galleryStore.setImage(null);
			store.galleryStore.setImageListSelected(null);
			(async () => {
				await store.albumStore.setAlbumSelected(null);
			})();
			store.galleryStore.promises = [];
			return () => store.galleryStore.setCurrentPage(1);
		},
		
		[],
	);

	useEffect(() => setIsLoading(true), [store.analysisStore.isFetching]);

	const handleEdit = (type) => {
		setEdit({ ...edit, type });
	};

	return {
		store,
		isLoading,
		albumId,
		patientId,
		refFilter,
		handleClick,
		setFilterPhoto,
		filterPhoto,
		filterHeight,
		handleSelectFile,
		imgSelected,
		handleDeleteFile,
		handleChangeTypeImage,
		handleSubmitEdit,
		handleAddFile,
		showModal,
		toggleModal,
		handleImageTypeChange,
		handleEdit,
		handleChangeCategoryPhoto,
		handleFetchNextPage,
		handleChangeFileType,
	};
};

export default useFileListData;
