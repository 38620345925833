import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { useDebounce, useWindowSize } from 'react-use';
import SearchInput from '../../../Styleguide/SearchInput/SearchInput';
import { useStore } from '../../../../hooks';
import NavbarHeader from '../../../Styleguide/NavbarHeader/NavbarHeader';
import PatientsImportLoader from '../../../Loader/PatientsImportLoader';

const SearchNavBar = ({title}) => {
  const [inputValue, setInputValue] = useState('');
  const store = useStore();
  const { width } = useWindowSize();

  useDebounce(
    async () => {
     await store.patientsStore.searchPatients(inputValue, store.authStore.user.id);
    },
    500,
    [inputValue],
  );
  if (width < 576) {
    return (
      <div className='d-flex col-12 border-bottom'>
        <Navbar
          bg='bg-navbar-gradient'
          className='d-flex flex-fill justify-content-between'
          style={{ minHeight: '30px' }}
        >
          {!!store.patientsStore.patientsList?.length && (
            <Nav className='d-flex w-100 justify-content-center py-2'>
              <Nav.Item className='w-100 d-flex flex-column flex-sm-row gap-2' style={{ maxWidth: '293px' }}>
                <SearchInput
                  className='d-none d-md-none'
                  placeholder='Buscar por nome'
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <PatientsImportLoader />
              </Nav.Item>
            </Nav>
          )}
        </Navbar>
      </div>
    );
  }
  return (
    <NavbarHeader
      hasTitle
      title={<h4>{title}</h4>}
      searchValue={inputValue}
      changeValue={setInputValue}
      placeholder='Buscar por nome'
      content='search'
    />
  );
};

export default observer(SearchNavBar);

SearchNavBar.propTypes = {
  title: PropTypes.string.isRequired,
}
