const Paper = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#D7DCE2', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...rest}
	>
		<g clipPath='url(#clip0_293_63400)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.125 3.75H1.875C1.70924 3.75 1.55027 3.81585 1.43306 3.93306C1.31585 4.05027 1.25 4.20924 1.25 4.375V15.625C1.25 15.7908 1.31585 15.9497 1.43306 16.0669C1.55027 16.1842 1.70924 16.25 1.875 16.25H18.125C18.2908 16.25 18.4497 16.1842 18.5669 16.0669C18.6842 15.9497 18.75 15.7908 18.75 15.625V4.375C18.75 4.20924 18.6842 4.05027 18.5669 3.93306C18.4497 3.81585 18.2908 3.75 18.125 3.75ZM1.875 2.5C1.37772 2.5 0.900805 2.69754 0.549175 3.04917C0.197544 3.40081 0 3.87772 0 4.375L0 15.625C0 16.1223 0.197544 16.5992 0.549175 16.9508C0.900805 17.3025 1.37772 17.5 1.875 17.5H18.125C18.6223 17.5 19.0992 17.3025 19.4508 16.9508C19.8025 16.5992 20 16.1223 20 15.625V4.375C20 3.87772 19.8025 3.40081 19.4508 3.04917C19.0992 2.69754 18.6223 2.5 18.125 2.5H1.875Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.75 10.625C3.75 10.4592 3.81585 10.3003 3.93306 10.1831C4.05027 10.0658 4.20924 10 4.375 10H15.625C15.7908 10 15.9497 10.0658 16.0669 10.1831C16.1842 10.3003 16.25 10.4592 16.25 10.625C16.25 10.7908 16.1842 10.9497 16.0669 11.0669C15.9497 11.1842 15.7908 11.25 15.625 11.25H4.375C4.20924 11.25 4.05027 11.1842 3.93306 11.0669C3.81585 10.9497 3.75 10.7908 3.75 10.625ZM3.75 13.125C3.75 12.9592 3.81585 12.8003 3.93306 12.6831C4.05027 12.5658 4.20924 12.5 4.375 12.5H11.875C12.0408 12.5 12.1997 12.5658 12.3169 12.6831C12.4342 12.8003 12.5 12.9592 12.5 13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75H4.375C4.20924 13.75 4.05027 13.6842 3.93306 13.5669C3.81585 13.4497 3.75 13.2908 3.75 13.125Z'
				fill={color}
			/>
			<path
				d='M3.75 6.875C3.75 6.70924 3.81585 6.55027 3.93306 6.43306C4.05027 6.31585 4.20924 6.25 4.375 6.25H15.625C15.7908 6.25 15.9497 6.31585 16.0669 6.43306C16.1842 6.55027 16.25 6.70924 16.25 6.875V8.125C16.25 8.29076 16.1842 8.44973 16.0669 8.56694C15.9497 8.68415 15.7908 8.75 15.625 8.75H4.375C4.20924 8.75 4.05027 8.68415 3.93306 8.56694C3.81585 8.44973 3.75 8.29076 3.75 8.125V6.875Z'
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id='clip0_293_63400'>
				<rect width='20' height='20' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default Paper;
