import { makeAutoObservable } from 'mobx';
import { authStore } from '.';
import baseAPI from '../../services/baseAPI';

class CheckoutStore {
  plans = [];

  selectedPlan = null;

  couponCheck = null;

  couponBenefits = null;

  checkoutResponse = null;

  modal = false;

  constructor() {
    makeAutoObservable(this);
  }

  clearCouponCheck() {
    this.couponCheck = null;
  }

  modalToggle() {
    this.modal = !this.modal;
  }

  *getPlans() {
    try {
      const res = yield baseAPI.get('/v1/plan');
      if (res.data == null) {
        return false;
      }
      this.plans = res.data;
    } catch (e) {
      if (e.response != null) {
        toast.error(e.response.data.Message);
      }
    }
    return true;
  }

  *getPlan(id) {
    try {
      const res = yield baseAPI.get(`/v1/plan/${id}`);
      if (res.data == null) {
        return false;
      }
      this.selectedPlan = res.data;
    } catch (e) {
      console.error(e);
    }
    return true;
  }

  *validateCoupon(coupon, planId) {
    try {
      const res = yield baseAPI.post(`/v1/checkout/validate-coupon`, {
        coupon,
        plan_id: planId,
      });
      if (res.data == null) {
        return false;
      }

      this.couponCheck = true;
      this.couponBenefits = res.data.data;
    } catch (e) {
      console.error(e);
      this.couponCheck = false;
      this.couponBenefits = null;
      return false;
    }
    return true;
  }

  *checkoutUpgrade(data) {
    const { email } = authStore.user;
    const payload = {
      plan_id: this.selectedPlan.third_party_id,
      name: data.billing.fullName,
      email,
      payment_type: data.payment.type,
      cpf_cnpj: data.billing.cpfCnpj,
      phone: data.billing.phone,
      address: {
        zip: data.billing.zip,
        street: data.billing.street,
        number: data.billing.number,
        complement: data.billing.complement,
        district: data.billing.district,
        city: data.billing.city,
        state: data.billing.uf,
      },
      card: {
        ...data.payment.credit_card,
      },
      coupon: data.coupon.code,
    };
    try {
      const res = yield baseAPI.post('/v1/checkout', payload);
      if (res.data == null) return false;
      this.checkoutResponse = {
        status: res.data.Status,
        checkout: payload,
        response: res.data.data,
      };
    } catch (e) {
      this.checkoutResponse = {
        status: e.response.data.Status,
        checkout: payload,
        response: e.response.data.data,
      };
    }
    this.modalToggle();
    return true;
  }
}

export default new CheckoutStore();
