import PropTypes from 'prop-types';
import { PHOTO_TYPES } from '../../constants/PhotoTypes';
import { useStore } from '../../hooks';
import Select from '../Select/Select';
import ModalComponent from '../Styleguide/ModalComponent/ModalComponent';

const CategoryModal = ({
	showModal,
	subTitle,
	toggleModal,
	handleImageTypeChange,
	handleEdit,
	disabled,
}) => {
	const { galleryStore } = useStore();

	return (
		<ModalComponent
			show={showModal}
			subTitle={subTitle}
			title='Definir categoria'
			onClose={toggleModal}
			onClickButton={handleImageTypeChange}
			nameButton='Salvar'
			idButton='define-category'
			disabled={disabled ? disabled : galleryStore.isFetching}
		>
			<Select
				handleChange={handleEdit}
				options={PHOTO_TYPES}
				selectLabel='Selecione'
				selectId='type-filter-modal'
			/>
		</ModalComponent>
	);
};

export default CategoryModal;

CategoryModal.propTypes = {
	showModal: PropTypes.bool,
	subTitle: PropTypes.string,
	toggleModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	disabled: PropTypes.bool,
	handleImageTypeChange: PropTypes.func,
	handleEdit: PropTypes.func,
};

CategoryModal.defaultProps = {
	showModal: false,
	subTitle: '',
	toggleModal: false,
	handleImageTypeChange: () => {},
	handleEdit: () => {},
	disabled: false,
};
