import { Line } from 'react-konva';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const LINE_DISTANCE = 40;

const HorizontalPropotion = ({ filteredDots, stroke, strokeWidth }) => (
  <>
    {filteredDots.map((_, i) => {
      const thisX = filteredDots[i]?.x;
      const thisY = filteredDots[i]?.y;

      return (
        <Fragment key={`${thisX}-${thisY}`}>
          <Line
            stroke={stroke}
            strokeWidth={strokeWidth}
            points={[
              thisX,
              thisY - LINE_DISTANCE,
              thisX,
              thisY + LINE_DISTANCE,
            ]}
          />
        </Fragment>
      );
    })}
  </>
);

HorizontalPropotion.propTypes = {
  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

HorizontalPropotion.defaultProps = {
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default HorizontalPropotion;
