import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import { useStore } from "../../../hooks"
import ModalComponent from "../../Styleguide/ModalComponent/ModalComponent";
import InfoModalCheckOut from "./InfoModalCheckOut";
import FeedbackBadge from '../../Styleguide/FeedbackBadge/FeedbackBadge';
import StandardButton from '../../Styleguide/StandardButton/StandardButton';
import { PAYMENT_TYPE_OPTIONS } from "../../../constants/SelectOptions";
import currency from "../../../utils/currency";
import { COLORS } from "../../../constants/Colors";
import IconComponent from "../../Svg/IconComponent/IconComponent";


const ModalManager = () => {
    const store = useStore();
    const history = useHistory();

    const planPrice = useMemo(() => {
      if(store.checkoutStore.couponBenefits?.discount) {
        if(store.checkoutStore.couponBenefits?.discountType === "percentual") {
          return store.checkoutStore.selectedPlan?.price - ((store.checkoutStore.couponBenefits?.discount * store.checkoutStore.selectedPlan?.price)/100);
        }
        return store.checkoutStore.selectedPlan?.price - store.checkoutStore.couponBenefits?.discount;
      }
      return store.checkoutStore.selectedPlan?.price;
  }, [store.checkoutStore.selectedPlan?.price, store.checkoutStore.couponBenefits?.discount])
    
    const modalContent = useMemo(() => {
        let textTitle = '';
        let textSubtitle = '';  
        
        switch (store.checkoutStore.checkoutResponse?.status){
            case "Success":
                if(store.checkoutStore.checkoutResponse?.checkout?.payment_type === PAYMENT_TYPE_OPTIONS[0].value){
                    textTitle = 'Upgrade realizado com sucesso!';
                } else {
                    textTitle = 'Boleto gerado com sucesso!';
                    textSubtitle = 'Após a confirmação do pagamento seu upgrade estará garantido!';
                    
                }
            return  <>
             <FeedbackBadge
                    icon={{
                        group: 'standard',
                        name: 'done',
                        size: 98,
                        color: COLORS.success,
                    }}
                    text={<div style={{ color: COLORS.dark }}>{textTitle}</div>}
                    subTitle={textSubtitle}
                    textColor='dark'
                    />
                    {store.checkoutStore.checkoutResponse?.checkout?.payment_type === PAYMENT_TYPE_OPTIONS[1].value ?<StandardButton handleClick={() => window.open(store.checkoutStore.checkoutResponse?.response?.link, '_blank')} label='Visualizar boleto' /> : null}
                    </>
            case "Error": 
            return  <FeedbackBadge
                    icon={{
                    group: 'standard',
                    name: 'notAuthorized',
                    size: 98,
                    color: COLORS.danger,
                    }}
                    text={<div style={{ color: COLORS.dark }}>Não autorizado</div>}
                    subTitle='Por favor, verifique os dados de compra e tente novamente'
                    textColor='dark'
                />
            default: 
            return null;
        }
        
    }, [store.checkoutStore.checkoutResponse?.checkout,store.checkoutStore.checkoutResponse?.response ])

    const cardNumber = store.checkoutStore.checkoutResponse?.checkout?.card?.number;

    const handleClose = () => { 
        if(store.checkoutStore.checkoutResponse?.status === 'Error') {
            store.checkoutStore.modalToggle();
            return false;
        } 
        store.checkoutStore.modalToggle();
        history.push('/');
        return true;

    }

    return   <ModalComponent
    title='Resumo da compra'
    hasButton={false}
    show={store.checkoutStore.modal}
    onClose={() => handleClose()}
    noFooter
    subTitle={` `}
  >
    <div className='border-bottom w-100'>
     {modalContent}
    </div>
    <div className='d-flex w-100 align-items-end justify-content-between p-2'>
      <InfoModalCheckOut
        title={`Assinatura Plano ${store.checkoutStore.selectedPlan?.name}`} 
        price={currency(planPrice)}
        paymentType={store.checkoutStore.checkoutResponse?.checkout?.payment_type}
        discountCode={store.checkoutStore.checkoutResponse?.checkout?.coupon}
        finalCardNumber={cardNumber?.substring(cardNumber.length - 4)}
      />
      {
        store.checkoutStore.checkoutResponse?.status === "Success" ?
        <a href={store.checkoutStore.checkoutResponse?.response?.subscription.invoice_url} rel="noreferrer" target="_blank">   
        <IconComponent
          group="standard"
          name="download"
          color={COLORS.blueBorder}
          size={40}
        /></a> : ''
      }
      
    </div>
  </ModalComponent>

}   


export default observer(ModalManager);
