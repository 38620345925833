export const setPhone = (v) => {
  const digitos = !v ? '' : v.replace(/[^\d]/g, '');
  if (!digitos || digitos.length < 10) return v;
  const corte = digitos.length === 10 ? 6 : 7;
  const max = digitos.length > 11 ? 11 : digitos.length;
  return `(${digitos.substring(0, 2)}) ${digitos.substring(
    2,
    corte,
  )}-${digitos.substring(corte, max)}`;
};

export const maskBuilder = (v) => {
  if (!v || v.length === 0) return '';
  const a = setPhone(v);
  return a.length >= 6 && a[5] === '9' ? '(99) 9.9999-9999' : '(99) 9999-9999';
};
