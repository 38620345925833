const File = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'black', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 138 138'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M34.5 8.625H77.625V17.25H34.5C32.2125 17.25 30.0187 18.1587 28.4012 19.7762C26.7837 21.3937 25.875 23.5875 25.875 25.875V112.125C25.875 114.412 26.7837 116.606 28.4012 118.224C30.0187 119.841 32.2125 120.75 34.5 120.75H103.5C105.787 120.75 107.981 119.841 109.599 118.224C111.216 116.606 112.125 114.412 112.125 112.125V51.75H120.75V112.125C120.75 116.7 118.933 121.088 115.698 124.323C112.463 127.558 108.075 129.375 103.5 129.375H34.5C29.925 129.375 25.5374 127.558 22.3024 124.323C19.0674 121.088 17.25 116.7 17.25 112.125V25.875C17.25 21.3 19.0674 16.9124 22.3024 13.6774C25.5374 10.4424 29.925 8.625 34.5 8.625V8.625Z'
      fill={color}
      fillOpacity='0.6'
    />
    <path
      d='M77.625 38.8125V8.625L120.75 51.75H90.5625C87.1313 51.75 83.8406 50.3869 81.4143 47.9607C78.9881 45.5344 77.625 42.2437 77.625 38.8125Z'
      fill={color}
      fillOpacity='0.6'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3913 76.7391V95H71.6087V76.7391H89V72.3913H71.6087V55H66.3913V72.3913H49V76.7391H66.3913Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3913 76.7391V95H71.6087V76.7391H89V72.3913H71.6087V55H66.3913V72.3913H49V76.7391H66.3913Z'
      fill={color}
    />
  </svg>
);
export default File;
