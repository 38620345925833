import propTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import ComparativeLoadFeedback from '../ComparativeLoadFeedback/ComparativeLoadFeedback';

const ComparativeDimmer = (props) => {
  const { show } = props;

  return (
    <>
      {show && (
        <Container
          fluid
          className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column zi-10 '
          style={{ backgroundColor: '#383C42', opacity: '0.8', padding: 0 }}
        >
          <div>
            <ComparativeLoadFeedback transparent />
          </div>
          <h5 className='text-white mt-4' style={{ fontWeight: 400 }}>
            Carregando comparativo...
          </h5>
        </Container>
      )}
    </>
  );
};

ComparativeDimmer.propTypes = {
  show: propTypes.bool,
};
ComparativeDimmer.defaultProps = {
  show: false,
};
export default ComparativeDimmer;
