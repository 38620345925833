const Closex = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 12 12'
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z" fill={color}/>

  </svg>
);

export default Closex;
