const Expand = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '12', color = '#fff', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.125 7.52698C1.22446 7.52698 1.31984 7.56649 1.39017 7.63681C1.46049 7.70714 1.5 7.80252 1.5 7.90198V10.527H4.125C4.22446 10.527 4.31984 10.5665 4.39016 10.6368C4.46049 10.7071 4.5 10.8025 4.5 10.902C4.5 11.0014 4.46049 11.0968 4.39016 11.1671C4.31984 11.2375 4.22446 11.277 4.125 11.277H1.125C1.02554 11.277 0.930161 11.2375 0.859835 11.1671C0.789509 11.0968 0.75 11.0014 0.75 10.902V7.90198C0.75 7.80252 0.789509 7.70714 0.859835 7.63681C0.930161 7.56649 1.02554 7.52698 1.125 7.52698V7.52698Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.76597 7.23456C4.8009 7.26939 4.8286 7.31078 4.84751 7.35633C4.86641 7.40189 4.87614 7.45073 4.87614 7.50006C4.87614 7.54939 4.86641 7.59823 4.84751 7.64378C4.8286 7.68934 4.8009 7.73073 4.76597 7.76556L1.39097 11.1406C1.32056 11.211 1.22506 11.2505 1.12547 11.2505C1.02589 11.2505 0.930389 11.211 0.859974 11.1406C0.789559 11.0701 0.75 10.9746 0.75 10.8751C0.75 10.7755 0.789559 10.68 0.859974 10.6096L4.23497 7.23456C4.26981 7.19964 4.31119 7.17193 4.35675 7.15303C4.40231 7.13412 4.45115 7.12439 4.50047 7.12439C4.5498 7.12439 4.59864 7.13412 4.6442 7.15303C4.68976 7.17193 4.73114 7.19964 4.76597 7.23456ZM11.141 0.85956C11.1759 0.894394 11.2036 0.935776 11.2225 0.981335C11.2414 1.02689 11.2511 1.07573 11.2511 1.12506C11.2511 1.17439 11.2414 1.22323 11.2225 1.26879C11.2036 1.31434 11.1759 1.35573 11.141 1.39056L7.76597 4.76556C7.69556 4.83597 7.60006 4.87553 7.50047 4.87553C7.40089 4.87553 7.30539 4.83597 7.23497 4.76556C7.16456 4.69514 7.125 4.59964 7.125 4.50006C7.125 4.40048 7.16456 4.30497 7.23497 4.23456L10.61 0.85956C10.6448 0.824638 10.6862 0.79693 10.7317 0.778026C10.7773 0.759121 10.8261 0.74939 10.8755 0.74939C10.9248 0.74939 10.9736 0.759121 11.0192 0.778026C11.0648 0.79693 11.1061 0.824638 11.141 0.85956V0.85956Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.52734 1.125C7.52734 1.02554 7.56685 0.930161 7.63718 0.859835C7.7075 0.789509 7.80289 0.75 7.90234 0.75H10.9023C11.0018 0.75 11.0972 0.789509 11.1675 0.859835C11.2378 0.930161 11.2773 1.02554 11.2773 1.125V4.125C11.2773 4.22446 11.2378 4.31984 11.1675 4.39016C11.0972 4.46049 11.0018 4.5 10.9023 4.5C10.8029 4.5 10.7075 4.46049 10.6372 4.39016C10.5669 4.31984 10.5273 4.22446 10.5273 4.125V1.5H7.90234C7.80289 1.5 7.7075 1.46049 7.63718 1.39017C7.56685 1.31984 7.52734 1.22446 7.52734 1.125Z'
      fill={color}
    />
  </svg>
);

export default Expand;
