import { action, makeObservable, observable } from 'mobx';
import baseAPI from '../../services/baseAPI';
import Default from './defaultStore';

class TutorialStore extends Default{

    isLoading= false;

    tutorialVideos = []

    constructor(){
        super();
        makeObservable(this, {
            isLoading: observable,
            setIsLoading: action,
            tutorialVideos: observable,
            setTutorialVideos: action,
            updateFlagDontRenderAnymore: action,
        })
    }

    setIsLoading(isLoading){
        this.isLoading = isLoading
    }

    setTutorialVideos(data){
        this.tutorialVideos = data;
    }

    async getTutorialFlags(onboardingVideoId){
        try{
            this.setIsLoading(true)
            const res = await this.isLoadingPromise(
                baseAPI.get(
                    `/v1/onboarding-video/${onboardingVideoId}`
                ),
            );
            return(res.data.data)
        }
        catch(error){
            return error
        }
        finally{
            this.setIsLoading(false)
        }
    }  

    async updateFlagDontRenderAnymore(shouldShowAgain, videoId){ 
        this.updateFlagOfAnything('show', shouldShowAgain, videoId, 'onboarding-video/flag')    
    }

    async getTutorialVideos(slug){
        await this.fetchTutorialVideo(slug).then((res)=>{
            this.setTutorialVideos([...this.tutorialVideos, res])
            return(res)
        })
    }

    fetchTutorialVideo(slug=''){
        return new Promise(async(resolve, reject)=>{

            try{
                const res = await this.isLoadingPromise(
                    baseAPI.get(
                        `/v1/onboarding-videos/list?slug=${slug}`
                    ),
                );
                
                resolve(res.data.data)
            }
            catch(error){
                reject(error)
            }
        })
    }

    
    async updateLike(isLiked, videoId){  
        this.updateFlagOfAnything('like', isLiked, videoId, 'onboarding-video/flag')    
    }


}

export default new TutorialStore()