import React from 'react';
import PropTypes from 'prop-types';

import {
	Analitic,
	AdminUser,
	Advanced,
	ArrowUp,
	ArrowUpDown,
	ArrowDown,
	AlbumFolder,
	AlbumPlus,
	ArrowBack,
	ArrowLeft,
	ArrowRepeat,
	ArrowRight,
	Bell,
	BurgerMenu,
	Check,
	CheckBox,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	ClockHistory,
	CloseError,
	Closex,
	CloudUp,
	Dash,
	Done,
	DoubleArrow,
	Download,
	Eye,
	EyeHide,
	FailCircle,
	File,
	FilePlus,
	FolderPlus,
	Gear,
	HomeIcon,
	HomeOutline,
	Info,
	Logout,
	MultipleVideos,
	Note,
	NotAuthorizedCard,
	FilledWarning,
	Chat,
	Video,
	CalendarPlus,
	TodayCalendar,
	Calendar,
	Paper,
	FolderOpen,
	TextPaper,
	QuestionOutline,
	MailOutline,
	ErrorReport,
	DoubleQuoteOutline,
	CellPhoneOutline,
	ChatOutline,
	Palette,
	PatientIcon,
	Pencil,
	Person,
	PersonFill,
	PersonPlus,
	Photo,
	Plus,
	PlusSquare,
	Positive,
	QuestionIcon,
	SearchIcon,
	SquareHalf,
	TextArea,
	ThreeDotsY,
	Toolss,
	TutorialVideo,
	UserIcon,
	UserOutline,
	Warning,
	Computer,
	GoogleDrive,
	Upload,
	MultiplePersons,
	FolderOpenOutline,
	FolderCloseOutline,
	Files,
	CellPhone,
	CheckCircle,
} from '../Standard/index';

import {
	Analysis,
	AnalysisPortrait,
	Clock,
	CompareBoard,
	ComparePortrait,
	Crop,
	Expand,
	HorizontalRuler,
	MagicPencil,
	Minimize,
	PersonBox,
	VerticalRuler,
} from '../Analysis';
import { Bright, Contrast, Rotate, Saturation, Zoom } from '../Edition';
import { Dialog, Error, Movie, PDF } from '../Album';
import CalendarClock from '../Standard/CalendarClock/CalendarClock';

const IconComponent = ({ group, name, size = '30', color, id, ...rest }) => {
	const iconStyleProps = {
		cursor: rest?.onClick ? 'pointer' : 'inherit',
		size,
		color,
		id,
		...rest,
	};

	const icons = {
		standard: {
			arrow_asc: <ArrowUp iconStyleProps={iconStyleProps} />,
			arrow_desc: <ArrowDown iconStyleProps={iconStyleProps} />,
			arrow_updown: <ArrowUpDown iconStyleProps={iconStyleProps} />,
			calendarPlus: <CalendarPlus iconStyleProps={iconStyleProps} {...rest} />,
			chat: <Chat iconStyleProps={iconStyleProps} />,
			chatOutline: <ChatOutline iconStyleProps={iconStyleProps} />,
			video: <Video iconStyleProps={iconStyleProps} />,
			filledWarning: <FilledWarning iconStyleProps={iconStyleProps} />,
			doubleArrow: <DoubleArrow iconStyleProps={iconStyleProps} />,
			failCircle: <FailCircle iconStyleProps={iconStyleProps} />,
			photo: <Photo iconStyleProps={iconStyleProps} />,
			warning: <Warning iconStyleProps={iconStyleProps} />,
			filePlus: <FilePlus iconStyleProps={iconStyleProps} />,
			burgerMenu: <BurgerMenu iconStyleProps={iconStyleProps} />,
			home: <HomeIcon iconStyleProps={iconStyleProps} />,
			user: <UserIcon iconStyleProps={iconStyleProps} />,
			done: <Done iconStyleProps={iconStyleProps} />,
			question: <QuestionIcon iconStyleProps={iconStyleProps} />,
			search: <SearchIcon iconStyleProps={iconStyleProps} />,
			dash: <Dash iconStyleProps={iconStyleProps} />,
			back: <ArrowBack iconStyleProps={iconStyleProps} />,
			eyehide: <EyeHide iconStyleProps={iconStyleProps} />,
			eye: <Eye iconStyleProps={iconStyleProps} />,
			arrowright: <ArrowRight iconStyleProps={iconStyleProps} />,
			arrowleft: <ArrowLeft iconStyleProps={iconStyleProps} />,
			album: <AlbumFolder iconStyleProps={iconStyleProps} />,
			plussquare: <PlusSquare iconStyleProps={iconStyleProps} />,
			plus: <Plus iconStyleProps={iconStyleProps} />,
			note: <Note iconStyleProps={iconStyleProps} />,
			dotsY: <ThreeDotsY iconStyleProps={iconStyleProps} />,
			pencil: <Pencil iconStyleProps={iconStyleProps} />,
			cloudup: <CloudUp iconStyleProps={iconStyleProps} />,
			chevronup: <ChevronUp iconStyleProps={iconStyleProps} />,
			chevrondown: <ChevronDown iconStyleProps={iconStyleProps} />,
			chevronleft: <ChevronLeft iconStyleProps={iconStyleProps} />,
			chevronright: <ChevronRight iconStyleProps={iconStyleProps} />,
			positive: <Positive iconStyleProps={iconStyleProps} />,
			file: <File iconStyleProps={iconStyleProps} />,
			folderOpen: <FolderOpen iconStyleProps={iconStyleProps} />,
			personfill: <PersonFill iconStyleProps={iconStyleProps} />,
			tools: <Toolss iconStyleProps={iconStyleProps} />,
			gear: <Gear iconStyleProps={iconStyleProps} />,
			bell: <Bell iconStyleProps={iconStyleProps} />,
			patienticon: <PatientIcon iconStyleProps={iconStyleProps} />,
			personplus: <PersonPlus iconStyleProps={iconStyleProps} />,
			person: <Person iconStyleProps={iconStyleProps} />,
			folderplus: <FolderPlus iconStyleProps={iconStyleProps} />,
			clockhistory: <ClockHistory iconStyleProps={iconStyleProps} />,
			squarehalf: <SquareHalf iconStyleProps={iconStyleProps} />,
			textarea: <TextArea iconStyleProps={iconStyleProps} />,
			palette: <Palette iconStyleProps={iconStyleProps} />,
			arrowrepeat: <ArrowRepeat iconStyleProps={iconStyleProps} />,
			closex: <Closex iconStyleProps={iconStyleProps} />,
			check: <Check iconStyleProps={iconStyleProps} />,
			checkCircle: <CheckCircle iconStyleProps={iconStyleProps}/>,
			logout: <Logout iconStyleProps={iconStyleProps} />,
			homeOutline: <HomeOutline iconStyleProps={iconStyleProps} />,
			userOutline: <UserOutline iconStyleProps={iconStyleProps} />,
			closeError: <CloseError iconStyleProps={iconStyleProps} />,
			checkBox: <CheckBox iconStyleProps={iconStyleProps} />,
			download: <Download iconStyleProps={iconStyleProps} />,
			albumPlus: <AlbumPlus iconStyleProps={iconStyleProps} />,
			info: <Info iconStyleProps={iconStyleProps} />,
			analyticProfile: <Analitic iconStyleProps={iconStyleProps} />,
			adminUser: <AdminUser iconStyleProps={iconStyleProps} />,
			advanced: <Advanced iconStyleProps={iconStyleProps} />,
			notAuthorized: <NotAuthorizedCard iconStyleProps={iconStyleProps} />,
			todayCalendar: <TodayCalendar iconStyleProps={iconStyleProps} />,
			calendar: <Calendar iconStyleProps={iconStyleProps} />,
			calendarClock: <CalendarClock iconStyleProps={iconStyleProps} />,
			paper: <Paper iconStyleProps={iconStyleProps} />,
			textPaper: <TextPaper iconStyleProps={iconStyleProps} />,
			questionOutline: <QuestionOutline iconStyleProps={iconStyleProps} />,
			mailOutline: <MailOutline iconStyleProps={iconStyleProps} />,
			errorReport: <ErrorReport iconStyleProps={iconStyleProps} />,
			doubleQuoteOutline: (
				<DoubleQuoteOutline iconStyleProps={iconStyleProps} />
			),
			cellPhoneOutline: <CellPhoneOutline iconStyleProps={iconStyleProps} />,
			tutorialVideo: <TutorialVideo iconStyleProps={iconStyleProps} />,
			multipleVideos: <MultipleVideos iconStyleProps={iconStyleProps} />,
			computer: <Computer iconStyleProps={iconStyleProps} />,
			googleDrive: <GoogleDrive iconStyleProps={iconStyleProps} />,
			upload: <Upload iconStyleProps={iconStyleProps} />,
			multiplePersons: <MultiplePersons iconStyleProps={iconStyleProps} />,
			folderOpenOutline: <FolderOpenOutline iconStyleProps={iconStyleProps} />,
			folderCloseOutline: <FolderCloseOutline iconStyleProps={iconStyleProps} />,
			files: <Files iconStyleProps={iconStyleProps} />,
			cellPhone: <CellPhone iconStyleProps={iconStyleProps} />,
		},
		analysis: {
			magicPencil: <MagicPencil iconStyleProps={iconStyleProps} />,
			analysisPortrait: <AnalysisPortrait iconStyleProps={iconStyleProps} />,
			comparePortrait: <ComparePortrait iconStyleProps={iconStyleProps} />,
			clock: <Clock iconStyleProps={iconStyleProps} />,
			minimize: <Minimize iconStyleProps={iconStyleProps} />,
			expand: <Expand iconStyleProps={iconStyleProps} />,
			compareBoard: <CompareBoard iconStyleProps={iconStyleProps} />,
			analysis: <Analysis iconStyleProps={iconStyleProps} />,
			verticalRuler: <VerticalRuler iconStyleProps={iconStyleProps} />,
			horizontalRuler: <HorizontalRuler iconStyleProps={iconStyleProps} />,
			crop: <Crop iconStyleProps={iconStyleProps} />,
			personbox: <PersonBox iconStyleProps={iconStyleProps} />,
		},
		edit: {
			zoom: <Zoom iconStyleProps={iconStyleProps} />,
			saturate: <Saturation iconStyleProps={iconStyleProps} />,
			rotate: <Rotate iconStyleProps={iconStyleProps} />,
			bright: <Bright iconStyleProps={iconStyleProps} />,
			contrast: <Contrast iconStyleProps={iconStyleProps} />,
		},
		album: {
			mov: <Movie iconStyleProps={iconStyleProps} />,
			avi: <Movie iconStyleProps={iconStyleProps} />,
			wmv: <Movie iconStyleProps={iconStyleProps} />,
			mpg: <Movie iconStyleProps={iconStyleProps} />,
			mp4: <Movie iconStyleProps={iconStyleProps} />,
			pdf: <PDF iconStyleProps={iconStyleProps} />,
			docx: <PDF iconStyleProps={iconStyleProps} />,
			dialog: <Dialog iconStyleProps={iconStyleProps} />,
			error: <Error iconStyleProps={iconStyleProps} />,
		},
	};
	if (!group || !name) {
		return null;
	}
	return icons[`${group}`][`${name}`];
};

export default IconComponent;

IconComponent.propTypes = {
	group: PropTypes.oneOf(['standard', 'analysis', 'edit', 'album']).isRequired,
	name: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	color: PropTypes.string,
	id: PropTypes.string,
};
