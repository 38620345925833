const ComparePortrait = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#fff', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <g clipPath='url(#a)' fillRule='evenodd' clipRule='evenodd' fill='#000'>
      <path d='M21 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm0 1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM3 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' />
      <path d='M2.25 3.75A2.25 2.25 0 0 1 4.5 1.5h15a2.25 2.25 0 0 1 2.25 2.25v6h-1.5v-6A.75.75 0 0 0 19.5 3h-15a.75.75 0 0 0-.75.75v6h-1.5v-6Zm1.5 10.5v6a.75.75 0 0 0 .75.75h15a.75.75 0 0 0 .75-.75v-6h1.5v6a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25v-6h1.5Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill={color} d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default ComparePortrait;
