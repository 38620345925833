const ErrorReport = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 15 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_780_101243)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.4725 0.12775C4.55449 0.0459647 4.66557 2.46041e-05 4.78137 0L10.2186 0C10.3344 2.46041e-05 10.4455 0.0459647 10.5275 0.12775L14.3722 3.9725C14.454 4.05449 14.5 4.16557 14.5 4.28137V9.71863C14.5 9.83443 14.454 9.94551 14.3722 10.0275L10.5275 13.8722C10.4455 13.954 10.3344 14 10.2186 14H4.78137C4.66557 14 4.55449 13.954 4.4725 13.8722L0.62775 10.0275C0.545965 9.94551 0.500025 9.83443 0.5 9.71863V4.28137C0.500025 4.16557 0.545965 4.05449 0.62775 3.9725L4.4725 0.12775ZM4.9625 0.875L1.375 4.4625V9.5375L4.9625 13.125H10.0375L13.625 9.5375V4.4625L10.0375 0.875H4.9625Z'
				fill={color}
			/>
			<path
				d='M6.62695 9.62547C6.62695 9.51056 6.64959 9.39678 6.69356 9.29062C6.73753 9.18446 6.80198 9.088 6.88323 9.00675C6.96449 8.9255 7.06095 8.86105 7.16711 8.81707C7.27327 8.7731 7.38705 8.75047 7.50195 8.75047C7.61686 8.75047 7.73064 8.7731 7.8368 8.81707C7.94296 8.86105 8.03942 8.9255 8.12067 9.00675C8.20192 9.088 8.26637 9.18446 8.31035 9.29062C8.35432 9.39678 8.37695 9.51056 8.37695 9.62547C8.37695 9.85753 8.28477 10.0801 8.12067 10.2442C7.95658 10.4083 7.73402 10.5005 7.50195 10.5005C7.26989 10.5005 7.04733 10.4083 6.88323 10.2442C6.71914 10.0801 6.62695 9.85753 6.62695 9.62547ZM6.7127 4.37109C6.70105 4.2607 6.71274 4.1491 6.747 4.04352C6.78127 3.93794 6.83735 3.84074 6.91161 3.75823C6.98586 3.67573 7.07664 3.60975 7.17804 3.56459C7.27944 3.51943 7.3892 3.49609 7.5002 3.49609C7.61121 3.49609 7.72097 3.51943 7.82237 3.56459C7.92377 3.60975 8.01454 3.67573 8.0888 3.75823C8.16306 3.84074 8.21914 3.93794 8.2534 4.04352C8.28767 4.1491 8.29936 4.2607 8.2877 4.37109L7.98145 7.43972C7.97116 7.56027 7.91601 7.67257 7.82689 7.7544C7.73777 7.83623 7.62119 7.88164 7.5002 7.88164C7.37921 7.88164 7.26263 7.83623 7.17352 7.7544C7.0844 7.67257 7.02924 7.56027 7.01895 7.43972L6.7127 4.37109Z'
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id='clip0_780_101243'>
				<rect
					width={size}
					height={size}
					fill={color}
					transform='translate(0.5)'
				/>
			</clipPath>
		</defs>
	</svg>
);
export default ErrorReport;
