import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ANALYSIS_TRANSLATION from '../../../../../constants/AnalysisTranslation';

const AngleEyebrow = ({ showText, analysis }) => (
  <div className='d-flex flex-column '>
    <div className='flex-fill p-2'>
      {showText && (
        <>
          <div className='text-primary d-flex flex-row justify-content-between'>
            <div className='text-white'>Obtido:</div>
          </div>
          <div className='d-flex flex-column '>
            {Object.keys(analysis).map((v) => (
              <Fragment key={v}>
                <div>
                  <span className='text-white'>
                    {`${ANALYSIS_TRANSLATION[v]}`}:
                  </span>{' '}
                  {Object.keys(analysis[v]).map((value) => (
                    <Fragment key={value}>
                      <br />
                      <span className='text-white'>
                        {`${ANALYSIS_TRANSLATION[value]}`}:
                      </span>
                      <span className='text-success'>
                        {`${analysis[v][value]?.toFixed(2)}`}º
                      </span>
                    </Fragment>
                  ))}
                </div>
                <br />
              </Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  </div>
);

AngleEyebrow.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

AngleEyebrow.defaultProps = {
  showText: false,
  analysis: null,
};

export default AngleEyebrow;
