import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormCheck, Spinner } from 'react-bootstrap';
import IconComponent from '../../Svg/IconComponent/IconComponent';

const HiddenRequestRow = ({ name, importId, error, progress, done }) => {

  const [id, setId] = useState(importId)

  if (error) {
    return (
			<>
				<div className='align-items-center d-flex gap-2'>
					<IconComponent
						group='standard'
						name={id ? 'folderCloseOutline' : 'files'}
						color='white'
						style={{ filter: 'invert(1)', fontWeight: 'bold' }}
						size='26'
					/>
					<FormCheck.Label 
            className='text-truncate text-danger'
            style={{ fontWeight: 'bold' }}
          >
            {name}
          </FormCheck.Label>
				</div>
				<IconComponent
					group='standard'
					name='closeError'
					style={{ minWidth: '33px' }}
					id='closeError'
				/>
			</>
		);
  }

  if (progress < 100 && !done) {
    return (
			<>
				<div className='align-items-center d-flex gap-2'>
					<IconComponent
						group='standard'
						name={id ? 'folderCloseOutline' : 'files'}
						color='white'
						style={{ filter: 'invert(1)' }}
						size='26'
					/>
					<FormCheck.Label className='text-truncate'>{name}</FormCheck.Label>
				</div>

				<div className='bg-transparent border-0 p-2'>
          {/* <span className='pr-1'>{progress}%</span> */}
					<Spinner animation='border' role='status' size='sm' />
				</div>
			</>
		);
  }

  return (
    <FormCheck className='d-flex justify-content-between w-100 align-items-center'>
      <>
        <div className='align-items-center d-flex gap-2'>
          <IconComponent
            group='standard'
            name= {id ? 'folderCloseOutline' : 'files'}
            color='white'
            style={{ filter: 'invert(1)' }}
            size='26'
          />
          <FormCheck.Label className='text-truncate'>{name}</FormCheck.Label>
        </div>
        <div className='p-1'>
          <IconComponent
            group='standard'
            name='checkCircle'
            size='24'
            color='white'
          />
        </div>
      </>
    </FormCheck>
  );
};

export default HiddenRequestRow;

HiddenRequestRow.propTypes = {
  importId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  progress: PropTypes.number,
  done: PropTypes.bool,
};

HiddenRequestRow.defaultProps = {
  importId: '',
  name: '',
  error: '',
  progress: 0,
  done: false,
};
