import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import ProfileNavBar from './ProfileNavBar';
import MainLayout from '../MainLayout/MainLayout';

const ProfileLayout = ({ children }) => (
  <MainLayout customNavBar={<ProfileNavBar />}>{children}</MainLayout>
);

ProfileLayout.propTypes = {
  children: PropTypes.node,
};
ProfileLayout.defaultProps = {
  children: '',
};
export default observer(ProfileLayout);
