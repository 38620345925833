import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../Styleguide/LoadingSpinner/LoadingSpinner';
import IconComponent from '../Svg/IconComponent/IconComponent';
import useIsMobile from '../../hooks/useIsMobile';
import { COLORS } from '../../constants/Colors';

const AsyncStatus = ({ status }) => {
  const isMobile = useIsMobile();
  return useMemo(() => {
    if (status === false) {
      return null;
    }
    if (status === 'pending' || status === true) {
      return (
        <LoadingSpinner
          size='sm'
          desc={isMobile ? '' : 'Salvando alterações...'}
        />
      );
    }
    if (status === 'resolved') {
      return (
        <IconComponent
          group='standard'
          name='check'
          color={COLORS.success}
          className='mx-1'
          id='icon-success'
        />
      );
    }
    if (status === 'rejected') {
      return (
        <>
          <span className='me-1' style={{ color: COLORS.danger }}>
            Falha ao salvar
          </span>
          <IconComponent
            group='standard'
            name='warning'
            color={COLORS.danger}
            className='mx-1'
            id='icon-error'
          />
        </>
      );
    }
    return null;
  }, [status]);
};

AsyncStatus.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default AsyncStatus;
