const Bell = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '30', color = '#383C42', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M15 30C15.9946 30 16.9484 29.6049 17.6516 28.9016C18.3549 28.1984 18.75 27.2446 18.75 26.25H11.25C11.25 27.2446 11.6451 28.1984 12.3483 28.9016C13.0516 29.6049 14.0054 30 15 30Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 3.59594L13.5056 3.89781C11.8106 4.24319 10.2869 5.16363 9.19247 6.50335C8.09807 7.84308 7.50019 9.51978 7.5 11.2497C7.5 12.4272 7.24875 15.3691 6.63937 18.2659C6.33937 19.7041 5.93438 21.2022 5.39625 22.4997H24.6037C24.0656 21.2022 23.6625 19.7059 23.3606 18.2659C22.7512 15.3691 22.5 12.4272 22.5 11.2497C22.4994 9.52011 21.9013 7.84384 20.8069 6.5045C19.7126 5.16517 18.1891 4.245 16.4944 3.89969L15 3.59406V3.59594ZM26.6625 22.4997C27.0806 23.3378 27.5644 24.0016 28.125 24.3747H1.875C2.43562 24.0016 2.91937 23.3378 3.3375 22.4997C5.025 19.1247 5.625 12.8997 5.625 11.2497C5.625 6.71219 8.85 2.92469 13.1344 2.06031C13.1082 1.7996 13.137 1.5363 13.2188 1.28739C13.3007 1.03848 13.4338 0.809481 13.6095 0.615171C13.7853 0.420862 13.9999 0.265552 14.2394 0.159258C14.4789 0.0529646 14.738 -0.00195312 15 -0.00195312C15.262 -0.00195313 15.5211 0.0529646 15.7606 0.159258C16.0001 0.265552 16.2147 0.420862 16.3905 0.615171C16.5662 0.809481 16.6993 1.03848 16.7812 1.28739C16.863 1.5363 16.8918 1.7996 16.8656 2.06031C18.9849 2.49138 20.8901 3.64161 22.2586 5.31625C23.627 6.99089 24.3747 9.08701 24.375 11.2497C24.375 12.8997 24.975 19.1247 26.6625 22.4997Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default Bell;
