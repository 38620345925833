import { useMemo } from 'react';

const HorizontalRuler = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = '#343A40', ...rest },
}) => {
  const lineNumber = useMemo(() => {
    let length = size;
    const arr = [];
    while (length > 0) {
      arr.push(length);
      length -= 8;
    }
    return arr;
  }, [size]);

  return (
    <svg
      width={size}
      height='20'
      viewBox={`0 0 ${size} 20`}
      fill={color}
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.8'
        x={size}
        y={0.5}
        width='21'
        height={size}
        transform={`rotate(90 ${size} 0.502441)`}
        fill='black'
        fillOpacity='0.8'
      />
      {lineNumber.map((value, i) => (
        <line
          key={`rulerH${value}`}
          x1={value}
          x2={value}
          y1={0}
          y2={i % 2 ? 11 : 16}
          stroke='white'
        />
      ))}
    </svg>
  );
};
export default HorizontalRuler;
