import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';

import { RootStoreProvider, useLocalStorage } from './hooks';
import Router from './components';
import Loader from './components/Loader/Loader';
import rootStore from './hooks/useStore';

import './App.scss';

const App = () => {
	const [token] = useLocalStorage('appToken');

	useEffect(() => {
		(async () => {
			if (window.location.pathname === '/logoff') {
				await rootStore.authStore.logout();
			}
			// Login automático
			if (token?.length) {
				const isValid = await rootStore.authStore.authenticate({ token });

				if (!isValid) {
					window.location.href = '/';
				}
			} else {
				rootStore.authStore.setStatus('unauthenticated');
				rootStore.authStore.setLoading(false);
			}
		})();
	}, []);

	if (rootStore?.authStore?.loading) {
		return <Loader />;
	}

	return (
		<RootStoreProvider>
			<Router />
			<ToastContainer />
		</RootStoreProvider>
	);
};

export default observer(App);
