import React from 'react';
import { Line } from 'react-konva';
import PropTypes from 'prop-types';

const NoseToEye = ({ filteredDots, stroke, strokeWidth }) => {
  const IOE = filteredDots.find((v) => v.categoryId === 'IOE');
  const IOD = filteredDots.find((v) => v.categoryId === 'IOD');
  const NAES = filteredDots.find((v) => v.categoryId === 'NAES');
  const NADS = filteredDots.find((v) => v.categoryId === 'NADS');
  const plusY = 20;
  return (
    <>
      <Line
        strokeWidth={strokeWidth}
        stroke={stroke}
        points={[IOE.x, IOE.y, IOE.x, NAES.y + plusY]}
      />
      <Line
        strokeWidth={strokeWidth}
        stroke={stroke}
        points={[NAES.x, NAES.y, NADS.x, NADS.y]}
      />
      <Line
        strokeWidth={strokeWidth}
        stroke={stroke}
        points={[IOD.x, IOD.y, IOD.x, NADS.y + plusY]}
      />
    </>
  );
};

NoseToEye.propTypes = {

  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

NoseToEye.defaultProps = {
  
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default NoseToEye;
