import { Image } from 'react-bootstrap';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import UpgradeNavBar from './UpgradeNavBar';
import haiahblacklogo from '../../../assets/images/icons-sidebar/haiahblacklogo.svg';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../constants/Colors';

const UpgradeLayout = ({ children }) => {
  const history = useHistory();

  return (
    <div className='d-flex flex-column w-100 align-items-center'>
      <div
        className='d-flex flex-row w-100 justify-content-center justify-content-md-between px-4 align-items-center bg-light'
        style={{ height: '70px' }}
      >
        <IconComponent
          group='standard'
          name='chevronleft'
          onClick={() => history.goBack()}
          color={COLORS.dark}
          className='justify-self-start d-none d-md-block'
        />
        <Image className='p-3' height='70' src={haiahblacklogo} />
        <div className='d-none d-md-block' />
      </div>
      <div className='d-flex flex-column w-100 align-items-center my-2'>
        <UpgradeNavBar />
      </div>
      {children}
    </div>
  );
};

UpgradeLayout.propTypes = {
  children: PropTypes.node,
};
UpgradeLayout.defaultProps = {
  children: '',
};
export default observer(UpgradeLayout);
