import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Image, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import SideBarIcon from '../SideBarIcon/SideBarIcon';
import { useToggle, useStore } from '../../../../hooks';

import IconComponent from '../../../Svg/IconComponent/IconComponent';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import { logohaiahwhite } from '../../../../assets/images/authentication';
import { COLORS } from '../../../../constants/Colors';
import NavbarDropdown from '../../../Styleguide/Dropdown/Navbar/NavbarDropdown';
import { useOutsideClickObserver } from '../../../../utils/useOutsiderClickObserver';
import sidebarDropdownData from '../SiderbarDropdown/Data/SidebarDropdownData';

const propTypes = {
	toggleIsOpen: PropTypes.func,
	isOpen: PropTypes.bool,
};

const defaultProps = {
	toggleIsOpen: () => {},
	isOpen: false,
};

const SideBarDesktop = (props) => {
	const { isOpen, toggleIsOpen } = props;

	const navDropRef = useRef(null);

	const store = useStore();

	const [modalIsOpen, toggleModal] = useToggle();

	const [isClicked, setIsClicked] = useState('');

	const location = useLocation();

	const logoutApplication = async () => {
		await store.authStore.logout();
	};

	const handleLogout = () => {
		toggleModal();
		toggleIsOpen(false);
		store.uiStore.setSideBarIsActive(false);
	};

	const handleOpen = (menuName) => {
		setIsClicked(menuName);
	};

	const handleClose = () => {
		setIsClicked('');
	};

	useOutsideClickObserver(navDropRef, setIsClicked, '');

	return (
		<>
			<Nav
				className='me-auto w-100 align-items-center d-none d-sm-flex flex-sm-column bg-dark 
				justify-content-between h-100 py-sm-3 zi-1050'
			>
				<div className='h-100 w-100 d-flex flex-column justify-content-between'>
					<div>
						<SideBarIcon
							title='Início'
							isActive={location.pathname === '/'}
							icon={{ group: 'standard', name: 'calendar' }}
							redirect='/'
							alt='Icone calendário'
							activeClassName='rounded'
							isOpen={isOpen}
						/>
						<SideBarIcon
							title='Pacientes'
							isActive={/(patients)/.test(location.pathname)}
							icon={{ group: 'standard', name: 'user' }}
							redirect='/patients'
							alt='Icone pessoa'
							activeClassName='rounded'
							isOpen={isOpen}
						/>
					</div>

					<div className={isOpen ? 'border-top border-grey mb-5' : 'h-15'}>
						<div
							className='position-relative me-0 ms-auto w-100'
							id='container-navbar-dropdown'
							ref={navDropRef}
						>
							{/* <OutsideWrapper> */}
							{sidebarDropdownData.map((navDropdown, index) => (
								<NavbarDropdown
									key={index}
									menu={{
										name: store.uiStore.sideBarIsActive ? navDropdown.name : '',
										className: `${
											isClicked === navDropdown.stateName
												? 'text-brand-green'
												: 'text-white'
										} m-0 align-self-center ps-2-5 fs-sm-6-5`,
										icon: {
											group: 'standard',
											name: navDropdown.iconName,
											size: '24',
											color:
												isClicked === navDropdown.stateName
													? COLORS.brandGreen
													: COLORS.white,
										},
									}}
									navItems={navDropdown.navItems}
									isClicked={isClicked === navDropdown.stateName}
									isSidebarOpen={store.uiStore.sideBarIsActive}
									handleOpen={() => handleOpen(navDropdown.stateName)}
									handleClose={handleClose}
									direction='end'
									chevron={
										<IconComponent
											group='standard'
											name={
												isClicked === navDropdown.stateName
													? 'chevronleft'
													: 'chevronright'
											}
											size='10'
											color={
												isClicked === navDropdown.stateName
													? '#00f1d0'
													: '#e5e5e5'
											}
										/>
									}
									id={`desktop-${index}`}
									index={index}
								/>
							))}

							{/* </OutsideWrapper> */}
						</div>
					</div>
				</div>

				<div
					className={clsx(
						'w-100 d-flex  flex-column align-items-center px-sm-0 pt-md-5 bg-dark',
						isOpen ? 'justify-content-between' : 'justify-content-end',
					)}
				>
					<Image
						src={logohaiahwhite}
						fluid
						className={isOpen ? 'px-4 py-3' : 'p-1 d-none'}
					/>
					<div
						className={clsx(
							'w-100 d-flex  flex-row align-items-center px-sm-2 pt-sm-3 border-top border-grey',
							isOpen ? 'justify-content-between' : 'justify-content-center',
						)}
					>
						{isOpen && store.uiStore.sideBarStatus && (
							<>
								<IconComponent
									onClick={handleLogout}
									group='standard'
									name='logout'
								/>
								<small style={{ fontSize: '0.7rem' }} className='text-grey'>
									V 1.0
								</small>
							</>
						)}
						<IconComponent
							group='standard'
							onClick={() => {
								store.uiStore.setSideBarIsActive(!isOpen);
							}}
							name={isOpen ? 'chevronleft' : 'chevronright'}
							size='14'
							color={isOpen ? '#00F1D0' : '#D7DCE2'}
						/>
					</div>
				</div>

				<ModalComponent
					show={modalIsOpen}
					onClose={toggleModal}
					subTitle='Deseja realmente sair?'
					nameButton='Confirmar'
					idButton='logout-confirm'
					onClickButton={logoutApplication}
					isDelete
				/>
			</Nav>
		</>
	);
};

export default observer(SideBarDesktop);

SideBarDesktop.propTypes = propTypes;
SideBarDesktop.defaultProps = defaultProps;
