import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useStore } from '../../../hooks';
import FilesLayoutSideBar from '../SideBar/FilesLayoutSideBar';
import FilesNavBar from './FilesNavBar';

const FilesLayout = ({ children }) => {
	const { uiStore } = useStore();

	const handleToggle = (status = false) => {
		// Workaround to persist the state of the sidebar when has a navigation;
		uiStore.setSideBarIsActive(status);
	};

	return (
		<div
			className='container m-0 p-0 vh-100 vw-100'
			id='container-files-layout'
		>
			<div className='row m-0 p-0 h-100 vw-100 overflow-hidden'>
				<div
					className={`row col-12 ${
						uiStore.sideBarIsActive
							? 'sidebar-desktop-active'
							: 'sidebar-desktop'
					} mh-xs-68px mh-sm-100 h-sm-100 bg-dark m-0 p-0`}
					id='col-files-layout-sidebar'
				>
					<FilesLayoutSideBar
						isOpen={uiStore.sideBarIsActive}
						handleToggle={handleToggle}
						uiStore={uiStore}
					/>
				</div>
				<div
					className={`d-flex m-0 p-0 w-100 h-100 ${
						uiStore.sideBarIsActive ? 'w-100-172' : 'w-100-70px'
					}`}
					id='col-files-layout-navbar-content'
				>
					<div className='h-auto h-sm-100 w-100'>
						<div
							className='row mnh-sm-10 w-100 m-0 p-0'
							id='row-files-layout-navbar'
						>
							<FilesNavBar />
						</div>
						<div className='row w-100 m-0 p-0' id='row-files-layout-content'>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(FilesLayout);

FilesLayout.propTypes = {
	children: PropTypes.node,
};

FilesLayout.defaultProps = {
	children: null,
};
