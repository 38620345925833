import { useHistory } from 'react-router-dom';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import SideBar from '../SideBar/SideBar';
import { useIsMobile } from '../../../hooks';

const ComparativeNavbar = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  if (isMobile) {
    return (
      <>
        <SideBar />
        <NavbarHeaderMobile content='nav' hasGoBack />
      </>
    );
  }
  return (
    <div className='bg-dark d-flex w-100 p-1'>
      <IconComponent
        group='standard'
        name='chevronleft'
        onClick={() => history.goBack()}
      />
    </div>
  );
};
export default ComparativeNavbar;
