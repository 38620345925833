import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import HomeNavbar from './HomeNavBar';
import MainLayout from '../MainLayout/MainLayout';

const HomeLayout = ({ children }) => (
  <MainLayout customNavBar={<HomeNavbar />}>{children}</MainLayout>
);

HomeLayout.propTypes = {
  children: PropTypes.node,
};
HomeLayout.defaultProps = {
  children: '',
};
export default observer(HomeLayout);
