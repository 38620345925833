const CheckCircle = ({
    // eslint-disable-next-line react/prop-types
    iconStyleProps: { size = '24', color = '#0AE2B9', ...rest },
  }) => (
    <svg 
        width={size}
        height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4353 2.31603C13.476 2.35667 13.5084 2.40495 13.5304 2.4581C13.5525 2.51125 13.5638 2.56824 13.5638 2.62578C13.5638 2.68333 13.5525 2.74031 13.5304 2.79346C13.5084 2.84661 13.476 2.89489 13.4353 2.93553L7.3103 9.06053C7.26966 9.10127 7.22138 9.1336 7.16823 9.15565C7.11508 9.17771 7.0581 9.18906 7.00055 9.18906C6.94301 9.18906 6.88603 9.17771 6.83287 9.15565C6.77972 9.1336 6.73144 9.10127 6.6908 9.06053L4.0658 6.43553C4.02513 6.39485 3.99286 6.34656 3.97084 6.29342C3.94883 6.24027 3.9375 6.18331 3.9375 6.12578C3.9375 6.06826 3.94883 6.01129 3.97084 5.95815C3.99286 5.905 4.02513 5.85671 4.0658 5.81603C4.10648 5.77535 4.15477 5.74309 4.20792 5.72107C4.26106 5.69906 4.31803 5.68773 4.37555 5.68773C4.43308 5.68773 4.49004 5.69906 4.54319 5.72107C4.59634 5.74309 4.64463 5.77535 4.6853 5.81603L7.00055 8.13216L12.8158 2.31603C12.8564 2.27529 12.9047 2.24296 12.9579 2.22091C13.011 2.19885 13.068 2.1875 13.1256 2.1875C13.1831 2.1875 13.2401 2.19885 13.2932 2.22091C13.3464 2.24296 13.3947 2.27529 13.4353 2.31603V2.31603Z" 
        fill="#0AE2B9"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.00001 2.18824C6.04819 2.18824 5.11774 2.47049 4.32633 2.99929C3.53491 3.5281 2.91808 4.27971 2.55384 5.15908C2.18959 6.03844 2.09429 7.00608 2.27998 7.93961C2.46567 8.87315 2.92402 9.73065 3.59706 10.4037C4.2701 11.0767 5.1276 11.5351 6.06114 11.7208C6.99467 11.9065 7.9623 11.8112 8.84167 11.4469C9.72104 11.0827 10.4727 10.4658 11.0015 9.67442C11.5303 8.88301 11.8125 7.95256 11.8125 7.00074C11.8125 6.88471 11.8586 6.77343 11.9407 6.69138C12.0227 6.60933 12.134 6.56324 12.25 6.56324C12.366 6.56324 12.4773 6.60933 12.5594 6.69138C12.6414 6.77343 12.6875 6.88471 12.6875 7.00074C12.6873 8.25272 12.2741 9.46967 11.5118 10.4628C10.7495 11.456 9.68087 12.1699 8.47151 12.4939C7.26216 12.8178 5.97972 12.7336 4.82308 12.2544C3.66643 11.7752 2.70023 10.9277 2.07431 9.84344C1.44838 8.75915 1.19772 7.49863 1.3612 6.25737C1.52468 5.01611 2.09315 3.86347 2.97847 2.97821C3.86378 2.09295 5.01646 1.52455 6.25773 1.36115C7.49901 1.19776 8.75951 1.4485 9.84376 2.07449C9.89604 2.10184 9.94225 2.13948 9.97961 2.18516C10.017 2.23083 10.0447 2.28359 10.0611 2.34025C10.0776 2.39692 10.0824 2.45633 10.0753 2.51491C10.0682 2.57348 10.0493 2.63001 10.0197 2.68109C9.99021 2.73218 9.95066 2.77676 9.90345 2.81216C9.85624 2.84756 9.80237 2.87305 9.74505 2.88709C9.68774 2.90113 9.62818 2.90343 9.56996 2.89385C9.51174 2.88427 9.45606 2.86302 9.40626 2.83136C8.67496 2.40847 7.84478 2.18659 7.00001 2.18824V2.18824Z" 
        fill="#0AE2B9"/>
    </svg>
  );
  export default CheckCircle;