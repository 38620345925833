// third
import axios, { isCancel } from 'axios';
import { makeObservable, observable, override, action } from 'mobx';
import { toast } from 'react-toastify';
// my
import { EDIT_TOOLS } from '../../constants/EditMenu';
import Files from '../models/Files';
import baseAPI, { baseURL } from '../../services/baseAPI';
import { analysisStore, defaultStore } from '.';
import getMessageErrors from '../../utils/getMessageErrors';
import Analysis from '../models/Analysis';
import DefaultStore from './defaultStore';
import convertHeicToJpg from '../../utils/convertHeicToJpg';

class GalleryStore extends DefaultStore {
	initFetch = false;

	/** @type {Files|null|undefined} image */
	image = null;

	imageList = [];

	isUploading = false;

	imageUpload = [];

	filterImages = [];

	filteredAlbumImage = [];

	editTool = null;

	editConfigMap = new Map();

	imageComparative = null;

	fileModalIsOpen = false;

	selectedImageName = '';

	selectedImageDate = '';
	
	keyWord = ''

	constructor() {
		super();
		makeObservable(this, {
			currentPage: override,
			setCurrentPage: override,
			addPageToCurrentPage: override,
			lastPage: override,
			setLastPage: override,
			isFetching: override,
			setIsFetching: override,
			isLoadingPromise: override,
			initFetch: observable,
			setInitFetch: action,
			toTransfer: override,
			setToTransfer: override,
			promises: override,
			setPromises: override,
			transferData: override,
			clearPromises: override,

			/** @type {Files|null|undefined} image */
			image: observable,
			imageList: observable,
			imageUpload: observable,
			isUploading: observable,
			setIsUploading: action,
			filterImages: observable,
			filteredAlbumImage: observable,
			editTool: observable,
			editConfigMap: observable,
			imageComparative: observable,
			fileModalIsOpen: observable,
			selectedImageName: observable,
			selectedImageDate: observable,
			setFileList: action,
			setImageListSelected: action,
			getListImage: action,
			updateImage: action,
			changeTypeImage: action,
			deleteImage: action,
			setAnalysisImage: action,
			setHasDots: action,
			resetCurrentPage: action,
			shareImageInEmail: action,
			keyWord: observable,
			setKeyWord: action,
			moveImageToAlbum: action,
		});
	}

	get imageId() {
		return this.image?.id || '';
	}

	setHasDots(hasDots) {
		this.image.hasDots = hasDots;
	}

	setAnalysisImage(analysis) {
		this.image.analysis = analysis;
	}

	setKeyWord(keyword){
		this.keyWord = keyword
	}

	setFileList(file) {
		this.imageList = [...this.imageList, file];
	}

	resetCurrentPage() {
		this.currentPage = 0;
	}

	// métodos síncronos
	getSelectedImageEdition() {
		const selectedEdit = {
			file_name: this.selectedImageName,
			date: this.selectedImageDate,
		};
		return selectedEdit;
	}

	setIsUploading(isUploading) {
		this.isUploading = isUploading;
	}

	setInitFetch(initFetch) {
		this.initFetch = initFetch;
	}

	editSelectedImage(payload) {
		if (!payload || Object.keys(payload).length === 0) {
			return false;
		}
		this.selectedImageName = payload.name;
		this.selectedImageDate = payload.date;
		return true;
	}

	clearFilteredImages() {
		this.filterImages = [];
	}

	clearFilteredAlbumImage() {
		this.filteredAlbumImage = [];
	}

	resetImageUpload() {
		this.imageUpload = [
			{
				name: '',
				progress: 0,
				error: '',
				done: false,
			},
		];
	}

	findImage(id) {
		return this.imageList?.find((img) => img.id === id);
	}

	setEditTool(tool) {
		this.editTool = tool;
	}

	toggleFileModal() {
		this.fileModalIsOpen = !this.fileModalIsOpen;
	}

	setEditConfig(tool, value) {
		this.editConfigMap.set(tool, value);
	}

	getEditConfig(tool) {
		return this.editConfigMap.get(tool);
	}

	deleteEditConfig(tool) {
		this.editConfigMap.delete(tool);
	}

	resetEditValues() {
		this.editConfigMap.delete('mobile_touched');
		this.editConfigMap.delete('touched');
		Object.keys(EDIT_TOOLS).forEach((v) =>
			this.editConfigMap.set(v, EDIT_TOOLS[v]),
		);
	}

	setImageUploadName(imageName, index) {
		this.imageUpload[index] = {
			name: imageName,
			progress: 0,
		};

		return this.imageUpload[index];
	}

	setImage(id) {
		this.image = this.findImage(id);
	}

	setImageListSelected(selected) {
		if (this.imageList !== null && selected === null) {
			this.imageList = [];
		}
	}

	// métodos assíncronos

	moveImageToAlbum(imageId, album) {

		const payload = {
			image_album_id: album.id,
		}

		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/analysis/gallery/move/${imageId}`, payload)
				)

				this.setIsFetching(false);

				toast.success(`Arquivo movido para ${album.album_name}`);
				
				return resolve(res);

			} catch (e) {
				this.setIsFetching(false);

				toast.error(e.response.data.Message);

				return reject(e);
			}
		})
	}

	filterAlbumImage(keyword, patientId, albumId) {

		const isDate = keyword instanceof Date
		this.addPageToCurrentPage(1);

		if (this.currentPage > this.lastPage) {
			return resolve(true);
		}
		
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/gallery?patient_id[eq]=${patientId}&album_id[eq]=${albumId}&${isDate ? `created_at[eq]=${keyword}` : `file_name[ilk]=${keyword}`}&page=${this.currentPage}`)
				);

				const { meta } = res.data;
				
				this.setLastPage(meta.last_page);
				this.setCurrentPage(meta.current_page)
				if(res.data.data){
					if (this.currentPage === 1) {
						this.imageList = [
							...res.data.data.map((v) => new Files(v)),
						];

						this.setIsFetching(false);

						return resolve(res);
					// eslint-disable-next-line
					}else{
						this.imageList = [
							...this.imageList,
							...res.data.data.map((v) => new Files(v)),
						];

						this.setIsFetching(false);

						return resolve(res);
					}
				}
				this.setIsFetching(false);

				return resolve(res);
			} catch (e) {
				this.isFetching = false;
				return reject(e);
			}
		});
	}

	resetImage() {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/analysis/gallery/reset/${this.imageId}`),
				);

				this.image.fillFields(res.data.data);
				this.image.editImage();

				this.setIsFetching(false);

				return resolve(res);
			} catch (e) {
				this.setIsFetching(false);

				return reject(e);
			}
		});
	}

	cropImage(payload) {
		return new Promise(async (resolve, reject) => {
			if (!payload) {
				return resolve(toast.error('Não foi possível ajustar esta imagem'));
			}

			const file = new File([payload], 'image.png', { type: 'image/png' });

			const form = new FormData();

			form.append('media', file);
			form.append('_method', 'PUT');

			try {
				const res = await this.isLoadingPromise(
					baseAPI.post(`/v1/analysis/gallery/edit/${this.image.id}`, form, {
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
						},
					}),
				);

				if (res.data) {
					this.image.fillFields(res.data.data);
					this.image.editImage();

					if (this.image.analysis && this.image.analysis.hasAnalysis) {
						await this.isLoadingPromise(
							analysisStore.resetAllData(this.imageId),
						);
					}

					this.setIsFetching(false);

					return resolve(res);
				}

				this.setIsFetching(false);

				return resolve(res);
			} catch (error) {
				this.setIsFetching(false);

				if (error.response) {
					toast.error(error.response.data.Message);
				}

				return reject(error);
			}
		});
	}

	renameFile(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/analysis/gallery/rename/${this.image.id}`, {
						file_name: payload.fileName,
					}),
				);

				if (res.data != null) {
					this.image = new Files(res.data.data);
					this.setIsFetching(false);
					return resolve(res);
				}

				this.setIsFetching(false);
				return resolve(res);
			} catch (e) {
				if (e.response) {
					toast.error(e.response.data.Message);
				}
				this.setIsFetching(false);
				return reject(e);
			}
		});
	}

	getListImage(patientId,albumId='', fileTypeId='', category='') {
		return new Promise(async (resolve, reject) => {
			try {
				this.addPageToCurrentPage(1);

				if (this.currentPage > this.lastPage) {
					return resolve(true);
				}

				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/gallery?
					patient_id[eq]=${patientId}&
					image_album_id[eq]=${albumId}&
					file_categories_id[eq]=${fileTypeId}&
					page=${this.currentPage}&
					image_type[eq]=${category}
				`)
					
				);
					
				const { meta } = res.data;

				this.setLastPage(meta.last_page);
				this.setCurrentPage(meta.current_page)
				if (res.data.data) {
					this.imageList = [
						...this.imageList,
						...res.data.data.map((v) => new Files(v)),
					];

					this.setIsFetching(false);

					return resolve(res);
				}

				this.setIsFetching(false);

				return resolve(res);
			} catch (e) {
				if (e.response) {
					toast.error(e.response.data.Message);
				}
				this.setIsFetching(false);
				return reject(e);
			}
		});
	}

	shareImageInEmail(imageId, data) {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsFetching(true);
				const res = await this.isLoadingPromise(
					baseAPI.post(`/v1/analysis/gallery/send-email/${imageId}`, data),
				);

				toast.success(res.data.Message);

				return resolve(res.data);
			} catch (error) {
				toast.error("Houve um erro ao enviar");

				return reject(error);
			} finally {
				this.setIsFetching(false);
			}
		});
	}

	saveImage(formData, i) {
		return new Promise(async (resolve, reject) => {
			try {
				// na defaultStore, setar a fonte de instância axios utilizada nessa request
				const { CancelToken } = axios;
				const source = CancelToken.source();

				defaultStore.setSourceAxios(source);

				this.setIsFetching(true);

				const newFile = await convertHeicToJpg(formData.get('media'));

				formData.set('media', newFile);

				const request = axios.request({
					url: `${baseURL}/v1/analysis/gallery`,
					method: 'POST',
					headers: {
						Authorization: `Bearer ${JSON.parse(
							localStorage.getItem('haiah_app@appToken'),
						)}`,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					timeout: 7200000,
					cancelToken: source.token,
					data: formData,
					// onUploadProgress: (progressEvent) => {
					//   const { loaded, total } = progressEvent;

					//   const percent = Math.floor((loaded * 100) / total);

					// if (percent < 100) {
					// }
					// },
				});

				const res = await this.isLoadingPromise(request);

				// incrementar lista de arquivos com dados recebidos da requisição
				this.setFileList(new Files(res.data.data));

				// caso haja dados, atualizar informação de status de upload do arquivo
				if (res.data) {
					this.imageUpload[i] = {
						...this.imageUpload[i],
						done: true,
					};

					// alterar na defaultStore o que está sendo transferido no momento
					defaultStore.updateTransferData(this.imageUpload[i].name, {
						done: this.imageUpload[i].done,
						name: this.imageUpload[i].name,
						loading: false,
					});

					// limpar dados de transferência
					defaultStore.resetToTransfer();

					this.setIsUploading(false);

					return resolve(res);
				}

				this.setIsUploading(false);
				return resolve(res);
			} catch (e) {
				if (isCancel(e)) {
					defaultStore.updateTransferData({
						done: false,
						error: 'Operação cancelada pelo usuário.',
						name: Object.keys(defaultStore.transferData)[i],
					});
				}

				if (e?.response?.data?.Message?.media) {
					toast.error(e.response.data.Message.media[0]);
				}

				// se a operação for cancelada pelo usuário não terá nem resposta muito menos terá status
				if (!e?.response || !e?.response?.status) {
					defaultStore.setHiddenRequestModalProps({
						text: '',
						title: '',
					});

					defaultStore.updateTransferData(this.imageUpload[i].name, {
						done: true,
						error: 'Falha ao enviar arquivo',
						name: this.imageUpload[i].name,
						loading: false,
					});
					
					toast.error('Houve um erro no servidor');

					return reject(e);
				}

				this.imageUpload[i] = {
					...this.imageUpload[i],
					done: true,
					error:
						e.response.status === 413
							? 'Tamanho máximo excedido de 256 mb'
							: getMessageErrors(e.response.data.Message),
				};

				defaultStore.updateTransferData(this.imageUpload[i].name, {
					done: this.imageUpload[i].done,
					error: this.imageUpload[i],
					name: this.imageUpload[i].name,
					loading: false,
				});

				// defaultStore.setTransferData(null, this.imageUpload[i]);

				defaultStore.setHiddenRequestModalProps({
					title: '',
					text: '',
				});

				defaultStore.resetToTransfer();

				if (e.response) {
					toast.error(e.response);
				}

				this.setIsUploading(false);

				return reject(e);
			}
		});
	}

	updateImage(payload, id = null) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.put(`/v1/analysis/gallery/${id || this.image?.id}`, payload),
				);

				const findIndex = this.imageList.findIndex(
					(v) => v.id === (id || this.image.id),
				);
				const newImage = new Files(res.data.data);
				if (findIndex > -1) {
					this.imageList = [
						...this.imageList.slice(0, findIndex),
						newImage,
						...this.imageList.slice(findIndex + 1),
					];
				}
				if (this.image != null) {
					this.image = newImage;
				}
				toast(res.data.Message);
				this.setIsFetching(false);
				return resolve(res);
			} catch (error) {
				this.isFetching = false;
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				return reject(res);
			}
		});
	}

	changeTypeImage(id, imageType) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await baseAPI.put(`/v1/analysis/gallery/type/${id}`, {
					image_type: imageType,
				});

				if (res.data != null) {
					if (this.imageList?.length > 0) {
						const findIndex = this.imageList.findIndex((v) => v.id === id);
						if (findIndex > -1) {
							this.imageList = [
								...this.imageList.slice(0, findIndex),
								new Files(res.data.data),
								...this.imageList.slice(findIndex + 1),
							];
						}
						this.image = new Files(res.data.data);
					} else {
						this.image = new Files(res.data.data);
					}
				}

				return resolve(res.status === 200);
			} catch (e) {
				this.setIsFetching(false);

				if (e?.response) {
					toast.error(e.response.data.Message);
				}

				return reject(e);
			}
		});
	}

	deleteImage(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.delete(`/v1/analysis/gallery/${id}`),
				);

				if (res.data != null) {
					const indexFound = this.imageList.findIndex(
						(image) => image.id === id,
					);
					if (indexFound > -1) {
						this.imageList = [
							...this.imageList.slice(0, indexFound),
							...this.imageList.slice(indexFound + 1),
						];
					}
					toast(res.data.Message);
					this.setIsFetching(false);
					return resolve(true);
				}

				return resolve(false);
			} catch (e) {
				if (e.response) {
					this.setIsFetching(false);
					toast.error(e.response.data.Message);
				}
				return reject(e);
			}
		});
	}

	/**
	 * @returns {Files| boolean | Object}
	 */
	getImage(id, dontRefreshImage = false) {
		return new Promise(async (resolve, reject) => {
			if (!id) {
				return resolve(toast.error('Não é possível acessar essa imagem'));
			}

			analysisStore.clearAllAnalysisData();

			try {
				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/gallery/${id}`),
				);

				analysisStore.setGuideLines([]);
				if (!dontRefreshImage) {
					if (res.data != null) {
						if (this.image != null) {
							this.image.fillFields(res.data.data);
						} else {
							this.image = new Files(res.data.data);
						}
					}

					analysisStore.updateDotsAndAnalysis(res.data.data.analysis);

					if (res.data.data.analysis != null) {
						this.image.analysis = new Analysis(res.data.data.analysis);
					}

					return resolve(res);
				}

				return resolve(res);
			} catch (e) {
				toast.error(e.response);

				if (res.data) {
					this.imageList = [
						...this.imageList,
						...res.data.data.map((v) => new Files(v)),
					];
					this.setIsFetching(false);
					return reject(res);
				}

				this.setIsFetching(false);

				return reject(e);
			}
		});
	}
}

export default new GalleryStore();
