import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import IMask from 'imask';
import 'react-credit-cards/es/styles-compiled.css';

import Input from '../../Styleguide/Input/Input';
import InputRadio from '../../Styleguide/InputRadio/InputRadio';
import { PAYMENT_TYPE_OPTIONS } from '../../../constants/SelectOptions';
import BankSlipHaiah from '../../../assets/images/checkout/bank-slip-haiah.svg'

/**
 *
 * @param {object} props
 * @param {boolean} [props.disabled=false]
 * @returns {JSX.Element}
 */
export default function PaymentForm(props) {
  const { disabled } = props;

  /** @type {import('../utils').CheckoutValues} */
  const formik = useFormikContext();

  const cardNumberRef = useRef();
  const cardExpiryRef = useRef();
  const cardCvcRef = useRef();

  const [focused, setFocused] = useState('number');

  useEffect(() => {
    if (cardNumberRef.current != null) {
      IMask(cardNumberRef.current, {
        mask: '0000 0000 0000 0000',
      });
    }

    if (cardExpiryRef.current != null) {
      IMask(cardExpiryRef.current, {
        mask: '00/00',
      });
    }

    if (cardCvcRef.current != null) {
      IMask(cardCvcRef.current, {
        mask: '000',
      });
    }
  }, [formik.values.payment?.type]);

  const handlePaymentTypeChange = (value) => {
    formik.setFieldValue('payment.type', value);
  };

  const renderPaymentData = () => {
    switch (formik.values.payment.type) {
      case 'credit_card':
        return (
          <div>
            <div className='row mb-3'>
              <div className='d-flex col-12  justify-content-center'>
                  <Cards
                    cvc={formik.values.payment?.credit_card.cvc}
                    expiry={formik.values.payment?.credit_card.expiry}
                    name={formik.values.payment?.credit_card.name}
                    number={formik.values.payment?.credit_card.number}
                    focused={focused}
                    locale={{ valid: 'validade' }}
                    placeholders={{ name: 'nome' }}
                    style={{width: '100%'}}
                  />
              </div>
            </div>

            <Input
              ref={cardNumberRef}
              id='payment.credit_card.number'
              name='payment.credit_card.number'
              label='Número do cartão'
              type='text'
              disabled={disabled}
              onFocus={() => setFocused('number')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.payment?.credit_card.number}
              isInvalid={
                formik.touched.payment?.credit_card.number &&
                !!formik.errors.payment?.credit_card.number
              }
              error={formik.errors.payment?.credit_card.number}
              maxLength={20}
            />

            <Input
              id='payment.credit_card.name'
              name='payment.credit_card.name'
              label='Nome impresso no cartão'
              type='text'
              disabled={disabled}
              onFocus={() => setFocused('name')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.payment?.credit_card.name}
              isInvalid={
                formik.touched.payment?.credit_card.name &&
                !!formik.errors.payment?.credit_card.name
              }
              error={formik.errors.payment?.credit_card.name}
              maxLength={60}
            />

            <div className='row'>
              <div className='col-6'>
                <Input
                  ref={cardExpiryRef}
                  id='payment.credit_card.expiry'
                  name='payment.credit_card.expiry'
                  label='Validade'
                  type='text'
                  disabled={disabled}
                  onFocus={() => setFocused('expiry')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.payment?.credit_card.expiry}
                  isInvalid={
                    formik.touched.payment?.credit_card.expiry &&
                    !!formik.errors.payment?.credit_card.expiry
                  }
                  error={formik.errors.payment?.credit_card.expiry}
                  maxLength={6}
                />
              </div>

              <div className='col-6'>
                <Input
                  ref={cardCvcRef}
                  id='payment.credit_card.cvc'
                  name='payment.credit_card.cvc'
                  label='CVV'
                  type='text'
                  disabled={disabled}
                  onFocus={() => setFocused('cvc')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.payment?.credit_card.cvc}
                  isInvalid={
                    formik.touched.payment?.credit_card.cvc &&
                    !!formik.errors.payment?.credit_card.cvc
                  }
                  error={formik.errors.payment?.credit_card.cvc}
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        );

      case 'invoice':
        return (
          <div className="d-flex w-100 justify-content-center align-items-center align-content-center">
            <img src={BankSlipHaiah} className='fluid w-100' alt="Código de barras"/>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Form>
      <InputRadio
        id='payment.type'
        name='payment.type'
        disabled={disabled}
        inline
        value={formik.values.payment?.type}
        options={PAYMENT_TYPE_OPTIONS}
        onChange={handlePaymentTypeChange}
        radioOptionClass='fs-7'
      />

      {renderPaymentData()}

      <Form.Check id='payment.remember' name='payment.remember' type='checkbox'>
        <Form.Check.Input
          type='checkbox'
          value={formik.values.payment?.remember}
          onChange={(e) =>
            formik.setFieldValue('payment.remember', e.target.value)
          }
          disabled={disabled}
        />
        <Form.Check.Label className='fs-7'>
          Salvar método de pagamento para compras futuras
        </Form.Check.Label>
      </Form.Check>
    </Form>
  );
}

PaymentForm.propTypes = {
  disabled: PropTypes.bool,
};

PaymentForm.defaultProps = {
  disabled: false,
};
