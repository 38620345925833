/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../../hooks';
import CustomRouteComponent from './CustomRouteComponent';
import ExportModal from '../Tools/Analysis/ExportModal/ExportModal';
import EditRequiredModal from '../Tools/Analysis/EditRequiredModal/EditRequiredModal';
import HiddenRequest from '../HiddenRequest/HiddenRequest';
import HiddenRequestModal from '../HiddenRequest/Modal/HiddenRequestModal';
import TutorialContainer from '../Tutorial/TutorialContainer/TutorialContainer';

const PrivateRoute = ({ component, layout: Layout, ...rest }) => {
  const store = useStore();
  const CustomLayout = Layout || React.Fragment;

  return (
    <Route
      {...rest}
      render={(props) =>
        store.authStore.isAuthenticated ? (
          <CustomLayout>
            <CustomRouteComponent
              className='position-relative'
              component={component}
              key={`${document.location.href}${new Date().getMilliseconds()}`}
              {...props}
              {...rest}
            />
            <ExportModal />
            <EditRequiredModal />
            <HiddenRequestModal />
            <HiddenRequest />
            <TutorialContainer/>

          </CustomLayout>
        ) : (
          <Redirect from='/*' to='/login' />
        )
      }
    />
  );
};

export default observer(PrivateRoute);
