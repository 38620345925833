import { observer } from 'mobx-react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import { useStore } from '../../../hooks';

const LibraryListNavBar = () => {
  const history = useHistory();
  const store = useStore();
  const { id: patientId } = useParams();
  const {patientsStore } = store;
  return (
  <>
    <div className='d-flex d-sm-none w-100'>
      <NavbarHeaderMobile hasGoBack content='nav' goBackAction={() => history.push(`/patients/${patientId}/profile`)}/>
    </div>
    <div className='d-none d-sm-flex w-100 justify-content-between align-items-start p-0'>
      <NavbarHeader content='nav' title={patientsStore.patientSelected?.name}  hasTitle hasHelper/>
    </div>
  </>
);
}

export default observer(LibraryListNavBar);
