import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import { useStore } from '../../../../hooks/useStore'

const MoveImageModal = ({
	moveModalShow,
	setMoveModalShow,
	imageId,
	patientId,
}) => {

	const [moveTo, setMoveTo] = useState('')

	const { albumStore, galleryStore } = useStore();

	const handleMoveImage = () => {
		const album = albumStore.albumList.find(item => item.album_name === moveTo)

		galleryStore.moveImageToAlbum(imageId, album)

		return setMoveModalShow(false)
	}

	const renderInfiniteScroll = () => (
			<InfiniteScroll
				horizontal={false}
				dataLength={albumStore.albumList?.length}
				next={() => albumStore.getAlbumsByPage('', patientId)}
				hasMore={albumStore.currentPage !== albumStore.lastPage}
				scrollableTarget='scroll-select'
			>
				<p className='mb-1'>Selecione a consulta de destino</p>
				<select className='form-select' size={5} id='scroll-select' onChange={(e) => setMoveTo(e.target.value)}>
					{albumStore.albumList.map(item => (
					<option key={item.id} 
							value={item.album_name}
							disabled={albumStore.albumSelected?.album_name === item.album_name}
							className={`${albumStore.albumSelected?.album_name === item.album_name && 'text-black-50'}`}
					>
						{item.album_name}
					</option>
					))}
				</select>
			</InfiniteScroll>
		)

	return (
		<ModalComponent
			className='py-5'
			show={moveModalShow}
			onClose={() => setMoveModalShow(false)}
			title='Mover Arquivo'
			nameButton='Mover '
			idButton='move-file'
			disabled={!moveTo}
			onClickButton={handleMoveImage}
		>
			<div className='pt-4'>
				{renderInfiniteScroll()}
			</div>
		</ModalComponent>
	);
};
export default observer(MoveImageModal);

MoveImageModal.propTypes = {
	setMoveModalShow: PropTypes.func,
	moveModalShow: PropTypes.bool,
	imageId: PropTypes.number.isRequired,
	patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MoveImageModal.defaultProps = {
	setMoveModalShow: () => {},
	moveModalShow: false,
	patientId: null,
};
