/* eslint-disable camelcase */
import { makeObservable, observable } from 'mobx';

export default class Professional {
  id = null;

  cpfCnpj = null;

  credential = null;

  phone = null;

  whats = null;

  address = null;

  socialLinks = {};

  rg = null;

  credentialType = null;

  gender = null;

  constructor(newProfessional) {
    makeObservable(this, {
      cpfCnpj: observable,
      credential: observable,
      phone: observable,
      whats: observable,
      address: observable,
      socialLinks: observable,
      rg: observable,
      credentialType: observable,
      gender: observable,
      id: observable,
    });

    if (newProfessional == null || newProfessional.id == null) {
      throw new Error('Invalid user constructor');
    }

    const {
      id,
      cpfCnpj,
      credential,
      phone,
      whats,
      socialLinks,
      address,
      rg,
      credentialType,
      gender,
    } = newProfessional;

    this.id = id;
    this.cpfCnpj = cpfCnpj || '';
    this.credential = credential || '';
    this.phone = phone || '';
    this.whats = whats || '';
    this.address = address || '';
    this.socialLinks = socialLinks || {};
    this.rg = rg || '';
    this.credentialType = credentialType || '';
    this.gender = gender || '';
  }

  static fromApi({
    id,
    cpf_cnpj,
    credential,
    phone,
    whats,
    social_links,
    address,
    rg,
    credential_type,
    gender,
  } = {}) {
    return new Professional({
      id,
      cpfCnpj: cpf_cnpj,
      credential,
      phone,
      whats,
      address,
      socialLinks: social_links,
      rg,
      credentialType: credential_type,
      gender,
    });
  }
}
