const DoubleArrow = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 19 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8535 9.87503C12.9261 9.80227 13.0123 9.74455 13.1072 9.70517C13.2022 9.66578 13.3039 9.64551 13.4067 9.64551C13.5094 9.64551 13.6112 9.66578 13.7061 9.70517C13.801 9.74455 13.8872 9.80227 13.9598 9.87503L18.6473 14.5625C18.7201 14.6351 18.7778 14.7213 18.8172 14.8162C18.8565 14.9111 18.8768 15.0129 18.8768 15.1157C18.8768 15.2184 18.8565 15.3202 18.8172 15.4151C18.7778 15.51 18.7201 15.5962 18.6473 15.6688L13.9598 20.3563C13.8131 20.503 13.6141 20.5854 13.4067 20.5854C13.1992 20.5854 13.0002 20.503 12.8535 20.3563C12.7068 20.2096 12.6244 20.0106 12.6244 19.8032C12.6244 19.5957 12.7068 19.3967 12.8535 19.25L16.9895 15.1157L12.8535 10.9813C12.7808 10.9087 12.7231 10.8225 12.6837 10.7276C12.6443 10.6327 12.624 10.5309 12.624 10.4282C12.624 10.3254 12.6443 10.2236 12.6837 10.1287C12.7231 10.0338 12.7808 9.9476 12.8535 9.87503V9.87503Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.12542 15.1157C0.12542 14.9085 0.20773 14.7097 0.354243 14.5632C0.500756 14.4167 0.69947 14.3344 0.90667 14.3344H17.3129C17.5201 14.3344 17.7188 14.4167 17.8653 14.5632C18.0119 14.7097 18.0942 14.9085 18.0942 15.1157C18.0942 15.3229 18.0119 15.5216 17.8653 15.6681C17.7188 15.8146 17.5201 15.8969 17.3129 15.8969H0.90667C0.69947 15.8969 0.500756 15.8146 0.354243 15.6681C0.20773 15.5216 0.12542 15.3229 0.12542 15.1157ZM6.14729 0.500029C6.22005 0.572601 6.27777 0.658813 6.31716 0.753727C6.35654 0.848641 6.37682 0.950393 6.37682 1.05315C6.37682 1.15592 6.35654 1.25767 6.31716 1.35258C6.27777 1.4475 6.22005 1.53371 6.14729 1.60628L2.01136 5.74065L6.14729 9.87503C6.21993 9.94767 6.27755 10.0339 6.31686 10.1288C6.35617 10.2237 6.37641 10.3254 6.37641 10.4282C6.37641 10.5309 6.35617 10.6326 6.31686 10.7275C6.27755 10.8224 6.21993 10.9086 6.14729 10.9813C6.07466 11.0539 5.98842 11.1115 5.89352 11.1508C5.79861 11.1902 5.69689 11.2104 5.59417 11.2104C5.49144 11.2104 5.38973 11.1902 5.29482 11.1508C5.19991 11.1115 5.11368 11.0539 5.04104 10.9813L0.353545 6.29378C0.28079 6.22121 0.223067 6.135 0.183682 6.04008C0.144297 5.94517 0.124023 5.84342 0.124023 5.74065C0.124023 5.63789 0.144297 5.53614 0.183682 5.44123C0.223067 5.34631 0.28079 5.2601 0.353545 5.18753L5.04104 0.500029C5.11362 0.427274 5.19983 0.369551 5.29474 0.330166C5.38966 0.290781 5.49141 0.270508 5.59417 0.270508C5.69693 0.270508 5.79868 0.290781 5.8936 0.330166C5.98851 0.369551 6.07472 0.427274 6.14729 0.500029V0.500029Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.90625 5.74072C0.90625 5.53352 0.98856 5.33481 1.13507 5.1883C1.28159 5.04178 1.4803 4.95947 1.6875 4.95947H18.0938C18.301 4.95947 18.4997 5.04178 18.6462 5.1883C18.7927 5.33481 18.875 5.53352 18.875 5.74072C18.875 5.94792 18.7927 6.14664 18.6462 6.29315C18.4997 6.43966 18.301 6.52197 18.0938 6.52197H1.6875C1.4803 6.52197 1.28159 6.43966 1.13507 6.29315C0.98856 6.14664 0.90625 5.94792 0.90625 5.74072Z'
      fill={color}
    />
  </svg>
);
export default DoubleArrow;
