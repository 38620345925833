import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import PatientDataNavBar from './PatientDataNavBar';
import MainLayout from '../MainLayout/MainLayout';

const PatientDataLayout = ({ children }) => (
  <MainLayout customNavBar={<PatientDataNavBar />}>{children}</MainLayout>
);

PatientDataLayout.propTypes = {
  children: PropTypes.node,
};
PatientDataLayout.defaultProps = {
  children: '',
};
export default observer(PatientDataLayout);
