import PropTypes from 'prop-types';
import IconComponent from '../../../components/Svg/IconComponent/IconComponent';

const ColorSquare = ({ colors, handleClick, selectedColor }) => (
	<>
		{colors?.map((color, i) => (
			<button
				type='submit'
				onClick={() => handleClick(color)}
				className={`col-3 mx-1 mb-0-5 reset-button m-0 p-0 position-relative rounded opacity-70 color-picker-bg-${color} ${
					selectedColor === color ? `border border-1 border-${color}` : ''
				}`}
				style={{ minWidth: '28px', minHeight: '28px' }}
				key={color + i}
				id={`btn-color-picker-${i}`}
			>
				<IconComponent
					group='standard'
					color='#fff'
					name='check'
					className={`position-absolute top-50 start-50 translate-middle ${
						selectedColor === color ? 'd-block' : 'd-none'
					}`}
					size='14'
				/>
			</button>
		))}
	</>
);

export default ColorSquare;

ColorSquare.propTypes = {
	colors: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleClick: PropTypes.func.isRequired,
	selectedColor: PropTypes.string.isRequired,
};
