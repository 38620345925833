/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';
import { BASE3x4 } from '../../constants/ToolsConstants';
import Analysis from './Analysis';

class Files {
  id = null;

  albumId = null;

  /** @type {Analysis}   */
  analysis = null;

  width = null;

  height = null;

  fileName = null;

  fileType = null;

  imageType = null;

  fileExt = null;

  filesize = null;

  editVersion = 0;

  media = null;

  updated = null;

  date = null;

  obs = null;

  hasDots = false;

  isReseting = false;

  constructor(options = {}) {
    makeAutoObservable(this, {});
    this.fillFields(options);
  }

  fillFields({
    id,
    album_id,
    analysis,
    width,
    height,
    file_name,
    file_type,
    image_type,
    file_ext,
    filesize,
    media,
    updated,
    date,
    obs,
  } = {}) {
    this.id = id;
    this.albumId = album_id;
    this.analysis = analysis != null ? new Analysis(analysis) : analysis;
    this.width = width;
    this.height = height;
    this.fileName = file_name;
    this.fileType = file_type;
    this.imageType = image_type;
    this.fileExt = file_ext;
    this.fileSize = filesize;
    this.media = media;
    this.updated = updated;
    this.date = date;
    this.obs = obs;
  }

  editImage() {
    const lastVersion = this.editVersion;
    this.editVersion += 1;
    this.media = this.media.replace(
      `&no-cache=${lastVersion}`,
      `&no-cache=${this.editVersion}`,
    );
  }
  
  setIsReseting(isReseting) {
    this.isReseting = isReseting;
  }

  get hasRightProportion() {
    if (this.width != null && this.height != null) {
      return Number((this.width / this.height).toFixed(2)) === BASE3x4 && !this.isReseting;
    }
    return false;
  }

  get hasAnalysis() {
    if (this.analysis != null) {
      return this.analysis.analysis != null && this.analysis.analysis !== '';
    }
    return false;
  }

  get temPontos() {
    if (this.analysis != null) {
      if (this.analysis.dots != null) {
        return !!this.analysis.dots.length;
      }
      return false;
    }
    return false;
  }
}

export default Files;
