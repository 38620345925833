const HomeIcon = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M9.75 16.4925V21.75C9.75 21.9489 9.67098 22.1397 9.53033 22.2803C9.38968 22.421 9.19891 22.5 9 22.5H3C2.80109 22.5 2.61032 22.421 2.46967 22.2803C2.32902 22.1397 2.25 21.9489 2.25 21.75V11.25C2.24983 11.1514 2.26908 11.0538 2.30665 10.9627C2.34423 10.8716 2.3994 10.7888 2.469 10.719L11.469 1.719C11.5387 1.64915 11.6214 1.59374 11.7126 1.55593C11.8037 1.51812 11.9014 1.49866 12 1.49866C12.0987 1.49866 12.1963 1.51812 12.2875 1.55593C12.3786 1.59374 12.4613 1.64915 12.531 1.719L21.531 10.719C21.6006 10.7888 21.6558 10.8716 21.6933 10.9627C21.7309 11.0538 21.7502 11.1514 21.75 11.25V21.75C21.75 21.9489 21.671 22.1397 21.5303 22.2803C21.3897 22.421 21.1989 22.5 21 22.5H15C14.8011 22.5 14.6103 22.421 14.4697 22.2803C14.329 22.1397 14.25 21.9489 14.25 21.75V16.5C14.25 16.125 13.875 15.75 13.5 15.75H10.5C10.125 15.75 9.75 16.125 9.75 16.4925Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.5 3.75V9L16.5 6V3.75C16.5 3.55109 16.579 3.36032 16.7197 3.21967C16.8603 3.07902 17.0511 3 17.25 3H18.75C18.9489 3 19.1397 3.07902 19.2803 3.21967C19.421 3.36032 19.5 3.55109 19.5 3.75Z'
      fill={color}
    />
  </svg>
);
export default HomeIcon;
