import PropTypes from 'prop-types';
import React from 'react';
import IconComponent from '../../../../Svg/IconComponent/IconComponent';
import { useToggle } from '../../../../../hooks';

const FaceType = ({ showText, analysis }) => {
  const [details, toggleDetails] = useToggle(false);
  return (
    <div className='d-flex flex-column'>
      {details ? (
        <div className='flex-fill p-2'>
          {showText && (
            <>
              <div className='text-primary d-flex flex-row justify-content-between'>
                <div className='text-white'>Tipo sugerido:</div>
                <div className='ms-2'>
                  <IconComponent
                    group='analysis'
                    name='minimize'
                    size='16'
                    color='white'
                    onClick={() => toggleDetails()}
                  />
                </div>
              </div>
              <span className='text-success'>{analysis.type}</span>
              <div className='text-white'>Obtido:</div>

              <span className='text-warning'>
                {analysis.proportion.toFixed(2)}º
              </span>
            </>
          )}
        </div>
      ) : (
        <div className='flex-fill p-2'>
          <div className='text-primary d-flex flex-row justify-content-between'>
            <div className='text-white'>Tipo sugerido:</div>
            <div className='ms-2'>
              <IconComponent
                group='analysis'
                name='expand'
                size='16'
                color='white'
                onClick={() => toggleDetails()}
              />
            </div>
          </div>
          {showText && <span className='text-white'>{analysis.type}</span>}
        </div>
      )}
    </div>
  );
};

FaceType.propTypes = {
  showText: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

FaceType.defaultProps = {
  showText: false,
  analysis: null,
};

export default FaceType;
