import React from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import IconNavbar from '../../Styleguide/IconNavbar/IconNavbar';
import CalendarTools from '../../CalendarTools/CalendarTools';
import { useStore } from '../../../hooks';

const HomeNavBar = () => {
	const { calendarStore } = useStore();
	const location = useLocation();

	return (
		<div className='d-sm-block'>
			<NavbarHeader
				hasTitle
				hasHelper
				title={<h4 className='d-none d-sm-block'>Agenda</h4>}
			>
				<div className='d-flex justify-content-center align-items-center w-md-100'>
					<IconNavbar
						redirect='/patients'
						nameButton='Buscar um paciente'
						icon={{ group: 'standard', name: 'search' }}
						isActive={/(status)/.test(location.pathname)}
					/>
					<IconNavbar
						redirect='/patients/register'
						nameButton='Adicionar um paciente'
						icon={{ group: 'standard', name: 'personplus' }}
						isActive={/(profile)/.test(location.pathname)}
						id='btn-user-data'
					/>
					<button
						type='button'
						className='reset-button'
						id='btn-open-new-event'
						onClick={() => calendarStore.setIsFullscreenModalOpen(true)}
					>
						<IconNavbar
							redirect='/'
							nameButton='Criar evento'
							icon={{ group: 'standard', name: 'calendarPlus' }}
							isActive={/(profile)/.test(location.pathname)}
							id='btn-user-data'
						/>
					</button>
					
				</div>
			</NavbarHeader>
			<div
				className='d-flex w-100 justify-content-center align-items-center px-0'
				id='container-calendar-tools'
			>
				<div className='d-flex col-12 col-md-10 col-lg-8 py-2 justify-content-between align-items-center'>
					<CalendarTools />
				</div>
			</div>
		</div>
	);
};

export default observer(HomeNavBar);
