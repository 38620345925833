import { Button } from "react-bootstrap";
import IconComponent from "../../Svg/IconComponent/IconComponent";
import { useTutorialContext } from '../../../stores/contexts/TutorialContext'

const SkipTutorialButton = () =>{ 
    
    const {setIsModalOpened, setIsCardOpened} = useTutorialContext()

    return(
    <Button  className='mx-auto fs-6 col-7 col-sm-5 bg-negative-red text-white border-0' onClick={()=>{
        setIsModalOpened(false)
        setIsCardOpened(false)
        }}> 
        Pular{' '}
        <IconComponent
            group='standard'
            name='chevronright'
            size='14'
        />
    </Button>
)}

export default SkipTutorialButton;