const Info = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = { size }, color = '#383C42', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15V15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16V16Z'
      fill={color}
    />
    <path
      d='M8.92995 6.58789L6.63995 6.87489L6.55795 7.25489L7.00795 7.33789C7.30195 7.40789 7.35995 7.51389 7.29595 7.80689L6.55795 11.2749C6.36395 12.1719 6.66295 12.5939 7.36595 12.5939C7.91095 12.5939 8.54395 12.3419 8.83095 11.9959L8.91895 11.5799C8.71895 11.7559 8.42695 11.8259 8.23295 11.8259C7.95795 11.8259 7.85795 11.6329 7.92895 11.2929L8.92995 6.58789Z'
      fill={color}
    />
    <path
      d='M8 5.5C8.55228 5.5 9 5.05228 9 4.5C9 3.94772 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5Z'
      fill={color}
    />
  </svg>
);
export default Info;

