import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../MainLayout/MainLayout';
import PhotoToolsNavBar from './PhotoToolsNavBar';

const PhotoToolsLayout = ({ children }) => (
  <MainLayout customNavBar={<PhotoToolsNavBar />}>{children}</MainLayout>
);

PhotoToolsLayout.propTypes = {
  children: PropTypes.node,
};
PhotoToolsLayout.defaultProps = {
  children: '',
};
export default observer(PhotoToolsLayout);
