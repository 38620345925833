import { HAIAH_PREFIX } from '../constants/StorageConstants';

const useLocalStorage = (key = '') => {
  const state =
    JSON.parse(localStorage.getItem(`${HAIAH_PREFIX}@${key}`)) || {};

  const setState = (newState) => {
    localStorage.setItem(`${HAIAH_PREFIX}@${key}`, JSON.stringify(newState));
  };

  return [state, setState];
};

export default useLocalStorage;
