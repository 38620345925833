import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
	Button,
	Col,
	Container,
	Image,
	Nav,
	Row,
	Spinner,
} from 'react-bootstrap';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import { useStore, useToggle } from '../../../../hooks';
import SideBarIcon from '../SideBarIcon/SideBarIcon';
import logohaiahmobile from '../../../../assets/images/icons-sidebar/haiahwhitelogo.svg';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { mobileAvatar } from '../../../../assets/images/authentication/index';
import Overlay from '../../../Styleguide/Overlay/Overlay';
// import SearchInput from '../../../Styleguide/SearchInput/SearchInput';
import { COLORS } from '../../../../constants/Colors';
import { UserTranslation } from '../../../../constants/UserDocsOptions';
import refreshFileSignature from '../../../../utils/refreshFileSignature';
import { HAIAH_PREFIX } from '../../../../constants/StorageConstants';
import NavbarDropdown from '../../../Styleguide/Dropdown/Navbar/NavbarDropdown';
import sidebarDropdownData from '../SiderbarDropdown/Data/SidebarDropdownData';

const propTypes = {
	isOpen: PropTypes.bool,
};

const defaultProps = {
	isOpen: false,
};

const SideBarMobile = (props) => {
	const { isOpen } = props;

	const store = useStore();
	const history = useHistory();
	const location = useLocation();

	const [modalShow, setModalShow] = useState(false);

	const [isClicked, setIsClicked] = useState('');

	const [menu, toggleMenu] = useToggle(false);

	const handleOpen = (menuName) => {
		setIsClicked(menuName);
	};

	const handleClose = () => {
		setIsClicked('');
	};

	const logoutApplication = async () => {
		await store.authStore.logout();
	};

	const handleClick = () => {
		store.authStore.setFetchingStatus('idle');
		history.push(`/user/status`);
	};

	const titleSidebar = useMemo(() => {
		if (location.pathname === '/patients') {
			return <div className='text-white'>Pacientes</div>;
		}

		if (location.pathname === '/') {
			return null;
		}

		if (/(user)/.test(location.pathname)) {
			return store.authStore.fetching ? (
				<>
					<Spinner animation='border' role='status' size='sm' /> Salvando
					dados...
				</>
			) : (
				<div className='text-truncate'>{store.authStore.user?.name}</div>
			);
		}
		return (
			<>
				{store.patientsStore.fetchingStatus === 'pending' ? (
					<>
						<Spinner animation='border' role='status' size='sm' />
						Salvando dados...
					</>
				) : (
					<div className='text-truncate'>
						{store.patientsStore.patientSelected?.name}
					</div>
				)}
			</>
		);
	}, [
		location.pathname,
		store.patientsStore.patientSelected?.name,
		store.patientsStore.isFetching,
		store.patientsStore.fetchingStatus,
		store.authStore.fetching,
	]);

	const refreshUserAvatarSignature = () => {
		const token = JSON.parse(localStorage.getItem(`${HAIAH_PREFIX}@appToken`));

		if (token?.length) {
			const refreshedSignature = store.authStore.authenticate({
				token,
			});

			return refreshedSignature;
		}

		return true;
	};

	const getFirstAndLastName = (name) => {
		const names = name.split(' ');

		const newName =
			names.length > 1 ? `${names[0]} ${names[names.length - 1]}` : names[0];

		return newName;
	};

	useEffect(async () => {
		if (store.authStore.user?.image) {
			await refreshFileSignature(
				store.authStore.user?.image,
				refreshUserAvatarSignature,
			);
		}
	}, [store.authStore.user?.image]);

	return (
		<>
			{/* Interrogação mobile -> whats */}

			<Nav.Link className='d-flex justify-content-end d-flex d-sm-none p-0 text-end'>
				{/*  */}
			</Nav.Link>
			<Nav.Link
				to='/'
				className='py-1 link-dark text-decoration-none d-flex p-0 col-10'
			>
				<div className='d-none d-sm-flex flex-row align-items-center justify-content-center container col-12'>
					<Image
						onClick={handleClick}
						src={store.authStore.user?.image || mobileAvatar}
						alt='Logo'
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = mobileAvatar;
						}}
						width='40'
						height='40'
						className={clsx(
							'item-center d-flex of-cover d-none d-sm-block',
							isOpen ? 'border-success border  col' : 'border-white',
						)}
						roundedCircle
					/>

					{isOpen && (
						<div className='d-flex flex-column justify-content-start text-white col-11 ms-2 text-danger'>
							<span
								className='col-12 fs-6'
								style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{getFirstAndLastName(store.authStore.user?.name)}
							</span>
							<small className='fs-7'>
								{
									UserTranslation[store.authStore.professional?.credentialType]
										.PTBR
								}
							</small>
							<div className='row col-12'></div>
						</div>
					)}
				</div>

				<Nav className='text-white d-block d-sm-none text-center col-12'>
					{titleSidebar}
				</Nav>
			</Nav.Link>
			<Nav className='d-block d-sm-none col-1'>
				<Overlay
					icon={{
						group: 'standard',
						name: 'burgerMenu',
						size: 24,
						color: COLORS.greyBoard,
					}}
					backdropClassName='bg-dark'
					backdrop
					bgDark
					open={menu}
					setOpen={toggleMenu}
				>
					<Container fluid className='d-flex h-100 d-sm-none'>
						<Col className='px-1 d-flex flex-column h-100 mw-100'>
							<Row>
								<Col className='text-white mb-1 col-12 border-bottom border-grey'>
									<Row className=' pb-3 d-flex justify-content-between flex-row'>
										<Col className='d-flex flex-row justify-content-between'>
											<Image
												width='65'
												height='65'
												src={store.authStore.user?.image || mobileAvatar}
												onError={(e) => {
													e.target.onerror = null;
													e.target.src = mobileAvatar;
												}}
												className='rounded-circle mb-3 of-cover'
												onClick={handleClick}
											/>
											<IconComponent
												onClick={() => toggleMenu()}
												group='standard'
												name='closex'
												size='20'
												color='#7D848D'
											/>
										</Col>
									</Row>
									<Button
										onClick={handleClick}
										variant='link'
										className='justify-content-between d-flex flex-column text-decoration-none text-white d-flex flex-row w-100 text-start py-1 px-0'
									>
										<Col className='d-flex justify-content-between flex-row w-100 align-items-start'>
											<div className='d-flex flex-column w-100'>
												<h5 className='m-0 col-11 text-truncate'>
													{store.authStore.user?.name}
												</h5>
												<small className='col-11'>
													{
														UserTranslation[
															store.authStore.professional?.credentialType
														].PTBR
													}
												</small>
											</div>
											<IconComponent
												onClick={handleClick}
												className='mt-1 col-1'
												group='standard'
												name='chevronright'
												size='20'
												color='#7D848D'
											/>
										</Col>
									</Button>
								</Col>
							</Row>
							<Row>
								<Nav className='border-bottom pb-2 border-grey'>
									<SideBarIcon
										title='Início'
										// isActive={location.pathname === '/'}
										icon={{ group: 'standard', name: 'calendar' }}
										redirect='/'
										alt='Icone home'
									/>
									<SideBarIcon
										title='Pacientes'
										// isActive={location.pathname === '/patients'}
										icon={{ group: 'standard', name: 'userOutline' }}
										redirect='/patients'
										alt='Icone pessoa'
									/>
								</Nav>
								<div
									className='position-relative me-0 ms-auto w-100 px-0 mt-3'
									id='container-navbar-dropdown'
								>
									{sidebarDropdownData.map((navDropdown, index, list) => (
										<NavbarDropdown
											key={index}
											navDropClassName={`
												${
													index - 1 >= 0 // se não é o primeiro menu
														? 'rounded-top-xs-0'
														: 'rounded-top-xs-10 rounded-bottom-xs-0'
												}
												${
													index - 1 >= 0 &&
													isClicked &&
													isClicked !== navDropdown.stateName
														? 'mt-mobile-160'
														: ''
												}
												${
													index === 0
														? 'rounded-bottom-xs-0 '
														: isClicked === navDropdown.stateName
														? 'rounded-bottom-xs-0'
														: 'rounded-bottom-xs-10'
												}
												${isClicked === navDropdown.stateName ? 'pb-1' : ''}
													`}
											menu={{
												name: menu ? navDropdown.name : '',
												className: `${
													isClicked === navDropdown.stateName
														? 'text-brand-green'
														: 'text-white'
												} m-0 align-self-center ps-2-5 fs-sm-6-5`,
												icon: {
													group: 'standard',
													name: navDropdown.iconName,
													size: '24',
													color:
														isClicked === navDropdown.stateName
															? COLORS.brandGreen
															: COLORS.white,
												},
											}}
											navItems={navDropdown.navItems}
											isClicked={isClicked === navDropdown.stateName}
											isSidebarOpen={menu}
											handleOpen={() => handleOpen(navDropdown.stateName)}
											handleClose={handleClose}
											direction='down'
											chevron={
												<IconComponent
													group='standard'
													name={
														isClicked === navDropdown.stateName
															? 'chevronup'
															: 'chevrondown'
													}
													size='10'
													color={
														isClicked === navDropdown.stateName
															? '#00f1d0'
															: '#e5e5e5'
													}
												/>
											}
											id={`mobile-${index}`}
										/>
									))}
								</div>
							</Row>
							<Col className='d-flex justify-content-center align-items-end flex-fill col-12 w-100'>
								<div className='pb-3 justify-content-between w-100 d-flex align-items-center'>
									<small className='text-grey '>V 1.0</small>
									<Image src={logohaiahmobile} className='col-5' />
									<IconComponent
										onClick={() => setModalShow(true)}
										className='col-2'
										name='logout'
										group='standard'
									/>
								</div>
							</Col>
						</Col>
						<ModalComponent
							show={modalShow}
							onClose={() => setModalShow(false)}
							subTitle='Deseja realmente sair?'
							nameButton='Confirmar'
							onClickButton={() => logoutApplication()}
							isDelete
						/>
					</Container>
				</Overlay>
			</Nav>
		</>
	);
};

export default observer(SideBarMobile);

SideBarMobile.propTypes = propTypes;
SideBarMobile.defaultProps = defaultProps;
