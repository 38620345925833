import React  from 'react';
import { observer } from 'mobx-react';
import { useWindowSize } from 'react-use';
import NavbarHeader from '../../Styleguide/NavbarHeader/NavbarHeader';
import NavbarHeaderMobile from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';

const PatientDataRegisterNavBar = () => {
    const {width} = useWindowSize();
  if(width >= 576) {
      return  <NavbarHeader hasTitle title='Adicionar novo paciente' hasHelper />
    }
    return <NavbarHeaderMobile  hasGoBack />
};

export default observer(PatientDataRegisterNavBar);
