import { useState } from 'react';
import PropTypes from 'prop-types';

const useColorPicker = ({ defaultColor }) => {
	const [show, setShow] = useState(false);
	const [selectedColor, setSelectedColor] = useState(defaultColor);

	const handleClick = (color) => {
		setShow(false);
		setSelectedColor(color);
	};

	const handleShow = () => setShow(!show);

	return {
		handleShow,
		handleClick,
		show,
		selectedColor,
	};
};

export default useColorPicker;

useColorPicker.propTypes = {
	defaultColor: PropTypes.string.isRequired,
};
