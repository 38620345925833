const ArrowRight = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.719 6.96888C15.7887 6.89903 15.8714 6.84362 15.9625 6.80581C16.0537 6.768 16.1513 6.74854 16.25 6.74854C16.3486 6.74854 16.4463 6.768 16.5374 6.80581C16.6286 6.84362 16.7113 6.89903 16.781 6.96888L21.281 11.4689C21.3508 11.5385 21.4063 11.6213 21.4441 11.7124C21.4819 11.8035 21.5013 11.9012 21.5013 11.9999C21.5013 12.0985 21.4819 12.1962 21.4441 12.2873C21.4063 12.3784 21.3508 12.4612 21.281 12.5309L16.781 17.0309C16.6402 17.1717 16.4492 17.2508 16.25 17.2508C16.0508 17.2508 15.8598 17.1717 15.719 17.0309C15.5782 16.89 15.4991 16.699 15.4991 16.4999C15.4991 16.3007 15.5782 16.1097 15.719 15.9689L19.6895 11.9999L15.719 8.03088C15.6492 7.96121 15.5937 7.87844 15.5559 7.78733C15.5181 7.69621 15.4987 7.59853 15.4987 7.49988C15.4987 7.40122 15.5181 7.30354 15.5559 7.21242C15.5937 7.12131 15.6492 7.03854 15.719 6.96888Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.5 12C3.5 11.8011 3.57902 11.6103 3.71967 11.4697C3.86032 11.329 4.05109 11.25 4.25 11.25H20C20.1989 11.25 20.3897 11.329 20.5303 11.4697C20.671 11.6103 20.75 11.8011 20.75 12C20.75 12.1989 20.671 12.3897 20.5303 12.5303C20.3897 12.671 20.1989 12.75 20 12.75H4.25C4.05109 12.75 3.86032 12.671 3.71967 12.5303C3.57902 12.3897 3.5 12.1989 3.5 12Z'
      fill='white'
    />
  </svg>
);
export default ArrowRight;
