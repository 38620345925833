import { makeObservable, observable } from 'mobx';
import { mobileAvatar } from '../../assets/images/authentication';

export default class Patient {
  id = '';

  name = '';

  city = '';

  address = {};

  anamnese = {};

  number = '';

  phone1 = '';

  email = '';

  avatar = '';

  gender = '';

  cpf = '';

  complement = '';

  rg = '';

  birthdate = '';

  imageVersion = 0;

  state = null;

  constructor(newPatient) {
    makeObservable(this, {
      id: observable,
      name: observable,
      address: observable,
      city: observable,
      number: observable,
      complement: observable,
      phone1: observable,
      email: observable,
      avatar: observable,
      gender: observable,
      cpf: observable,
      rg: observable,
      birthdate: observable,
      imageVersion: observable,
      state: observable,
    });

    if (newPatient == null || newPatient.id == null) {
      throw new Error('invalid Patient');
    }

    const {
      id,
      name,
      address,
      anamnese,
      city,
      number,
      phone1,
      email,
      avatar,
      gender,
      cpf,
      complement,
      rg,
      birthdate,
      state,
    } = newPatient;
    this.id = String(id);
    this.name = String(name);
    this.address = address;
    this.anamnese = anamnese;
    this.phone1 = phone1 || '';
    this.email = email || '';
    this.avatar = avatar ? avatar : mobileAvatar;
    this.gender = String(gender);
    this.cpf = cpf || '';
    this.rg = rg || '';
    this.birthdate = birthdate;
    this.city = city || '';
    this.number = number || '';
    this.complement = complement || '';
    this.state = state || '';
  }

  fillFields(newPatient) {
    const {
      id,
      name,
      address,
      anamnese,
      city,
      number,
      phone1,
      email,
      avatar,
      gender,
      cpf,
      complement,
      rg,
      birthdate,
      state,
    } = newPatient;
    this.id = String(id);
    this.name = String(name);
    this.address = address;
    this.anamnese = anamnese;
    this.phone1 = phone1 || '';
    this.email = email || '';
    this.avatar = avatar ? avatar : mobileAvatar;
    this.gender = String(gender);
    this.cpf = cpf || '';
    this.rg = rg || '';
    this.birthdate = birthdate;
    this.city = city || '';
    this.number = number || '';
    this.complement = complement || '';
    this.state = state || '';
  }

  noCacheImage() {
    const lastVersion = this.imageVersion;
    this.imageVersion += 1;
    this.avatar = this.avatar.replace(
      `&no-cache=${lastVersion}`,
      `&no-cache=${this.imageVersion}`,
    );
  }

  static fromApi({
    id,
    name,
    email,
    address,
    anamnese,
    city,
    number,
    complement,
    phone1,
    avatar,
    gender,
    cpf,
    rg,
    birthdate,
    state,
  } = {}) {
    return new Patient({
      id,
      name,
      email,
      address,
      anamnese,
      city,
      number,
      complement,
      phone1,
      avatar,
      gender,
      cpf,
      rg,
      birthdate,
      state,
    });
  }
}
