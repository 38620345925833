import { Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const OverlayTriggered = (props) => {
  const { title, children, show, setShow } = props;

  return (
    <>
      <Offcanvas show={show} onHide={() => setShow()} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='text-center w-100 text-truncate'>
            {title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='d-flex justify-content-between flex-column'>
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

OverlayTriggered.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

OverlayTriggered.defaultProps = {
  title: '',
  show: false,
  children: '',
  setShow: () => {},
};

export default observer(OverlayTriggered);
