// third
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
// my
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';
import Input from '../../../Styleguide/Input/Input';

const EditImageModal = ({
  setEditModalShow,
  editModalShow,
  date,
  name,
  gallery,
  handleSubmitEdit,
}) => {
  const fileDate = date?.split('T')[0];

  useEffect(() => {
    gallery.editSelectedImage({ name, date: fileDate });
  }, [date, name]);
  const [editImage, setEditImage] = useState({ name, date: fileDate });

  const handleEditImage = () => {
    gallery.editSelectedImage(editImage);
    handleSubmitEdit();
    setEditModalShow(false);
  }

  return (
    <ModalComponent
      show={editModalShow}
      onClose={() => setEditModalShow(false)}
      title='Editar arquivo'
      isLoading={gallery.isFetching}
      disabled={gallery.isFetching}
      nameButton='Editar'
      idButton='edit-file'
      onClickButton={handleEditImage}
    >
      <Input
        value={editImage?.name}
        name='editAlbum'
        id='editAlbum'
        label='Nome do arquivo'
        onChange={(e) =>
          setEditImage((prev) => ({
            ...prev,
            name: e.target.value,
          }))
        }
        placeholder={name}
        maxLength={255}
      />
      <Input
        value={editImage?.date}
        name='date'
        id='date'
        type='date'
        label='Data do arquivo'
        max={format(new Date(), 'yyyy-MM-dd')}
        onChange={(e) =>
          setEditImage((prev) => ({
            ...prev,
            date: e.target.value,
          }))
        }
        placeholder='DD/MM/AAAA'
      />
    </ModalComponent>
  );
};
export default observer(EditImageModal);

EditImageModal.propTypes = {
  name: PropTypes.string,
  date: PropTypes.any,
  setEditModalShow: PropTypes.func,
  editModalShow: PropTypes.bool,
  gallery: PropTypes.any,
  handleSubmitEdit: PropTypes.func,
};

EditImageModal.defaultProps = {
  name: '',
  date: '',
  setEditModalShow: () => null,
  editModalShow: false,
  gallery: {},
  handleSubmitEdit: () => Promise.resolve(true),
};
