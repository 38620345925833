import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import IconComponent from '../../../Svg/IconComponent/IconComponent';
import NavbarDropdownItem from './Item/NavbarDropdownItem';

const NavbarDropdown = ({
	menu,
	children,
	navItems,
	className,
	handleOpen,
	handleClose,
	isClicked,
	isSidebarOpen,
	direction,
	chevron,
	id,
	index,
	navDropClassName,
}) => {
	const [isOnButtonItem, setOnButtonItem] = useState(false);

	return (
		<NavDropdown
			id={`navbar-dropdown-${id}`}
			onClick={isClicked ? isOnButtonItem ? null : handleClose : handleOpen}
			className={`${isClicked ? 'bg-grey-dropdown ' : 'bg-dark'} ${
				isSidebarOpen ? 'ms-sm-1-5' : 'ms-sm-1 mt-sm-3 mb-sm-2-5'
			} mt-0 mt-sm-2 py-3  py-sm-2 rounded-top-sm-0 rounded-start-sm-30 ${navDropClassName}`}
			drop={direction}
			autoClose='outside'
			title={
				<div
					className={
						className
							? className
							: 'd-flex align-items-center justify-content-between col-12'
					}
					id={`navbar-dropdown-${id}-title-container`}
				>
					<div
						className={` p-0 d-flex ${
							isSidebarOpen ? 'col-sm-11' : 'col-sm-10 justify-content-center'
						} `}
					>
						<IconComponent
							group={menu.icon.group}
							name={menu.icon.name}
							size={menu.icon.size}
							color={menu.icon.color}
						/>{' '}
						{menu?.name ? (
							<h6
								className={
									menu.className ? menu.className : 'm-0 align-self-center'
								}
							>
								{menu.name}
							</h6>
						) : null}
					</div>
					<div className={`${isSidebarOpen ? 'col-sm-1' : 'col-sm-2'} p-0`}>
						{chevron}
					</div>
				</div>
			}
		>
			{navItems.map(
				(
					{ href, itemName, icon, button, hasButton, disabledLink, onClick },
					i,
				) =>
					hasButton ? (
						<button
							onMouseOver={() => setOnButtonItem(true)}
							onMouseOut={() => setOnButtonItem(false)}
							type='button'
							className='w-100 reset-button'
							onClick={onClick}
							id={`btn-wrapper-nav-item-${i}`}
							key={i}
						>
							<NavbarDropdownItem
								i={i}
								index={index}
								disabledLink={disabledLink}
								href={href}
								icon={icon}
								itemName={itemName}
								hasButton={hasButton}
								button={button}
							/>
						</button>
					) : (
						<NavbarDropdownItem
							i={i}
							index={index}
							disabledLink={disabledLink}
							href={href}
							icon={icon}
							itemName={itemName}
							hasButton={hasButton}
							button={button}
							key={i}
						/>
					),
			)}
			{children}
		</NavDropdown>
	);
};

export default NavbarDropdown;

NavbarDropdown.propTypes = {
	menu: PropTypes.shape({
		name: PropTypes.string,
		className: PropTypes.string,
		icon: PropTypes.shape({
			group: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			size: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}),
	}).isRequired,
	children: PropTypes.node,
	navItems: PropTypes.arrayOf(
		PropTypes.shape({
			href: PropTypes.string.isRequired,
			itemName: PropTypes.string.isRequired,
			item: PropTypes.shape({
				group: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				size: PropTypes.string.isRequired,
				color: PropTypes.string.isRequired,
			}),
			onClick: PropTypes.func,
			button: PropTypes.node,
			hasButton: PropTypes.bool,
			disabledLink: PropTypes.bool,
		}).isRequired,
	).isRequired,
	className: PropTypes.string,
	handleOpen: PropTypes.func,
	handleClose: PropTypes.func,
	isClicked: PropTypes.bool,
	isSidebarOpen: PropTypes.bool,
	direction: PropTypes.string.isRequired,
	chevron: PropTypes.node.isRequired,
	id: PropTypes.string,
	index: PropTypes.number,
	navDropClassName: PropTypes.string,
};

NavbarDropdown.defaultProps = {
	className: '',
	handleOpen: () => {},
	handleClose: () => {},
	isClicked: false,
	children: <></>,
	isSidebarOpen: false,
	id: '',
	index: 0,
	navDropClassName: '',
};
