const CellPhone = ({
    // eslint-disable-next-line react/prop-types
    iconStyleProps: { size = '24', color = '#0091FF', ...rest },
  }) => (
    <svg width={size} height={size} viewBox="0 0 31 30" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g clipPath="url(#clip0_1315_30606)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.125 1.875H9.875C9.37772 1.875 8.90081 2.07254 8.54918 2.42417C8.19754 2.77581 8 3.25272 8 3.75V26.25C8 26.7473 8.19754 27.2242 8.54918 27.5758C8.90081 27.9275 9.37772 28.125 9.875 28.125H21.125C21.6223 28.125 22.0992 27.9275 22.4508 27.5758C22.8025 27.2242 23 26.7473 23 26.25V3.75C23 3.25272 22.8025 2.77581 22.4508 2.42417C22.0992 2.07254 21.6223 1.875 21.125 1.875ZM9.875 0C8.88044 0 7.92661 0.395088 7.22335 1.09835C6.52009 1.80161 6.125 2.75544 6.125 3.75V26.25C6.125 27.2446 6.52009 28.1984 7.22335 28.9016C7.92661 29.6049 8.88044 30 9.875 30H21.125C22.1196 30 23.0734 29.6049 23.7766 28.9016C24.4799 28.1984 24.875 27.2446 24.875 26.25V3.75C24.875 2.75544 24.4799 1.80161 23.7766 1.09835C23.0734 0.395088 22.1196 0 21.125 0L9.875 0Z" fill="#F7F7F7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5 26.25C15.9973 26.25 16.4742 26.0525 16.8258 25.7008C17.1775 25.3492 17.375 24.8723 17.375 24.375C17.375 23.8777 17.1775 23.4008 16.8258 23.0492C16.4742 22.6975 15.9973 22.5 15.5 22.5C15.0027 22.5 14.5258 22.6975 14.1742 23.0492C13.8225 23.4008 13.625 23.8777 13.625 24.375C13.625 24.8723 13.8225 25.3492 14.1742 25.7008C14.5258 26.0525 15.0027 26.25 15.5 26.25Z" fill="#F7F7F7"/>
        </g>
        <defs>
        <clipPath id="clip0_1315_30606">
        <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>
  );
  export default CellPhone;
  