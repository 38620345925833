import { Image } from 'react-bootstrap';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../../../hooks';

// import IconComponent from '../../../Svg/IconComponent/IconComponent';
import { COLORS } from '../../../../constants/Colors';
import Check from '../../../../assets/images/check-solid.svg';
import Xmark from '../../../../assets/images/xmark-solid.svg';
import Plus from '../../../../assets/images/plus-solid.svg';

const DotsTableRowIcon = ({
  isLoading,
  isActive,
  setActive,
  removeDot,
  nodeName,
}) => {
  const isMobile = useIsMobile();
  const [isHover, setIsHover] = useState(false);

  const icon = useMemo(() => {
    if (isHover && isActive && !isMobile) {
      return {
        name: 'closex',
        color: COLORS.danger,
        src: Xmark,
      };
    }
    if (isActive && !isMobile) {
      return {
        name: 'check',
        color: COLORS.success,
        src: Check,
      };
    }
    if (isActive && isMobile) {
      return {
        name: 'closex',
        color: COLORS.danger,
        src: Xmark,
      };
    }
    return {
      name: 'plus',
      color: COLORS.primary,
      src: Plus,
    };
  }, [isHover, isActive]);

  return (
    <>
      {!isLoading ? (
        <Image
        className='csr-pointer'
          src={icon.src}
          style={{ width: '20px', height: '20px' }}
          id={nodeName}
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseOut={() => {
            setIsHover(false);
          }}
          onClick={() =>
            !isLoading ? (isActive ? removeDot() : setActive()) : null
          }
        />
      ) : (
        <div className='spinner-border spinner-border-sm' />
      )}
    </>
  );
};

export default DotsTableRowIcon;

DotsTableRowIcon.propTypes = {
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  removeDot: PropTypes.func,
  nodeName: PropTypes.string,
};

DotsTableRowIcon.defaultProps = {
  isLoading: false,
  isActive: false,
  setActive: () => null,
  removeDot: () => null,
  nodeName: '',
};
