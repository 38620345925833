import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import Radio from './Radio';

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.name
 * @param {string|number} props.value
 * @param {string} props.label
 * @param {string} [props.error=null]
 * @param {boolean} [props.disabled=false]
 * @param {boolean} [props.isInvalid=false]
 * @param {boolean} [props.inline=false]
 * @param {{key: string, text: string, value: string| number, disabled?: boolean}[]} [props.options=[]]
 * @param {(value: string | number) => void} [props.onChange]
 * @returns {JSX.Element}
 */
const InputRadio = (props) => {
  const {
    id,
    name,
    value,
    label,
    error,
    disabled,
    inline = false,
    options = [],
    onChange = null,
    radioOptionClass = null,
  } = props;

  const handleChange = (e) => {
    onChange?.(e.target.value);
  };

  return (
    <Form.Group data-testid='radio-group' className='mb-3'>
      {label && <Form.Label testID='label'>{label}</Form.Label>}

      {options.map(({ key, text, value: opValue, disabled: opDisabled }) => (
        <Radio
          key={key}
          id={`${id}-${key}`}
          name={name}
          value={opValue}
          label={text}
          inline={inline}
          disabled={disabled || opDisabled}
          checked={value === opValue}
          onChange={handleChange}
          labelClass={radioOptionClass}
        />
      ))}

      <Form.Control.Feedback data-testid='error' type='invalid'>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default observer(InputRadio);

InputRadio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  radioOptionClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      disabled: PropTypes.bool,
    }),
  ),
};

InputRadio.defaultProps = {
  value: '',
  label: '',
  inline: false,
  error: '',
  disabled: false,
  onChange: null,
  radioOptionClass: null,
  options: [],
};
