const Logout = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.4691 17.031C17.3993 16.9613 17.3439 16.8785 17.3061 16.7874C17.2682 16.6963 17.2488 16.5986 17.2488 16.5C17.2488 16.4013 17.2682 16.3036 17.3061 16.2125C17.3439 16.1214 17.3993 16.0386 17.4691 15.969L21.4396 12L17.4691 8.03097C17.3283 7.89014 17.2492 7.69913 17.2492 7.49997C17.2492 7.30081 17.3283 7.1098 17.4691 6.96897C17.6099 6.82814 17.801 6.74902 18.0001 6.74902C18.1993 6.74902 18.3903 6.82814 18.5311 6.96897L23.0311 11.469C23.101 11.5386 23.1564 11.6214 23.1942 11.7125C23.232 11.8036 23.2515 11.9013 23.2515 12C23.2515 12.0986 23.232 12.1963 23.1942 12.2874C23.1564 12.3785 23.101 12.4613 23.0311 12.531L18.5311 17.031C18.4615 17.1008 18.3787 17.1562 18.2876 17.194C18.1965 17.2318 18.0988 17.2513 18.0001 17.2513C17.9015 17.2513 17.8038 17.2318 17.7127 17.194C17.6216 17.1562 17.5388 17.1008 17.4691 17.031Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.75 12C6.75 11.8011 6.82902 11.6103 6.96967 11.4697C7.11032 11.329 7.30109 11.25 7.5 11.25H21C21.1989 11.25 21.3897 11.329 21.5303 11.4697C21.671 11.6103 21.75 11.8011 21.75 12C21.75 12.1989 21.671 12.3897 21.5303 12.5303C21.3897 12.671 21.1989 12.75 21 12.75H7.5C7.30109 12.75 7.11032 12.671 6.96967 12.5303C6.82902 12.3897 6.75 12.1989 6.75 12Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 20.25C2.40326 20.25 1.83097 20.0129 1.40901 19.591C0.987053 19.169 0.75 18.5967 0.75 18V6C0.75 5.40326 0.987053 4.83097 1.40901 4.40901C1.83097 3.98705 2.40326 3.75 3 3.75H13.5C14.0967 3.75 14.669 3.98705 15.091 4.40901C15.5129 4.83097 15.75 5.40326 15.75 6V8.25C15.75 8.44891 15.671 8.63968 15.5303 8.78033C15.3897 8.92098 15.1989 9 15 9C14.8011 9 14.6103 8.92098 14.4697 8.78033C14.329 8.63968 14.25 8.44891 14.25 8.25V6C14.25 5.80109 14.171 5.61032 14.0303 5.46967C13.8897 5.32902 13.6989 5.25 13.5 5.25H3C2.80109 5.25 2.61032 5.32902 2.46967 5.46967C2.32902 5.61032 2.25 5.80109 2.25 6V18C2.25 18.1989 2.32902 18.3897 2.46967 18.5303C2.61032 18.671 2.80109 18.75 3 18.75H13.5C13.6989 18.75 13.8897 18.671 14.0303 18.5303C14.171 18.3897 14.25 18.1989 14.25 18V15.75C14.25 15.5511 14.329 15.3603 14.4697 15.2197C14.6103 15.079 14.8011 15 15 15C15.1989 15 15.3897 15.079 15.5303 15.2197C15.671 15.3603 15.75 15.5511 15.75 15.75V18C15.75 18.5967 15.5129 19.169 15.091 19.591C14.669 20.0129 14.0967 20.25 13.5 20.25H3Z'
      fill={color}
    />
  </svg>
);
export default Logout;
