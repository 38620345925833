import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useStore } from '../../../hooks';

const HiddenRequestModal = () => {
  const [show, setShow] = useState(false);
  const { defaultStore } = useStore();

  const handleClose = () => {
    defaultStore.transferData = {};
    setShow(false);
    defaultStore.setHiddenRequestModalProps({
      title: '',
      text: '',
      buttonLabel: '',
      buttonAction: () => {},
      hasSecondButton: false,
      secondButtonLabel: '',
      secondButtonAction: () => {},
    });
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant='primary'
        id='btn-show-modal-hidden-request'
        hidden
        onClick={handleShow}
      >
        Abrir
      </Button>

      <Button
        variant='primary'
        id='btn-close-modal-hidden-request'
        hidden
        onClick={handleClose}
      >
        Fechar
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='col-12'>
          <div className='col-2'> </div>
          <Modal.Title className='col-8'>
            <div className='text-center'>
              {defaultStore.hiddenRequestModalProps.title}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='align-self-center'>
          {defaultStore.hiddenRequestModalProps.text}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white'
            variant='secondary'
            onClick={
              defaultStore.hiddenRequestModalProps.buttonAction
                ? defaultStore.hiddenRequestModalProps.buttonAction
                : handleClose
            }
          >
            {defaultStore.hiddenRequestModalProps.buttonLabel}
          </Button>
          {defaultStore.hiddenRequestModalProps.secondButtonLabel ? (
            <Button
              className='text-white'
              variant='primary'
              onClick={
                defaultStore.hiddenRequestModalProps.secondButtonAction
                  ? defaultStore.hiddenRequestModalProps.secondButtonAction
                  : handleClose
              }
            >
              {defaultStore.hiddenRequestModalProps.secondButtonLabel}
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HiddenRequestModal;
