import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDebounce, useToggle } from 'react-use';

import Input from '../../Styleguide/Input/Input';
import { useStore } from '../../../hooks';
import IconComponent from '../../Svg/IconComponent/IconComponent';

/**
 * @param {object} props
 * @param {boolean} [props.disabled=false]
 * @returns {JSX.Element}
 */
export default function CouponForm(props) {
  const { disabled } = props;
  const store = useStore();

  /** @type {import('../utils').CheckoutValues} */
  const formik = useFormikContext();

  /** This will toggle loading when checking coupon */
  const [isChecking, toggleChecking] = useToggle(false);

  const checkCoupon = async () => {
    if (isChecking) return;
    toggleChecking(true);
    await store.checkoutStore.validateCoupon(formik.values.coupon?.code, store.checkoutStore.selectedPlan.third_party_id);
    toggleChecking(false);
  };

  useDebounce(()=> {
    if(store.checkoutStore.couponCheck === false) {
      store.checkoutStore.clearCouponCheck()
    }
  },2000, [isChecking])

  return (
    <Form>
      <Row className='align-items-end'>
        <Col xs='9'>
          <Input
            id='coupon.code'
            name='coupon.code'
            label='Insira o código do desconto:'
            disabled={disabled}
            value={formik.values.coupon?.code}
            onChange={formik.handleChange}
            isInvalid={store.checkoutStore.couponCheck === false}
            error='Cupom inválido' 
            className={clsx({
              'border border-success': store.checkoutStore.couponCheck === true && formik.values.coupon?.code !== '',
            })}
            maxLength={32}
          />
          <small className='text-success'> {store.checkoutStore.couponCheck? 'Cupom adicionado com sucesso': null }</small>
        </Col>
        <Col
          xs='auto'
          className='d-flex justify-content-center pb-4 '
        >
          <Button
            variant='primary text-white'
            disabled={disabled}
            onClick={checkCoupon}
          >
            {isChecking ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              >
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            ) : (
              <IconComponent group='standard' name='plus' size='16' />
            )}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs='12' />
      </Row>
    </Form>
  );
}

CouponForm.propTypes = {
  disabled: PropTypes.bool,
};

CouponForm.defaultProps = {
  disabled: false,
};
