export const EQUATION_TYPE = {
  face_type: 'Tipo de rosto (frontal)',
  proportion_face_front: 'Proporção facial (frontal)',
  proportion_lips_front: 'Proporção labial (frontal)',
  proportion_face_side: 'Proporção facial (perfil)',
  proportion_lips_side: 'Proporção labial (perfil)',
  nose_lips_angle: 'Ângulo nasolabial (perfil)',
  nose_frontal_angle: 'Ângulo nasofrontal (perfil)',
  jaw_angle: 'Ângulo mandibular (perfil)',
  profile_type: 'Tipo de perfil (perfil)',
  mouth_to_eyes: 'Comissura ocular x Largura da boca (frontal)',
  nose_to_eyes: 'Comissura ocular x Largura do nariz (frontal)',
  bottom_third_proportion_front: 'Proporção do terço inferior (frontal)',
  bottom_third_proportion_side: 'Proporção do terço inferior (perfil)',
  horizontal_proportion_third: 'Proporção do terço horizontal (frontal)',
  horizontal_proportion_fifth: 'Proporção do quinto horizontal (frontal)',
  vertical_vs_horizontal_facial: 'Largura x Altura facial (frontal)',
  glabella_facial_opening: 'Abertura facial glabela',
  chin_facial_opening: 'Abertura facial do queixo',
  eyebrow_angle: 'Angulação sobrancelha',
};

export const DOTS_GROUP = {
  face_type: ['Trichio', 'Pg', 'Zi_E', 'Zi_D'],
  proportion_face_front: ['Trichio', 'GL', 'Sn', 'Pg'],
  proportion_lips_front: ['Ls', 'St', 'Li'],
  proportion_face_side: ['Trichio', 'GL', 'Sn', 'Pg'],
  proportion_lips_side: ['Ls', 'St', 'Li'],
  mouth_to_eyes: ['IIE', 'ABE', 'ABD', 'IID'],
  nose_lips_angle: ['Cm', 'Sn', 'Ls'],
  nose_frontal_angle: ['GL', 'N', 'Pn'],
  jaw_angle: ['Go_D', 'Tragus_D', 'Me', 'Tragus_E', 'Go_E'],
  profile_type: ['GL', 'Sn', 'Pg'],
  nose_to_eyes: ['NADS', 'NAES', 'IOE', 'IOD'],
  bottom_third_proportion_front: ['Sn', 'St', 'Me'],
  bottom_third_proportion_side: ['Sn', 'St', 'Me'],
  horizontal_proportion_third: ['EXOE', 'IOE', 'IOD', 'EXOD'],
  horizontal_proportion_fifth: [
    'Tragus_E',
    'EXOE',
    'IOE',
    'IOD',
    'EXOD',
    'Tragus_D',
  ],
  vertical_vs_horizontal_facial: ['Trichio', 'Pg', 'Tragus_E', 'Tragus_D'],
  glabella_facial_opening: ['EXOE', 'Pg', 'GL', 'EXOD'],
  chin_facial_opening: ['EXOE', 'Pg', 'GL', 'EXOD'],
  eyebrow_angle: [
    'EBER',
    'EBSR',
    'EBIR',
    'NADS',
    'EBEL',
    'EBSL',
    'EBIL',
    'NAES',
  ],
};
