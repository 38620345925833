import * as Yup from 'yup';
import cnpjValidation from '../utils/cnpjValidation';
import isCPF from '../utils/cpfValidation';
import { STATE_OPTIONS, PEOPLE_TYPE_OPTIONS } from './SelectOptions';

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX =
	"[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

const ValidationMessages = {
	required: '* Campo obrigatório',
	email: '* E-mail inválido',
	min: ({ min }) => `* Mínimo de ${min} caracteres`,
	max: ({ max }) => `* Máximo de ${max} caracteres`,
	minNumber: ({ min }) => `* Valor mímino é ${min}`,
	passwordConfirm: '* Senhas não coincidem',
	cpf: '* CPF Inválido',
	birthdate: '* Formato de data: dia/mês/ano',
	phone: '* Formato de celular inválido',
	whatsapp: '* Formato de whatsapp inválido',
	zipCode: '* Insira um CEP válido',
	personType: '* Escolha uma opção válida',
	time: '* Formato de horário inválido',
	url: '* Url inválida. Ex.: https://meet.google.com/abc-def-ghi',
	cnpj: '* CNPJ Inválido',
	password: '* É necessário preencher a senha',
	passwordRegex:
		'Senha deve conter uma letra maiúscula, uma letra minúscula, um número e um simbolo.',
	rg: '* RG Inválido',
	price: '* O valor digitado deve ser númerico com 2 dígitos decimais',
	matches: '* Caractere(s) inválido(s)',
	cpfCnpj: '* CPF ou CNPJ inválido',
};

const FILE_SIZE = 1024 * 256 * 1000;

const SUPPORTED_FILE_TYPES = [
	'image/png',
	'image/jpeg',
	'image/jpg',
	'image/gif',
	'image/svg',
	'image/gif',
	'image/webp',
	'image/heic',
	'image/heif',
	'image/HEIC',
	'image/HEIF',
	'image/jfif',
];

const SUPPORTED_EXTENSIONS = [
	'png',
	'jpeg',
	'jpg',
	'gif',
	'svg',
	'gif',
	'webp',
	'heic',
	'heif',
	'HEIC',
	'HEIF',
	'jfif',
];

const ValidationRules = {
	file: Yup.mixed()
		.nullable()
		.notRequired()
		.test(
			'FILE_SIZE',
			'* O arquivo enviado é muito grande. Tamanho máximo: 256mb',
			(value) =>
				!value ||
				(value && value.size <= FILE_SIZE) ||
				typeof value === 'string',
		)
		.test(
			'FILE_FORMAT',
			'* O arquivo enviado não é tipo correto. Formatos aceitos: png, jpeg, gif, svg, gif, heic, heif, jfif e webp',
			(value) => {
				const stringTypeFile = value?.name?.slice(
          value?.name?.indexOf('.') + 1,
          value?.name?.length,
        );
	
				return (
					!value ||
					(value && SUPPORTED_FILE_TYPES.includes(String(value.type))) ||
					typeof value === 'string' ||
					(value && SUPPORTED_EXTENSIONS.includes(String(stringTypeFile)))
				);
			},
		),

	requiredString: Yup.string().required(ValidationMessages.required).nullable(),

	nullableCredential: Yup.string().max(9, ValidationMessages.max).nullable(),

	nullableString: Yup.string().nullable(),

	nullableEmail: Yup.string().email(ValidationMessages.email).nullable(),

	nullableCPF: Yup.string()
		.test('is-cpf', ValidationMessages.cpf, (value) => isCPF(value))
		.nullable(),

	nullableCNPJ: Yup.string()
		.test('is-cnpj', ValidationMessages.cnpj, (value) => cnpjValidation(value))
		.nullable(),

	nullableCpfCnpj: Yup.string()
		.test('is-cpf-cnpj', ValidationMessages.cpfCnpj, (value) => {
			if (value) {
				return value.length <= 14 ? isCPF(value) : cnpjValidation(value);
			}
			return true;
		})
		.nullable(),

	nullablePhone: Yup.string()
		.matches(
			/^([(][1-9]{2}[)] )([0-9.])?([0-9]{4})[-]([0-9]{4})$/,
			ValidationMessages.phone,
		)
		.nullable(),

	nullableWhatsapp: Yup.string().matches(
		/^([(][1-9]{2}[)] )([0-9.])?([0-9]{4})[-]([0-9]{4})$/,
		ValidationMessages.whatsapp,
	),

	nullableUrl: Yup.string().matches(
		// eslint-disable-next-line no-useless-escape
		/(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
		ValidationMessages.url,
	),

	numberRequired: Yup.number().required(ValidationMessages.required),

	name: Yup.string()
		.required(ValidationMessages.required)
		.matches(
			/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-,0-9]+$/g,
			'*Não são permitidos caracteres especiais.',
		),

	password: Yup.string()
		.min(8, ValidationMessages.min)
		.matches(
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/,
			ValidationMessages.passwordRegex,
		)
		.required(ValidationMessages.required),

	passwordNotRequired: Yup.string().min(8, ValidationMessages.min),

	cpf: Yup.string()
		.test('is-cpf', ValidationMessages.cpf, (value) => isCPF(value))
		.required(ValidationMessages.required),

	birthdate: Yup.string()
		// Basic regex for birthdates
		.matches(
			/[0-3]?[0-9]\/[0-1]?[0-9]\/(19|20)?[0-9]{2}$/,
			ValidationMessages.birthdate,
		)
		.required(ValidationMessages.required),

	rg: Yup.string()
		.nullable()
		.matches(/^[0-9.-]+$/, ValidationMessages.rg),

	phone: Yup.string()
		.matches(
			/^([(][1-9]{2}[)] )([0-9.])?([0-9]{4})[-]([0-9]{4})$/,
			ValidationMessages.phone,
		)
		.required(ValidationMessages.required),

	zipCode: Yup.string()
		.matches(/\d{2}\.\d{3}-\d{3}/, ValidationMessages.zipCode)
		.required(ValidationMessages.required)
		.nullable(),

	nullableZipCode: Yup.string()
		.matches(/\d{2}\.\d{3}-\d{3}/, ValidationMessages.zipCode)
		.nullable(),

	personType: Yup.string()
		.oneOf(
			PEOPLE_TYPE_OPTIONS.map((type) => type.value),
			ValidationMessages.personType,
		)
		.required(ValidationMessages.required)
		.nullable(),

	email: Yup.string()
		.email(ValidationMessages.email)
		.matches(new RegExp(EMAIL_REGEX, 'gi'), ValidationMessages.matches)
		.required(ValidationMessages.required),

	time: Yup.string()
		.matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, ValidationMessages.time)
		.required(ValidationMessages.required),

	url: Yup.string()
		.matches(
			// eslint-disable-next-line no-useless-escape
			/(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
			ValidationMessages.url,
		)
		.required(ValidationMessages.required),

	cnpj: Yup.string()
		.required(ValidationMessages.required)
		.test('is-cnpj', ValidationMessages.cnpj, (value) => cnpjValidation(value)),

	cpfCnpj: Yup.string()
		.test('is-cpf-cnpj', ValidationMessages.cpfCnpj, (value) => {
			if (value) {
				return value.length <= 14 ? isCPF(value) : cnpjValidation(value);
			}
			return true;
		})
		.required(ValidationMessages.required),

	street: Yup.string()
		.min(3, ValidationMessages.min)
		.required(ValidationMessages.required)
		.nullable(),

	number: Yup.number()
		.min(1, ValidationMessages.minNumber)
		.required(ValidationMessages.required)
		.nullable(),

	district: Yup.string()
		.min(3, ValidationMessages.min)
		.required(ValidationMessages.required)
		.nullable(),

	uf: Yup.string()
		.oneOf(STATE_OPTIONS.map((state) => state.value))
		.required(ValidationMessages.required)
		.nullable(),

	city: Yup.string().nullable().required(ValidationMessages.required),
};

export { ValidationMessages, ValidationRules };
