import React from 'react';
import { observer } from 'mobx-react';
import NavbarHeader from '../../Styleguide/NavbarHeaderMobile/NavbarHeaderMobile';
import { useStore } from '../../../hooks';
import { PHOTO_TRANSLATION } from '../../../constants/PhotoTypes';

const TimelapseNavBar = () => {
  const store = useStore();
  const { timelapseStore } = store;

  return (
    <NavbarHeader
      hasGoBack
      content='title'
      title='Time Lapse'
      subtitle={PHOTO_TRANSLATION[timelapseStore.imageTimelapse?.imageType]?.pt}
    />
  );
};

export default observer(TimelapseNavBar);
