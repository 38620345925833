import { Button } from "react-bootstrap";
import ModalComponent from "../../Styleguide/ModalComponent/ModalComponent"
import { useTutorialContext } from '../../../stores/contexts/TutorialContext';
import DontShowTutorialCheckbox from '../DontShowTutorialCheckbox/DontShowTutorialCheckbox'
import Carousel from "../Carousel/Carousel";
import IconComponent from "../../Svg/IconComponent/IconComponent";


const WelcomeTutorialModal = () => {

    const { isWelcomeTutorialOpened,
            setIsWelcomeTutorialOpened,
            welcomeTutorialInfo,
            contentTypes,
            cases,
            index,
            setIndex,
        } = useTutorialContext();

        const firstTutorial = index === 0;
        const lastTutorial = index === welcomeTutorialInfo.length - 1;
    
    return(
        <>
        <ModalComponent show = {isWelcomeTutorialOpened} title={welcomeTutorialInfo[index].title} noFooter size='lg' closeButton={false} bodyClassName= 'justify-content-center d-flex flex-column' titleClassName='text-center font-weight-bold w-100 fs-6 text-truncate'>           
            <Carousel
                array={welcomeTutorialInfo}
                index={index}
                cases={cases}
                changeIndex={setIndex}
                className='col-12 col-md-10'
            />
            <p className='p-3 fs-7 col-11 mx-auto my-1 text-center'>{welcomeTutorialInfo[index].description}</p>
            <div className="col-10 col-md-8 d-flex gap-3 justify-content-center align-self-center mb-3">
               {(!firstTutorial && !lastTutorial ) 
                    ? (<Button type='button' className = 'col-6  text-white bg-grey-header border-0' onClick={()=>{setIndex(index - 1)}}>
                            <IconComponent
                                group='standard'
                                name='chevronleft'
                                size='14'
                            />
                            {' '}Voltar
                        </Button>)
                    : null  
                }

                <Button type='button' className = {`${firstTutorial || lastTutorial ? 'col-8' : 'col-6'}  text-white`} onClick={()=>{
                    if(lastTutorial){
                        setIsWelcomeTutorialOpened(false)
                        setIndex(0)
                    }else{
                        setIndex(index + 1)
                    }
                }}>
                    {lastTutorial 
                        ? 'Começar a usar' 
                        : <>
                            Avançar{' '}
                            <IconComponent
                                group='standard'
                                name='chevronright'
                                size='14'
                            />
                        </>
                    }
                </Button>
            </div>
            <DontShowTutorialCheckbox videoId={1}/>
            <button type='button' className = 'reset-button mx-auto d-flex mt-2' onClick={()=>{setIsWelcomeTutorialOpened(false)}}><p className="fw-normal fs-7 text-grey-header">Pular tutorial</p></button>
        </ModalComponent>
        </>
    )
}

export default WelcomeTutorialModal;


