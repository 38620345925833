import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import useImage from 'use-image';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useStore } from '../../../../hooks';
import Facemesh from './Facemesh';
import { DOTS_GROUP } from '../../../../constants/EquationTypes';
import useAsync from '../../../../hooks/useAsync';
import DotModel from '../../../../stores/models/Dot';
import StandardLoader from '../../../Styleguide/StandardLoader/StandardLoader';
import refreshFileSignature from '../../../../utils/refreshFileSignature';

const initializer = {
  dots: '',
  lastWidth: 0,
  imageAspectRatio: 0,
  imageSize: {
    width: 0,
    height: 0,
  },
  analysis: [],
  isFetched: false,
  imageUrl: '',
};

const reducer = (state = initializer, action) => {
  switch (action.type) {
    case 'DOTS':
      return { ...state, dots: action.payload };
    case 'LAST_WIDTH':
      return {
        ...state,
        lastWidth: action.payload,
      };
    case 'IMAGE_ASPECT_RATIO':
      return {
        ...state,
        imageAspectRatio: action.payload,
      };
    case 'IMAGE_SIZE':
      return {
        ...state,
        imageSize: action.payload,
      };
    case 'ANALYSIS':
      return { ...state, analysis: action.payload };
    case 'IS_FETCHED':
      return {
        ...state,
        isFetched: action.payload,
      };
    default:
      return { ...state };
  }
};

const FacemeshMobx = forwardRef(
  (
    {
      image,
      patientId,
      show = true,
      width,
      height,
      showLines,
      disableDots,
      comparative,
    },
    ref,
  ) => {
    const store = useStore();
    const { status, run } = useAsync();
    const { dotsStore, analysisStore, galleryStore } = store;
    const stageRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, initializer);

    const handleRulers = async (guideLine) => {
      analysisStore.setGuideLines([...new Set([...guideLine])]);
      await store.analysisStore.saveAnalysis(
        patientId,
        image?.id,
        null,
        guideLine,
      );
    };

    const fillDots = (data) => {
      if (typeof data !== 'object' || (data && data.length === 0)) {
        return dispatch({ type: 'DOTS', payload: [] });
      }
      return dispatch({ type: 'DOTS', payload: data });
    };

    const fillAnalysis = (data) => {
      if (data == null) {
        return dispatch({ type: 'ANALYSIS', payload: [] });
      }
      if (typeof data === 'string') {
        const list = JSON.parse(data);
        return dispatch({ type: 'ANALYSIS', payload: list });
      }
      return dispatch({ type: 'ANALYSIS', payload: data });
    };

    useEffect(() => {
      dotsStore.setLastWidth(state.lastWidth);
      if (image?.analysis != null) {
        fillDots(image?.analysis?.dots);
        fillAnalysis(image?.analysis?.analysis);
      }
      dispatch({
        type: 'LAST_WIDTH',
        payload: image?.analysis?.lastWidth,
      });

      const imageWidth = image?.width;
      const imageHeight = image?.height;

      if (
        imageWidth != null &&
        +imageWidth > 0 &&
        imageHeight != null &&
        +imageHeight > 0
      ) {
        dispatch({
          type: 'IMAGE_ASPECT_RATIO',
          payload: +imageWidth / +imageHeight,
        });
      }

      dispatch({ type: 'IS_FETCHED', payload: true });
    }, []);

    useEffect(
      () => () => {
        dotsStore.setActiveCategory(null);
      },
      [],
    );

    const [newImage] = useImage(image?.media, 'anonymous');

    const refreshKonvaImage = () => {
      const refreshedImage = galleryStore.getImage(image?.id);

      return refreshedImage;
    };

    useEffect(async () => {
      await refreshFileSignature(image?.media, refreshKonvaImage);
    }, [newImage]);

    useEffect(() => {
      if (state?.imageAspectRatio > 0) {
        if (width <= height) {
          dispatch({
            type: 'IMAGE_SIZE',
            payload: {
              width,
              height: width / state.imageAspectRatio,
            },
          });
        } else {
          dispatch({
            type: 'IMAGE_SIZE',
            payload: {
              width: height / state.imageAspectRatio,
              height,
            },
          });
        }
      }
    }, [width, height, state?.imageAspectRatio]);

    useEffect(() => {
      dispatch({
        type: 'IS_FETCHED',
        payload: !store.dotsStore.isFetching,
      });
    }, [store.dotsStore.isFetching]);

    useEffect(() => {
      analysisStore.setFetchingStatus(status);
    }, [status]);

    useEffect(() => {
      if (dotsStore.lastWidth > 0 && comparative) {
        return false;
      }
      return store.dotsStore.setLastWidth(state.lastWidth);
    }, [state.lastWidth]);

    /**
     * Se a função de redefinir pontos for disparada, apagar pontos da imagem (modelo File) e passar hasDots para falso
     * Se a função de busca de pontos for disparada, persistir pontos da imagem (modelo File) e passar hasDots para verdadeiro
     */

    // SETAR PONTOS E ANÁLISES DA IMAGEM NO ESTADO DO FACEMESHMOBX
    useEffect(() => {
      if ((state.analysis || state.dots) && comparative) {
        return false;
      }
      dispatch({
        type: 'ANALYSIS',
        payload: galleryStore.image?.analysis?.analysis,
      });
      dispatch({
        type: 'DOTS',
        payload: galleryStore.image?.analysis?.dots,
      });
      return true;
    }, [
      // dotsStore.dots,
      galleryStore.image?.temPontos,
      galleryStore.image?.hasDots,
      galleryStore.image?.analysis?.dots,
      galleryStore.image?.analysis?.analysis,
    ]);

    useImperativeHandle(ref, () => ({
      export: () => exportAnalysis(),
      exportComparative,
    }));

    const exportComparative = () => {
      const imageExport = stageRef.current.toDataURL({
        mimeType: 'imageExport/png',
      });

      store.comparativeStore.setExportMap(image?.id, imageExport);
    };

    const exportAnalysis = async () => {
      store.analysisStore.toggleExporting(true);

      await refreshFileSignature(image.media, () =>
        store.galleryStore.getImage(image.id),
      );

      // caso a foto não possua pontos (análise), loop será nulo
      const loop = image?.analysis?.analysisList;

      const result = [];

      for (let i = 0; i < loop.length; i++) {
        store.analysisStore.setAnalysisActive(loop[i]);

        result.push({
          [`${loop[i]}`]: stageRef.current.toDataURL({
            mimeType: 'image/png',
          }),
        });
      }
      store.analysisStore.setAnalysisActive(null);

      const results = await store.analysisStore.exportAnalysis(
        { images: result },
        image?.id,
      );

      return results;
    };

    const handleActiveCategory = useCallback((id) => {
      dotsStore.setActiveCategory(id);
    }, []);

    /* Dot Actions */
    const createDot = (e) => {
      let newDots;
      let index;

      const stage = e.target.getStage();

      const oldScale = stage.scaleX();

      const dot = new DotModel({
        categoryId: dotsStore.activeDot,
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      });

      /**
       * State dots quando redefinimos os pontos é nulo
       * Quando criamos um ponto vai ter informações de um novo ponto
       * State dots continua nulo */

      if (state.dots != null) {
        index = state.dots.findIndex(
          (v) => v.categoryId === dotsStore.activeDot,
        );
      }

      dotsStore.dotListSetter(dot, dotsStore.dotsMap);
      if (index > -1) {
        newDots = [
          ...state.dots.slice(0, index),
          dot,
          ...state.dots.slice(index + 1),
        ];
        dispatch({ type: 'DOTS', payload: newDots });
      } else {
        if (state.dots) {
          newDots = [...state.dots, dot];
        } else {
          newDots = [dot];
        }
        dispatch({ type: 'DOTS', payload: newDots });
      }

      dotsStore.setActiveCategory(null);
      run(
        store.analysisStore.saveAnalysis(
          patientId,
          image?.id,
          newDots,
          analysisStore.guideLines,
        ),
      );
    };

    /**
     * @type {KonvaNodeEvents['onDragEnd']}
     */
    const onDragEnd = (e) => {
      createDot(e);
      dotsStore.setActiveCategory(null);
    };

    const filteredDots = useMemo(() => {
      if (
        !analysisStore.analysisActive ||
        !state.dots ||
        state.dots.length === 0
      ) {
        return [];
      }
      return DOTS_GROUP[analysisStore.analysisActive]
        .map((v) => state.dots.find((dot) => dot.categoryId === v))
        .filter((value) => value != null);
    }, [analysisStore.analysisActive, state?.dots]);

    if (newImage == null || !state?.isFetched) {
      return <StandardLoader />;
    }

    return (
      <Facemesh
        hasDots={galleryStore.image?.temPontos}
        ref={stageRef}
        showDots={show}
        width={width}
        height={height}
        disableDots={disableDots}
        showAnalysis={show}
        showLines={showLines}
        filteredDots={filteredDots}
        analysisActive={analysisStore.analysisActive}
        activeDot={dotsStore.activeDot}
        analysis={state?.analysis}
        dots={state?.dots}
        imageSize={state?.imageSize}
        setImageWidth={(_width) => dotsStore.setImageWidth(_width)}
        onDragEnd={onDragEnd}
        createDot={createDot}
        changeCategory={handleActiveCategory}
        newImage={newImage ? newImage : null}
        setDots={(val) => dispatch({ type: 'DOTS', payload: val })}
        setLastWidth={(_width) =>
          dispatch({ type: 'LAST_WIDTH', payload: _width })
        }
        lastWidth={state?.lastWidth}
        isFetched={state?.isFetched}
        aspectRatio={state?.imageAspectRatio}
        insertMode='dot'
        imageId={image?.id}
        isComparative={comparative}
        imageType={image.imageType}
        hasAnalysis={image.analysis ? image.analysis?.hasAnalysis : false}
        handleRulers={handleRulers}
        rulers={
          galleryStore.image?.analysis?.guideLines?.legnth > 0
            ? galleryStore.image?.analysis?.guideLines
            : image?.analysis?.guideLines
        }
      />
    );
  },
);

export default observer(FacemeshMobx);

FacemeshMobx.propTypes = {
  showLines: PropTypes.bool,
  show: PropTypes.bool,
  disableDots: PropTypes.bool,
  comparative: PropTypes.bool,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FacemeshMobx.defaultProps = {
  comparative: false,
  showLines: false,
  show: true,
  disableDots: false,
  image: null,
  patientId: null,
  width: null,
  height: null,
};
