import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-konva';

const Angle = ({ filteredDots, stroke, strokeWidth }) =>
  filteredDots.map((_, i) => {
    const thisX = filteredDots[i]?.x;
    const thisY = filteredDots[i]?.y;
    const nextX = filteredDots[i + 1]?.x;
    const nextY = filteredDots[i + 1]?.y;

    return (
      <Fragment key={thisX}>
        {i !== filteredDots.length - 1 ? (
          <Line
            strokeWidth={strokeWidth}
            stroke={stroke}
            points={[thisX, thisY, nextX, nextY]}
          />
        ) : null}
      </Fragment>
    );
  });
Angle.propTypes = {


  filteredDots: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

Angle.defaultProps = {
 
  filteredDots: [],
  stroke: 'red',
  strokeWidth: 1,
};

export default Angle;
