import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useMeasure, useWindowSize } from 'react-use';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

import {
	Button,
	Col,
	Container,
	Dropdown,
	Image,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import IconComponent from '../../Svg/IconComponent/IconComponent';
import ModalComponent from '../../Styleguide/ModalComponent/ModalComponent';
import { COLORS } from '../../../constants/Colors';
import { useStore } from '../../../hooks';
import { IMAGE_EXTENSIONS } from '../../../constants/ToolsConstants';
import EditImageModal from '../Modal/EditModal/EditImageModal';
import useFileReaderController from '../../FileReader/FileReaderController';
import createAnchorToDownloadFile from '../../../utils/createAnchorAndDownloadFile';
import ShareImageModal from '../Modal/ShareImageModal/ShareImageModal';
import MoveImageModal from '../Modal/MoveImageModal/MoveImageModal';

const ToggleDropdown = React.forwardRef(({ onClick, id }, ref) => (
	<>
		<div ref={ref}>
			<IconComponent
				className='f-drop-shadow'
				group='standard'
				name='dotsY'
				color={COLORS.white}
				onClick={(e) => {
					onClick(e);
				}}
				id={id}
			/>
		</div>
	</>
));

const FileBox = ({
	name,
	src,
	type,
	date,
	fileType,
	analysis,
	select,
	deleteImage,
	selectable,
	imgSelected,
	changeTypeImage,
	dots,
	disabledButton,
	id,
	handleSubmitEdit,
	fileBoxProps,
	imageId,
	patientId,
	shareable = true,
}) => {
	const store = useStore();
	const [ref, { width }] = useMeasure();
	const [editModalShow, setEditModalShow] = useState(false);
	const { width: windowWidth } = useWindowSize();
	const { handleSourceFile } = useFileReaderController();

	const openFile = (link) => window.open(link);

	const [deleteModalShow, setDeleteModalShow] = useState(false);
	const [shareModalShow, setShareModalShow] = useState(false);
	const [loadSrc, setLoadSrc] = useState(true);

	const [moveModalShow, setMoveModalShow] = useState(false)

	return (
		<>
			<Container
				className={clsx(
					'p-2 rounded rounded-1 d-flex flex-column border border-1 border-grey-image-box',
					disabledButton ? 'bg-grey-nav' : 'bg-white',
				)}
			>
				<Col ref={ref} className='w-100  d-flex flex-column '>
					{src ? (
						<>
							{IMAGE_EXTENSIONS.includes(fileType) ? (
								<>
									<div className='d-flex flex-column rounded w-100 border-0 position-relative bg-transparent'>
										{selectable ? (
											<>
												{imgSelected ? (
													<IconComponent
														className='position-absolute start-xs-85 top-xs-5 border-0 zi-1'
														group='standard'
														name='checkBox'
														size={14}
													/>
												) : (
													<IconComponent
														className='position-absolute start-xs-85 top-xs-5  zi-1'
														group='standard'
														name='checkBox'
														color='white'
														size={14}
													/>
												)}
											</>
										) : null}
										<Image
											className='d-none'
											src={src}
											onError={() => setLoadSrc(false)}
										/>
										{loadSrc ? (
											<button
												aria-label='image-button'
												type='button'
												style={{
													backgroundImage: `url(${src})`,
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													opacity: disabledButton ? 0.6 : 1,
													minHeight: `${width}px`,
													zIndex: '0',
												}}
												className='rounded p-0 border-0 position-relative bg-transparent'
												onClick={
													fileBoxProps?.handleClickFile
														? () => select()
														: async () => {
																store.galleryStore.setImage(imageId);
																await handleSourceFile(src, imageId);
																setTimeout(() => {
																	document
																		.getElementById(
																			'btn-show-file-reader-modal',
																		)
																		?.click();
																}, 100);
														  }
												}
												id={id}
											/>
										) : (
											<button
												type='button'
												onClick={
													fileBoxProps.handleClickFile
														? () => select()
														: () => {
																store.galleryStore.setImage(imageId);
																setTimeout(() => {
																	document
																		.getElementById(
																			'btn-show-file-reader-modal',
																		)
																		?.click();
																}, 100);
														  }
												}
												style={{
													minHeight: `${width}px`,
												}}
												className={`${
													fileBoxProps.handleClickFile ? '' : 'pe-none'
												} d-flex flex-column justify-content-center text-center zi-0 align-items-center bg-danger bg-opacity-10 rounded border-0 text-danger btn-outline-light w-100 h-100 fs-xs-7 fs-sm-6 fs-md-7 fs-lg-6`}
											>
												<IconComponent group='album' name='error' size={32} />
												Erro no carregamento
											</button>
										)}

										{dots && fileBoxProps.hasFileMenu ? (
											<Dropdown
												id={`dropdown-photolist-${id}`}
												className='position-absolute top-0 end-0 zi-2 px-1 '
												align='end'
											>
												<Dropdown.Toggle
													as={ToggleDropdown}
													id={`dropdown-photolist-toggle-${id}`}
												/>
												<Dropdown.Menu align='end' className='p-0'>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='1'
														onClick={() => select()}
													>
														Ver
													</Dropdown.Item>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='2'
														onClick={() => setEditModalShow(true)}
													>
														Editar arquivo
													</Dropdown.Item>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='6'
														onClick={() => setMoveModalShow(true)}
													>
														Mover arquivo
													</Dropdown.Item>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='3'
														onClick={() => changeTypeImage()}
													>
														Definir categoria
													</Dropdown.Item>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='4'
														onClick={() => {
															imgSelected();
															setDeleteModalShow(true);
														}}
													>
														Excluir Arquivo
													</Dropdown.Item>

													{shareable ? (
														<Dropdown.Item
															className='py-2'
															eventKey='5'
															onClick={() => {
																setShareModalShow(true);
															}}
														>
															Compartilhar
														</Dropdown.Item>
													) : null}
												</Dropdown.Menu>
											</Dropdown>
										) : dots ? (
											<Dropdown
												id={`dropdown-photolist-${id}`}
												className='position-absolute top-0 end-0 zi-2 px-1 '
												align='end'
											>
												<Dropdown.Toggle
													as={ToggleDropdown}
													id={`dropdown-photolist-toggle-${id}`}
												/>
												<Dropdown.Menu align='end' className='p-0'>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='2'
														onClick={() => setEditModalShow(true)}
													>
														Editar arquivo
													</Dropdown.Item>
													<Dropdown.Item
														className='border-bottom py-2'
														eventKey='3'
														onClick={() => {
															imgSelected();
															setDeleteModalShow(true);
														}}
													>
														Excluir Arquivo
													</Dropdown.Item>
													<Dropdown.Item
														className='py-2'
														eventKey='1'
														onClick={() =>
															createAnchorToDownloadFile(
																src,
																name,
																store.galleryStore.getImage(imageId),
															)
														}
													>
														Fazer download
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										) : null}
									</div>
									<Row
										className='d-flex justify-content-between  mt-2'
										style={{ minHeight: '20px' }}
									>
										<div
											className={clsx(
												'd-flex flex-row  align-items-center justify-content-between ',
												windowWidth > 1024 ? 'fs-7' : 'fs-8',
											)}
										>
											<span>{type}</span>
											<div className='d-flex align-items-center'>
												<span className='me-1'>
													{date != null && format(new Date(date), 'dd/MM/yy')}
												</span>
												{analysis ? (
													<IconComponent
														group='analysis'
														name='analysis'
														size={windowWidth > 1024 ? '16' : '12'}
														color={COLORS.dark}
													/>
												) : null}
											</div>
										</div>
									</Row>
								</>
							) : (
								<div className='d-flex flex-column justify-content-center position-relative align-items-center w-100 h-100'>
									<button
										type='button'
										onClick={
											fileBoxProps.handleClickFile
												? () => openFile(src)
												: () => {
														store.galleryStore.setImage(imageId);

														setTimeout(() => {
															document
																.getElementById('btn-show-file-reader-modal')
																?.click();
														}, 100);
												  }
										}
										style={{ minHeight: `${width}px` }}
										className='d-flex flex-column text-start justify-content-center  zi-0 align-items-center bg-light rounded border-0 text-dark btn-outline-light w-100 h-100'
									>
										<IconComponent group='album' name={fileType} />
										{fileType?.toUpperCase()}
									</button>
									<Dropdown
										id={`dropdown-imagebox-${id}`}
										className='position-absolute top-0 end-0 zi-2 p-2'
										align='end'
									>
										<Dropdown.Toggle
											as={ToggleDropdown}
											id={`dropdown-photolist-toggle-${id}`}
										/>
										<Dropdown.Menu align='end' className='p-0'>
											<Dropdown.Item
												className='border-bottom py-2'
												eventKey='1'
												onClick={() => setDeleteModalShow(true)}
											>
												Excluir arquivo
											</Dropdown.Item>
											<Dropdown.Item
												className='border-bottom  py-2'
												eventKey='2'
												onClick={() =>
													createAnchorToDownloadFile(
														src,
														name,
														store.galleryStore.getImage(imageId),
													)
												}
											>
												Fazer download
											</Dropdown.Item>
											{shareable ? (
												<Dropdown.Item
													className='py-2'
													eventKey='5'
													onClick={() => {
														setShareModalShow(true);
													}}
												>
													Compartilhar
												</Dropdown.Item>
											) : null}
										</Dropdown.Menu>
									</Dropdown>
									<Row
										style={{ minHeight: '30px' }}
										className='d-flex justify-content-between  w-100'
									>
										<Col className='w-100 text-start pointer-event'>
											<OverlayTrigger
												placement='top'
												delay={{ show: 250, hide: 300 }}
												overlay={
													<Tooltip id='button-tooltip' className='zi-9'>
														{name}
													</Tooltip>
												}
											>
												<span
													className='text-wrap'
													style={{ fontSize: '0.7rem' }}
												>
													{name?.substr(0, 8).concat('...')}
												</span>
											</OverlayTrigger>
										</Col>
									</Row>
								</div>
							)}

							{/* modal excluir arquivo */}
							<ModalComponent
								isDelete
								disabled={store.galleryStore.isFetching}
								show={deleteModalShow}
								onClose={() => setDeleteModalShow(false)}
								title={`Excluir arquivo ${name} ?`}
								subTitle={`Você tem certeza que deseja excluir  ${name}? Essa ação não poderá ser desfeita.`}
								nameButton='Excluir'
								idButton='delete-file'
								onClickButton={deleteImage}
							/>
						</>
					) : (
						<Button
							onClick={() => {
								select();
								store.defaultStore.resetTransferData();
							}}
							style={{ minHeight: `${width + 30}px` }}
							disabled={disabledButton}
							className={clsx(
								disabledButton ? 'bg-grey-nav' : 'bg-transparent',
								' flex-column justify-content-center align-items-center  border-0 text-dark btn-outline-light w-100 d-none d-sm-flex h-100 p-0',
							)}
							variant='dark'
						>
							<div className='d-flex flex-column justify-content-center rounded rounded-1 align-items-center w-100 flex-fill'>
								<IconComponent
									group='standard'
									name='filePlus'
									size={windowWidth > 1024 ? '120' : '60'}
									color={COLORS.greyPlaceholder}
								/>
								<span style={{ fontSize: '0.8rem' }}>
									Adicionar fotos e arquivos{' '}
								</span>
							</div>
						</Button>
					)}
				</Col>
				<EditImageModal
					handleSubmitEdit={handleSubmitEdit}
					setEditModalShow={setEditModalShow}
					editModalShow={editModalShow}
					date={date}
					gallery={store.galleryStore}
					name={name}
				/>

				<ShareImageModal
					showModal={shareModalShow}
					onClose={() => setShareModalShow(false)}
					imageId={imageId}
				/>

				<MoveImageModal
					moveModalShow={moveModalShow}
					setMoveModalShow={setMoveModalShow}
					imageId={imageId}
					patientId={patientId}
				/>
			</Container>
		</>
	);
};
export default observer(FileBox);

FileBox.propTypes = {
	name: PropTypes.string,
	src: PropTypes.string,
	type: PropTypes.string,
	date: PropTypes.string,
	analysis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	select: PropTypes.func.isRequired,
	fileType: PropTypes.string,
	deleteImage: PropTypes.func,
	imgSelected: PropTypes.bool,
	selectable: PropTypes.bool,
	disabledButton: PropTypes.bool,
	changeTypeImage: PropTypes.func,
	dots: PropTypes.bool,
	handleSubmitEdit: PropTypes.func,
	id: PropTypes.string,
	fileBoxProps: PropTypes.object,
	imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	shareable: PropTypes.bool,
	patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FileBox.defaultProps = {
	name: '',
	src: '',
	type: '',
	date: '',
	fileType: '',
	analysis: null,
	disabledButton: false,
	deleteImage: () => null,
	selectable: false,
	imgSelected: false,
	changeTypeImage: () => null,
	handleSubmitEdit: () => null,
	dots: true,
	id: '',
	fileBoxProps: {},
	shareable: true,
	patientId: null,
};

ToggleDropdown.propTypes = {
	onClick: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
};
