
const DAY_OF_WEEK_DICTIONARY = {
    0: 'DOM',
    1: 'SEG',
    2: 'TER',
    3: 'QUA',
    4: 'QUI',
    5: 'SEX',
    6: 'SAB'   
}

const FULL_DAY_OF_WEEK_DICTIONARY = {
    0: 'Doming',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado'   
}

const MONTH_DICTIONARY = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

export {DAY_OF_WEEK_DICTIONARY, FULL_DAY_OF_WEEK_DICTIONARY, MONTH_DICTIONARY};