import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ analysis, hasLabel }) => (
  <div className='text-white d-flex flex-column justify-content-between p-2 '>
    {hasLabel && <span>Error:</span>}
    <span className='text-white'>{analysis?.error ?? analysis}</span>
  </div>
);

Error.propTypes = {
  hasLabel: PropTypes.bool,
  analysis: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

Error.defaultProps = {
  analysis: null,
  hasLabel: true,
};

export default Error;
