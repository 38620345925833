import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ModalComponent from '../../../Styleguide/ModalComponent/ModalComponent';

const EditModalNotSave = ({
  show,
  onClose,
  clickButton,
  undoButton,
  isLoading,
  disabled,
}) => (
  <ModalComponent
    isLoading={isLoading}
    disabled={disabled}
    onClose={onClose}
    show={show}
    title='Ajustes não salvos'
    onClickButton={() => clickButton()}
    nameButton='Salvar'
    idButton='save-adjusts'
    hasSecondButton
    isSecondDelete
    secondButtonAction={() => undoButton()}
    secondButtonLabel='Desfazer'
  >
    Você tem certeza que deseja desfazer os ajustes?
  </ModalComponent>
);

EditModalNotSave.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  clickButton: PropTypes.func,
  undoButton: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

EditModalNotSave.defaultProps = {
  show: false,
  onClose: () => {},
  clickButton: () => {},
  undoButton: () => {},
  isLoading: false,
  disabled: false,
};

export default observer(EditModalNotSave);
