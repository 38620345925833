
const Plus = ({
                      // eslint-disable-next-line react/prop-types
                      iconStyleProps: { size = '24', color = 'white', ...rest },
                    }) => (


  <svg
    width={size}
    height={size}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M7.53033 0.46967C7.38968 0.329018 7.19891 0.25 7 0.25C6.80109 0.25 6.61032 0.329018 6.46967 0.46967C6.32902 0.610322 6.25 0.801088 6.25 1V6.25H1C0.801088 6.25 0.610322 6.32902 0.46967 6.46967C0.329018 6.61032 0.25 6.80109 0.25 7C0.25 7.19891 0.329018 7.38968 0.46967 7.53033C0.610322 7.67098 0.801088 7.75 1 7.75H6.25V13C6.25 13.1989 6.32902 13.3897 6.46967 13.5303C6.61032 13.671 6.80109 13.75 7 13.75C7.19891 13.75 7.38968 13.671 7.53033 13.5303C7.67098 13.3897 7.75 13.1989 7.75 13V7.75H13C13.1989 7.75 13.3897 7.67098 13.5303 7.53033C13.671 7.38968 13.75 7.19891 13.75 7C13.75 6.80109 13.671 6.61032 13.5303 6.46967C13.3897 6.32902 13.1989 6.25 13 6.25H7.75V1C7.75 0.801088 7.67098 0.610322 7.53033 0.46967Z" fill={color}/>

  </svg>
);
export default Plus;
