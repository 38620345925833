import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
// import {getTodayDate} from '../../utils/dateHelper';
import { DAY_OF_WEEK_DICTIONARY } from '../../constants/CalendarDictionary';
import DayOfMonth from './DayOfMonth/DayOfMonth';
import DaySelector from './DaySelector/DaySelector';
import TodaySelector from './TodaySelector/TodaySelector';
import { useStore } from '../../hooks';

const CalendarTools = () => {
	const { calendarStore } = useStore();
	const today = new Date();
	const [calendarDate, setCalendarDate] = useState(today);
	const [date, setDate] = useState(today);
	const [resetRender, setResetRender] = useState(false);
	const dayOfWeek = DAY_OF_WEEK_DICTIONARY[date.getDay()];
	const dateOfMonth = date.getDate().toString().padStart(2, '0');

	useEffect(() => () => setDate(new Date()), []);

	useEffect(() => {
		calendarStore.setSelectedDateOnCalendar(date);
	}, [date]);

	const openCalendar = () => {
		calendarStore.setIsCalendarOpen();
	};

	const goToToday = () => {
		if (
			today.getDate() !== date.getDate() ||
			today.getMonth() !== calendarDate.getMonth()
		) {
			calendarStore.setCalendarDate(today);
			setCalendarDate(today);
			setDate(today);
			setResetRender(true);
			setTimeout(() => setResetRender(false), 10);
		}
	};

	const chooseADate = (chosenDate) => {
		setDate(chosenDate);
		calendarStore.setCalendarDate(chosenDate);
		calendarStore.setIsCalendarOpen();
	};

	return (
		<div className='container p-0 h-md-100'>
			<div className='row h-md-100 align-items-center m-0'>
				<div
					className='col-2 p-0 position-relative'
					style={{ minHeight: '70px' }}
				>
					<DayOfMonth dateOfMonth={dateOfMonth} dayOfWeek={dayOfWeek} />
				</div>
				<div
					className='col-8 col-lg-7 d-flex align-items-center p-0'
					style={{ minHeight: '70px' }}
				>
					<DaySelector
						month={date.getMonth()}
						handleClick={openCalendar}
					></DaySelector>
				</div>
				<div
					className='col-2 col-lg-3 p-0 position-relative'
					style={{ minHeight: '70px' }}
				>
					<TodaySelector handleClick={goToToday} />
				</div>
			</div>
			<div className='col-12 row m-0'>
				{!resetRender ? (
					<Calendar
						// eslint-disable-next-line
						onActiveStartDateChange={({ _, activeStartDate, value, view }) =>
							setCalendarDate(activeStartDate)
						}
						className={
							calendarStore.isCalendarOpen
								? 'd-block mt-3 border-0 w-100'
								: 'd-none '
						}
						onChange={chooseADate}
						value={date}
						activeStartDate={calendarDate}
					/>
				) : (
					<div
						className={
							!calendarStore.isCalendarOpen
								? 'd-block mt-3 border-0 w-100'
								: 'd-none '
						}
					></div>
				)}
			</div>
		</div>
	);
};

export default observer(CalendarTools);
