
import { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useStore } from '../../../../hooks';
import Input from "../../../Styleguide/Input/Input";
import ModalComponent from "../../../Styleguide/ModalComponent/ModalComponent";
import { ValidationRules } from '../../../../constants/Validations';



const ShareImageModal = ({showModal, onClose, imageId}) => {
    const store = useStore();
    const [email, setEmail] = useState(store.patientsStore?.patientSelected?.email)
    const [name, setName] = useState(store.patientsStore?.patientSelected?.name)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isNameInvalid, setIsNameInvalid] = useState(false)

    const validateName = async() => {
        const res = await ValidationRules.name.isValid(name);
        setIsNameInvalid(!res);
        return res
    }

    const validateEmail = async() => {
        const res = await ValidationRules.email.isValid(email);
        setIsEmailInvalid(!res);
        return res
    }

    return(
        <ModalComponent
            show ={showModal}
            onClose={onClose}
            title='Compartilhar'
            nameButton='Compartilhar'
            idButton='share-file'
            onClickButton={async()=>{
                if (await validateName() && await validateEmail()) {
                    const res = await store.galleryStore.shareImageInEmail(imageId, { email, name });
                    if (res.Status === 'Success') {
                        onClose()
                    }
                }            
            }}
            isLoading = {store.galleryStore.isFetching}
        >
            <Input
                value={name}
                name='name'
                id='name'
                label='Nome'
                onChange={(e) =>
                    setName(e.target.value)
                }
                isInvalid = {isNameInvalid}
                error = '* Nome inválido'
                onBlur={() => validateName()}
            />
            <Input
                value={email}
                name='email'
                id='email'
                label='E-mail'
                onChange={(e) =>
                    setEmail(e.target.value)
                }
                maxLength={255}
                isInvalid = {isEmailInvalid}
                error = '* E-mail inválido'
                onBlur={() => validateEmail()}
            />
        </ModalComponent>
)}

export default observer(ShareImageModal)
ShareImageModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    imageId: PropTypes.number.isRequired
};

