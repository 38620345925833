import PropTypes from 'prop-types';

const LoaderModal = ({ text, id }) => (
  <div
    id={id}
    style={{ zIndex: 9999999, background: 'rgba(0,0,0,0.5)' }}
    className='modal'
    role='dialog'
    data-backdrop='static'
    data-keyboard='false'
  >
    <div className='modal-dialog text-center p-5'>
      <div className='lds-roller '>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {text && <p className='text-white'>{text}</p>}
    </div>
  </div>
);

LoaderModal.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string
};

LoaderModal.defaultProps = {
  text: '',
  id: ''
};

export default LoaderModal;
