import { useFormikContext, withFormik } from 'formik';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useReducer } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useIsMobile, useStore } from '../../hooks';

import CouponForm from '../../components/UserProfile/Checkout/CouponForm';
import BillingForm from '../../components/UserProfile/Checkout/BillingForm';
import NextButton from '../../components/UserProfile/Checkout/NextButton';
import FormBlock from '../../components/UserProfile/Checkout/FormBlock';
import PaymentForm from '../../components/UserProfile/Checkout/PaymentForm';

import {
  createActions,
  initialState,
  mapPropsToValues,
  reducer,
  validationSchema,
} from './utils';

import currency from '../../utils/currency';
import { PAYMENT_TYPE_OPTIONS } from '../../constants/SelectOptions';
import { checkoutStore } from '../../stores/containers';
import ModalManager from '../../components/UserProfile/ModalCheckOut/ModalManager';

const PlanCheckoutScreen = () => {
  /** @type {import('./utils').CheckoutValues} */
  const store = useStore();
  const { planId } = useParams();
  const formik = useFormikContext();

  const isMobile = useIsMobile();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setActiveTab } = useMemo(() => createActions(dispatch), [dispatch]);

  const { activeTab } = state;

  const isBillingTabVisible = activeTab === 'billing';
  const isPaymentTabVisible = activeTab === 'payment';
  const isCouponTabVisible = activeTab === 'coupon';

  useEffect(() => {
    store.checkoutStore.getPlan(planId);
    initUser();
  }, []);

  const initUser = () => {
    formik.setValues({
      ...formik.values,
      billing: {
        personType: '',
        fullName: store.authStore.user?.name,
        ...store.authStore.professional,
        ...store.authStore.professional?.address,
        uf: store.authStore.professional?.address?.state,
      },
    });
  };

  const nextStep = () => {
    switch (activeTab) {
      case 'billing':
        setActiveTab('payment');
        break;

      case 'payment':
        setActiveTab('coupon');
        break;

      default:
        break;
    }
  };

  // eslint-disable-next-line react/prop-types
  const ResumeContainer = ({ children }) => {
    if (isMobile) {
      return (
        <div className='fixed-bottom'>
          <Card className='px-4 py-2' style={{ color: '#50565f' }}>
            {children}
          </Card>
        </div>
      );
    }

    return (
      <div
        className='d-flex justify-content-start py-2'
        style={{ color: '#50565f' }}
      >
        {children}
      </div>
    );
  };

  const planPrice = useMemo(() => {
    if (store.checkoutStore.couponBenefits?.discount) {
      if (store.checkoutStore.couponBenefits?.discountType === 'percentual') {
        return (
          store.checkoutStore.selectedPlan?.price -
          (store.checkoutStore.couponBenefits?.discount *
            store.checkoutStore.selectedPlan?.price) /
            100
        );
      }
      return (
        store.checkoutStore.selectedPlan?.price -
        store.checkoutStore.couponBenefits?.discount
      );
    }
    return store.checkoutStore.selectedPlan?.price;
  }, [
    store.checkoutStore.selectedPlan?.price,
    store.checkoutStore.couponBenefits?.discount,
  ]);

  const Resume = () => (
    <ResumeContainer>
      <Row>
        <Col xs='8' lg='12' md='8' sm='8' xl='12' xxl='12'>
          <Row>
            <Col xs='12' className='fs-7 fw-bold' style={{ color: '#c4c4c4' }}>
              Resumo da compra:
            </Col>

            <Col xs='12' className='fs-7'>
              Plano {store.checkoutStore.selectedPlan?.name} mensal
            </Col>

            {store.checkoutStore.couponCheck === true && (
              <Col xs='12' className='fs-7'>
                <span className='' style={{ color: '#FF0029' }}>
                  De <s>{currency(store.checkoutStore.selectedPlan?.price)}</s>{' '}
                </span>
              </Col>
            )}

            <Col xs='12'>
              <span className='me-1' style={{ fontWeight: 'bold' }}>
                {currency(planPrice)}
              </span>
              <span style={{ fontSize: 10 }}>
                mensal
                {!isMobile && ' (renovação automática)'}
              </span>
            </Col>
          </Row>
        </Col>

        <Col
          xs='4'
          md='12'
          className='d-flex mt-md-2 d-md-block justify-content-center align-items-center'
        >
          <div className='d-grid gap-2'>
            <NextButton
              id='btn-checkout'
              loading={formik.isSubmitting}
              title='Finalizar'
              disabled={
                ((store.checkoutStore.couponCheck === false ||
                  store.checkoutStore.couponCheck == null) &&
                  formik.values.coupon?.code !== '') ||
                formik.isSubmitting
              }
              type='submit'
              onClick={formik.handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </ResumeContainer>
  );

  return (
    <>
      <div
        className='row w-100 '
        style={{ paddingBottom: isMobile ? '5rem' : 0 }}
      >
        <div className='col-12 col-md-4'>
          <FormBlock
            title='Dados de faturamento'
            isActive={isBillingTabVisible}
            onClick={() => setActiveTab('billing')}
          >
            <BillingForm />

            {!isMobile && isBillingTabVisible && (
              <div className='d-grid gap-2'>
                <NextButton
                  disabled={!!formik.errors.billing}
                  onClick={nextStep}
                  size='md'
                />
              </div>
            )}
          </FormBlock>
        </div>

        <div className='col-12 col-md-4'>
          <FormBlock
            title='Método de pagamento'
            isActive={isPaymentTabVisible}
            onClick={() => setActiveTab('payment')}
          >
            <PaymentForm
              disabled={
                (isMobile && isBillingTabVisible) ||
                (!isMobile && isBillingTabVisible)
              }
            />

            {!isMobile && isPaymentTabVisible && (
              <div className='d-grid gap-2'>
                <NextButton
                  disabled={
                    !!formik.errors.payment &&
                    formik.values.payment.type === PAYMENT_TYPE_OPTIONS[0].value
                  }
                  onClick={nextStep}
                  size='md'
                />
              </div>
            )}
          </FormBlock>
        </div>

        <div className='col-12 col-md-4'>
          <FormBlock
            title='Cupom de desconto'
            isActive={isCouponTabVisible}
            onClick={() => setActiveTab('coupon')}
          >
            <CouponForm disabled={isBillingTabVisible || isPaymentTabVisible} />

            {!isMobile && <Resume />}
          </FormBlock>
        </div>
      </div>

      {isMobile && <Resume />}

      <ModalManager />
    </>
  );
};

export default withFormik({
  mapPropsToValues,
  handleSubmit: async (values, { setSubmitting }) => {
    setSubmitting(true);
    await checkoutStore.checkoutUpgrade(values);
    setSubmitting(false);
  },
  validationSchema,
})(observer(PlanCheckoutScreen));
