const CloseError = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '33', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 33 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M32.5 15.5C32.5 23.7553 25.3665 30.5 16.5 30.5C7.63354 30.5 0.5 23.7553 0.5 15.5C0.5 7.24472 7.63354 0.5 16.5 0.5C25.3665 0.5 32.5 7.24472 32.5 15.5Z'
      fill='#FF516D'
      stroke={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.781 9.21897C22.8508 9.28864 22.9062 9.3714 22.944 9.46252C22.9818 9.55363 23.0013 9.65132 23.0013 9.74997C23.0013 9.84862 22.9818 9.9463 22.944 10.0374C22.9062 10.1285 22.8508 10.2113 22.781 10.281L12.281 20.781C12.1401 20.9218 11.9491 21.0009 11.75 21.0009C11.5508 21.0009 11.3598 20.9218 11.219 20.781C11.0781 20.6401 10.999 20.4491 10.999 20.25C10.999 20.0508 11.0781 19.8598 11.219 19.719L21.719 9.21897C21.7886 9.14912 21.8714 9.09371 21.9625 9.0559C22.0536 9.01809 22.1513 8.99863 22.25 8.99863C22.3486 8.99863 22.4463 9.01809 22.5374 9.0559C22.6285 9.09371 22.7113 9.14912 22.781 9.21897Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.219 9.21897C11.1492 9.28864 11.0937 9.3714 11.0559 9.46252C11.0181 9.55363 10.9987 9.65132 10.9987 9.74997C10.9987 9.84862 11.0181 9.9463 11.0559 10.0374C11.0937 10.1285 11.1492 10.2113 11.219 10.281L21.719 20.781C21.8598 20.9218 22.0508 21.0009 22.25 21.0009C22.4492 21.0009 22.6402 20.9218 22.781 20.781C22.9218 20.6401 23.0009 20.4491 23.0009 20.25C23.0009 20.0508 22.9218 19.8598 22.781 19.719L12.281 9.21897C12.2113 9.14912 12.1286 9.09371 12.0374 9.0559C11.9463 9.01809 11.8486 8.99863 11.75 8.99863C11.6513 8.99863 11.5537 9.01809 11.4625 9.0559C11.3714 9.09371 11.2887 9.14912 11.219 9.21897Z'
      fill={color}
    />
  </svg>
);
export default CloseError;
