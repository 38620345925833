import { action, makeObservable, observable, override } from 'mobx';
import { toast } from 'react-toastify';
import Patient from '../models/Patient';
import baseAPI from '../../services/baseAPI';
import DefaultStore from './defaultStore';
import convertHeicToJpg from '../../utils/convertHeicToJpg';

class PatientsStore extends DefaultStore {
	patientsList = [];

	filterPatient = [];

	isSearch = false;

	isAvatarLoading = false;

	fetchingStatus = 'idle';

	containerPatientButtonsHeight = 0;

	/** @type {Patient} */
	patientSelected = null;

	constructor() {
		super();
		makeObservable(this, {
			isFetching: override,
			setIsFetching: override,
			isLoadingPromise: override,
			patientsList: observable,
			currentPage: override,
			lastPage: override,
			filterPatient: observable,
			isSearch: observable,
			fetchingStatus: observable,
			resetPatientList: action,
			isAvatarLoading: observable,
			setIsAvatarLoading: action,
			/** @type {Patient} */
			patientSelected: observable,
			containerPatientButtonsHeight: observable,
			setContainerPatientButtonsHeight: action
		});
	}

	get patientId() {
		return this.patientSelected?.id;
	}

	findPatient = (id) => this.patientsList.find((p) => p.id === id);

	setPatientClear() {
		this.patientSelected = null;
	}

	setIsAvatarLoading(status) {
		this.isAvatarLoading = status;
	}

	resetPatientList() {
		this.patientsList = [];
		this.currentPage = 0;
	}

	setFetchingStatus(status) {
		this.fetchingStatus = status;
	}

	// métodos síncronos
	setPatientSelected = (selected) => {
		this.patientSelected = this.findPatient(selected);
	};

	setPatient(patient) {
		this.patient = patient;
	}

	setContainerPatientButtonsHeight(containerPatientButtonsHeight) {
		this.containerPatientButtonsHeight = containerPatientButtonsHeight;
	}

	// métodos assíncronos
	getPatientsById(id) {
		return new Promise(async (resolve, reject) => {
			if (!id) {
				return null;
			}
			try {
				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/patient/${id}`),
				);

				if (res.data !== null) {
					this.patientSelected = new Patient(res.data);
					this.setIsFetching(false);
					return resolve(res);
				}

				this.setIsFetching(false);
				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);
				return reject(error);
			}
		});
	}

	getPatientsByPage(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.get(
						`/v1/analysis/patient?clinic_group_id[eq]=${userId}&page=${this.currentPage + 1}`,
						),
					);
						
				const { meta } = res.data;
				this.currentPage = meta.current_page;
				this.lastPage = meta.last_page;

				this.patientsList = [
					...this.patientsList,
					...res.data.data.map((v) => new Patient(v)),
				];

				this.setIsFetching(false);
				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				this.setIsFetching(false);
				return reject(error);
			}
		});
	}

	setPatientsList(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/patient?clinic_group_id[eq]=${userId}&page=${this.currentPage + 1}`),
				);

				const { meta } = res.data;
				this.currentPage = meta.current_page;
				this.lastPage = meta.last_page;
				this.patientsList = res.data.data.map((v) => new Patient(v));

				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}
				return reject(error);
			}
		});
	}

	searchPatients(keyword, userId) {
		return new Promise(async (resolve, reject) => {
			if (!keyword) {
				this.isSearch = false;
				return resolve();
			}

			try {
				const res = await this.isLoadingPromise(
					baseAPI.get(`/v1/analysis/patient?clinic_group_id[eq]=${userId}&name[ilk]=${keyword}&page=${this.currentPage}`),
				);

				if (!res.data.meta) {
					this.isSearch = false;
					return resolve(res);
				}

				const { meta } = res.data;
				this.currentPage = meta.current_page;
				this.lastPage = meta.last_page;

				if (res.data.data == null) {
					this.isSearch = false;
					return resolve(res);
				}

				this.isSearch = true;

				this.filterPatient = res.data.data.map((v) => new Patient(v));

				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);

				return reject(error);
			}
		});
	}

	patientRegister(address, patient, anamnese, userId) {
		return new Promise(async (resolve, reject) => {
			const form = new FormData();

			// eslint-disable-next-line
			patient.email = patient.email.toLowerCase();
			const patientData = {
				address: JSON.stringify(address),
				anamnese: JSON.stringify(anamnese),
				...patient,
				clinic_group_id: userId,
			};

			Object.keys(patientData).forEach((val) => {
				if (val === 'avatar') {
					form.append(val, convertHeicToJpg(patientData[val]));
				} else {
					form.append(val, patientData[val]);
				}
			});

			const jpgImg = await convertHeicToJpg(patientData.avatar);

			form.set('avatar', jpgImg);

			try {
				const res = await this.isLoadingPromise(
					baseAPI.post(`/v1/analysis/patient`, form, {
						headers: {
							'Content-Type': 'multipart/form-data',
							Accept: 'application/json',
						},
					}),
				);

				const responseStatus = res.data.Status === 'Success';

				if (responseStatus) {
					this.patientSelected = new Patient(res.data.data);
					this.patientsList.push(this.patientSelected);

					this.setIsFetching(false);

					toast.success('Paciente cadastrado com sucesso.');

					return resolve(responseStatus);
				}

				this.setIsFetching(false);

				return resolve(responseStatus);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);

				toast.error('Erro ao cadastrar paciente.');

				return reject(error);
			}
		});
	}

	editPatient(address, patient, anamnese, index) {
		return new Promise(async (resolve, reject) => {
			try {
				const form = new FormData();

				this.setFetchingStatus('pending');
				// eslint-disable-next-line
				patient.email = patient.email.toLowerCase();
				const patientData = {
					address: JSON.stringify(address),
					anamnese: JSON.stringify(anamnese),
					// see if is necessary to make use of json.stringify
					...patient,
				};

				Object.keys(patientData).forEach(async (val) => {
					form.append(val, patientData[val]);
				});

				form.append('_method', 'PUT');

				const jpgImg = await convertHeicToJpg(patientData.avatar);
			
				form.set('avatar', jpgImg);

				const res = await this.isLoadingPromise(
					baseAPI.post(`/v1/analysis/patient/${index}`, form, {
						headers: {
							'Content-Type': 'multipart/form-data',
							Accept: 'application/json',
						},
						timeout: 7200000,
					}),
				);

				this.setFetchingStatus('resolved');

				const findIndex = this.patientsList.findIndex(
					(v) => v.id === this.patientSelected.id,
				);

				if (findIndex > -1) {
					this.patientsList = [
						...this.patientsList.slice(0, findIndex),
						new Patient(res.data.data),
						...this.patientsList.slice(findIndex + 1),
					];
				}

				this.patientSelected = new Patient(res.data.data);

				this.setIsFetching(false);
				this.setIsAvatarLoading(false);
				return resolve(res);
			} catch (error) {
				this.setFetchingStatus('rejected');

				this.setIsFetching(false);

				return reject(error);
			}
		});
	}

	deletePatient(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.delete(`/v1/analysis/patient/${this.patientSelected.id}`),
				);

				toast(res.data.Message);

				this.setPatientsList(userId);

				this.setIsFetching(false);

				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);

				return reject(error);
			}
		});
	}

	resetPatientAvatar() {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this.isLoadingPromise(
					baseAPI.post(
						`/v1/analysis/patient/reset/image/${this.patientSelected.id}`,
					),
				);

				const imgAlreadyRemoved = res.status === 204;

				if (imgAlreadyRemoved) {
					toast('Imagem já removida');

					this.setIsFetching(false);

					return resolve(res);
				}

				toast(res.data.Message);

				this.patientSelected.fillFields(res.data.data);

				this.patientSelected.noCacheImage();

				this.setIsFetching(false);

				return resolve(res);
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.Message);
				}

				this.setIsFetching(false);

				return reject(error);
			}
		});
	}
}

export default new PatientsStore();
