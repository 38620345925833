const TextPaper = ({
	// eslint-disable-next-line react/prop-types
	iconStyleProps: { size = '24', color = '#F7F7F7', ...rest },
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.5 0.875H10.5C10.9641 0.875 11.4092 1.05937 11.7374 1.38756C12.0656 1.71575 12.25 2.16087 12.25 2.625V11.375C12.25 11.8391 12.0656 12.2842 11.7374 12.6124C11.4092 12.9406 10.9641 13.125 10.5 13.125H3.5C3.03587 13.125 2.59075 12.9406 2.26256 12.6124C1.93437 12.2842 1.75 11.8391 1.75 11.375V2.625C1.75 2.16087 1.93437 1.71575 2.26256 1.38756C2.59075 1.05937 3.03587 0.875 3.5 0.875V0.875ZM3.5 1.75C3.26794 1.75 3.04538 1.84219 2.88128 2.00628C2.71719 2.17038 2.625 2.39294 2.625 2.625V11.375C2.625 11.6071 2.71719 11.8296 2.88128 11.9937C3.04538 12.1578 3.26794 12.25 3.5 12.25H10.5C10.7321 12.25 10.9546 12.1578 11.1187 11.9937C11.2828 11.8296 11.375 11.6071 11.375 11.375V2.625C11.375 2.39294 11.2828 2.17038 11.1187 2.00628C10.9546 1.84219 10.7321 1.75 10.5 1.75H3.5Z'
			fill={color}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.9375 9.1875C3.9375 9.07147 3.98359 8.96019 4.06564 8.87814C4.14769 8.79609 4.25897 8.75 4.375 8.75H7C7.11603 8.75 7.22731 8.79609 7.30936 8.87814C7.39141 8.96019 7.4375 9.07147 7.4375 9.1875C7.4375 9.30353 7.39141 9.41481 7.30936 9.49686C7.22731 9.57891 7.11603 9.625 7 9.625H4.375C4.25897 9.625 4.14769 9.57891 4.06564 9.49686C3.98359 9.41481 3.9375 9.30353 3.9375 9.1875ZM3.9375 7.4375C3.9375 7.32147 3.98359 7.21019 4.06564 7.12814C4.14769 7.04609 4.25897 7 4.375 7H9.625C9.74103 7 9.85231 7.04609 9.93436 7.12814C10.0164 7.21019 10.0625 7.32147 10.0625 7.4375C10.0625 7.55353 10.0164 7.66481 9.93436 7.74686C9.85231 7.82891 9.74103 7.875 9.625 7.875H4.375C4.25897 7.875 4.14769 7.82891 4.06564 7.74686C3.98359 7.66481 3.9375 7.55353 3.9375 7.4375ZM3.9375 5.6875C3.9375 5.57147 3.98359 5.46019 4.06564 5.37814C4.14769 5.29609 4.25897 5.25 4.375 5.25H9.625C9.74103 5.25 9.85231 5.29609 9.93436 5.37814C10.0164 5.46019 10.0625 5.57147 10.0625 5.6875C10.0625 5.80353 10.0164 5.91481 9.93436 5.99686C9.85231 6.07891 9.74103 6.125 9.625 6.125H4.375C4.25897 6.125 4.14769 6.07891 4.06564 5.99686C3.98359 5.91481 3.9375 5.80353 3.9375 5.6875ZM3.9375 3.9375C3.9375 3.82147 3.98359 3.71019 4.06564 3.62814C4.14769 3.54609 4.25897 3.5 4.375 3.5H9.625C9.74103 3.5 9.85231 3.54609 9.93436 3.62814C10.0164 3.71019 10.0625 3.82147 10.0625 3.9375C10.0625 4.05353 10.0164 4.16481 9.93436 4.24686C9.85231 4.32891 9.74103 4.375 9.625 4.375H4.375C4.25897 4.375 4.14769 4.32891 4.06564 4.24686C3.98359 4.16481 3.9375 4.05353 3.9375 3.9375Z'
			fill={color}
		/>
	</svg>
);
export default TextPaper;
