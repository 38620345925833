import clsx from 'clsx';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { Col } from 'react-bootstrap';
import { useStore } from '../../../hooks';
import IconNavbar from '../IconNavbar/IconNavbar';
import SearchInput from '../SearchInput/SearchInput';
import PatientsImportLoader from '../../Loader/PatientsImportLoader';
// import Overlay from '../Overlay/Overlay';
// import { COLORS } from '../../../constants/Colors';

const NavbarHeader = ({
	hasTitle,
	content,
	title,
	hasBg = true,
	hasBottomBorder = true,
	bottomContent,
	centerContent,
	topContent,
	searchValue,
	placeholder,
	hasHelper,
	changeValue,
	children,
}) => {
	const store = useStore();
	const location = useLocation();

	const { id: patientId, imageId } = useParams();

	const middle = useMemo(() => {
		switch (content) {
			case 'nav':
				return (
					<Col className='d-flex flex-fill justify-content-center col-12 col-sm-8 align-items-center '>
						<div>
							<IconNavbar
								redirect={`/patients/${
									store.patientsStore.patientId || patientId
								}/profile`}
								nameButton='Dados do paciente'
								icon={{ group: 'standard', name: 'personfill' }}
								isActive={/(profile)/.test(location.pathname)}
								id='profile-navbar-person'
							/>
						</div>
						<div>
							<IconNavbar
								redirect={`/patients/${
									store.patientsStore.patientId || patientId
								}/library`}
								nameButton='Consultas'
								icon={{ group: 'standard', name: 'file' }}
								isActive={/(library)/.test(location.pathname)}
								id='profile-navbar-appointment'
							/>
						</div>

						<div>
							<IconNavbar
								redirect={`/patients/${
									store.patientsStore.patientId || patientId
								}/comparative`}
								nameButton='Ferramentas'
								icon={
									location.pathname ===
									`/patients/${
										store.patientsStore.patientId || patientId
									}/tools/${imageId}`
										? { group: 'standard', name: 'tools' }
										: { group: 'standard', name: 'squarehalf' }
								}
								isActive={/(comparative|tools|analysis|timelapse|album|photos)/.test(
									location.pathname,
								)}
								id='profile-navbar-comparative'
							/>
						</div>
					</Col>
				);
			case 'user':
				return (
					<div className='d-flex justify-content-center align-items-center  '>
						<IconNavbar
							redirect='/user/status'
							nameButton='Dados do plano'
							icon={{ group: 'standard', name: 'analyticProfile' }}
							isActive={/(status)/.test(location.pathname)}
						/>
						<IconNavbar
							redirect='/user/profile'
							nameButton='Dados do usuário'
							icon={{ group: 'standard', name: 'adminUser' }}
							isActive={/(profile)/.test(location.pathname)}
							id='btn-user-data'
						/>
						<IconNavbar
							redirect='/user/documents'
							nameButton='Meus documentos'
							icon={{ group: 'standard', name: 'folderOpen' }}
							isActive={/(documents)/.test(location.pathname)}
							id='btn-user-data'
						/>
					</div>
				);
			case 'search':
				return (
					<>
						<Col className="col-md-5 col-xl-4">
							<SearchInput
								value={searchValue}
								onChange={(e) => {
									changeValue(e.target.value);
								}}
								placeholder={placeholder}
								id='input-search-patient'
							/>
						</Col>
						{/* <PatientsImportLoader /> */}
					</>
				);

			case 'content':
				return (
					<Col className='d-flex flex-column text-center justify-content-center my-2 '>
						<small className=''>{topContent}</small>
						<strong className='fs-5'>{centerContent}</strong>
						{bottomContent}
					</Col>
				);
			default:
				return <Col />;
		}
	}, [content, title, searchValue, changeValue]);

	return (
		<div
			className={clsx(
				'd-flex w-100 justify-content-center align-items-center px-0',
				hasBottomBorder && 'border-bottom',
				hasBg && 'bg-sm-grey-nav',
			)}
			style={{ minHeight: '70px' }}
		>
			<div className='d-flex w-100 py-2 justify-content-between align-items-center'>
				{hasTitle ? (
					<Col className='d-flex col-sm-3 ps-3'>
						<div className='m-0 text-truncate' id='navbar-title'>
							{title}
						</div>
					</Col>
				) : (
					<Col />
				)}
				{children}
				{middle}
				<PatientsImportLoader />
			</div>
		</div>
	);
};

export default observer(NavbarHeader);

NavbarHeader.propTypes = {
	title: PropTypes.string,
	content: PropTypes.oneOf(['nav', 'title']),
	bottomContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	topContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	centerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	searchValue: PropTypes.string,
	placeholder: PropTypes.string,
	hasTitle: PropTypes.string,
	changeValue: PropTypes.func,
	hasHelper: PropTypes.bool,
	hasBg: PropTypes.bool,
	hasBottomBorder: PropTypes.bool,
	children: PropTypes.oneOf([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

NavbarHeader.defaultProps = {
	changeValue: () => {},
	content: '',
	title: '',
	bottomContent: '',
	centerContent: '',
	topContent: '',
	searchValue: '',
	placeholder: '',
	hasTitle: '',
	hasHelper: false,
	hasBg: true,
	hasBottomBorder: true,
	children: <></>,
};
