const SquareHalf = ({
  // eslint-disable-next-line react/prop-types
  iconStyleProps: { size = '24', color = 'white', ...rest },
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 15V1h6a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H8zm6 1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12z'
      fill={color}
    />
  </svg>
);
export default SquareHalf;
